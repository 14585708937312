import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import useDownloadReportForm from "../../hooks/components/form/report/useDownloadReportForm";

const Reports = () => {
  const {
    formik,
    statusOptions,
    billTypeOptions,
    customerList,
    serviceProviderList,
    billedOptions,
    weightVerifiedOptions,
    packageTypeOptions,
    isLoading,
  } = useDownloadReportForm({});
  const [shipperId, setShipperId] = useState(null);

  const [inputShipper, setInputShipper] = useState("");

  const [serviceProvider, setServiceProvider] = useState(null);
  const [inputServiceProvider, setInputServiceProvider] = useState("");

  const handleChange = (value) => {
    formik.setFieldValue("serviceProviderId", value);
  };

  const handleCustomerChange = (data) => {
    formik.setFieldValue("shipperId", parseInt(data?.id));
  };

  return isLoading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <Box form={formik} noValidate>
      <Grid container spacing={2} sx={{ p: 5 }}>
        <Grid item xs={12}>
          <Typography variant="h5" color="text.primary">
            Generate Report
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            margin="dense"
            select
            name="billType"
            label="billType"
            value={formik.values.billType}
            onChange={formik.handleChange}
            error={formik.touched.billType && Boolean(formik.errors.billType)}
            helperText={formik.touched.billType && formik.errors.billType}
            fullWidth
          >
            {billTypeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            margin="dense"
            select
            name="status"
            label="Status"
            value={formik.values.status}
            onChange={formik.handleChange}
            fullWidth
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            margin="dense"
            type="date"
            required
            InputLabelProps={{ shrink: true }}
            name="dateFrom"
            label="From"
            value={formik.values.dateFrom}
            InputProps={{ inputProps: { max: formik.values.dateTo } }}
            onChange={formik.handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            margin="dense"
            type="date"
            required
            InputLabelProps={{ shrink: true }}
            name="dateTo"
            label="To"
            value={formik.values.dateTo}
            InputProps={{ inputProps: { min: formik.values.dateFrom } }}
            onChange={formik.handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            value={shipperId}
            onChange={(_event, newValue) => {
              handleCustomerChange(newValue);
              setShipperId(newValue);
            }}
            inputValue={inputShipper}
            onInputChange={(_event, newInputValue) => {
              setInputShipper(newInputValue);
            }}
            options={customerList}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                size="small"
                margin="dense"
                error={
                  formik.touched.shipperId && Boolean(formik.errors.shipperId)
                }
                helperText={formik.touched.shipperId && formik.errors.shipperId}
                label="Shipper"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            value={serviceProvider}
            onChange={(_event, newValue) => {
              handleChange(newValue?.value);
              setServiceProvider(newValue);
            }}
            inputValue={inputServiceProvider}
            onInputChange={(_event, newInputValue) => {
              setInputServiceProvider(newInputValue);
            }}
            options={serviceProviderList}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                size="small"
                margin="dense"
                error={
                  formik.touched.serviceProviderId &&
                  Boolean(formik.errors.serviceProviderId)
                }
                helperText={
                  formik.touched.serviceProviderId &&
                  formik.errors.serviceProviderId
                }
                label="Service Provider"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            margin="dense"
            select
            name="billed"
            label="Billed"
            value={formik.values.billed}
            onChange={formik.handleChange}
            error={formik.touched.billed && Boolean(formik.errors.billed)}
            helperText={formik.touched.billed && formik.errors.billed}
            fullWidth
          >
            {billedOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            margin="dense"
            select
            name="weightVerified"
            label="Weight Verified"
            value={formik.values.weightVerified}
            onChange={formik.handleChange}
            error={
              formik.touched.weightVerified &&
              Boolean(formik.errors.weightVerified)
            }
            helperText={
              formik.touched.weightVerified && formik.errors.weightVerified
            }
            fullWidth
          >
            {weightVerifiedOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            margin="dense"
            select
            name="packageType"
            label="Package Type"
            value={formik.values.packageType}
            onChange={formik.handleChange}
            error={
              formik.touched.packageType && Boolean(formik.errors.packageType)
            }
            helperText={formik.touched.packageType && formik.errors.packageType}
            fullWidth
          >
            {packageTypeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            fullWidth
            onClick={formik.handleSubmit}
            variant="contained"
            sx={{ textTransform: "none" }}
          >
            Download Report
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Reports;
