import * as Yup from "yup";

const shipmentRequestSchema = Yup.object().shape({
  actualWeight: Yup.number()
    .typeError("Weight must be in Kg and cannot be empty")
    .required("Required"),
  city: Yup.string().required("Required"),
  consignee: Yup.string().required("Required"),
  contentType: Yup.string(),
  currency: Yup.string(),
  customClearanceProvider: Yup.string(),
  description: Yup.string(),
  destinationCountry: Yup.string().required("Required"),
  dimensionalWeight: Yup.number(),
  exchangeRate: Yup.string(),
  fullPickupAddress: Yup.string(),
  houseNumber: Yup.string().required("Required"),
  importExport: Yup.string().required("Required"),
  ironStrips: Yup.number(),
  numberOfBoxes: Yup.number()
    .typeError("No of Boxes must include numerical values and cannot be empty")
    .required("Required"),
  numberOfOversizeBoxes: Yup.number(),
  otherNotes: Yup.string(),
  packageType: Yup.string(),
  parcelValue: Yup.string(),
  paymentMethod: Yup.string(),
  pickupDateTime: Yup.string(),
  plasticStrips: Yup.number(),
  postalCode: Yup.string(),
  shipmentService: Yup.string(),
  street: Yup.string().required("Required"),
  totalDimension: Yup.number(),
});

export { shipmentRequestSchema };
