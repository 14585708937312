import { useQuery } from "react-query";
import { getCustomerInfoConfigs } from "../../../api/customer-info/customerinfo-api";

export const useGetCustomerInfoConfigs = () => {
  return useQuery(["getCustomerInfoConfigs"], () => getCustomerInfoConfigs(), {
    cacheTime: 12000,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};
