import { useFormik } from "formik";
import { useUpdateAdmin } from "../../../../api/admin/useAdmin";
import { adminValidationSchema } from "./adminValidationSchema";

const useEditAdminForm = ({ data, handleClose }) => {
  const { mutate } = useUpdateAdmin({});
  const formik = useFormik({
    initialValues: {
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      sys: data?.userRole === "SysAdmin",
      id: data?.id,
    },
    validationSchema: adminValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleUpdate(values);
    },
  });

  const handleUpdate = (values) => {
    values = {
      ...values,
      userRole: values?.sys ? "SysAdmin" : "Admin",
    };
    mutate(values, { onSuccess: () => handleClose() });
  };

  return { formik };
};

export default useEditAdminForm;
