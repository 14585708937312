import { useFormik } from "formik";
import {
  useCreateShipmentRequest,
  useGetServiceProviders,
} from "../../../../api/ship/useShip";
import { shipmentRequestSchema } from "./shipmentRequestValidationSchema";

const useShipmentRequestForm = () => {
  const { data: serviceProviderData } = useGetServiceProviders();

  const serviceProviderList =
    serviceProviderData &&
    serviceProviderData?.map((serviceProvider) => {
      return { value: serviceProvider?.id, label: serviceProvider?.name };
    });

  const { mutate } = useCreateShipmentRequest({});

  const formik = useFormik({
    initialValues: {
      importExport: "Export",
      shipmentService: "Courier",
      serviceProviderId: 0,
      customClearanceProvider: "NepalX",
      doorAirport: "AirportToAirport",
      pickupDateTime: "",

      consignee: "",
      destinationCountry: "",
      houseNumber: "",
      street: "",
      city: "",
      fullPickupAddress: "",
      postalCode: "",
      remoteArea: false,

      numberOfBoxes: null,
      numberOfOversizeBoxes: 0,
      ironStrips: 0,
      plasticStrips: 0,
      actualWeight: null,
      dimensionalWeight: 0,
      description: "",
      contentType: "",
      packageType: "",

      parcelValue: 0,
      currency: "",
      exchangeRate: "",
      paymentMethod: "BankTransfer",
      otherNotes: "",
    },
    validationSchema: shipmentRequestSchema,
    onSubmit: (values) => {
      handleRequest(values);
    },
  });

  const handleRequest = (values) => {
    values = {
      ...values,
    };
    mutate(values, formik, { onSuccess: () => formik.handleReset() });
  };

  return {
    formik,
    serviceProviderList,
  };
};

export default useShipmentRequestForm;
