import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import CustomerPaymentFormItem from "./items/CustomerPaymentFormItem";
import { useAddCustomerPaymentForm } from "../../../hooks/components/form/payments/useAddCustomerPaymentForm";

const AddCustomerPaymentForm = ({ open, handleClose }) => {
  const { formik } = useAddCustomerPaymentForm({
    handleClose,
  });
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>Add Payment</DialogTitle>
      <Divider />
      <DialogContent>
        <CustomerPaymentFormItem formik={formik} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCustomerPaymentForm;
