import * as Yup from "yup";

const changePasswordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Required"),
  newPassword: Yup.string()
    .required("Required")
    .min(6, "Password must be greater than 6 characters"),
  repeatNewPassword: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("newPassword"), null], "New Passwords must match"),
});

export { changePasswordValidationSchema };
