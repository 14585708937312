import * as Yup from "yup";

const shipmentProviderCountryValidationSchema = Yup.object().shape({
  country: Yup.string().required("Required"),
  currency: Yup.string(),
  packageType: Yup.string().required("Required"),
  rate: Yup.number().required("").min(0, "should be a positive number"),
  weight: Yup.number().required("").min(0, "Should be a positive number"),
  emergencySurcharge:Yup.number().min(0,"Should be a positive number"),
});

export { shipmentProviderCountryValidationSchema };
