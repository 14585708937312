import { Grid, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const RateModal = ({ open, handleClose, data }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 400,
    width: "100%",
    bgcolor: "#fff",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style}>
        <Grid container spacing={1}>
          <Grid item xs={12} sx={{ backgroundColor: "primary.main" }}>
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
              sx={{ textAlign: "center", m: "16px" }}
            >
              Estimated Rate
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={0}
              sx={{
                mt: 2,
                borderBottom: "1px solid black",
                pt: 2,
                pb: 2,
                mb: 2,
              }}
            >
              <Grid item xs={6}>
                <Typography id="keep-mounted-modal-description">
                  Service Provider
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography id="keep-mounted-modal-description">
                  : {data?.shipmentProvider?.label}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography id="keep-mounted-modal-description">
                  Country
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography id="keep-mounted-modal-description">
                  : {data?.country}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography id="keep-mounted-modal-description">
                  Package Type
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography id="keep-mounted-modal-description">
                  : {data?.packageType}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography id="keep-mounted-modal-description">
                  Service Type
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography id="keep-mounted-modal-description">
                  : {data?.shipmentServiceType}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography id="keep-mounted-modal-description">
                  Weight (In Kg)
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography id="keep-mounted-modal-description">
                  : {data?.weight}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
              sx={{ textAlign: "right" }}
            >
              Rate: Rs. {parseInt(data?.rate).toLocaleString("en-us")}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default RateModal;
