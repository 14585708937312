import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

import {
  calculateShipmentRate,
  getPublicServiceProviders,
  getServiceProviderRates,
  getShipmentProviderCountries,
} from "../../api/shipment_rates/shipment_rates_api";

export const useCalculateShipmentRate = ({ onSuccess }) => {
  return useMutation(
    ["calculate"],
    (formData) => calculateShipmentRate(formData),
    {
      onSuccess: (data, variables, context) => {
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};

export const useGetPublicServiceProviders = () => {
  return useQuery(
    ["getPublicServiceProviders"],
    () => getPublicServiceProviders(),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetServiceProviders = () => {
  return useQuery(["getServiceProviders"], () => getServiceProviderRates(), {
    cacheTime: 12000,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetShipmentProviderCountries = (id) => {
  return useQuery(
    ["getShipmentProviderCountries", id],
    () => getShipmentProviderCountries(id),
    {
      cacheTime: 12000,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );
};
