import { useNavigate, useParams } from "react-router-dom";
import { useGetMyBills } from "../../../api/ship/useShip";

export const useMyBillsDetail = () => {
  const { id } = useParams({});
  const { data, isLoading } = useGetMyBills(id);
  const navigate = useNavigate();

  function handleDoubleClickRow(rowData) {
    navigate(`/my-shipments/${rowData?.id}`);
  }
  return { data, isLoading, handleDoubleClickRow };
};
