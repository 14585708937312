import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import { useForgetPasswordForm } from "../../../hooks/components/forgetpassword/useForgetPasswordForm";

const ForgetPasswordForm = (props) => {
  const { onClose, open, ...other } = props;
  const { formik } = useForgetPasswordForm();
  const textFieldRef = React.useRef(null);
  const handleEntering = () => {
    if (textFieldRef.current != null) textFieldRef.current.focus();
  };

  const handleCancel = () => {
    onClose();
  };
  const handleOk = () => {
    formik.submitForm();
    onClose();
  };
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "50%", maxHeight: 435 } }}
      maxWidth="sm"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>Request O.T.P</DialogTitle>
      <DialogContent dividers>
        <TextField
          variant="outlined"
          ref={textFieldRef}
          name="username"
          label="Username"
          placeholder="Enter Username"
          value={formik.values.username}
          onChange={formik.handleChange}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
          fullWidth
          required
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ForgetPasswordForm;
