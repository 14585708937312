import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import NoCrashIcon from "@mui/icons-material/NoCrash";
import AddBoxIcon from "@mui/icons-material/AddBox";
import HailIcon from "@mui/icons-material/Hail";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DescriptionIcon from "@mui/icons-material/Description";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import PaymentsIcon from "@mui/icons-material/Payments";

export const adminmenu = [
  {
    icon: <DashboardIcon />,
    title: "Dashboard",
    to: "/admin/dashboard",
  },
  {
    icon: <GroupAddIcon />,
    title: "Users",
    to: "/admin/admin-list",

    items: [
      {
        icon: <SupervisorAccountIcon />,
        title: "Admins",
        to: "/admin/admin-list",
      },
      {
        icon: <AccountBoxIcon sx={{ ml: "-8px" }} />,
        title: "Customer",
        to: "/admin/customer",
      },
      {
        icon: <LocalPostOfficeIcon />,
        title: "Office Notices",
        to: "/admin/office-notices",
      },
      {
        icon: <ContactMailIcon />,
        title: "Customer Notices",
        to: "/admin/customer-notices",
      },
      {
        icon: <HailIcon />,
        title: "Pick Up",
        to: "/admin/pickup",
      },
    ],
  },

  {
    icon: <MiscellaneousServicesIcon sx={{ ml: "-8px" }} />,
    title: "Service Providers",
    to: "/admin/service-provider",
  },
  {
    icon: <HomeRepairServiceIcon />,
    title: "Shipments",
    items: [
      {
        icon: <AddBoxIcon sx={{ ml: "-8px" }} />,
        title: "Add Shipment",
        to: "/admin/add-shipment-lite",
      },
      {
        icon: <LocalShippingIcon sx={{ ml: "-8px" }} />,
        title: "Requests",
        to: "/admin/shipment-lite",
      },
      {
        icon: <NoCrashIcon sx={{ ml: "-8px" }} />,
        title: "Confirmed",
        to: "/admin/final-shipments",
      },
    ],
  },
  {
    icon: <PaymentsIcon sx={{ ml: "-8px" }} />,
    title: "Payment",
    to: "/admin/payments",
  },
  {
    icon: <AssessmentIcon />,
    title: "Reports",
    items: [
      {
        icon: <DescriptionIcon sx={{ ml: "-8px" }} />,
        title: "Bills",
        to: "/admin/bill",
      },
      {
        icon: <NoteAddIcon sx={{ ml: "-8px" }} />,
        title: "Generate",
        to: "/admin/reports",
      },
    ],
  },
];
