import React from "react";
import PaymentTable from "../../components/table/payment/PaymentTable";

const Payment = () => {
  return (
    <div>
      <PaymentTable />
    </div>
  );
};

export default Payment;
