import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const WarehouseAndDistribution = () => {
  return (
    <Grid container spacing={0} direction={{ xs: "column-reverse", md: "row" }}>
      <Grid item xs={12} md={6}>
        <img
          src="/assests/images/landing_page/warehousing.jpg"
          alt="aircargo"
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: { xs: "300px", md: "600px" },
          padding: "16px",
        }}
      >
        <Box sx={{ maxWidth: "600px" }}>
          <Typography sx={{ fontWeight: 800, fontSize: "30px" }}>
            Warehousing & Distribution
          </Typography>
          <Typography sx={{ fontSize: "14px" }}>
            Ecommerce logistics refers to the processes involved in storing and
            shipping inventory for an online store or marketplace, including
            inventory management and the picking, packing, and shipping of
            online orders.Ecommerce logistics starts with moving inventory from
            the manufacturer and lasts until it ends up at the end customer’s
            destination.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default WarehouseAndDistribution;
