import MaterialTable from "@material-table/core";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import SendBillEmail from "../../../components/form/billed_shipment/SendBillEmail";
import { useBilledShipmentDetail } from "../../../hooks/components/detail/shipment_bill/useBilledShipmentDetail";

const BilledShipmentDetail = () => {
  const { id } = useParams();
  const { data, isLoading, handleDoubleClickRow, handleDownload } =
    useBilledShipmentDetail({ id });
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return isLoading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper
            elevation={4}
            sx={{
              ml: { sm: 1, md: 2 },
              mr: { sm: 1, md: 2 },
              mt: 2,
              mb: 1,
              p: "16px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "24px",
                  }}
                >
                  Billing Details - {data?.billingSequence}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  sx={{ textTransform: "none" }}
                  variant="contained"
                  onClick={() => setOpen(true)}
                  fullWidth
                >
                  Send Email
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  sx={{ textTransform: "none" }}
                  variant="contained"
                  onClick={() => handleDownload()}
                  fullWidth
                >
                  Download
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2">Grand Total :</Typography>
                <Typography>{data?.grandTotal}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2">Vat Total :</Typography>
                <Typography>{data?.vatTotal}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="subtitle2">Freight Total :</Typography>
                <Typography>{data?.freightTotal}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="subtitle2">Tia Total :</Typography>
                <Typography>{data?.tiaTotal}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="subtitle2">Customs Total :</Typography>
                <Typography>{data?.customsTotal}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="subtitle2">Strip Total :</Typography>
                <Typography>{data?.stripTotal}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="subtitle2">Doc Total :</Typography>
                <Typography>{data?.docTotal}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="subtitle2">Others Total :</Typography>
                <Typography>{data?.otherTotal}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ ml: { sm: 1, md: 2 }, mr: { sm: 1, md: 2 }, overflowX: "auto" }}
        >
          <MaterialTable
            title="Shipment List"
            data={data?.shipmentList || []}
            columns={[
              { title: "AWB", field: "awb" },
              { title: "shipper", field: "shipper" },
              { title: "Freight", field: "freight" },
              { title: "Tia", field: "tia" },
              { title: "Customs", field: "custom" },
              { title: "Strip", field: "strip" },
              { title: "Doc", field: "doc" },
              { title: "Remote", field: "remote" },
              { title: "Others", field: "others" },
              { title: "Vat", field: "vat" },
              { title: "total", field: "totalCharges" },
            ]}
            options={{
              padding: "dense",
            }}
            onRowDoubleClick={(_event, rowData) =>
              handleDoubleClickRow(rowData)
            }
          />
        </Grid>
      </Grid>
      <SendBillEmail open={open} handleClose={handleClose} id={id} />
    </>
  );
};

export default BilledShipmentDetail;
