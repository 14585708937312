import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetBilledShipmentRequestLiteDetail } from "../../../../hooks/api/nepal_lite/useNepalLite";
import AddShipmentBillingForm from "../shipment_billing/AddShipmentBillingForm";

const UpdateShipmentBillingForm = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetBilledShipmentRequestLiteDetail(id);
  return isLoading ? (
    <div style={{ display: "flex", justifyContent: "center" }}>Loading ...</div>
  ) : (
    <Paper sx={{ padding: "16px", mb: "20px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <b style={{ fontSize: "16px" }}>Shipper : </b>
            <p
              style={{
                fontSize: "14px",
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
            >
              {data?.shipper},
            </p>
            <b style={{ fontSize: "16px" }}>AWB</b> :{" "}
            <p
              style={{
                fontSize: "14px",
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
            >
              {data?.awb},
            </p>
            <b style={{ fontSize: "16px" }}>Exchange rate</b> :{" "}
            <p
              style={{
                fontSize: "14px",
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
            >
              {data?.exchangeRate},
            </p>
            <b style={{ fontSize: "16px" }}>postal code</b> :{" "}
            <p
              style={{
                fontSize: "14px",
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
            >
              {data?.postalCode},
            </p>
            <b style={{ fontSize: "16px" }}>Description</b> :{" "}
            <p
              style={{
                fontSize: "14px",
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
            >
              {data?.descriptionOfGoods}
            </p>{" "}
            <br />
          </Typography>
          <Typography>
            <b style={{ fontSize: "16px" }}>{data?.service}</b> having{" "}
            <b style={{ fontSize: "16px" }}>{data?.appliedWeight}</b> kg weight
            of shipment type{" "}
            <b style={{ fontSize: "16px" }}>{data?.shipmentType}</b> by{" "}
            <b style={{ fontSize: "16px" }}>{data?.serviceProvider}</b> to
            destination <b style={{ fontSize: "16px" }}>{data?.destination}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <AddShipmentBillingForm id={id} data={data} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default UpdateShipmentBillingForm;
