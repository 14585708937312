import * as Yup from "yup";

const registerSchema = Yup.object().shape({
  companyName: Yup.string(),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  mobile: Yup.string().required("Required"),
  panNo: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
});

const businessRegisterSchema = Yup.object().shape({
  companyName: Yup.string().required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  mobile: Yup.string().required("Required"),
  panNo: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
});
export { registerSchema, businessRegisterSchema };
