import MaterialTable from "@material-table/core";
import React, { useState } from "react";
import { useShipmentLiteTable } from "../../../../hooks/components/table/nepal_lite/shipment/useShipmentLiteTable";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import { Chip, Grid, MenuItem, TextField } from "@mui/material";

const shipmentTypeOptions = [
  { label: "All", value: "All" },
  {
    label: "Commercial",
    value: "Commercial",
  },
  {
    label: "Non-Commercial",
    value: "NonCommercial",
  },
];

const paymentTypeOptions = [
  { label: "All", value: "All" },
  { label: "Credit", value: "Credit" },
  { label: "Cash", value: "Cash" },
];

const LiteShipmentTable = ({ handleId }) => {
  const [filter, setFilter] = useState({
    requestDate: "",
  });

  const {
    data,
    columns,
    isLoading,
    handleBulkConfirm,
    total,
    uiFilter,
    handleUiFilter,
  } = useShipmentLiteTable({
    filter,
  });

  const handleChange = (e) => {
    if (e.target.name === "status") {
      setFilter({
        ...filter,
        status: e.target.value,
      });
    } else {
      setFilter({
        ...filter,
        requestDate: e.target.value,
      });
    }
  };
  return (
    <Grid container spacing={2} sx={{ mb: 2 }} justifyContent="flex-end">
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          size="small"
          margin="dense"
          required
          select
          name="shipmentType"
          label="Shipment Type"
          value={uiFilter?.shipmentType}
          onChange={handleUiFilter}
          fullWidth
        >
          {shipmentTypeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          size="small"
          margin="dense"
          required
          select
          name="paymentType"
          label="Payment Type"
          value={uiFilter?.paymentType || ""}
          onChange={handleUiFilter}
          fullWidth
        >
          {paymentTypeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          size="small"
          margin="dense"
          type="date"
          InputLabelProps={{ shrink: true }}
          name="requestDate"
          label="Request Date"
          value={filter.requestDate}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          displayPrint: "flex",
          flexWrap: "wrap",
        }}
      >
        <Chip
          label={`Total No of Boxes: ${total?.totalNoOfBoxes}`}
          sx={{ m: 2 }}
        />
        <Chip
          label={`Total No of Oversize Boxes: ${total?.totalOversize}`}
          sx={{ m: 2 }}
        />
        <Chip
          label={`Total Applied Weight: ${total?.totalAppliedWeight}`}
          sx={{ m: 2 }}
        />
        <Chip
          label={`Actual Weight: ${total?.totalActualWeight}`}
          sx={{ m: 2 }}
        />
        <Chip
          label={`Dimensional Weight: ${total?.totalDimensionalWeight}`}
          sx={{ m: 2 }}
        />
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          columns={columns}
          data={data}
          title="Shipment Requests"
          isLoading={isLoading}
          onRowDoubleClick={(_event, rowData) => handleId(rowData?.id)}
          options={{
            pageSize: 100,
            pageSizeOptions:[100,200,500],
            padding: "dense",
            selection: true,
              emptyRowsWhenPaging:false,
          }}
          actions={[
            {
              tooltip: "Confirm All Selected Shipment Requests",
              icon: () => <LibraryAddCheckIcon />,
              onClick: (_evt, data) => {
                if (window.confirm(`You want to confirm ${data.length} rows`)) {
                  handleBulkConfirm(data);
                }
              },
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default LiteShipmentTable;
