import { Divider, Grid, Paper, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import DetailTypography from "../../../../app/component/detail_view/DetailTypography";

const MyShipmentTrack = ({ data }) => {
  return (
    <Paper sx={{ pb: 4, mt: 2 }} elevation={4}>
      <Grid container spacing={3} sx={{ ml: "16px", mr: "16px" }}>
        <Grid item xs={12}>
          <Typography variant="h5">Shipment Tracking</Typography>
          <Divider sx={{ mt: 2 }} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DetailTypography title="AWB" name={data?.awb} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DetailTypography
            title="Service Provider"
            name={data?.serviceProvider}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DetailTypography
            title="Created Date / Time"
            name={moment(data?.created).format("MMMM Do YYYY / HH:mm")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DetailTypography
            title="Estimated Delivary Date"
            name={data?.estimatedDeliveryDate}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DetailTypography
            title="Custom Clearance"
            name={data?.customClearance}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          <DetailTypography
            title="Weight Verified"
            name={data?.weightVerified}
            bool={true}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MyShipmentTrack;
