import { useFormik } from "formik";
import { useSuperRegister } from "../../api/superregister/useSuperRegister";
import { superRegisterSchema } from "./superRegisterValidationSchema";

export const useSuperRegisterForm = () => {
  const { mutate } = useSuperRegister({});
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    },
    validationSchema: superRegisterSchema,
    onSubmit: (values) => {
      handleRegister(values);
    },
  });

  const handleRegister = (values) => {
    mutate(values);
  };

  return {
    handleRegister,
    formik,
  };
};
