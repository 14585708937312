import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faChartGantt,
  faEnvelopeCircleCheck,
  faPlaneCircleExclamation,
  faTruckArrowRight,
  faTruckPlane,
  faTruckRampBox,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import DetailTypography from "../../../../app/component/detail_view/DetailTypography";

const MyShipmentDetail = ({ data }) => {
  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <FontAwesomeIcon icon={faPlaneCircleExclamation} size="lg" />
        <Typography sx={{ width: "50%", flexShrink: 0, ml: 3 }}>
          Shipment Details
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={4}>
            <DetailTypography
              icon={<FontAwesomeIcon icon={faCalendarAlt} size="lg" />}
              title="Request Date"
              name={data?.requestDate}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <DetailTypography
              title="Status"
              name={data?.status}
              icon={<FontAwesomeIcon icon={faChartGantt} size="lg" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DetailTypography
              icon={<FontAwesomeIcon icon={faTruckRampBox} size="lg" />}
              title="Import/Export"
              name={data?.importExport}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <DetailTypography
              icon={<FontAwesomeIcon icon={faTruckArrowRight} />}
              title="Shipment Service"
              name={data?.service}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <DetailTypography
              icon={<FontAwesomeIcon icon={faTruckPlane} size="lg" />}
              title="Shipment Type"
              name={data?.shipmentType}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DetailTypography
              icon={<FontAwesomeIcon icon={faEnvelopeCircleCheck} size="lg" />}
              title="Email Sent"
              bool={true}
              name={data?.emailSent}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default MyShipmentDetail;
