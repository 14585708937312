import { axiosInstance } from "../axiosInterceptor";

export const getUserDetails = async () => {
  const { data } = await axiosInstance.get("/v/user");
  return data;
};

export const updateUser = async (values) => {
  const { data } = await axiosInstance.put("/v/user", values);
  return data;
};

export const loadMyAvatarImageLogo = async () => {
  return axiosInstance.get(`/v/my-avatar`, { responseType: "blob" });
};

export const updateAvatarImage = async ({ image, setProgress }) => {
  const imgData = new FormData();
  imgData.append("avatar", image);

  const { data } = await axiosInstance.post(`/v/my-avatar`, imgData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let precentage = Math.floor((loaded * 100) / total);
      setProgress(precentage);
    },
  });
  return data;
};

export const updateUserPassword = async (values) => {
  const { data } = await axiosInstance.put(`/v/user/password`, values);
  return data;
};
