import { useFormik } from "formik";
import { useState } from "react";
import { useUpdateUserPassword } from "../../../../../../app/hooks/api/profile/useUser";
import { changePasswordValidationSchema } from "./changePasswordValidationSchema";

export const useChangePasswordForm = () => {
  const { mutate } = useUpdateUserPassword({});
  const [showValues, setShowValues] = useState({
    showPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  });
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      repeatNewPassword: "",
    },
    validationSchema: changePasswordValidationSchema,
    onSubmit: (values) => {
      handleChange(values);
    },
  });

  const handleChange = (values) => {
    mutate(values, { onSuccess: () => formik.handleReset() });
  };

  const handleClickShowPassword = () => {
    setShowValues({
      ...showValues,
      showPassword: !showValues.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setShowValues({
      ...showValues,
      showConfirmPassword: !showValues.showConfirmPassword,
    });
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowNewPassword = () => {
    setShowValues({
      ...showValues,
      showNewPassword: !showValues.showNewPassword,
    });
  };

  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  return {
    formik,
    showValues,
    handleClickShowPassword,
    handleMouseDownPassword,
    handleClickShowConfirmPassword,
    handleMouseDownConfirmPassword,
    handleClickShowNewPassword,
    handleMouseDownNewPassword,
  };
};
