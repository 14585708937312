import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../../../app/utils/cookieHelper";
import { useTempPassword } from "../../api/temppassword/useTempPassword";
import { tempPasswordSchema } from "./tempPasswordValidationScheme";

export const useTempPasswordForm = () => {
  const history = useNavigate();
  const { role } = getUser();
  const [showValues, setShowValues] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });
  const { mutate } = useTempPassword({});
  const formik = useFormik({
    initialValues: {
      password: "",
      repeatPassword: "",
    },
    validationSchema: tempPasswordSchema,
    onSubmit: (values) => {
      handleChange(values);
    },
  });

  const handleChange = (values) => {
    mutate(values);
    switch (role) {
      case "Neesum":
        return history.push(`/super/dashboard`);
      case "Merchant":
        return history.push(`/admin/dashboard`);
      case "User":
        return history.push(`/user/dashboard`);
      default:
        return <></>;
    }
  };

  const handleClickShowPassword = () => {
    setShowValues({
      ...showValues,
      showPassword: !showValues.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setShowValues({
      ...showValues,
      showConfirmPassword: !showValues.showConfirmPassword,
    });
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  return {
    formik,
    showValues,
    handleClickShowPassword,
    handleMouseDownPassword,
    handleClickShowConfirmPassword,
    handleMouseDownConfirmPassword,
  };
};
