import { useFormik } from "formik";
import { customerInfoConfigValidationSchema } from "./customerInfoConfigValidationSchema";
import { useAddCustomerInfoConfig } from "../../../api/customer-info/useCustomerInfo";

export const useAddCustomerInfo = ({ handleClose }) => {
  const { mutate } = useAddCustomerInfoConfig({});
  const formik = useFormik({
    initialValues: {
      infoKey: "",
      value: "",
    },
    validationSchema: customerInfoConfigValidationSchema,
    onSubmit: (values) => {
      handleAdd(values);
    },
  });

  const handleAdd = (values) => {
    mutate(values, {
      onSuccess: () => {
        handleClose();
        formik.handleReset();
      },
    });
  };

  return { formik };
};
