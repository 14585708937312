import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import React from "react";
import { useEditServiceProviderForm } from "../../../hooks/components/form/service_provider/useEditServiceProvider";
import ServiceProviderFormItem from "./items/ServiceProviderFormItem";

const EditServiceProviderForm = ({ open, handleClose, data }) => {
  const { formik } = useEditServiceProviderForm({ data, handleClose });
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>Edit Service Provider</DialogTitle>
      <Divider />
      <DialogContent>
        <ServiceProviderFormItem formik={formik} data={data} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditServiceProviderForm;
