import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import DetailTypography from "../../../../app/component/detail_view/DetailTypography";
import {
  faGlobe,
  faInbox,
  faLocationDot,
  faMapLocationDot,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";

const MyAddressDetail = ({ data }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <FontAwesomeIcon size="lg" icon={faMapLocationDot} />
        <Typography sx={{ width: "50%", flexShrink: 0, ml: 3 }}>
          Address Details
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={4}>
            <DetailTypography
              icon={<FontAwesomeIcon size="lg" icon={faUserAlt} />}
              title="Consignee"
              name={data?.consignee}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <DetailTypography
              icon={<FontAwesomeIcon size="lg" icon={faInbox} />}
              title="Postal Code"
              name={data?.postalCode}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <DetailTypography
              icon={<FontAwesomeIcon size="lg" icon={faGlobe} />}
              title="Country"
              name={data?.destination}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={4}>
            <DetailTypography
              icon={<FontAwesomeIcon size="lg" icon={faLocationDot} />}
              title="Remote"
              name={data?.remoteArea}
              bool={true}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default MyAddressDetail;
