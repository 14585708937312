import MaterialTable from "@material-table/core";
import React, { useState } from "react";
import { useServiceProviderTable } from "../../../hooks/components/table/service_provider/useServiceProviderTable";
import AddServiceProviderForm from "../../form/service_provider/AddServiceProviderForm";
import EditServiceProviderForm from "../../form/service_provider/EditServiceProviderForm";
import { EditOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";

const ServiceProviderTable = () => {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editData, setEditData] = useState();

  const handleEdit = (rowData) => {
    setEditData(rowData);
    setOpenEdit(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const { column, data, isLoading } = useServiceProviderTable();

  return (
    <>
      <MaterialTable
        title="Service Providers"
        columns={column}
        data={data}
        isLoading={isLoading}
        options={{
          pageSize: 10,
          padding: "dense",
          actionsColumnIndex: -1,
        }}
        actions={[
          (rowData) => ({
            icon: () => <EditOutlined />,
            tooltip: "Edit",
            onClick: () => {
              handleEdit(rowData);
            },
          }),
          {
            icon: () => <AddIcon />,
            tooltip: `Add Customer`,
            isFreeAction: true,
            onClick: () => handleOpen(),
          },
        ]}
      />
      <AddServiceProviderForm open={open} handleClose={handleClose} />

      <EditServiceProviderForm
        open={openEdit}
        handleClose={handleClose}
        data={editData}
      />
    </>
  );
};

export default ServiceProviderTable;
