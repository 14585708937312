import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import React from "react";
import { useEditExportRatesForm } from "../../../hooks/components/form/export_rates/useEditExportRatesForm";
import ServiceProviderRateFormItem from "../service_provider/rate/items/ServiceProviderRateFormItem";

const EditExportRatesForm = ({ open, handleClose, data, id }) => {
  const { formik } = useEditExportRatesForm({
    data,
    handleClose,
    id,
  });

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>Edit Export Rates</DialogTitle>
      <Divider />
      <DialogContent>
        <ServiceProviderRateFormItem formik={formik} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditExportRatesForm;
