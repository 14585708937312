import { Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";

const InvoiceDetails = ({
  currency,
  exchangeRate,
  invoiceValue,
  invoiceValueForCustom,
}) => {
  return (
    <Paper
      elevation={4}
      sx={{
        ml: { sm: 1, md: 2 },
        mr: { sm: 1, md: 2 },
        mt: 1,
        mb: 1,
        p: "16px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "24px",
            }}
          >
            Invoice Details
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">Currency :</Typography>
          <Typography>{currency}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">Exchange Rate :</Typography>
          <Typography>{exchangeRate}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">Invoice :</Typography>
          <Typography>{invoiceValue}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">
            Invoice value for custom :
          </Typography>
          <Typography>{invoiceValueForCustom}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default InvoiceDetails;
