import * as Yup from "yup";

const updateWeightValidationSchema = Yup.object().shape({
  dimensionalWeight: Yup.number()
    .min(0.1, "Should be greater than 0")
    .required("Required"),
  actualWeight: Yup.number()
    .min(0.1, "Should be greater than 0")
    .required("Required"),
});

export { updateWeightValidationSchema };
