import { useGetCustomerInfoConfigs } from "../../../../../app/hooks/api/customer-info/useCustomerInfo";

export const useNepalExinfoTable = () => {
  const { data, isLoading } = useGetCustomerInfoConfigs();
  const columns = [
    { title: "Info", field: "infoKey" },
    { title: "value", field: "value" },
  ];
  return { columns, data, isLoading };
};
