import { Grid } from "@mui/material";
import React from "react";
import AboutUs from "./parts/AboutUs";
import HeroContent from "./parts/HeroContent";
import Services from "./parts/Services";
import "./css/home.css";
import ChooseUs from "./parts/ChooseUs";
import ContactUs from "./parts/ContactUs";

const Home = () => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <HeroContent />
      </Grid>
      <Grid item xs={12}>
        <AboutUs />
      </Grid>
      <Grid item xs={12}>
        <Services />
      </Grid>
      <Grid item xs={12}>
        <ChooseUs />
      </Grid>
      <Grid item xs={12}>
        <ContactUs />
      </Grid>
    </Grid>
  );
};

export default Home;
