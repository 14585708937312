import {
    Autocomplete,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material"
import React, {useCallback, useEffect, useState} from "react"
import {useGetExchangeRate} from "../../../../../app/hooks/api/exchange_rate/useExchangeRate"
import {info} from "../../../../../app/utils/info"
import {useCheckDuplicateAwb} from "../../../../hooks/api/nepal_lite/useNepalLite"
import DimensionDialog from "./dialog/DimensionDialog"

const shipmentTypeOptions = [
    {
        label: "Commercial",
        value: "Commercial",
    },
    {
        label: "Non-Commercial",
        value: "NonCommercial",
    },
]
const importExportOptions = [
    {
        label:"Import",
        value:"Import",
    },
    {
        label:"Export",
        value:"Export",
    },
]
const customClearanceProvider = [
    {label: "Self", value: "Self"},
    {label: "NepalEx", value: "NepalEx"},
]

const packageTypeOptions = [
    {label: "-", value: ""},
    {label: "Document", value: "Document"},
    {label: "Non-Document", value: "NonDocument"},
]

const paymentTypeOptions = [
    {label: "Credit", value: "Credit"},
    {label: "Cash", value: "Cash"},
]

const serviceOptions = [
    {
        label: "Cargo",
        value: "Cargo",
    },
    {
        label: "Courier",
        value: "Courier",
    },
]

const statusOptions = [
    {label: "Delivered", value: "Delivered"},
    {label: "Exception", value: "Exception"},
    {label: "In Transit", value: "InTransit"},
    {label: "Pending", value: "Pending"},
    {label: "Return", value: "Return"},
]

function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export const AddShipmentFormLite = ({
                                        formik,
                                        serviceProviderList,
                                        customerList,
                                        statusEditable,
                                    }) => {
    const {data: exchangeRate} = useGetExchangeRate({
        date: formik.values.requestDate,
    })
    useEffect(() => {
        if (exchangeRate) {
            formik.setFieldValue("exchangeRate", parseFloat(exchangeRate?.sell))
        }
        //eslint-disable-next-line
    }, [exchangeRate])


    const {countries, currencies} = info()
    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    const {mutate} = useCheckDuplicateAwb({})

    const [duplicateValue, setDuplicateValue] = useState({
        awbChecked: false,
        isDuplicate: false,
    })

    const handleCheckDuplicateAWB = () => {
        mutate(formik.values.awb, {
            onSuccess: (data) => {
                setDuplicateValue({awbChecked: true, isDuplicate: data})
            },
        })
    }

    const [shipperId, setShipperId] = useState(
        customerList?.filter((customer) => {
            return customer?.id === formik.values.shipperId
        })[0] || null
    )

    const [inputShipper, setInputShipper] = useState(
        customerList?.filter((customer) => {
            return customer?.id === formik.values.shipperId
        })[0]?.label || ""
    )

    const checkShipperData = useCallback(() => {
        if (formik.values.shipperId !== shipperId?.id) {
            setShipperId(
                customerList?.filter((customer) => {
                    return customer?.id === formik.values.shipperId
                })[0] || null
            )
            setInputShipper(
                customerList?.filter((customer) => {
                    return customer?.id === formik.values.shipperId
                })[0]?.label || ""
            )
        }
        //eslint-disable-next-line
    }, [formik.values.shipperId])

    useEffect(() => {
        let isCancelled = false

        const handleChange = async () => {
            await timeout(500)
            if (!isCancelled) {
                checkShipperData()
            }
        }
        handleChange()
        return () => {
            isCancelled = true
        }
    }, [checkShipperData])
    const [service, setService] = useState(null)
    const [inputService, setInputService] = useState("")
    const [serviceProvider, setServiceProvider] = useState(
        serviceProviderList?.filter((serviceProvider) => {
            return serviceProvider?.value === formik.values.serviceProviderId
        })[0] || null
    )
    const [inputServiceProvider, setInputServiceProvider] = useState(
        serviceProviderList?.filter((serviceProvider) => {
            return serviceProvider?.value === formik.values.serviceProviderId
        })[0]?.label || ""
    )

    const checkServiceProviderData = useCallback(() => {
        if (formik.values.serviceProviderId !== serviceProvider?.value) {
            setServiceProvider(
                serviceProviderList?.filter((serviceProvider) => {
                    return serviceProvider?.value === formik.values.serviceProviderId
                })[0] || null
            )
            setInputServiceProvider(
                serviceProviderList?.filter((serviceProvider) => {
                    return serviceProvider?.value === formik.values.serviceProviderId
                })[0]?.label || ""
            )
        }
        //eslint-disable-next-line
    }, [formik.values.serviceProviderId])

    useEffect(() => {
        let isCancelled = false

        const handleChange = async () => {
            await timeout(500)
            if (!isCancelled) {
                checkServiceProviderData()
            }
        }
        handleChange()
        return () => {
            isCancelled = true
        }
    }, [checkServiceProviderData])

    const [destination, setDestination] = useState(
        countries?.filter((country) => {
            return (
                country?.value?.toUpperCase() ===
                formik.values.destination?.toUpperCase()
            )
        })[0] || null
    )

    const [inputDestination, setInputDestination] = useState(
        countries?.filter((country) => {
            return (
                country?.value?.toUpperCase() ===
                formik.values.destination?.toUpperCase()
            )
        })[0]?.label || ""
    )

    const checkDestinationData = useCallback(() => {
        if (
            formik.values.destination?.toUpperCase() !==
            destination?.value?.toUpperCase()
        ) {
            setDestination(
                countries?.filter((country) => {
                    return (
                        country?.value?.toUpperCase() ===
                        formik.values.destination?.toUpperCase()
                    )
                })[0] || null
            )
            setInputDestination(
                countries?.filter((country) => {
                    return (
                        country?.value?.toUpperCase() ===
                        formik.values.destination?.toUpperCase()
                    )
                })[0]?.label || ""
            )
        }
        //eslint-disable-next-line
    }, [formik.values.destination])

    useEffect(() => {
        let isCancelled = false

        const handleChange = async () => {
            await timeout(500)
            if (!isCancelled) {
                checkDestinationData()
            }
        }
        handleChange()
        return () => {
            isCancelled = true
        }
    }, [checkDestinationData])

    const handleDimensionalWeight = () => {
        const {length, bredth, height, numberOfBoxes, noOfBoxesToCalculate} =
            formik.values

        let totalDimensionalWeight = 0

        for (let i = 0; i < numberOfBoxes; i++) {
            const dimensionalWeight =
                (((length[i] || 0) * (bredth[i] || 0) * (height[i] || 0)) / 5000) *
                (noOfBoxesToCalculate[i] || 1)
            totalDimensionalWeight += dimensionalWeight
        }
        formik.setFieldValue("dimensionalWeight", totalDimensionalWeight)
    }

    const [currency, setCurrency] = useState(
        currencies?.filter((currency) => {
            return currency?.value === formik.values.currency?.toUpperCase()
        })[0] || null
    )

    const [inputCurrency, setInputCurrency] = useState(
        currencies?.filter((currency) => {
            return currency?.value === formik.values.currency?.toUpperCase()
        })[0]?.label || ""
    )

    const checkCurrencyData = useCallback(() => {
        if (formik.values.currency !== currency?.value) {
            setCurrency(
                currencies?.filter((currency) => {
                    return currency?.value === formik.values.currency?.toUpperCase()
                })[0] || null
            )
            setInputCurrency(
                currencies?.filter((currency) => {
                    return currency?.value === formik.values.currency?.toUpperCase()
                })[0]?.label || ""
            )
        }
        //eslint-disable-next-line
    }, [formik.values.currency])

    useEffect(() => {
        let isCancelled = false

        const handleChange = async () => {
            await timeout(500)
            if (!isCancelled) {
                checkCurrencyData()
            }
        }
        handleChange()
        return () => {
            isCancelled = true
        }
    }, [checkCurrencyData])

    const handleChange = (value) => {
        formik.setFieldValue("serviceProviderId", value)
    }

    const handleChangeService = (value) => {
        formik.setFieldValue("service", value)
    }
    const handleCustomerChange = (data) => {
        formik.setFieldValue("shipperId", parseInt(data?.id))
        formik.setFieldValue("email", data?.email)
    }

    const handleAutoComplete = useCallback(() => {
        if (
            formik.values.shipperId === null ||
            formik.values.shipperId === undefined
        ) {
            setShipperId(null)
            setInputShipper("")
        }
        if (
            formik.values.serviceProviderId === null ||
            formik.values.serviceProviderId === undefined
        ) {
            setServiceProvider(null)
            setInputServiceProvider("")
        }
        if (
            formik.values.destination === null ||
            formik.values.destination === undefined ||
            formik.values.destination === ""
        ) {
            setDestination(null)
            setInputDestination("")
        }
        if (
            formik.values.currency === undefined ||
            formik.values.currency === "USD"
        ) {
            setCurrency({value: "USD", label: "USD"})
            setInputCurrency("USD")
        }
        if (
            formik.values.service === undefined ||
            formik.values.service === "" ||
            formik.values.service === null
        ) {
            setService(null)
            setInputService("")
        }
    }, [
        formik.values.shipperId,
        formik.values.serviceProviderId,
        formik.values.destination,
        formik.values.currency,
        formik.values.service,
    ])

    const handleAppliedWeight = useCallback(
        () => {
            formik.setFieldValue(
                "appliedWeight",
                formik.values.actualWeight > formik.values.dimensionalWeight
                    ? formik.values.actualWeight
                    : formik.values.dimensionalWeight
            )
        },
        //eslint-disable-next-line
        [formik.values.actualWeight, formik.values.dimensionalWeight]
    )

    const [isFocused, setIsFocused] = useState({
        requestDate: false,
        exchangeRate: false,
        packageType: false,
        paymentType: false,
        awb: false,
        shipmentType: false,
        shipperId: false,
        consignee: false,
        email: false,
        destination: false,
        postal: false,
        service: false,
        serviceProviderId: false,
        box: false,
        oversize: false,
        dimension: false,
        actualWeight: false,
        dimensionalWeight: false,
        appliedWeight: false,
        noOfIronStrip: false,
        noOfPlasticStrip: false,
        descriptionOfGoods: false,
        invoiceValue: false,
        currency: false,
        invoiceValueForCustom: false,
        customClearance: false,
        remarks: false,
        importExport: false,
        shipperReference: false,
    })

    useEffect(() => {
        let isCancelled = false

        const handleChange = async () => {
            await timeout(200)
            if (!isCancelled) {
                handleAppliedWeight()
            }
        }
        handleChange()
        return () => {
            isCancelled = true
        }
    }, [handleAppliedWeight])

    useEffect(() => {
        let isCancelled = false

        const handleChange = async () => {
            await timeout(200)
            if (!isCancelled) {
                handleAutoComplete()
            }
        }
        handleChange()
        return () => {
            isCancelled = true
        }
    }, [handleAutoComplete])

    useEffect(() => {
        let isCancelled = false

        const handleChange = async () => {
            await timeout(700)
            if (!isCancelled) {
                localStorage.setItem(
                    "addShipmentRequest-nepal",
                    JSON.stringify(formik.values)
                )
            }
        }
        handleChange()
        return () => {
            isCancelled = true
        }
    }, [formik.values])

    return (
        <Grid container spacing={1} sx={{padding: 1}}>
            <Grid item xs={12} sm={6} md={3}>
                <TextField
                    size="small"
                    margin="dense"
                    fullWidth
                    name="requestDate"
                    label="Date"
                    type="date"
                    value={formik.values.requestDate}
                    onFocus={() => setIsFocused({...isFocused, requestDate: true})}
                    onChange={formik.handleChange}
                    inputProps={{
                        style: {
                            color: isFocused?.requestDate ? "green" : "text.primary",
                        },
                    }}
                    error={
                        formik.touched.requestDate && Boolean(formik.errors.requestDate)
                    }
                    helperText={formik.touched.requestDate && formik.errors.requestDate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <TextField
                    size="small"
                    margin="dense"
                    fullWidth
                    name="exchangeRate"
                    label="Exchange rate"
                    value={formik.values.exchangeRate}
                    onFocus={() => setIsFocused({...isFocused, exchangeRate: true})}
                    onChange={formik.handleChange}
                    inputProps={{
                        style: {
                            color: isFocused?.exchangeRate ? "green" : "text.primary",
                        },
                    }}
                    error={
                        formik.touched.exchangeRate && Boolean(formik.errors.exchangeRate)
                    }
                    helperText={formik.touched.exchangeRate && formik.errors.exchangeRate}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <TextField
                    size="small"
                    margin="dense"
                    required
                    select
                    name="packageType"
                    label="Package Type"
                    SelectProps={{
                        style: {
                            color: isFocused?.packageType ? "green" : "text.primary",
                        },
                        onFocus: () => setIsFocused({...isFocused, packageType: true}),
                    }}
                    value={formik.values.packageType || ""}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.packageType && Boolean(formik.errors.packageType)
                    }
                    helperText={formik.touched.packageType && formik.errors.packageType}
                    fullWidth
                >
                    {packageTypeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <TextField
                    size="small"
                    margin="dense"
                    required
                    select
                    name="paymentType"
                    label="Payment Type"
                    SelectProps={{
                        style: {
                            color: isFocused?.paymentType ? "green" : "text.primary",
                        },
                        onFocus: () => setIsFocused({...isFocused, paymentType: true}),
                    }}
                    value={formik.values.paymentType || "Credit"}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.paymentType && Boolean(formik.errors.paymentType)
                    }
                    helperText={formik.touched.paymentType && formik.errors.paymentType}
                    fullWidth
                >
                    {paymentTypeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} md={5}>
                <TextField
                    size="small"
                    margin="dense"
                    fullWidth
                    required
                    name="awb"
                    label="AWB"
                    value={formik.values.awb}
                    onFocus={() => setIsFocused({...isFocused, awb: true})}
                    inputProps={{
                        style: {
                            color: isFocused?.awb ? "green" : "text.primary",
                        },
                    }}
                    onChange={(e) => {
                        formik.setFieldValue("awb", e.target.value)
                        setDuplicateValue({awbChecked: false, isDuplicate: false})
                    }}
                    error={formik.touched.awb && Boolean(formik.errors.awb)}
                    helperText={formik.touched.awb && formik.errors.awb}
                />
            </Grid>
            <Grid item xs={12} md={2}>
                <Button
                    fullWidth
                    variant="contained"
                    sx={{textTransform: "none", mt: 1}}
                    disabled={formik.values.awb === ""}
                    onClick={handleCheckDuplicateAWB}
                >
                    Check Awb
                </Button>
            </Grid>
            <Grid item xs={12} md={5}>
                <TextField
                    size="small"
                    margin="dense"
                    required
                    select
                    name="shipmentType"
                    label="Shipment Type"
                    value={formik.values.shipmentType}
                    onChange={formik.handleChange}
                    SelectProps={{
                        style: {
                            color: isFocused?.shipmentType ? "green" : "text.primary",
                        },
                        onFocus: () => setIsFocused({...isFocused, shipmentType: true}),
                    }}
                    error={
                        formik.touched.shipmentType && Boolean(formik.errors.shipmentType)
                    }
                    helperText={formik.touched.shipmentType && formik.errors.shipmentType}
                    fullWidth
                >
                    {shipmentTypeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            {duplicateValue?.awbChecked && (
                <Grid item xs={12}>
                    {duplicateValue?.isDuplicate ? (
                        <Typography variant="subtitle1" sx={{color: "primary.dark"}}>
                            * Duplicate Awb detected
                        </Typography>
                    ) : (
                        <Typography variant="subtitle1" sx={{color: "green"}}>
                            * valid awb
                        </Typography>
                    )}
                </Grid>
            )}

            <Grid item xs={12} md={4}>
                <Autocomplete
                    value={shipperId}
                    onChange={(_event, newValue) => {
                        handleCustomerChange(newValue)
                        setShipperId(newValue)
                    }}
                    getOptionLabel={(option, i) => `${option.label} : ${option.email}`}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    inputValue={inputShipper}
                    onInputChange={(_event, newInputValue) => {
                        setInputShipper(newInputValue)
                    }}
                    options={customerList}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            size="small"
                            margin="dense"
                            onFocus={() => setIsFocused({...isFocused, shipperId: true})}
                            inputProps={{
                                ...params.inputProps,
                                style: {
                                    color: isFocused?.shipperId ? "green" : "text.primary",
                                },
                            }}
                            error={
                                formik.touched.shipperId && Boolean(formik.errors.shipperId)
                            }
                            helperText={formik.touched.shipperId && formik.errors.shipperId}
                            label="Shipper"
                        />
                    )}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <TextField
                    size="small"
                    margin="dense"
                    fullWidth
                    required
                    name="consignee"
                    label="Consignee"
                    value={formik.values.consignee}
                    onChange={formik.handleChange}
                    onFocus={() => setIsFocused({...isFocused, consignee: true})}
                    inputProps={{
                        style: {
                            color: isFocused?.consignee ? "green" : "text.primary",
                        },
                    }}
                    error={formik.touched.consignee && Boolean(formik.errors.consignee)}
                    helperText={formik.touched.consignee && formik.errors.consignee}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    size="small"
                    margin="dense"
                    required
                    select
                    name="importExport"
                    label="Import/Export"
                    value={formik.values.importExport}
                    onChange={formik.handleChange}
                    SelectProps={{
                        style: {
                            color: isFocused?.importExport ? "green" : "text.primary",
                        },
                        onFocus: () => setIsFocused({...isFocused, importExport: true}),
                    }}
                    error={
                        formik.touched.importExport && Boolean(formik.errors.importExport)
                    }
                    helperText={formik.touched.importExport && formik.errors.importExport}
                    fullWidth
                >
                    {importExportOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size="small"
                    margin="dense"
                    name="shipperReference"
                    label="Shipper Reference"
                    fullWidth
                    inputProps={{
                        style: {
                            color: isFocused?.shipperReference ? "green" : "text.primary",
                        },
                        onFocus: () => setIsFocused({...isFocused, shipperReference: true}),
                    }}
                    value={formik.values.shipperReference}
                    onChange={formik.handleChange}
                    error={formik.touched.shipperReference && Boolean(formik.errors.shipperReference)}
                    helperText={formik.touched.shipperReference && formik.errors.shipperReference}
                    multiline
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    size="small"
                    margin="dense"
                    fullWidth
                    required
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onFocus={() => setIsFocused({...isFocused, email: true})}
                    inputProps={{
                        style: {
                            color: isFocused?.email ? "green" : "text.primary",
                        },
                    }}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Autocomplete
                    value={destination}
                    onChange={(_event, newValue) => {
                        setDestination(newValue)
                        formik.setFieldValue("destination", newValue?.value)
                    }}
                    inputValue={inputDestination}
                    isOptionEqualToValue={(option, value) => {
                        return option.value === value.value
                    }}
                    onFocus={() => setIsFocused({...isFocused, destination: true})}
                    onInputChange={(_event, newInputValue) => {
                        setInputDestination(newInputValue)
                    }}
                    options={countries}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            size="small"
                            margin="dense"
                            inputProps={{
                                ...params.inputProps,
                                style: {
                                    color: isFocused?.destination ? "green" : "text.primary",
                                },
                            }}
                            error={
                                formik.touched.destination && Boolean(formik.errors.destination)
                            }
                            helperText={
                                formik.touched.destination && formik.errors.destination
                            }
                            label="Destination"
                        />
                    )}
                />
            </Grid>
            <Grid item xs={8} md={4}>
                <TextField
                    size="small"
                    margin="dense"
                    fullWidth
                    required
                    name="postalCode"
                    label="Postal"
                    onFocus={() => setIsFocused({...isFocused, postal: true})}
                    inputProps={{
                        style: {
                            color: isFocused?.postal ? "green" : "text.primary",
                        },
                    }}
                    value={formik.values.postalCode}
                    onChange={formik.handleChange}
                    error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                    helperText={formik.touched.postalCode && formik.errors.postalCode}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel
                    id="remoteArea"
                    name="remoteArea"
                    sx={{
                        color: "text.primary",
                    }}
                    value={formik.values.remoteArea}
                    onChange={formik.handleChange}
                    control={
                        <Checkbox
                            checked={formik.values.remoteArea}
                            onChange={formik.handleChange}
                        />
                    }
                    label="Remote Area"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Autocomplete
                    value={service}
                    onChange={(_event, newValue) => {
                        handleChangeService(newValue?.value)
                        setService(newValue)
                    }}
                    inputValue={inputService}
                    onFocus={() => setIsFocused({...isFocused, service: true})}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onInputChange={(_event, newInputValue) => {
                        setInputService(newInputValue)
                    }}
                    options={serviceOptions}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            size="small"
                            margin="dense"
                            error={formik.touched.service && Boolean(formik.errors.service)}
                            inputProps={{
                                ...params.inputProps,
                                style: {
                                    color: isFocused?.service ? "green" : "text.primary",
                                },
                            }}
                            helperText={formik.touched.service && formik.errors.service}
                            label="Service"
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Autocomplete
                    value={serviceProvider}
                    onChange={(_event, newValue) => {
                        handleChange(newValue?.value)
                        setServiceProvider(newValue)
                    }}
                    inputValue={inputServiceProvider}
                    onFocus={() =>
                        setIsFocused({...isFocused, serviceProviderId: true})
                    }
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onInputChange={(_event, newInputValue) => {
                        setInputServiceProvider(newInputValue)
                    }}
                    options={serviceProviderList}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            size="small"
                            margin="dense"
                            error={
                                formik.touched.serviceProviderId &&
                                Boolean(formik.errors.serviceProviderId)
                            }
                            inputProps={{
                                ...params.inputProps,
                                style: {
                                    color: isFocused?.serviceProviderId
                                        ? "green"
                                        : "text.primary",
                                },
                            }}
                            helperText={
                                formik.touched.serviceProviderId &&
                                formik.errors.serviceProviderId
                            }
                            label="Service Provider"
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            size="small"
                            margin="dense"
                            fullWidth
                            required
                            name="numberOfBoxes"
                            label="Box"
                            type="number"
                            InputProps={{
                                inputProps: {
                                    min: 1,
                                    style: {
                                        color: isFocused?.box ? "green" : "text.primary",
                                    },
                                    onFocus: () => setIsFocused({...isFocused, box: true}),
                                },
                            }}
                            value={formik.values.numberOfBoxes}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.numberOfBoxes &&
                                Boolean(formik.errors.numberOfBoxes)
                            }
                            helperText={
                                formik.touched.numberOfBoxes && formik.errors.numberOfBoxes
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        sx={{display: "flex", alignItems: "center"}}
                    >
                        <Button
                            variant="contained"
                            fullWidth
                            sx={{textTransform: "none", mt: 1}}
                            onClick={() => setOpen(true)}
                        >
                            Add dimensions
                        </Button>
                        <DimensionDialog
                            open={open}
                            handleClose={handleClose}
                            formik={formik}
                            handleDimensionalWeight={handleDimensionalWeight}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            size="small"
                            margin="dense"
                            fullWidth
                            required
                            name="numberOfOversizeBoxes"
                            label="No of Oversize Box"
                            type="number"
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                    style: {
                                        color: isFocused?.oversize ? "green" : "text.primary",
                                    },
                                    onFocus: () => setIsFocused({...isFocused, oversize: true}),
                                },
                            }}
                            value={formik.values.numberOfOversizeBoxes}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.numberOfOversizeBoxes &&
                                Boolean(formik.errors.numberOfOversizeBoxes)
                            }
                            helperText={
                                formik.touched.numberOfOversizeBoxes &&
                                formik.errors.numberOfOversizeBoxes
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={6} md={4}>
                <TextField
                    size="small"
                    margin="dense"
                    fullWidth
                    name="actualWeight"
                    label="Actual Weight"
                    InputProps={{
                        inputProps: {
                            min: 0,
                            style: {
                                color: isFocused?.actualWeight ? "green" : "text.primary",
                            },
                            onFocus: () => setIsFocused({...isFocused, actualWeight: true}),
                        },
                    }}
                    type="number"
                    value={formik.values.actualWeight}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.actualWeight && Boolean(formik.errors.actualWeight)
                    }
                    helperText={formik.touched.actualWeight && formik.errors.actualWeight}
                />
            </Grid>
            <Grid item xs={6} md={4}>
                <TextField
                    size="small"
                    margin="dense"
                    fullWidth
                    name="dimensionalWeight"
                    label="Dimensional Weight"
                    InputProps={{
                        inputProps: {
                            min: 0,
                            style: {
                                color: isFocused?.dimensionalWeight ? "green" : "text.primary",
                            },
                            onFocus: () =>
                                setIsFocused({...isFocused, dimensionalWeight: true}),
                        },
                    }}
                    type="number"
                    value={formik.values.dimensionalWeight}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.dimensionalWeight &&
                        Boolean(formik.errors.dimensionalWeight)
                    }
                    helperText={
                        formik.touched.dimensionalWeight && formik.errors.dimensionalWeight
                    }
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    size="small"
                    margin="dense"
                    fullWidth
                    name="appliedWeight"
                    label="Applied Weight"
                    InputProps={{
                        inputProps: {
                            min: 0,
                            style: {
                                color: isFocused?.appliedWeight ? "green" : "text.primary",
                            },
                            onFocus: () =>
                                setIsFocused({
                                    ...isFocused,
                                    appliedWeight: true,
                                }),
                        },
                    }}
                    type="number"
                    value={formik.values.appliedWeight}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.appliedWeight && Boolean(formik.errors.appliedWeight)
                    }
                    helperText={
                        formik.touched.appliedWeight && formik.errors.appliedWeight
                    }
                />
            </Grid>
            <Grid item xs={6} md={3}>
                <TextField
                    size="small"
                    margin="dense"
                    fullWidth
                    name="noOfIronStrip"
                    label="No of Iron Strip"
                    InputProps={{
                        inputProps: {
                            min: 0,
                            style: {
                                color: isFocused?.noOfIronStrip ? "green" : "text.primary",
                            },
                            onFocus: () =>
                                setIsFocused({...isFocused, noOfIronStrip: true}),
                        },
                    }}
                    type="number"
                    value={formik.values.noOfIronStrip}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.noOfIronStrip && Boolean(formik.errors.noOfIronStrip)
                    }
                    helperText={
                        formik.touched.noOfIronStrip && formik.errors.noOfIronStrip
                    }
                />
            </Grid>
            <Grid item xs={6} md={3}>
                <TextField
                    size="small"
                    margin="dense"
                    fullWidth
                    name="noOfPlasticStrip"
                    label="No of Plastic Strip"
                    type="number"
                    value={formik.values.noOfPlasticStrip}
                    InputProps={{
                        inputProps: {
                            min: 0,
                            style: {
                                color: isFocused?.noOfPlasticStrip ? "green" : "text.primary",
                            },
                            onFocus: () =>
                                setIsFocused({...isFocused, noOfPlasticStrip: true}),
                        },
                    }}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.noOfPlasticStrip &&
                        Boolean(formik.errors.noOfPlasticStrip)
                    }
                    helperText={
                        formik.touched.noOfPlasticStrip && formik.errors.noOfPlasticStrip
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size="small"
                    margin="dense"
                    name="descriptionOfGoods"
                    label="Description of Goods"
                    fullWidth
                    required
                    placeholder="(eg Document/List of the goods of shipments)"
                    value={formik.values.descriptionOfGoods}
                    inputProps={{
                        style: {
                            color: isFocused?.descriptionOfGoods ? "green" : "text.primary",
                        },
                        onFocus: () =>
                            setIsFocused({
                                ...isFocused,
                                descriptionOfGoods: true,
                            }),
                    }}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.descriptionOfGoods &&
                        Boolean(formik.errors.descriptionOfGoods)
                    }
                    helperText={
                        formik.touched.descriptionOfGoods &&
                        formik.errors.descriptionOfGoods
                    }
                    multiline
                />
            </Grid>
            <Grid item xs={6} md={3}>
                <TextField
                    size="small"
                    margin="dense"
                    fullWidth
                    required
                    name="invoiceValue"
                    label="Invoice Value"
                    InputProps={{
                        inputProps: {
                            min: 1,
                            style: {
                                color: isFocused?.invoiceValue ? "green" : "text.primary",
                            },
                            onFocus: () => setIsFocused({...isFocused, invoiceValue: true}),
                        },
                    }}
                    value={formik.values.invoiceValue}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.invoiceValue && Boolean(formik.errors.invoiceValue)
                    }
                    helperText={formik.touched.invoiceValue && formik.errors.invoiceValue}
                />
            </Grid>
            <Grid item xs={6} md={3}>
                <Autocomplete
                    value={currency}
                    onChange={(_event, newValue) => {
                        setCurrency(newValue)
                        formik.setFieldValue("currency", newValue?.value)
                    }}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    inputValue={inputCurrency}
                    onInputChange={(_event, newInputValue) => {
                        setInputCurrency(newInputValue)
                    }}
                    onFocus={() => setIsFocused({...isFocused, currency: true})}
                    options={currencies}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            required
                            margin="dense"
                            inputProps={{
                                ...params.inputProps,
                                style: {
                                    color: isFocused?.currency ? "green" : "text.primary",
                                },
                            }}
                            error={formik.touched.currency && Boolean(formik.errors.currency)}
                            helperText={formik.touched.currency && formik.errors.currency}
                            label="Currency"
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6} md={3}>
                <TextField
                    size="small"
                    margin="dense"
                    fullWidth
                    InputProps={{
                        inputProps: {
                            min: 1,
                            style: {
                                color: isFocused?.invoiceValueForCustom
                                    ? "green"
                                    : "text.primary",
                            },
                            onFocus: () =>
                                setIsFocused({...isFocused, invoiceValueForCustom: true}),
                        },
                    }}
                    type="number"
                    name="invoiceValueForCustom"
                    label="Invoice Value for Custom"
                    value={formik.values.invoiceValueForCustom}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.invoiceValueForCustom &&
                        Boolean(formik.errors.invoiceValueForCustom)
                    }
                    helperText={
                        formik.touched.invoiceValueForCustom &&
                        formik.errors.invoiceValueForCustom
                    }
                />
            </Grid>
            <Grid item xs={6} md={3}>
                <TextField
                    size="small"
                    margin="dense"
                    select
                    id="customClearance"
                    name="customClearance"
                    label="Custom Clearance"
                    value={formik.values.customClearance}
                    onChange={formik.handleChange}
                    SelectProps={{
                        style: {
                            color: isFocused?.customClearance ? "green" : "text.primary",
                        },
                        onFocus: () =>
                            setIsFocused({...isFocused, customClearance: true}),
                    }}
                    error={
                        formik.touched.customClearance &&
                        Boolean(formik.errors.customClearance)
                    }
                    helperText={
                        formik.touched.customClearance && formik.errors.customClearance
                    }
                    fullWidth
                >
                    {customClearanceProvider.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    size="small"
                    margin="dense"
                    required
                    select
                    disabled={!statusEditable}
                    name="status"
                    label="status"
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    error={formik.touched.status && Boolean(formik.errors.status)}
                    helperText={formik.touched.status && formik.errors.status}
                    fullWidth
                >
                    {statusOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size="small"
                    margin="dense"
                    name="remarks"
                    label="Remarks"
                    fullWidth
                    inputProps={{
                        style: {
                            color: isFocused?.remarks ? "green" : "text.primary",
                        },
                        onFocus: () => setIsFocused({...isFocused, remarks: true}),
                    }}
                    value={formik.values.remarks}
                    onChange={formik.handleChange}
                    error={formik.touched.remarks && Boolean(formik.errors.remarks)}
                    helperText={formik.touched.remarks && formik.errors.remarks}
                    multiline
                />
            </Grid>
        </Grid>
    )
}

export default AddShipmentFormLite
