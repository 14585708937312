import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetUsers } from "../../../../super/hooks/api/admin/useAdmin";
import UpdatePickupForm from "../../../components/form/pickup/UpdatePickupForm";
import { useGetPickupDetail } from "../../../hooks/api/pickup/usePickup";
import EventIcon from "@mui/icons-material/Event";

export const PickupDetail = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetPickupDetail(id);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const { data: adminData, isLoading: adminIsLoading } = useGetUsers();
  const adminList =
    !adminIsLoading &&
    adminData.map((admin) => {
      return {
        value: admin?.id,
        label: admin?.fullName,
      };
    });

  return isLoading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <Box sx={{ mt: 2, p: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={9} sx={{ display: "flex" }}>
          <Typography variant="h5" color="text.primary">
            Pick up
          </Typography>
          <Chip
            label={data?.status}
            variant="contained"
            color={
              data?.status === "Complete"
                ? "success"
                : data?.status === "Cancelled"
                ? "error"
                : "warning"
            }
            sx={{ p: 2, ml: 2 }}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            fullWidth
            sx={{ textTransform: "none" }}
            onClick={() => setOpen(true)}
          >
            Update
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ mt: 3, p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} sx={{ display: "flex" }}>
                <EventIcon sx={{ mr: 2 }} />
                <Typography>{moment(data?.dateTime).format("LLL")}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography>Created By: {data?.createdBy}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <Typography>Assigned To: {data?.adminName}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>CN Number: {data?.cnNumber}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Party Name: {data?.partyName}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Contact: {data?.phoneNumber}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Pickup Address: {data?.pickupAddress}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Vehicle: {data?.vehicle}</Typography>
              </Grid>
              <Grid item xs={12}>
                Remarks: {data?.remarks}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {!adminIsLoading && (
        <UpdatePickupForm
          data={data}
          open={open}
          handleClose={handleClose}
          adminList={adminList}
        />
      )}
    </Box>
  );
};
