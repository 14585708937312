import React from "react";
import MyShipmentTable from "../../components/ship/table/shipment/MyShipmentTable";

const MyShipments = () => {
  return (
    <div>
      <MyShipmentTable />
    </div>
  );
};

export default MyShipments;
