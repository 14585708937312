import { Grid, MenuItem, TextField } from "@mui/material";
import { SingleSelect } from "react-select-material-ui";


import React from "react";
import moment from "moment";

const importExportOptions = [
  { label: "Import", value: "Import" },
  { label: "Export", value: "Export" },
];
const shipmentServiceOptions = [
  { label: "Courier", value: "Courier" },
  { label: "Cargo", value: "Cargo" },
];
const shipmentTypeOptions = [
  {
    label: "Airport To Airport",
    value: "AirportToAirport",
  },
  {
    label: "Door to Door",
    value: "DoorToDoor",
  },
];
const customClearanceProvider = [
  { label: "Self", value: "Self" },
  { label: "NepalX", value: "NepalX" },
];

const ShipmentDetailsForm = ({ formik, serviceProviderList, customerList }) => {
  const handleChange = (value) => {
    formik.setFieldValue("serviceProviderId", value);
  };
  const handleCustomerChange = (value) => {
    formik.setFieldValue("customerId", value);
  };
  const handlePickUpDateTime = (value) => {
    formik.setFieldValue(
      "pickupDateTime",
      moment(value).format("YYYY-MM-DD HH:mm")
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <SingleSelect
          id="customerId"
          name="customerId"
          label="Customer"
          fullWidth
          SelectProps={{ isClearable: true }}
          options={customerList}
          value={formik.values.customerId}
          onChange={handleCustomerChange}
          error={formik.touched.customerId && Boolean(formik.errors.customerId)}
          helperText={formik.touched.customerId && formik.errors.customerId}
          autoComplete="customer"
          variant="standard"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          select
          id="importExport"
          name="importExport"
          label="Import / Export"
          value={formik.values.importExport}
          onChange={formik.handleChange}
          error={
            formik.touched.importExport && Boolean(formik.errors.importExport)
          }
          helperText={formik.touched.importExport && formik.errors.importExport}
          fullWidth
          autoComplete="import-export"
          variant="standard"
        >
          {importExportOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          select
          id="shipmentService"
          name="shipmentService"
          label="Shipment Service"
          value={formik.values.shipmentService}
          onChange={formik.handleChange}
          error={
            formik.touched.shipmentService &&
            Boolean(formik.errors.shipmentService)
          }
          helperText={
            formik.touched.shipmentService && formik.errors.shipmentService
          }
          fullWidth
          autoComplete="shipment-service"
          variant="standard"
        >
          {shipmentServiceOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          select
          id="doorAirport"
          name="doorAirport"
          label="Shipment Type"
          value={formik.values.doorAirport}
          onChange={formik.handleChange}
          error={
            formik.touched.doorAirport && Boolean(formik.errors.doorAirport)
          }
          helperText={formik.touched.doorAirport && formik.errors.doorAirport}
          fullWidth
          autoComplete="custom-clearance-provider"
          variant="standard"
        >
          {shipmentTypeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          select
          id="customClearanceProvider"
          name="customClearanceProvider"
          label="Custom Clearance Provider"
          value={formik.values.customClearanceProvider}
          onChange={formik.handleChange}
          error={
            formik.touched.customClearanceProvider &&
            Boolean(formik.errors.customClearanceProvider)
          }
          helperText={
            formik.touched.customClearanceProvider &&
            formik.errors.customClearanceProvider
          }
          fullWidth
          autoComplete="custom-clearance-provider"
          variant="standard"
        >
          {customClearanceProvider.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SingleSelect
          id="serviceProviderId"
          name="serviceProviderId"
          label="Service ProviderId"
          fullWidth
          SelectProps={{ isClearable: true }}
          options={serviceProviderList}
          value={formik.values.serviceProviderId}
          onChange={handleChange}
          error={
            formik.touched.serviceProviderId &&
            Boolean(formik.errors.serviceProviderId)
          }
          helperText={
            formik.touched.serviceProviderId && formik.errors.serviceProviderId
          }
          autoComplete="service-provider"
          variant="standard"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id="datetime-local"
          fullWidth
          name="pickupDateTime"
          variant="standard"
          label="Pick-Up Date/Time"
          type="datetime-local"
          onChange={(e) => handlePickUpDateTime(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ShipmentDetailsForm;
