import { useFormik } from "formik";
import { useSuspendCustomer } from "../../../api/customer/useCustomer";
import { suspendCustomerSchema } from "./suspendCustomerValidationSchema";

export const useSuspendCustomerForm = (id) => {
  const { mutate } = useSuspendCustomer({});
  const formik = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema: suspendCustomerSchema,
    onSubmit: (values) => {
      handleSuspend(values);
    },
  });

  const handleSuspend = (values) => {
    values = { ...values, id: id };
    mutate(values);
  };

  return {
    formik,
  };
};
