import { axiosInstance } from "../../../app/api/axiosInterceptor";

export const getServiceProviders = async (id) => {
  if (id) {
    const { data } = await axiosInstance.get(
      `/v/admin/service-providers?id=${id}`
    );
    return data;
  }
  const { data } = await axiosInstance.get("/v/admin/service-providers");
  return data;
};

export const createServiceProvider = async (values) => {
  const { data } = await axiosInstance.post(
    "/v/admin/service-provider",
    values
  );
  return data;
};

export const updateServiceProvider = async (values) => {
  const { data } = await axiosInstance.put("/v/admin/service-provider", values);
  return data;
};

export const getZone = async (id) => {
  const { data } = await axiosInstance.get(
    `/v/admin/zones?shipmentProviderId=${id}`
  );
  return data;
};

export const addZone = async (values) => {
  const { data } = await axiosInstance.post(`/v/admin/zone-country`, values);
  return data;
};

export const deleteZone = async ({ zone, id }) => {
  if (id) {
    const { data } = await axiosInstance.delete(
      `/v/admin/zone?shipmentProviderId=${id}&zone=${zone}`
    );
    return data;
  }
};

export const deleteShipmentProviderCountry = async (id) => {
  const { data } = await axiosInstance.delete(`/v/admin/zone-country?id=${id}`);
  return data;
};

export const importShipmentRateFromFile = async ({ file, id, zone }) => {
  const fileData = new FormData();

  fileData.append("file", file);
  const { data } = await axiosInstance.post(
    `/v/admin/shipment-sate/import-xls?shipmentProviderId=${id}&zone=${zone}`,
    fileData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout:0,
    }
  );
  return data;
};
