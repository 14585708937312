import DashboardIcon from "@mui/icons-material/Dashboard";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
// import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import HailIcon from "@mui/icons-material/Hail";
import PaymentsIcon from "@mui/icons-material/Payments";
import InfoIcon from "@mui/icons-material/Info";

export const customermenu = [
  {
    icon: <DashboardIcon />,
    title: "Dashboard",
    to: "/dashboard",
  },
  {
    icon: <LocalShippingIcon />,
    title: "Shipment",
    to: "/my-shipments",
  },
  // {
  //   icon: <AddShoppingCartIcon />,
  //   title: "Shipping Request",
  //   to: "/shipping-request",
  // },
  {
    icon: <PaymentsIcon />,
    title: "Payments",
    to: "/payments",
  },
  {
    icon: <HailIcon />,
    title: "Pick Up",
    to: "/my-pickups",
  },
  {
    icon: <InfoIcon />,
    title: "NepalEx Info",
    to: "/nepalex-info",
  },
];
