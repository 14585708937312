import { useCallback, useEffect, useState } from "react";
import {
  useConfirmShipmentRequestLite,
  useGetShipmentRequestLite,
} from "../../../../api/nepal_lite/useNepalLite";

export const useShipmentLiteTable = ({ filter }) => {
  const [uiFilter, setUiFilter] = useState({
    shipmentType: "All",
    paymentType: "All",
  });
  const [filteredData, setFilteredData] = useState();

  const handleUiFilter = (e) => {
    if (e.target.name === "shipmentType") {
      setUiFilter({
        ...uiFilter,
        shipmentType: e.target.value,
      });
    } else if (e.target.name === "paymentType") {
      setUiFilter({
        ...uiFilter,
        paymentType: e.target.value,
      });
    }
  };
  const { data, isLoading } = useGetShipmentRequestLite({ filter });

  const handleFilteredData = useCallback(async () => {
    if (!isLoading) {
      let finalFilter = data.filter((item) => {
        if (
          uiFilter.paymentType !== "All" &&
          item.paymentType !== uiFilter.paymentType
        ) {
          return false;
        }
        if (
          uiFilter.shipmentType !== "All" &&
          item.shipmentType !== uiFilter.shipmentType
        ) {
          return false;
        }
        return true;
      });
      setFilteredData(finalFilter);
    }
    //eslint-disable-next-line
  }, [uiFilter, data, isLoading]);

  useEffect(() => {
    handleFilteredData();
  }, [handleFilteredData]);
  const { mutate } = useConfirmShipmentRequestLite({});
  const handleBulkConfirm = (dataList) => {
    let idList = [];
    dataList.forEach((data) => {
      idList.push(data?.id);
    });
    mutate(idList);
  };
  const [total, setTotal] = useState({
    totalNoOfBoxes: 0,
    totalOversize: 0,
    totalAppliedWeight: 0,
    totalActualWeight: 0,
    totalDimensionalWeight: 0,
  });
  useEffect(() => {
    let totalNoOfBoxes;
    let totalOversize;
    let totalAppliedWeight;
    let totalActualWeight;
    let totalDimensionalWeight;
    if (!isLoading && filteredData?.length > 0) {
      totalNoOfBoxes = filteredData?.reduce(
        (acc, item) => acc + item?.numberOfBoxes,
        0
      );
      totalOversize = filteredData?.reduce(
        (acc, item) => acc + item?.numberOfOversizeBoxes,
        0
      );
      totalAppliedWeight = filteredData?.reduce(
        (acc, item) => acc + item?.appliedWeight,
        0
      );
      totalActualWeight = filteredData?.reduce(
        (acc, item) => acc + item?.actualWeight,
        0
      );
      totalDimensionalWeight = filteredData?.reduce(
        (acc, item) => acc + item?.dimensionalWeight,
        0
      );
      setTotal({
        totalNoOfBoxes: totalNoOfBoxes,
        totalOversize: totalOversize,
        totalAppliedWeight: totalAppliedWeight,
        totalActualWeight: totalActualWeight,
        totalDimensionalWeight: totalDimensionalWeight,
      });
    } else {
      setTotal({
        totalNoOfBoxes: 0,
        totalOversize: 0,
        totalAppliedWeight: 0,
        totalActualWeight: 0,
        totalDimensionalWeight: 0,
      });
    }
  }, [filteredData, isLoading]);

  const columns = [
    {
      title: "#",
      field: "index",
      width: 80,
      render: (rowData) =>
        filteredData?.findIndex((x) => x?.id === rowData?.id) + 1,
    },
    {
      title: "Awb",
      field: "awb",
    },
    {
      title: "Date",
      field: "requestDate",
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Shipper",
      field: "shipper",
    },
    {
      title: "Package Type",
      field: "packageType",
    },
    {
      title: "Payment Type",
      field: "paymentType",
    },
    {
      title: "No. of Boxes",
      field: "numberOfBoxes",
    },
    {
      title: "No. of OversizeBoxes",
      field: "numberOfOversizeBoxes",
    },
    {
      title: "Applied Weight",
      field: "appliedWeight",
    },
    {
      title: "Actual Weight",
      field: "actualWeight",
    },
    {
      title: "Dimensional Weight",
      field: "dimensionalWeight",
    },
    {
      title: "Invoice Value",
      field: "invoiceValue",
    },
    {
      title: "Invoice value for custom",
      field: "invoiceValueForCustom",
    },
    {
      title: "Pan No.",
      field: "panNo",
    },
    {
      title: "Consignee",
      field: "consignee",
    },
    {
      title: "Destination",
      field: "destination",
    },
    {
      title: "Postal code",
      field: "postalCode",
    },
    {
      title: "Service",
      field: "service",
    },
    {
      title: "Service Provider",
      field: "serviceProvider",
    },
  ];
  return {
    data: filteredData,
    isLoading,
    columns,
    handleBulkConfirm,
    total,
    uiFilter,
    handleUiFilter,
  };
};
