import { Box, Grid, TextField } from "@mui/material";
import React from "react";

const CustomerInfoConfigFormItem = ({ formik }) => {
  return (
    <Box component="form" noValidate sx={{ mt: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="infoKey"
            required
            value={formik.values.infoKey}
            onChange={formik.handleChange}
            error={formik.touched.infoKey && Boolean(formik.errors.infoKey)}
            helperText={formik.touched.infoKey && formik.errors.infoKey}
            fullWidth
            label="Info"
            autoFocus
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="value"
            required
            value={formik.values.value}
            onChange={formik.handleChange}
            error={formik.touched.value && Boolean(formik.errors.value)}
            helperText={formik.touched.value && formik.errors.value}
            fullWidth
            label="value"
            autoFocus
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerInfoConfigFormItem;
