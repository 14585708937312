import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Typography
} from "@mui/material"
import React from "react"
import {useShipmentActionMutation} from "../../../../hooks/api/service_provider/rates/useServiceProviderRate"

const DeleteAllRate = ({openDelete, handleClose, shipmentProviderId, zone}) => {
    const {
        deleteAllRate,
        isDeleting,
    } = useShipmentActionMutation({handleClose})
    return (
            <Dialog open={openDelete} onClose={() => handleClose()}>
                <DialogTitle>Delete All Rage </DialogTitle>
                <Divider/>
                {isDeleting?
                    <DialogContent>
                        <Typography sx={{mb: 3}}>
                            <CircularProgress/>
                        </Typography>
                    </DialogContent>
                    :
                    <>
                        <DialogContent>
                            <Typography sx={{mb: 3}}>
                                Are you sure you want to delete all rates?
                            </Typography>
                        </DialogContent>
                        <Divider/>
                        <DialogActions>
                            <Button onClick={() => handleClose()}>Cancel</Button>
                            <Button
                                onClick={() => {
                                    deleteAllRate({shipmentProviderId, zone})
                                }}
                            >
                                Delete
                            </Button>
                        </DialogActions>
                    </>
                }
            </Dialog>
    )
}
export default DeleteAllRate
