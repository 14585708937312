import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AddProductForm from "../../components/form/nepal_lite/add_product/AddProductForm";
import AddShipmentFormLite from "../../components/form/nepal_lite/add_shipment/AddShipmentFormLite";
import { useShipmentLiteForm } from "../../hooks/components/form/nepal_lite/shipment_request/useShipmentLiteForm";

const AddShipmentLite = () => {
  const { formik, isLoading, serviceProviderList, customerList, loading } =
    useShipmentLiteForm({});
  const [openFile, setOpenFile] = useState(false);
  const handleClose = () => {
    setOpenFile(false);
  };
  return isLoading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <div>
      <Grid container spacing={3} sx={{ mt: 1, pb: 4, pl: { xs: 0, md: 4 } }}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              <Typography color="text.primary">Add Shipment</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                fullWidth
                sx={{ textTransform: "none" }}
                variant="contained"
                onClick={() => setOpenFile(true)}
              >
                Upload Shipments using file
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box component="form" form={formik} noValidate>
            <AddShipmentFormLite
              statusEditable={false}
              formik={formik}
              serviceProviderList={serviceProviderList}
              customerList={customerList}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3} justifyContent="flex-end">
            {!loading && (
              <Grid item xs={6} md={3}>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                  onClick={() => formik.handleReset()}
                >
                  Clear
                </Button>
              </Grid>
            )}
            <Grid item xs={6} md={3}>
              <LoadingButton
                fullWidth
                onClick={() => formik.submitForm()}
                variant="contained"
                loading={loading}
                sx={{ textTransform: "none" }}
              >
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AddProductForm open={openFile} handleClose={handleClose} />
    </div>
  );
};

export default AddShipmentLite;
