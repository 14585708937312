import { useFormik } from "formik";
import { useAddCustomer } from "../../../api/customer/useCustomer";
import { businessSchema, personalSchema } from "./addCustomerValidationSchema";

export const useAddCustomerForm = ({ isBusiness, handleClose }) => {
  const { mutate } = useAddCustomer({});
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      mobile: "",
      emailOne: "",
      emailTwo: "",
      companyName: "",
      customerCategory: "C1",
      email: "",
      panNo: "",
    },
    validationSchema: isBusiness ? businessSchema : personalSchema,
    onSubmit: (values) => {
      handleAdd(values);
    },
  });

  const handleAdd = (values) => {
    values = {
      ...values,
      mobile: values?.mobile || null,
      companyName: isBusiness ? values.companyName : null,
      customerType: isBusiness ? "Business" : "Personal",
    };
    mutate(values, {
      onSuccess: () => {
        handleClose();
      },
    });
  };

  return {
    formik,
  };
};
