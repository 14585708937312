import React from "react";
import { PickUpTable } from "../../components/table/pickup/PickUpTable";

const PickUp = () => {
  return (
    <div>
      <PickUpTable />
    </div>
  );
};

export default PickUp;
