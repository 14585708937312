import { Grid } from "@mui/material";
import React from "react";
import AdminDashboardChips from "../../components/chips/dashboard/AdminDashboardChips";

const AdminDashboard = () => {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AdminDashboardChips />
        </Grid>
      </Grid>
    </div>
  );
};

export default AdminDashboard;
