import { useState } from "react";
import { useDeleteCustomerPayment } from "../../../../api/payment/usePayment";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../../../../../app/contexts/user/UserContext";
import { Tooltip, Typography } from "@mui/material";

export const useCustomerPaymentHistoryTable = () => {
  const [openAdd, setOpenAdd] = useState(false);
  const { role } = useContext(UserContext);
  const { mutate } = useDeleteCustomerPayment({});
  const navigate = useNavigate({});

  function handleOpenAdd() {
    setOpenAdd(true);
  }

  function handleCloseAdd() {
    setOpenAdd(false);
  }

  function handleDoubleClickRow(rowData) {
    navigate(
      `/${role === "SysAdmin" ? "super" : "admin"}/bill/${rowData?.billedId}`
    );
  }

  const handleDelete = (values) => {
    mutate(values?.id);
  };
  const columns = [
    {
      title: "S.No",
      field: "id",
    },
    {
      title: "Receipt Number",
      field: "receiptNumber",
    },
    {
      title: "amount",
      field: "amount",
    },
    {
      title: "date",
      field: "date",
    },
    {
      title: "Description",
      field: "description",
      render: (rowData) => {
        return (
          <Tooltip title={rowData?.description}>
            <Typography>
              {rowData?.description?.length > 10
                ? rowData?.description?.slice(0, 10) + "..."
                : rowData?.description}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      title: "Received By",
      field: "receivedBy",
    },
  ];

  const billsColumns = [
    {
      title: "Invoice Date",
      field: "invoiceDate",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "awb",
      field: "awb",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    { title: "Shipper", field: "shipper" },
    {
      title: "Consignee",
      field: "consignee",
      emptyValue: "-",
    },
    {
      title: "Destination",
      field: "destination",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    { title: "Dimensional Weight", field: "dimensionalWeight" },
    { title: "Number of Boxes", field: "numberOfBoxes" },
    {
      title: "Notes",
      field: "notes",
    },
    {
      title: "Freight",
      field: "freight",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "TIA",
      field: "tia",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "Custom",
      field: "custom",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },

    {
      title: "Remote",
      field: "remote",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },

    {
      title: "Others",
      field: "others",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "Total Charges",
      field: "totalCharges",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
  ];

  return {
    columns,
    handleDelete,
    billsColumns,
    openAdd,
    handleOpenAdd,
    handleCloseAdd,
    handleDoubleClickRow,
  };
};
