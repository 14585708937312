import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  createPickup,
  getMyPickUps,
} from "../../../api/pickup/pickup-customer-api";

export const useGetPickupCustomer = () => {
  return useQuery(["getMyPickUps"], getMyPickUps, {
    cacheTime: 60000,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};

export const useCreatePickUp = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(["createPickUp"], (formData) => createPickup(formData), {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["getMyPickUps"]);
      toast.success("Succesfully requested for pickup");
      onSuccess && onSuccess(data, variables, context);
    },
    onError: (err, _variables, _context) => {
      toast.error(`error: ${err.message}`);
    },
  });
};
