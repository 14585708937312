import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useUpdateShipmentLiteForm } from "../../../../hooks/components/form/nepal_lite/update_shipping_request/useUpdateShippingLiteForm";
import AddShipmentFormLite from "../add_shipment/AddShipmentFormLite";

const UpdateShipmentForm = ({ data }) => {
  const navigate = useNavigate();

  const {
    formik,
    serviceProviderLoading,
    customerLoading,
    serviceProviderList,
    customerList,
  } = useUpdateShipmentLiteForm({ data });

  return (
    <div>
      <Grid container spacing={3} sx={{ mt: 1, mb: 4, pl: 4 }}>
        <Grid item xs={12}>
          <Typography>Update Shipment</Typography>
        </Grid>
        <Grid item xs={12}>
          {!serviceProviderLoading && !customerLoading && (
            <Box component="form" form={formik}>
              <AddShipmentFormLite
                formik={formik}
                serviceProviderList={serviceProviderList}
                customerList={customerList}
              />
            </Box>
          )}
          <Grid container spacing={3} justifyContent="flex-end">
            <Grid item xs={3}>
              <Button
                fullWidth
                variant="outlined"
                sx={{ textTransform: "none" }}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                variant="contained"
                sx={{ textTransform: "none" }}
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default UpdateShipmentForm;
