import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import React, { useState } from "react";
import { useImportProductsFromFile } from "../../../../hooks/api/nepal_lite/useNepalLite";
import DownloadIcon from "@mui/icons-material/Download";

const AddProductForm = ({ open, handleClose }) => {
  const [progress, setProgress] = useState(false);

  const [file, setFile] = useState(null);

  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };
  const { mutate } = useImportProductsFromFile({});

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>Upload File</DialogTitle>
      {progress ? (
        <></>
      ) : (
        <DialogContent>
          <input
            type="file"
            name="file"
            className="custom-file-input"
            id="inputGroupFile"
            required
            onChange={handleFile}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </DialogContent>
      )}
      <Divider />
      {progress ? (
        <DialogActions>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress color="primary" />
          </Box>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button
            variant="outlined"
            href={"/template/template.xlsx"}
            download
            sx={{ textTransform: "none", mr: 3 }}
          >
            * Download template
            <DownloadIcon />
          </Button>
          <Button
            sx={{ textTransform: "none" }}
            variant="outlined"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ textTransform: "none" }}
            onClick={() => {
              setProgress(true);
              mutate(
                { file },
                {
                  onSettled: () => {
                    setProgress(false);
                    handleClose();
                  },
                }
              );
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default AddProductForm;
