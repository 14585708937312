import { Grid, Paper } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import MyAddressDetail from "../../../components/ship/detail/MyAddressDetail";
import MyPackageDetail from "../../../components/ship/detail/MyPackageDetail";
import MyPayment from "../../../components/ship/detail/MyPayment";
import MyPaymentDetail from "../../../components/ship/detail/MyPaymentDetail";
import MyShipmentDetail from "../../../components/ship/detail/MyShipmentDetail";
import MyShipmentTrack from "../../../components/ship/detail/MyShipmentTrack";

import { useGetMyShipmentDetail } from "../../../hooks/api/ship/useShip";

const MyShipmentDetailPage = () => {
  const { id } = useParams();
  const { data } = useGetMyShipmentDetail(id);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MyShipmentTrack data={data} />
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ maxWidth: "100vw", overflowX: "hidden" }}>
            <MyShipmentDetail data={data} />
            <MyPackageDetail data={data} />
            <MyAddressDetail data={data} />
            <MyPaymentDetail data={data} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <MyPayment data={data} />
        </Grid>
      </Grid>
    </div>
  );
};

export default MyShipmentDetailPage;
