import { useMutation, useQueryClient } from "react-query";
import {
  createCustomerInfoConfig,
  updateCustomerInfoConfig,
} from "../../../api/customer-info/customerinfo-api";
import { toast } from "react-toastify";

export const useAddCustomerInfoConfig = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(
    ["createCustomerInfoConfig"],
    (formData) => createCustomerInfoConfig(formData),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["getCustomerInfoConfigs"]);
        toast.success("Succesfully added customer config");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};

export const useUpdateCustomerInfoConfig = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(
    ["updateCustomerInfoConfig"],
    (formData) => updateCustomerInfoConfig(formData),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["getCustomerInfoConfigs"]);
        toast.success("Succesfully updated customer config");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};
