import React from "react";
import MyPaymentsTable from "../../components/payments/table/MyPaymentsTable";
import { useGetMyPayments } from "../../hooks/api/payment/usePayment";
import {
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import MyShipmentInvoiceTable from "../../components/payments/table/MyShipmentInvoiceTable";

const Payments = () => {
  const { data, isLoading } = useGetMyPayments({});
  return isLoading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "10vh",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Total</Typography>
              <Typography>{data?.totalAmount}</Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Amount Paid</Typography>
              <Typography>{data?.amountPaid}</Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography variant="subtitle2">Credit Remaining</Typography>
              <Typography>{data?.creditRemaining}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <MyPaymentsTable data={data?.customerPayments} isLoading={isLoading} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <MyShipmentInvoiceTable
          data={data?.shipmentInvoices}
          isLoading={isLoading}
        />
      </Grid>
    </Grid>
  );
};

export default Payments;
