import { useGetPickupCustomer } from "../../../api/pickup/usePickupCustomer";

const useMyPickupTable = () => {
  const { data, isLoading } = useGetPickupCustomer();

  const columns = [
    {
      title: "id",
      field: "id",
      render: (rowData) => data?.findIndex((x) => x?.id === rowData?.id) + 1,
    },
    {
      title: "Date Time",
      field: "dateTime",
    },
    {
      title: "C.N Number",
      field: "cnNumber",
    },
    {
      title: "Phone Number",
      field: "phoneNumber",
    },
    {
      title: "Address",
      field: "pickupAddress",
    },
    {
      title: "Vehicle",
      field: "vehicle",
    },
  ];

  return { data, isLoading, columns };
};

export default useMyPickupTable;
