import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  addZoneCountry,
  deleteZoneCountry,
  getZoneCountries,
} from "../../../../api/service_provider/country/zonecountry-api";

export const useGetZoneCountries = ({ id, zone }) => {
  return useQuery(
    ["getZoneCountries", id, zone],
    () => getZoneCountries({ id, zone }),
    {
      cacheTime: 10000,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useAddZoneCountry = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(
    ["addZoneCpuntry"],
    (formData) => addZoneCountry(formData),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["getZone", variables.shipmentProviderId])
        queryClient.invalidateQueries(["getZoneCountries"]);
        toast.success("Succesfully added country");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};

export const useDeleteZoneCountry = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(["deleteZoneCountry"], (id) => deleteZoneCountry(id), {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["getZoneCountries"]);
      toast.success("Succesfully deleted country");
      onSuccess && onSuccess(data, variables, context);
    },
    onError: (err, _variables, _context) => {
      toast.error(`error: ${err.message}`);
    },
  });
};
