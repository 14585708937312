import React from "react";
import {Chip, Fab, Grid} from "@mui/material"
import { useFinalShipmentTable } from "../../hooks/components/table/shipment-final/useFinalShipmentTable";
import ShipmentTableFilters from "../../components/table/shipment-final/filters/ShipmentTableFilters";
import ShipmentTable from "../../components/table/shipment-final/ShipmentTable";
import BillShipmentsForm from "../../components/form/shipment/BillShipmentsForm";
import UpdateShipmentStatus from "../../components/form/shipment/UpdateShipmentStatus";
import UpdateWeightForm from "../../components/form/shipment/UpdateWeightForm";
import BuyingPriceForm from "../../components/form/shipment/BuyingPriceForm";

const Shipments = ({ billed }) => {
  const {
    statusOptions,
    billedOptions,
    rateOptions,
    data,
    open,
    handleClose,
    columns,
    isLoading,
    filter,
    handleChange,
    handleDoubleClickRow,
    copyAwb,
    inputSearch,
    handleInputSearch,
    updateWeight,
    groupBilling,
    billingForm,
    uiFilter,
    handleUiFilter,
    shipmentTypeOptions,
    paymentTypeOptions,
    weightVerifiedOptions,
    serviceOptions,
    customerMemoizedOptions,
    customerMemoizedGetOptionLabel,
    serviceProviderMemoizedOptions,
    serviceProviderMemoizedGetOptionLabel,
    customerLoading,
    handleAutoCompleteFilter,
    serviceProviderLoading,
    billTypeOptions,
    handleCloseBuyingPrice,
    buyingPriceOpen,
    total,
    refetchCustomer
  } = useFinalShipmentTable({ billed });

  const handleRefetchCustomers = () => {
    sessionStorage.removeItem("customerData");
    refetchCustomer()
  };
  return (
    <div style={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {customerLoading && serviceProviderLoading ? (
            <></>
          ) : (
            <ShipmentTableFilters
              filter={filter}
              handleChange={handleChange}
              uiFilter={uiFilter}
              handleUiFilter={handleUiFilter}
              statusOptions={statusOptions}
              billedOptions={billedOptions}
              rateOptions={rateOptions}
              weightVerifiedOptions={weightVerifiedOptions}
              paymentTypeOptions={paymentTypeOptions}
              serviceOptions={serviceOptions}
              shipmentTypeOptions={shipmentTypeOptions}
              billTypeOptions={billTypeOptions}
              handleAutoCompleteFilter={handleAutoCompleteFilter}
              customerMemoizedGetOptionLabel={customerMemoizedGetOptionLabel}
              customerMemoizedOptions={customerMemoizedOptions}
              serviceProviderMemoizedGetOptionLabel={
                serviceProviderMemoizedGetOptionLabel
              }
              serviceProviderMemoizedOptions={serviceProviderMemoizedOptions}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <div className="MTable">
            <ShipmentTable
              inputSearch={inputSearch}
              handleInputSearch={handleInputSearch}
              data={data}
              columns={columns}
              isLoading={isLoading}
              handleDoubleClickRow={handleDoubleClickRow}
              groupBilling={groupBilling}
              copyAwb={copyAwb}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            displayPrint: "flex",
            flexWrap: "wrap",
          }}
        >
          <Chip
            label={`Total No of Boxes: ${total?.totalNoOfBoxes}`}
            sx={{ m: 2 }}
          />
          <Chip
            label={`Total No of Oversize Boxes: ${total?.totalOversize}`}
            sx={{ m: 2 }}
          />
          <Chip
            label={`Total Applied Weight: ${total?.totalAppliedWeight}`}
            sx={{ m: 2 }}
          />
          <Chip
            label={`Actual Weight: ${total?.totalActualWeight}`}
            sx={{ m: 2 }}
          />
          <Chip
            label={`Dimensional Weight: ${total?.totalDimensionalWeight}`}
            sx={{ m: 2 }}
          />
        </Grid>
      </Grid>
      <UpdateShipmentStatus
        open={open?.isOpen}
        handleClose={handleClose}
        data={open?.data}
      />
      <UpdateWeightForm
        open={updateWeight?.isOpen}
        handleClose={handleClose}
        data={updateWeight?.data}
      />
      <BillShipmentsForm
        open={billingForm?.open}
        handleClose={handleClose}
        data={billingForm?.data}
      />
      <BuyingPriceForm
        id={buyingPriceOpen?.id}
        open={buyingPriceOpen?.open}
        handleClose={handleCloseBuyingPrice}
      />
      <Fab
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
            fontSize:"0.5rem",
          }}
          color="primary"
          aria-label="refetch-customers"
          onClick={handleRefetchCustomers}
      >
        Refetch Customers
      </Fab>
    </div>
  );
};

export default Shipments;
