import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  createPickup,
  getFindPickups,
  getPickupDetail,
  markPicked,
  updatePickup,
} from "../../../api/pickup/pickup-api";

export const useFindPickups = (filter) => {
  return useQuery(["getFindPickups", filter], () => getFindPickups(filter), {
    cacheTime: 10000,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetPickupDetail = (id) => {
  return useQuery(["getPickupDetail", id], () => getPickupDetail(id), {
    cacheTime: 10000,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};

export const useCreatePickup = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(["createPickup"], (formData) => createPickup(formData), {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["getFindPickups"]);
      toast.success("Succesfully created pickup");
      onSuccess && onSuccess(data, variables, context);
    },
    onError: (err, _variables, _context) => {
      toast.error(`error: ${err.message}`);
    },
  });
};

export const useUpdatePickup = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(["updatePickup"], (formData) => updatePickup(formData), {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["getPickupDetail"]);
      queryClient.invalidateQueries(["getFindPickups"]);
      toast.success("Succesfully Updated pickup");
      onSuccess && onSuccess(data, variables, context);
    },
    onError: (err, _variables, _context) => {
      toast.error(`error: ${err.message}`);
    },
  });
};

export const useMarkPicked = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(["markPicked"], (id) => markPicked(id), {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["getPickupDetail"]);
      toast.success("Succesfully marked picked");
      onSuccess && onSuccess(data, variables, context);
    },
    onError: (err, _variables, _context) => {
      toast.error(`error: ${err.message}`);
    },
  });
};
