import {useAddBulkMasterAWBMutation, useMasterAWBActionMutation} from "../../../api/shipment/useShipment"
import {useFormik} from "formik"
import {AWBSchema, bulkAWBSchema} from "./addAWBValidationSchema"
import {useGetCustomers} from "../../../api/custom_shipment/useCustomShipment"
import {useEffect} from "react"

const useAddAWBForm = ({closeDialog, initialData}) => {
    /*const {
        mutateAsync,
        isLoading,
    } = useAddMasterAWBMutation()*/
    const {
        mutateAsync,
        isLoading
    } = useAddBulkMasterAWBMutation()
    const {
        updateMasterAWB,
    } = useMasterAWBActionMutation()
    const {data: customerData} = useGetCustomers()
    const customerList =
        customerData &&
        customerData?.map((customer) => {
            return {
                value: customer?.id,
                label: customer?.companyName,
                email: customer?.email,
            }
        })
    const initialValues = initialData ? {
        airlinesName: "",
        customerId: 0,
        date: "",
        destination: "",
        emails: [
            ""
        ],
        mAwbNumber: ""
    } : {
        airlinesName: "",
        masterAwbs: [],
    }
    const formik = useFormik({
        initialValues,
        validationSchema: initialData?AWBSchema:bulkAWBSchema,
        onSubmit: async (values) => {
            try {
                formik.setSubmitting(true)
                formik.values.id ?
                    await updateMasterAWB(values) :
                    await mutateAsync(values)
                closeDialog()
            } catch (error) {
                console.log(error)
            } finally {
                formik.setSubmitting(false)
            }
        }
    })

    useEffect(() => {
        const setFormValues = () => {
            if (initialData) {
                formik.setValues(initialData)
            }
        }
        setFormValues()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialData])

    return {
        formik,
        isLoading,
        customerList,
    }
}
export default useAddAWBForm
