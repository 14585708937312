import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  getUserDetails,
  loadMyAvatarImageLogo,
  updateAvatarImage,
  updateUserPassword,
} from "../../../api/profile/user-api";

export const useGetUserDetails = () => {
  return useQuery(["userDetail"], getUserDetails, {
    cacheTime: 60000,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetMyAvatar = () => {
  return useQuery(["getMyAvatar"], () => loadMyAvatarImageLogo(), {
    cacheTime: 60000,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};

export const useAddAvatar = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(
    ["addMyAvatar"],
    ({ image, setProgress }) => updateAvatarImage({ image, setProgress }),
    {
      onSuccess: (data, variables, context) => {
        toast.success("Successfully updated the profile image");
        queryClient.invalidateQueries("getMyAvatar");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};

export const useUpdateUserPassword = ({ onSuccess }) => {
  return useMutation(
    ["updateUserPassword"],
    (values) => updateUserPassword(values),
    {
      onSuccess: (data, variables, context) => {
        toast.success("Successfully updated user password");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};
