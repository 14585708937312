import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import React from "react";
import { useCreatePickupForm } from "../../../hooks/components/form/pickup/useCreatePickupForm";
import CreatePickupFormItem from "./items/CreatePickupFormItem";

const CreatePickupForm = ({ open, handleClose, adminList }) => {
  const { formik, adminId, setAdminId, inputAdmin, setInputAdmin } =
    useCreatePickupForm({ handleClose });
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>Create Pick up</DialogTitle>
      <Divider />
      <DialogContent>
        <CreatePickupFormItem
          formik={formik}
          adminList={adminList}
          adminId={adminId}
          setAdminId={setAdminId}
          inputAdmin={inputAdmin}
          setInputAdmin={setInputAdmin}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatePickupForm;
