import { Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetOfficeNoticeDetail } from "../../../hooks/api/office_notices/useOfficeNotices";

const OfficeNoticesDetailPage = () => {
  const { id } = useParams();
  const { data } = useGetOfficeNoticeDetail(id);
  return (
    <div style={{ maxWidth: "100vw" }}>
      <Card>
        <CardContent sx={{ ml: { xs: 2, sm: 6, md: 10 } }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">{data?.subject}</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="secondary">
                {moment(data?.created).format("MMMM Do YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography>Sent by: {data?.sender}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mb: 2 }} />
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  Message:
                </Grid>
                <Grid item xs={12}>
                  <Typography>{data?.message}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default OfficeNoticesDetailPage;
