import { useFormik } from "formik";
import { useAddAdmin } from "../../../../api/admin/useAdmin";
import { adminValidationSchema } from "./adminValidationSchema";

const useAdminForm = ({ handleClose }) => {
  const { mutate } = useAddAdmin({});
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      sys: false,
    },
    validationSchema: adminValidationSchema,
    onSubmit: (values) => {
      handleRegister(values);
    },
  });

  const handleRegister = (values) => {
    mutate(values, {
      onSuccess: () => {
        handleClose();
        formik.handleReset();
      },
    });
  };

  return { formik, handleRegister };
};

export default useAdminForm;
