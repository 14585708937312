import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  ListItemText,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

import useAddZoneCountriesForm from "../../../../../hooks/components/form/service_provider/zone/country/useAddZoneCountriesForm";
import { info } from "../../../../../../app/utils/info";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AddZoneCountryForm = ({ open, handleClose, id, zone }) => {
  const { countries } = info();
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelect = (event, newValue) => {
    const uniqueOptions = newValue.filter(
      (option, index, self) =>
        index ===
        self.findIndex(
          (o) =>
            o.value === option.value &&
            (o.label === option.label || !o.label || !option.label)
        )
    );

    setSelectedOptions(uniqueOptions);
  };

  const { formik } = useAddZoneCountriesForm({
    handleClose,
    id,
    zone,
    selectedOptions,
  });

  return (
    <Dialog open={open} onClose={() => handleClose()} fullWidth>
      <DialogTitle>Add Zone</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={countries}
              value={selectedOptions}
              onChange={handleSelect}
              disableCloseOnSelect
              getOptionLabel={(option) => option?.label}
              renderOption={(props, option) => (
                <li {...props}>
                  <Checkbox
                    checked={selectedOptions?.some(
                      (obj) => obj?.label === option?.label
                    )}
                    style={{ marginRight: 8 }}
                    icon={icon}
                    checkedIcon={checkedIcon}
                  />
                  <ListItemText primary={option?.label} />
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Countires" />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => handleClose()} sx={{ textTransform: "none" }}>
          Cancel
        </Button>
        <Button
          sx={{ textTransform: "none" }}
          onClick={() => formik.handleSubmit()}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddZoneCountryForm;
