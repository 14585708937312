import { useGetUsers } from "../../../../../super/hooks/api/admin/useAdmin";
import { useFindPickups, useUpdatePickup } from "../../../api/pickup/usePickup";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import { IconButton, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";

export const usePickupTable = ({ filter }) => {
  const [openCancel, setOpenCancel] = useState(false);

  const [selectedData, setSelectedData] = useState();
  const [uiFilter, setUiFilter] = useState({
    adminId: null,
    status: "Pending",
  });

  const { data, isLoading } = useFindPickups({...filter, status:uiFilter?.status});

  const { data: adminData, isLoading: adminIsLoading } = useGetUsers();

  const [filteredData, setFilteredData] = useState();



  const handleFilteredData = useCallback(async () => {
    if (!isLoading) {
      let finalFilter = data.filter((item) => {
        if (uiFilter.status !== "All" && item.status !== uiFilter.status) {
          return false;
        }
          if (uiFilter.adminId && item.adminId !== uiFilter.adminId) {
          return false;
        }
        return true;
      });
      setFilteredData(finalFilter);
    }
    //eslint-disable-next-line
  }, [uiFilter, data, isLoading]);

  useEffect(() => {
    handleFilteredData();
  }, [handleFilteredData]);

  const handleUiFilter = (values) => {
    setUiFilter(values);
  };

  const { mutate } = useUpdatePickup({});
  const adminList =
    !adminIsLoading &&
    adminData.map((admin) => {
      return {
        value: admin?.id,
        label: admin?.fullName,
      };
    });
  const handleCloseCancel = () => {
    setOpenCancel(false);
  };

  const handleCancelUpdate = (data) => {
    setOpenCancel(true);
    setSelectedData(data);
  };

  const handleSubmit = ({ reason, setReason }) => {
    let remarks = selectedData?.remarks;
    remarks = remarks?.concat(" | Cancelled Reason:" + reason);
    const values = { ...selectedData, remarks: remarks, status: "Cancelled" };
    mutate(values, {
      onSuccess: () => {
        handleCloseCancel();
        setReason("");
      },
    });
  };
  const handleChange = (data) => {
    window.confirm("Do you want to set the pickup as picked?") &&
      mutate({ ...data, status: "Complete" });
  };
  const column = [
    {
      title: "Date",
      field: "dateTime",
      render: (rowData) => (
        <Typography>{moment(rowData?.dateTime).format("lll")}</Typography>
      ),
    },
    {
      title: "Party Name",
      field: "partyName",
      width: 150,
    },
    {
      title: "Assigned To",
      field: "adminName",
      width: 150,
    },
    {
      title: "CN Number",
      field: "cnNumber",
    },

    {
      title: "Phone Number",
      field: "phoneNumber",
      width: "5%",
    },
    {
      title: "Address",
      field: "pickupAddress",
      width: "5%",
    },
    {
      title: "Vehicle",
      field: "vehicle",
      width: "5%",
    },
    {
      title: "Remarks",
      field: "remarks",
    },
    {
      title: "Status",
      field: "Status",
      render: (rowData) =>
        rowData?.status === "Complete" ? (
          <CheckCircleIcon color="success" sx={{ ml: 1 }} />
        ) : rowData?.status === "Cancelled" ? (
          <CancelIcon color="error" sx={{ ml: 1 }} />
        ) : (
          <Box>
            <IconButton sx={{ mr: 1 }} onClick={() => handleChange(rowData)}>
              <CheckIcon color="success" />
            </IconButton>
            <IconButton onClick={() => handleCancelUpdate(rowData)}>
              <CloseIcon color="error" />
            </IconButton>
          </Box>
        ),
    },
  ];
  return {
    column,
    data: filteredData,
    isLoading,
    adminList,
    openCancel,
    handleCloseCancel,
    uiFilter,
    handleUiFilter,
    handleSubmit,
  };
};
