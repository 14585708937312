import { useFormik } from "formik";
import moment from "moment";
import { useContext } from "react";
import { UserContext } from "../../../../../app/contexts/user/UserContext";
import { useCreatePickUp } from "../../../api/pickup/usePickupCustomer";
import { customerPickupValidationSchema } from "./customerPickupValidationSchema";

const useCreatePickupForm = ({ handleClose }) => {
  const { fullName } = useContext(UserContext);
  const { mutate } = useCreatePickUp({});

  const formik = useFormik({
    initialValues: {
      cnNumber: "",
      dateTime: "",
      phoneNumber: "",
      pickupAddress: "",
      remarks: "",
      vehicle: "",
    },
    validationSchema: customerPickupValidationSchema,
    onSubmit: (values) => {
      values = {
        ...values,
        createdBy: fullName,
        dateTime: moment(values?.dateTime).format("YYYY-MM-DD HH:mm"),
      };
      handleCreate(values);
    },
  });

  const handleCreate = (values) => {
    mutate(values, { onSuccess: () => handleClose() });
  };

  return { formik };
};

export default useCreatePickupForm;
