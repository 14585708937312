import {
  Avatar,
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import React from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTempPasswordForm } from "../../../hooks/components/tempPassword/useTempPasswordForm";

const TempPasswordForm = () => {
  const {
    formik,
    showValues,
    handleClickShowPassword,
    handleMouseDownPassword,
    handleClickShowConfirmPassword,
    handleMouseDownConfirmPassword,
  } = useTempPasswordForm();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: 30,
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
        <PersonOutlineIcon />
      </Avatar>
      <Typography component="h1" variant="h5" color="text.primary">
        Set Password
      </Typography>
      <Box component="form" noValidate sx={{ mt: 1 }}>
        <TextField
          variant="outlined"
          label="Password"
          fullWidth
          autoComplete="new-password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          id="password"
          type={showValues.showPassword ? "text" : "password"}
          sx={{ minWidth: "10vw", mt: 1 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword(true)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showValues.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          variant="outlined"
          label="Repeat Password"
          fullWidth
          autoComplete="new-password"
          id="repeatPassword"
          type={showValues.showConfirmPassword ? "text" : "password"}
          value={formik.values.repeatPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.repeatPassword &&
            Boolean(formik.errors.repeatPassword)
          }
          helperText={
            formik.touched.repeatPassword && formik.errors.repeatPassword
          }
          sx={{ minWidth: "10vw", mt: 1 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownConfirmPassword}
                  edge="end"
                >
                  {showValues.showConfirmPassword ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
          onClick={() => formik.submitForm()}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default TempPasswordForm;
