import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import React from "react";
import useEditAdminForm from "../../../hooks/components/admin/form/Admin/useEditAdminForm";
import AddAdminFormItem from "./items/AddAdminFormItem";

const EditAdminForm = ({ open, handleClose, data }) => {
  const { formik } = useEditAdminForm({ data, handleClose });

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>Edit Admin</DialogTitle>
      <Divider />
      <DialogContent>
        <AddAdminFormItem formik={formik} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button
          onClick={() => {
            formik.submitForm();
          }}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAdminForm;
