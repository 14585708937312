import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import RoleMenuItem from "../menu_item/RoleMenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Avatar,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { removeUser } from "../../utils/cookieHelper";
import { UserContext } from "../../contexts/user/UserContext";
import moment from "moment";
import ThemeModeContext from "../../contexts/theme/ThemeModeContext";
import Notification from "../notification/Notification";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

export const MobileSideDrawer = ({ children }) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const { role, fullName, myAvatar } = React.useContext(UserContext);
  const [open, setOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    removeUser();
    navigate("/login");
  };

  const { mode, toggleMode } = React.useContext(ThemeModeContext);

  const [themeMode, setThemeMode] = React.useState(
    mode === "light" ? false : true
  );

  return (
    <div>
      <Drawer anchor={"left"} open={open} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 270,
            backgroundColor: "#000",
            minHeight: "100vh",
            color: "#fff",
          }}
          role="presentation"
          onKeyDown={toggleDrawer(false)}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              width: "100%",
              paddingTop: "16px",
            }}
          >
            <Typography
              sx={{
                color: "text.white",
                fontSize: "24px",
                fontWeight: 800,
                cursor: "pointer",
              }}
              onClick={() =>
                navigate(
                  role === "SysAdmin" ? "/super/dashboard" : "/admin/dashboard"
                )
              }
            >
              NepalEx
            </Typography>
          </Box>

          <Divider sx={{ mt: "2vh", mb: "2vh", background: "#fff" }} />
          <div
            style={{
              maxHeight: "80vh",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            <RoleMenuItem />
          </div>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          width: "100%",
          backgroundColor: "background.default",
          overflowX: "hidden",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6} sx={{ display: "flex" }}>
                <IconButton onClick={toggleDrawer(true)}>
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  pr: "16px",
                  pl: "16px",
                }}
              >
                <Tooltip title="Go Back">
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip>
                <MaterialUISwitch
                  sx={{ m: 1 }}
                  checked={themeMode}
                  onChange={(e) => {
                    setThemeMode(e.target.checked);
                    toggleMode();
                  }}
                />
                {role === "Customer" ? <></> : <Notification />}
                <Chip
                  sx={{ mt: 1, mr: 10, display: { md: "flex", xs: "none" } }}
                  label={moment().format("MMM DD, yyyy ")}
                  variant="outlined"
                />
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt={fullName}
                    src={myAvatar}
                    sx={{ cursor: "pointer" }}
                  />
                </IconButton>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Link
                      to="/settings"
                      style={{
                        display: "flex",
                        textDecoration: "none",
                        color: "#000",
                      }}
                    >
                      <SettingsIcon sx={{ mr: 2, color: "text.primary" }} />
                      <Typography textAlign="center" color="text.primary">
                        Settings
                      </Typography>
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={() => handleLogout()}>
                    <LogoutIcon sx={{ mr: 2 }} />
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ mt: 2 }}>{children}</Box>
      </Box>
    </div>
  );
};
