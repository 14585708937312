import MaterialTable from "@material-table/core";
import React from "react";
import { useNepalExinfoTable } from "../../hooks/components/NepalExInfo/table/useNepalExinfoTable";

const NepalExInfo = () => {
  const { data, isLoading, columns } = useNepalExinfoTable({});
  return (
    <>
      <MaterialTable
        data={data}
        isLoading={isLoading}
        columns={columns}
        title="NepalEx-Info"
      />
    </>
  );
};

export default NepalExInfo;
