import { useNavigate } from "react-router-dom";
import {
  useDeleteZone,
  useGetZone,
} from "../../../../api/service_provider/zones/useZones";

export const useZoneTable = (id) => {
  const navigate = useNavigate();

  const { mutate } = useDeleteZone({});

  const { data, isLoading } = useGetZone(id);

  const handleDelete = (zone) => {
    mutate({ id: id, zone: zone });
  };

  const dataList = data?.map((item,index)=>({
    ...item,
    id:index
  }))

  function handleDoubleClickRow(rowData) {
    navigate(`${window.location.pathname}/${rowData?.zone}`);
  }

  const columns = [
    {
      title: "id",
      field: "id",
      render: (rowData) => rowData?.id + 1,
    },
    {
      title: "Zone",
      field: "zone",
    },
  ];
  return { dataList, isLoading, columns, handleDoubleClickRow, handleDelete };
};

export default useZoneTable;
