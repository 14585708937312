import { axiosInstance } from "../../../../app/api/axiosInterceptor";

export const getZoneCountries = async ({ id, zone }) => {
  const { data } = await axiosInstance.get(
    `/v/admin/zone-countries?shipmentProviderId=${id}&zone=${zone}`
  );
  return data;
};

export const addZoneCountry = async (values) => {
  const { data } = await axiosInstance.post(`/v/admin/zone-country`, values);
  return data;
};

export const deleteZoneCountry = async (id) => {
  const { data } = await axiosInstance.delete(`/v/admin/zone-country?id=${id}`);
  return data;
};
