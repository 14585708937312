import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const Parcels = () => {
  return (
    <Grid container spacing={0}>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: { xs: "300px", md: "600px" },
          padding: "16px",
        }}
      >
        <Box sx={{ maxWidth: "600px" }}>
          <Typography sx={{ fontWeight: 800, fontSize: "30px" }}>
            Parcels
          </Typography>
          <Typography sx={{ fontSize: "14px" }}>
            Package delivery or parcel delivery is the delivery of shipping
            containers, parcels, or high value mail as single shipments. The
            vice is provided by most postal systems, express mail, private
            courier companies, and less than truckload shipping carriers.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <img
          src="/assests/images/landing_page/parcels.jpg"
          alt="aircargo"
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Parcels;
