import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  cancelShipment,
  createShipmentRequest,
  getMyBills,
  getMyShipmentDetail,
  getServiceProviders,
  getShipmentRequests,
  getShipments,
  trackShipment,
} from "../../../api/ship/ship-api";

export const useGetServiceProviders = () => {
  return useQuery(["getServiceProviders"], getServiceProviders, {
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetShipmentRequests = () => {
  return useQuery(["getShipmentRequests"], getShipmentRequests, {
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetShipments = () => {
  return useQuery(["getMyShipments"], getShipments, {
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetMyShipmentDetail = (id) => {
  return useQuery(["getMyShipmentDetail"], () => getMyShipmentDetail(id), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};

export const useCreateShipmentRequest = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(
    ["createShipmentRequest"],
    (formData) => createShipmentRequest(formData),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["getMyShipments"]);
        toast.success("Succesfully requested for shipping");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};

export const useCancelShipment = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(["cancelShipmentRequest"], (id) => cancelShipment(id), {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["getMyShipmentDetail"]);
      toast.success("Succesfully canceled shipment");
      onSuccess && onSuccess(data, variables, context);
    },
    onError: (err, _variables, _context) => {
      toast.error(`error: ${err.message}`);
    },
  });
};

export const useTrackShipment = ({ onSuccess }) => {
  return useMutation(["getMyPayments"], (nstn) => trackShipment(nstn), {
    onSuccess: (data, variables, context) => {
      toast.success("Succesfully canceled shipment");
      onSuccess && onSuccess(data, variables, context);
    },
    onError: (err, _variables, _context) => {
      toast.error(`error: ${err.message}`);
    },
  });
};

export const useGetMyBills = (id) => {
  return useQuery(["getBills", id], () => getMyBills(id), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};
