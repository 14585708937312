import { useFormik } from "formik";
import { useForgetPassword } from "../../api/forgetpassword/useForgetPassword";
import { forgetPasswordSchema } from "./forgetPasswordValidationSchema";

export const useForgetPasswordForm = () => {
  const { mutate } = useForgetPassword({});
  const formik = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema: forgetPasswordSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values) => {
    const { username } = values;
    mutate(username);
  };

  return {
    formik,
  };
};
