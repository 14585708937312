import {
  Box,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from "@mui/material";
import React from "react";

const AddCustomerFormItem = ({ formik, isBusiness, setIsBusiness }) => {
  return (
    <Box component="form" form={formik} noValidate sx={{ mt: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="given-name"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
            required
            fullWidth
            id="firstName"
            label="First Name"
            autoFocus
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="family-name"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel id="demo-row-radio-buttons-group-label">
            Category
          </FormLabel>
          <RadioGroup
            row
            name="customerCategory"
            value={formik.values.customerCategory}
            onChange={formik.handleChange}
          >
            <FormControlLabel value="C1" control={<Radio />} label="C1" />
            <FormControlLabel value="C2" control={<Radio />} label="C2" />
            <FormControlLabel value="M1" control={<Radio />} label="M1" />
            <FormControlLabel
              value="COUNTER"
              control={<Radio />}
              label="Counter"
            />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={formik.values.emailOne}
            onChange={formik.handleChange}
            error={formik.touched.emailOne && Boolean(formik.errors.emailOne)}
            helperText={formik.touched.emailOne && formik.errors.emailOne}
            label="Optional Email 1"
            name="emailOne"
            autoComplete="email"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={formik.values.emailTwo}
            onChange={formik.handleChange}
            error={formik.touched.emailTwo && Boolean(formik.errors.emailTwo)}
            helperText={formik.touched.emailTwo && formik.errors.emailTwo}
            label="Optional Email 2"
            name="emailTwo"
            autoComplete="email"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          <FormControlLabel
            label={isBusiness ? "Business Account" : "Personal Account"}
            labelPlacement="end"
            control={
              <Switch
                checked={isBusiness}
                onChange={(event) => setIsBusiness(event.target.checked)}
              />
            }
          />
        </Grid>
        {isBusiness && (
          <Grid item xs={12}>
            <TextField
              autoComplete="Business Name"
              name="companyName"
              value={formik.values.companyName}
              onChange={formik.handleChange}
              error={
                formik.touched.companyName && Boolean(formik.errors.companyName)
              }
              helperText={
                formik.touched.companyName && formik.errors.companyName
              }
              required
              fullWidth
              label="Business Name"
              autoFocus
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            value={formik.values.panNo}
            onChange={formik.handleChange}
            error={formik.touched.panNo && Boolean(formik.errors.panNo)}
            helperText={formik.touched.panNo && formik.errors.panNo}
            id="panNo"
            label="Pan No."
            name="panNo"
            autoComplete="Pan No."
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={formik.values.mobile}
            onChange={formik.handleChange}
            error={formik.touched.mobile && Boolean(formik.errors.mobile)}
            helperText={formik.touched.mobile && formik.errors.mobile}
            name="mobile"
            label="Contact Number"
            type="text"
            id="phoneNumber"
            autoComplete="Contact Number"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddCustomerFormItem;
