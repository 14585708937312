import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  addCustomer,
  getCustomerDetail,
  getCustomers,
  getCustomerShipments,
  importCustomerFromFile,
  issueTempPassword,
  restoreCustomer,
  suspendCustomer,
  updateCustomer,
} from "../../../api/customer/customer-api";

export const useGetCustomers = (categoryFilter, typeFilter) => {
  return useQuery(
    ["getCustomers", categoryFilter, typeFilter],
    () => {
        const customerData = sessionStorage.getItem("customerData");

        if (!customerData) {
            return getCustomers().then((data) => {
                sessionStorage.setItem("customerData", JSON.stringify(data));
                return data;
            });
        } else {
            return JSON.parse(customerData);
        }
    },
    {
      cacheTime: 10000,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetCustomerDetail = (id) => {
  return useQuery(["getCustomerDetail", id], () => getCustomerDetail(id), {
    cacheTime: 10000,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetCustomerShipments = (id) => {
  return useQuery(
    ["getCustomerShipments", id],
    () => getCustomerShipments(id),
    {
      cacheTime: 10000,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useAddCustomer = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(["AddCustomer"], (formData) => addCustomer(formData), {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["getCustomers"]);
      toast.success("Succesfully added customer");
      onSuccess && onSuccess(data, variables, context);
    },
    onError: (err, _variables, _context) => {
      toast.error(`error: ${err.message}`);
    },
  });
};

export const useSuspendCustomer = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(
    ["SuspendCustomer"],
    (formData) => suspendCustomer(formData),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["getCustomerDetail"]);
        toast.success("Succesfully suspended customer");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};

export const useRestoreCustomer = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(
    ["restoreCustomer"],
    (formData) => restoreCustomer(formData),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["getCustomerDetail"]);
        toast.success("Succesfully restored customer");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};

export const useUpdateCustomer = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(
    ["updateCustomer"],
    (formData) => updateCustomer(formData),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["getCustomerDetail"]);
        toast.success("Succesfully updated customer detail");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};

export const useImportCustomerFromFile = ({ onSuccess }) => {
  return useMutation(
    ["importCustomerFromFile"],
    (formData) => importCustomerFromFile(formData),
    {
      onSuccess: (data, variables, context) => {
        toast.success("Succesfully uploaded");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};

export const useIssueTempPassword = ({ onSuccess }) => {
  return useMutation(["updateCustomer"], (ids) => issueTempPassword(ids), {
    onSuccess: (data, variables, context) => {
      toast.success("Succesfully sent temp password");
      onSuccess && onSuccess(data, variables, context);
    },
    onError: (err, _variables, _context) => {
      toast.error(`error: ${err.message}`);
    },
  });
};
