import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  faBoxesPacking,
  faBoxesStacked,
  faBoxOpen,
  faCartFlatbedSuitcase,
  faFileAlt,
  faPallet,
  faWeight,
  faWeightHanging,
} from "@fortawesome/free-solid-svg-icons";
import DetailTypography from "../../../../app/component/detail_view/DetailTypography";

const MyPackageDetail = ({ data }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <FontAwesomeIcon icon={faBoxOpen} size="lg" />
        <Typography sx={{ width: "50%", flexShrink: 0, ml: 3 }}>
          Package Details
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={4}>
            <DetailTypography
              icon={<FontAwesomeIcon icon={faBoxesStacked} size="lg" />}
              title="Boxes"
              name={data?.numberOfBoxes}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <DetailTypography
              icon={<FontAwesomeIcon icon={faBoxesPacking} size="lg" />}
              title="Oversize Boxes"
              name={data?.numberOfOversizeBoxes}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <DetailTypography
              icon={<FontAwesomeIcon icon={faWeight} size="lg" />}
              title="Actual Weight"
              name={data?.actualWeight}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <DetailTypography
              icon={<FontAwesomeIcon icon={faWeightHanging} size="lg" />}
              title="Dimensional Weight"
              name={data?.dimensionalWeight}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <DetailTypography
              icon={<FontAwesomeIcon icon={faPallet} size="lg" />}
              title="Iron Strips"
              name={data?.noOfIronStrip}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <DetailTypography
              icon={<FontAwesomeIcon icon={faPallet} size="lg" />}
              title="Plastic Strips"
              name={data?.noOfPlasticStrip}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DetailTypography
              icon={<FontAwesomeIcon icon={faCartFlatbedSuitcase} size="lg" />}
              title="Package Type"
              name={data?.packageType}
            />
          </Grid>
          <Grid item xs={12}>
            <DetailTypography
              icon={<FontAwesomeIcon icon={faFileAlt} size="lg" />}
              title="Description"
              name={data?.descriptionOfGoods}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default MyPackageDetail;
