import * as yup from "yup";

const BillTypes = ["Pan", "TPan", "Vat"];

const shipmentBillingSchema = yup.object().shape({
  billType: yup
    .string()
    .oneOf(BillTypes, "Invalid bill type")
    .required("Bill Type is required"),
});

export { shipmentBillingSchema };
