import axios from "axios";

export const getExchangeRate = async (date) => {
  if (date) {
    const { data } = await axios.get(
      `https://www.nrb.org.np/api/forex/v1/rates?from=${date}&to=${date}&q=USD&page=1&per_page=10`
    );
    return data?.data?.payload[0]?.rates?.filter(
      (rates) => rates?.currency?.iso3 === "USD"
    )[0];
  }
};
