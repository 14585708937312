import {
  Box,
  Button,
  Container,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import useShipmentRequestForm from "../../../hooks/components/ship/form/shipment_request/useShipmentRequestForm";
import AddressDetailsForm from "./steps/address_details/AddressDetailsForm";
import PackageDetailsForm from "./steps/package_details/PackageDetailsForm";
import PaymentDetailsForm from "./steps/payment_details/PaymentDetailsForm";
import ShipmentDetailsForm from "./steps/shipment_details/ShipmentDetailsForm";

const steps = [
  "Shipment Details",
  "Package details",
  "Address Details",
  "Payment Details",
];

const ShippingRequestForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { formik, serviceProviderList } = useShipmentRequestForm();

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        formik.setFieldTouched("serviceProviderId");
        break;
      case 1:
        formik.setFieldTouched("numberOfBoxes");
        formik.setFieldTouched("actualWeight");
        break;
      case 2:
        formik.setFieldTouched("consignee");
        formik.setFieldTouched("destinationCountry");
        formik.setFieldTouched("city");
        formik.setFieldTouched("street");
        formik.setFieldTouched("houseNumber");
        break;
      case 3:
        break;
      default:
        break;
    }
    setActiveStep(activeStep + 1);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <ShipmentDetailsForm
            formik={formik}
            serviceProviderList={serviceProviderList}
          />
        );
      case 1:
        return <PackageDetailsForm formik={formik} />;
      case 2:
        return <AddressDetailsForm formik={formik} />;
      case 3:
        return <PaymentDetailsForm formik={formik} />;
      default:
        throw new Error("Unknown Step");
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleReturn = () => {
    setActiveStep(0);
  };
  return (
    <Container component="main" maxWidth="md" sx={{ mt: 5 }}>
      <Paper variant="plain" sx={{ my: { xs: 0, md: 6 }, p: { xs: 0, md: 3 } }}>
        <Typography component="h1" varient="h4" align="center">
          Shipping Request
        </Typography>
        <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <React.Fragment>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                Thank you for your shipping request.
              </Typography>
              <Typography variant="subtitle1">
                Your shipping request has been placed. We have emailed your
                shipment confirmation, and will send you an update when your
                order has shipped.
              </Typography>
              <Button onClick={handleReturn} sx={{ mt: 3, ml: 1 }}>
                Return
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent(activeStep)}
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                )}
                {activeStep === steps.length - 1 ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      formik.handleSubmit();
                      formik.isValid
                        ? handleNext()
                        : toast.error(
                            "Please make sure you have filled the form correctly"
                          );
                    }}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    Place Request
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    Next
                  </Button>
                )}
              </Box>
            </React.Fragment>
          )}
        </React.Fragment>
      </Paper>
    </Container>
  );
};

export default ShippingRequestForm;
