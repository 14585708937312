import MaterialTable from "@material-table/core";
import React from "react";
import useBilledShipmentsTable from "../../../hooks/components/table/billed_shipments/useBilledShipmentsTable";
import { Grid, TextField } from "@mui/material";

const BilledShipmentsTable = () => {
  const {
    data,
    isLoading,
    columns,
    handleDoubleClickRow,
    handleChange,
    filter,
  } = useBilledShipmentsTable();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <TextField
              size="small"
              margin="dense"
              type="date"
              InputLabelProps={{ shrink: true }}
              name="dateFrom"
              label="From"
              value={filter?.dateFrom}
              InputProps={{ inputProps: { max: filter?.dateTo } }}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              size="small"
              margin="dense"
              type="date"
              InputLabelProps={{ shrink: true }}
              name="dateTo"
              label="To"
              value={filter?.dateTo}
              InputProps={{ inputProps: { min: filter?.dateFrom } }}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          columns={columns}
          data={data}
          title="Billed"
          isLoading={isLoading}
          options={{
            padding: "dense",
            pageSize: 10,
          }}
          onRowDoubleClick={(_event, rowData) => handleDoubleClickRow(rowData)}
        />
      </Grid>
    </Grid>
  );
};

export default BilledShipmentsTable;
