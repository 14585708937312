import axios from "axios";
import { axiosInstance } from "../../../app/api/axiosInterceptor";

export const createPayment = async (values) => {
  const { data } = await axios.post(
    "https://uat.esewa.com.np/epay/main",
    values
  );
  return data;
};

export const getMyPayments = async () => {
  const { data } = await axiosInstance.get("/v/payments");
  return data;
};
