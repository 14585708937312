import { Grid } from "@mui/material";
import React from "react";
import BilledShipmentsTable from "../../components/table/billed_shipments/BilledShipmentsTable";

const BilledShipments = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <BilledShipmentsTable />
      </Grid>
    </Grid>
  );
};

export default BilledShipments;
