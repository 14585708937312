import React from "react";
import CustomerNoticesTable from "../../components/table/customer_notices/CustomerNoticeTable";

const CustomerNotice = () => {
  return (
    <div>
      <CustomerNoticesTable />
    </div>
  );
};

export default CustomerNotice;
