import { axiosInstance } from "../../../app/api/axiosInterceptor";

export const getOfficeNotices = async () => {
  const { data } = await axiosInstance.get("/v/admin/office-notices?page=0");
  return data;
};

export const getOfficeNoticeDetail = async (id) => {
  const { data } = await axiosInstance.get(`/v/admin/office-notices?id=${id}`);
  return data;
};

export const sendOfficeNotice = async (values) => {
  const { data } = await axiosInstance.post(
    "/v/admin/send-office-notice",
    values
  );
  return data;
};

export const sendCustomerNotice = async (values) => {
  const { data } = await axiosInstance.post(
    "/v/admin/send-customer-notice",
    values
  );
  return data;
};
