import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import LiteShipmentTable from "../../../components/table/nepal_lite/shipment/LiteShipmentTable";
import ShipmentDetail from "./detail/ShipmentDetail";

const ShipmentTable = () => {
  const [id, setId] = useState();
  const handleId = (value) => {
    setId(value);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <LiteShipmentTable handleId={handleId} />
      </Grid>
      <Grid item xs={12}>
        {id ? (
          <ShipmentDetail id={id} />
        ) : (
          <div>
            <Typography color="primary">
              #Double click on the row to display it's detail
            </Typography>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default ShipmentTable;
