import * as Yup from "yup";

const superRegisterSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  phoneNumber: Yup.string().required("Required"),
});

export { superRegisterSchema };
