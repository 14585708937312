import { useFormik } from "formik";
import { useAddZoneCountry } from "../../../../../api/service_provider/countries/useZoneCountries";

const useAddZoneCountriesForm = ({
  id,
  zone,
  handleClose,
  selectedOptions,
}) => {
  const { mutate } = useAddZoneCountry({});
  const handleCreate = (values) => {
    let countries = selectedOptions?.map((item) => item.value);
    values = {
      zone: zone,
      countries: countries,
      shipmentProviderId: id,
    };
    mutate(values, {
      onSuccess: () => {
        handleClose();
      },
    });
  };
  const formik = useFormik({
    initialValues: {
      countries: [],
    },
    onSubmit: (values) => {
      handleCreate(values);
    },
  });
  return { formik };
};

export default useAddZoneCountriesForm;
