import { Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CancelIcon from "@mui/icons-material/Cancel";

const DestinationDetails = ({ destination, postal, remoteArea }) => {
  return (
    <Paper
      elevation={4}
      sx={{
        ml: { sm: 1, md: 2 },
        mr: { sm: 1, md: 2 },
        mt: 1,
        mb: 1,
        p: "16px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "24px",
            }}
          >
            Destination Details
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="subtitle2">Destination :</Typography>
          <Typography>{destination}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="subtitle2">Postal :</Typography>
          <Typography>{postal}</Typography>
        </Grid>
        <Grid item sm={12} md={4}>
          <Typography variant="subtitle2">Remote Area :</Typography>
          {remoteArea ? (
            <CheckBoxIcon color="success" />
          ) : (
            <CancelIcon color="error" />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DestinationDetails;
