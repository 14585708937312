import MaterialTable from "@material-table/core";
import React, { useContext } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ThemeModeContext from "../../../../app/contexts/theme/ThemeModeContext";
import { UserContext } from "../../../../app/contexts/user/UserContext";
import BuyingPriceForm from "../../form/shipment/BuyingPriceForm";
import ExportCsv from "@material-table/exporters/csv";

const ShipmentTable = ({
  columns,
  data,
  handleDoubleClickRow,
  copyAwb,
  isLoading,
  groupBilling,
  handleInputSearch,
}) => {
  const { isMobile } = useContext(ThemeModeContext);
  const { role } = useContext(UserContext);
  const { mode } = useContext(ThemeModeContext);

  const fixedColumns = isMobile
    ? { left: 0, right: 0 }
    : data?.length > 0
    ? role === "SysAdmin"
      ? {
          left: 4,
          right: 0,
        }
      : {
          left: 4,
          right: 0,
        }
    : { left: 0, right: 0 };

  const options = {
    fixedColumns,
    selection: true,
    pageSize: 100,
    pageSizeOptions:[100,200,500],
    padding: "dense",
    emptyRowsWhenPaging:false,
    cellStyle: {
      backgroundColor: mode === "light" ? "#fff" : "#424242",
      border: "1px solid #000",
    },
    exportMenu: [
      {
        label: "Export CSV",
        exportFunc: (cols, datas) =>
          ExportCsv(cols, datas, "myCsvFileName.csv"),
      },
    ],
  };
  const actions = [
    {
      tooltip: "Copy Awb",
      icon: () => <ContentCopyIcon />,
      onClick: (_evt, data) => {
        copyAwb(data);
      },
    },
    role === "SysAdmin" && {
      tooltip: "Bill Shipment",
      icon: () => <ReceiptLongIcon />,
      onClick: (_evt, data) => {
        groupBilling(data);
      },
    },
  ];

  return (
    <div className={mode === "dark" ? "dark-table" : "light-table"}>
      <MaterialTable
        columns={columns}
        data={data}
        title="Shipments"
        isLoading={isLoading}
        onRowDoubleClick={(_event, rowData) => handleDoubleClickRow(rowData)}
        onSearchChange={(query) => handleInputSearch(query)}
        options={options}
        actions={actions}
      />
      <BuyingPriceForm />
    </div>
  );
};

export default ShipmentTable;
