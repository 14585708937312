import useAddAWBForm from "../../../hooks/components/form/shipments/useAddAWBForm"
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from "@mui/material"
import AddAWBFormItem from "./items/AddAWBFormItem"
import React from "react"
import AddMultipleAWBFormItem from "./items/AddMultipleAWBFormItem"

const AddAWBForm = ({open, handleClose,initialData}) => {
    const closeDialog=()=>{
        formik.setValues(initialData?{
            airlinesName: "",
            customerId: 0,
            date: "",
            destination: "",
            emails: [
                ""
            ],
            mAwbNumber: ""
        }:{
            airlinesName: "",
            masterAwbs: [],
        })
        handleClose()
    }
    const {formik,customerList} = useAddAWBForm({closeDialog,initialData})
    return (
        <Dialog open={open} onClose={closeDialog}>
            <DialogTitle>{initialData ?"Edit AWB": "Add AWB"}</DialogTitle>
            <Divider/>
            <DialogContent>
                {initialData?
                    <AddAWBFormItem
                        formik={formik}
                        customerList={customerList}
                    />
                    :
                    <AddMultipleAWBFormItem
                        formik={formik}
                    />
                }
            </DialogContent>
            <Divider/>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button
                    onClick={() => {
                        formik.handleSubmit()
                    }}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default AddAWBForm
