import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();

  const buttonStyle = {
    width: 120,
    borderRadius: 20,
    background: "#cc9900",
  };
  return (
    <Container sx={{ p: 10 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img src="/Animated_page_not_found (1).svg" alt={"not found"} />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            style={buttonStyle}
            onClick={() => navigate("/")}
          >
            Go Home
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default NotFound;
