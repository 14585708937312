import { useFormik } from "formik";
import { RateState } from "../../../../../../app/contexts/rates/RateContext";
import { useUpdateShipmentRates } from "../../../../api/settings/useSettings";

export const useRateSettingForm = () => {
  const { mutate } = useUpdateShipmentRates({});
  const { state } = RateState();

  const handleUpdate = (values) => {
    mutate(values);
  };

  const formik = useFormik({
    initialValues: {
      plasticStrip: state?.plasticStrip,
      ironStrip: state?.ironStrip,
      remote: state?.remote,
      tiaCourier: state?.tiaCourier,
      tiaCargo: state?.tiaCargo,
      customClearance: state?.customClearance,
      minCustomCharge: state?.minCustomCharge,
      minTiaForCourierCommercial: state?.minTiaForCourierCommercial,
      minTiaForCargo: state?.minTiaForCargo,
      oversize: state?.oversize,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      handleUpdate(values);
    },
  });
  return { formik };
};
