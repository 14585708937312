import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

const ContactUs = () => {
  return (
    <Box component="div" sx={{ background: "background.default" }}>
      <Container
        sx={{
          paddingTop: "5%",
          paddingBottom: "10%",
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: 700,
                color: "primary.main",
                mb: "10%",
              }}
            >
              Need Any Help?
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mb: 3 }}>
                <Typography sx={{ fontWeight: 600, color: "text.primary" }}>
                  Contact Us
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <TextField name="name" label="Name" fullWidth />
              </Grid>
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <TextField name="email" label="Email" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  name="message"
                  minRows={4}
                  maxRows={6}
                  fullWidth
                  label="Your Message"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{ textTransform: "none" }}
                >
                  Send Message
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography sx={{ fontWeight: 600, color: "text.primary" }}>
                  We are here !
                </Typography>
                <Typography sx={{ color: "text.primary" }}>
                  Sunday-Friday / 10:00 AM TO 7:00 PM
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <iframe
                  title="map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.8367009643393!2d85.34977585016195!3d27.691441582714035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19040328159d%3A0xf36c72473f0441!2sNepal%20Express%20Parcel!5e0!3m2!1sen!2snp!4v1674455031971!5m2!1sen!2snp"
                  style={{
                    border: 0,
                    width: "85%",
                    height: "400px",
                  }}
                  allowFullScreen={false}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ContactUs;
