import * as Yup from "yup";

const updateShipmentStatusValidationSchema = Yup.object().shape({
  status: Yup.string().required("Status is required"),
  receiverName: Yup.string(),
  receivedDate: Yup.string().when("status", {
    is: "Delivered",
    then: Yup.string().required("Received Date is required"),
    otherwise: Yup.string(),
  }),
  remarks: Yup.string().when("status", (status, schema) => {
    return status !== "Pending" && status !== "InTransit"
      ? schema.required("Remarks is required")
      : schema;
  }),
});

export { updateShipmentStatusValidationSchema };
