import React, { useEffect } from "react";
import { useNavigate, Navigate, Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import { getUser, removeUser } from "../app/utils/cookieHelper";

const LoggedInRoutes = ({ redirectTo, isRole }) => {
  const history = useNavigate();

  const { token, tempPassword, role } = getUser();

  useEffect(() => {
    if (!token) {
      removeUser();
      history("/login");
      toast.warn("Login To Access The Link");
    } else {
      if (tempPassword) history("/temp-password");
    }
    // eslint-disable-next-line
  }, []);

  if (isRole !== role) return <Navigate exact to={redirectTo} />;

  return <Outlet />;
};

export default LoggedInRoutes;
