import { useFormik } from "formik";
import {
  useGetCustomers,
  useGetServiceProviders,
} from "../../../../api/custom_shipment/useCustomShipment";
import { useUpdateConfirmedShipmentRequestLite } from "../../../../api/nepal_lite/useNepalLite";
import { shipmentLiteValidationSchema } from "../shipment_request/shipmentLiteValidationSchema";

export const useUpdateConfirmShipmentLiteForm = ({ data, isLoading }) => {
  const { data: serviceProviderData, isLoading: serviceProviderLoading } =
    useGetServiceProviders();
  const { data: customerData, isLoading: customerLoading } = useGetCustomers();

  const serviceProviderList =
    serviceProviderData &&
    serviceProviderData?.map((serviceProvider) => {
      return { value: serviceProvider?.id, label: serviceProvider?.name };
    });

  const customerList =
    customerData &&
    customerData?.map((customer) => {
      return {
        id: customer?.id,
        email: customer?.email,
        label:
          customer?.companyName ||
          customer?.firstName + " " + customer?.lastName,
      };
    });

  const { mutate } = useUpdateConfirmedShipmentRequestLite({});
  const dimension = data?.dimension?.split("_");
  const length = [];
  const bredth = [];
  const height = [];
  const noOfBoxesToCalculate = [];

  for (const element of dimension) {
    const dimensions = element.split("*"); // split each set of dimensions into separate values

    length.push(parseInt(dimensions[0])); // assume the dimensions are in integer format
    bredth.push(parseInt(dimensions[1]));
    height.push(parseInt(dimensions[2]));
    noOfBoxesToCalculate.push(parseInt(dimensions[3] || 1));
  }
  const formik = useFormik({
    initialValues: {
      actualWeight: data?.actualWeight || 0,
      appliedWeight: data?.appliedWeight || 0,
      awb: data?.awb || "",
      consignee: data?.consignee || "",
      currency: data?.currency || "",
      customClearance: data?.customClearance || "NepalEx",
      descriptionOfGoods: data?.descriptionOfGoods || "",
      destination: data?.destination || "",
      length: length || 0,
      bredth: bredth || 0,
      height: height || 0,
      noOfBoxesToCalculate: noOfBoxesToCalculate || [],
      dimensionalWeight: data?.dimensionalWeight || 0,
      email: data?.email || "",
      exchangeRate: data?.exchangeRate || "",
      invoiceValue: data?.invoiceValue || 0,
      invoiceValueForCustom: data?.invoiceValueForCustom || 0,
      noOfIronStrip: data?.noOfIronStripe || 0,
      noOfPlasticStrip: data?.noOfPlasticStrip || 0,
      numberOfBoxes: data?.numberOfBoxes || 0,
      numberOfOversizeBoxes: data?.numberOfOversizeBoxes || 0,
      postalCode: data?.postalCode || "",
      remarks: data?.remarks || "",
      remoteArea: data?.remoteArea || false,
      packageType: data?.packageType || "",
      paymentType: data?.paymentType || "Credit",
      requestDate: data?.requestDate || "",
      service: data?.service || "Courier",
      serviceProviderId: data?.serviceProviderId || null,
      shipmentType: data?.shipmentType || "Commercial",
      shipperId: data?.shipperId || null,
      status: data?.status || "InTransit",
      shipper: data?.shipper || "",
      importExport: data?.importExport || "",
      shipperReference: data?.shipperReference || "",
    },
    validationSchema: shipmentLiteValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleConfrim(values);
    },
  });

  const handleConfrim = (values) => {
    let dimension = "";
    for (let i = 0; i < values?.length?.length; i++) {
      dimension += `${values?.length[i] || 0}*${values?.bredth[i] || 0}*${
        values?.height[i] || 0
      }*${values?.noOfBoxesToCalculate[i] || 1}_`;
    }
    dimension = dimension.slice(0, -1);

    values = {
      id: data?.id,
      actualWeight: values?.actualWeight,
      appliedWeight: values?.appliedWeight,
      awb: values?.awb,
      consignee: values?.consignee,
      currency: values?.currency,
      customClearance: values?.customClearance,
      descriptionOfGoods: values?.descriptionOfGoods,
      destination: values?.destination,
      dimension: dimension,
      dimensionalWeight: values?.dimensionalWeight,
      email: values?.email,
      exchangeRate: values?.exchangeRate,
      invoiceValue: values?.invoiceValue,
      invoiceValueForCustom: values?.invoiceValueForCustom,
      noOfIronStrip: values?.noOfIronStrip,
      noOfPlasticStrip: values?.noOfPlasticStrip,
      numberOfBoxes: values?.numberOfBoxes,
      numberOfOversizeBoxes: values?.numberOfOversizeBoxes,
      postalCode: values?.postalCode,
      remarks: values?.remarks,
      remoteArea: values?.remoteArea,
      requestDate: values?.requestDate,
      packageType: values?.packageType,
      paymentType: values?.paymentType,
      service: values?.service,
      serviceProviderId: values?.serviceProviderId,
      shipmentType: values?.shipmentType,
      status: values?.status,
      shipperId: values?.shipperId,
      shipper: values?.shipper,
      sendEmail: values?.sendEmail || false,
      importExport: values?.importExport,
      shipperReference: values?.shipperReference,
    };
    mutate(values, {
      onSuccess: () => {
        formik.handleReset();
      },
    });
  };

  return {
    formik,
    serviceProviderList,
    customerList,
    isLoading: serviceProviderLoading || customerLoading || isLoading,
  };
};
