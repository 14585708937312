import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  getZone,
  addZone,
  deleteZone,
} from "../../../../api/service_provider/serviceprovider-api";

export const useGetZone = (id) => {
  return useQuery(["getZone", id], () => getZone(id), {
    cacheTime: 10000,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};

export const useAddZone = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(["addZone"], (formData) => addZone(formData), {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["getZone"]);
      toast.success("Succesfully added service provider zone");
      onSuccess && onSuccess(data, variables, context);
    },
    onError: (err, _variables, _context) => {
      toast.error(`error: ${err.message}`);
    },
  });
};

export const useDeleteZone = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(["deleteZone"], (zone) => deleteZone(zone), {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["getZone"]);
      toast.success("Succesfully deleted service provider zone");
      onSuccess && onSuccess(data, variables, context);
    },
    onError: (err, _variables, _context) => {
      toast.error(`error: ${err.message}`);
    },
  });
};
