import {axiosInstance} from "../../../../app/api/axiosInterceptor"

export const addNewShipmentRate = async (values) => {
    const {data} = await axiosInstance.post("/v/admin/shipment-rate", values)
    return data
}

export const updateShipmentRate = async (values) => {
    const {data} = await axiosInstance.put("/v/admin/shipment-rate", values)
    return data
}

export const getShipmentRate = async ({id, zone}) => {
    const {data} = await axiosInstance.get(
        `/v/admin/shipment-rates?shipmentProviderId=${id}&zone=${zone}`
    )
    return data
}

export const deleteShipmentRate = async ({shipmentProviderId, zone}) => {
    const endpoint = '/v/admin/shipment-rate'
    const config = {
        params: {
            shipmentProviderId,
            zone,
        }
    }
    const {data} = await axiosInstance.delete(endpoint,config)
    return data
}