import {useFormik} from "formik"
import {useUpdateShipmentRate} from "../../../api/service_provider/rates/useServiceProviderRate"
import {shipmentProviderCountryValidationSchema} from "./shipmentProviderCountryValidationSchema"

export const useEditExportRatesForm = ({data, handleClose, id}) => {
    const {mutate: mutateUpdate} = useUpdateShipmentRate({})

    const formik = useFormik({
        initialValues: {
            country: data?.country,
            packageType: data?.packageType,
            currency: data?.currency,
            customerCategory: data?.customerCategory,
            emergencySurcharge: data?.emergencySurcharge,
            rate: data?.rate,
            weight: data?.weight,
        },
        validationSchema: shipmentProviderCountryValidationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleUpdate({
                ...values,
                emergencySurcharge: values.emergencySurcharge || 0
            })
        },
    })

    const handleUpdate = (values) => {
        values = {
            ...values,
            id: data?.id,
            shipmentProviderId: Number(id),
        }

        mutateUpdate(values, {onSuccess: () => handleClose()})
    }

    return {formik}
}
