import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SafetyCheckIcon from "@mui/icons-material/SafetyCheck";
import HandshakeIcon from "@mui/icons-material/Handshake";

const ChooseUs = () => {
  return (
    <Box sx={{ background: "#F8F8F8" }}>
      <Container
        sx={{
          paddingTop: "5%",
          paddingBottom: "15%",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: 700,
                color: "primary.main",
                mb: "15%",
              }}
            >
              Why Choose Us?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center", mb: 3 }}
                  >
                    <EmojiEventsIcon sx={{ fontSize: "70px" }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      align="center"
                      sx={{
                        color: "primary.main",
                        fontSize: "20px",
                        fontWeight: 600,
                      }}
                    >
                      #1 Service Provider
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align="center" sx={{ fontSize: "14px", mt: 1 }}>
                      Currently Nepal's Number 1 Courier Service Provider with
                      largest volume of shipments.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center", mb: 3 }}
                  >
                    <AccessTimeIcon sx={{ fontSize: "70px" }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      align="center"
                      sx={{
                        color: "primary.main",
                        fontSize: "20px",
                        fontWeight: 600,
                      }}
                    >
                      9+ Years of Experience
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align="center" sx={{ fontSize: "14px", mt: 1 }}>
                      More than 9 years of experience in the service.One of the
                      oldest service provider in Nepal.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center", mb: 3 }}
                  >
                    <SafetyCheckIcon sx={{ fontSize: "70px" }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      align="center"
                      sx={{
                        color: "primary.main",
                        fontSize: "20px",
                        fontWeight: 600,
                      }}
                    >
                      Fast and Reliable
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align="center" sx={{ fontSize: "14px", mt: 1 }}>
                      Providing Fast and Reliable service since 2012. We take
                      care of every consignments serously.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center", mb: 3 }}
                  >
                    <HandshakeIcon sx={{ fontSize: "70px" }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      align="center"
                      sx={{
                        color: "primary.main",
                        fontSize: "20px",
                        fontWeight: 600,
                      }}
                    >
                      Provides Best Deals
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align="center" sx={{ fontSize: "14px", mt: 1 }}>
                      Provides best deal among the competition. We care for our
                      customer feelings.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ChooseUs;
