import { Box } from "@mui/material";
import React from "react";
import TempPasswordForm from "../../components/form/tempPassword/TempPasswordForm";

const TempPassword = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        backgroundColor: "background.default",
      }}
    >
      <TempPasswordForm />
    </Box>
  );
};

export default TempPassword;
