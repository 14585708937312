import { useNavigate } from "react-router-dom";

export const useShipmentInvoiceTable = () => {
  const navigate = useNavigate();
  const columns = [
    {
      title: "Invoice Date",
      field: "invoiceDate",
      emptyValue: "-",
    },
    {
      title: "Consignee",
      field: "consignee",
      emptyValue: "-",
    },
    {
      title: "Destination",
      field: "destination",
    },
    {
      title: "Notes",
      field: "notes",
    },
    {
      title: "Total Charges",
      field: "totalCharges",
    },
  ];
  function handleDoubleClickRow(rowData) {
    navigate(`/bill/${rowData?.billedId}`);
  }
  return { columns, handleDoubleClickRow };
};
