import { useGetServiceProviders } from "../../../api/service_provider/useServiceProvider";

import { Link } from "react-router-dom";

export const useServiceProviderTable = () => {
  const { data, isLoading } = useGetServiceProviders();

  const column = [
    {
      title: "id",
      field: "id",
      render: (rowData) => data?.findIndex((x) => x?.id === rowData?.id) + 1,
    },
    {
      title: "Name",
      field: "name",
      render: (rowData) => <Link to={`${rowData?.id}`}>{rowData?.name}</Link>,
    },
    {
      title: "Description",
      field: "description",
    },
    // {
    //   title: "Enabled",
    //   field: "enabled",
    // },
    // {
    //   title: "Api Service",
    //   field: "apiService",
    // },
    // {
    //   title: "Api Key",
    //   field: "apiKey",
    // },
  ];

  return { column, data, isLoading };
};
