import MaterialTable from "@material-table/core";
import React from "react";
import { useZoneTable } from "../../../../hooks/components/table/service_provider/zone/useZoneTable";
import AddIcon from "@mui/icons-material/Add";
import AddServiceProviderZoneCountryForm from "../../../form/service_provider/zone/AddServiceProviderZoneCountryForm";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddZoneCountryForm from "../../../form/service_provider/zone/country/AddZoneCountryForm"

export const ZoneTable = ({ id }) => {
  const { dataList, isLoading, columns, handleDoubleClickRow, handleDelete } =
    useZoneTable(id);
  const [open, setOpen] = useState(false);
    const [addCountry, setAddCountry] = useState({
        open:false,
        zone:"",
    })

    const handleClose = () => {
    setOpen(false);
    setAddCountry({
        open:false,
        zone:"",
    })
  };

  return (
    <div>
      <MaterialTable
        title="Zone"
        data={dataList || []}
        detailPanel={({rowData})=>{
            return(
                <div
                    style={{marginLeft:"2rem"}}
                >
                    <p>Countries: {rowData?.countries.map((item,index)=>index === rowData?.countries.length -1?item:item +", ")}</p>
                </div>
            )
        }}
        isLoading={isLoading}
        columns={columns}
        onRowDoubleClick={(_event, rowData) => handleDoubleClickRow(rowData)}
        actions={[
          {
            icon: () => <AddIcon />,
            tooltip: "Add Zone",
            isFreeAction: true,
            onClick: () => setOpen(true),
          },
          {
                icon: () => <AddIcon />,
                tooltip: "add country",
                onClick: (_event, row) =>{
                    setAddCountry({
                        open:true,
                        zone:row.zone,
                    })
                }
          },
          {
            icon: () => <DeleteIcon />,
            tooltip: "delete Zone",
            onClick: (_event, row) =>
              window.confirm(
                "Do you want to delete this zone? (All details i.e: rates and countries, of this zone will be deleted )"
              )
                ? handleDelete(row?.zone)
                : alert("Zone deletion was cancelled."),
          },
        ]}
        options={{
          padding: "dense",
          pageSize: 10,
          actionsColumnIndex: -1,
        }}
      />
      <AddServiceProviderZoneCountryForm
        open={open}
        handleClose={handleClose}
        id={id}
      />
        <AddZoneCountryForm
            id={id}
            zone={addCountry.zone}
            handleClose={handleClose}
            open={addCountry.open}
        />
    </div>
  );
};
