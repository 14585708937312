import moment from "moment"
import {toast} from "react-toastify"

export const validateDateRange = (fromDateStr, toDateStr,title, maxRangeDays = 14,) => {
    const fromDate = moment(fromDateStr)
    const toDate = moment(toDateStr)
    const dateDiffInDays = toDate.diff(fromDate, 'days')

    if (dateDiffInDays > maxRangeDays) {
        toast.dismiss()
        toast.warning(`Date range can only be up to ${maxRangeDays} days. Adjusting dates accordingly.`)
        const newToDate = fromDate.clone().add(maxRangeDays, 'days')
        const newFromDate = toDate.clone().subtract(maxRangeDays, 'days')
        return {
            requestDateFrom:title ==="requestDateFrom"?fromDate.format('YYYY-MM-DD'): fromDate.isBefore(newFromDate) ? newFromDate.format('YYYY-MM-DD') : fromDate.format('YYYY-MM-DD'),
            requestDateTo:title ==="requestDateTo"?toDate.format('YYYY-MM-DD'): toDate.isAfter(newToDate) ? newToDate.format('YYYY-MM-DD') : toDate.format('YYYY-MM-DD'),
        }
    }

    return {
        requestDateFrom: fromDate.format('YYYY-MM-DD'),
        requestDateTo: toDate.format('YYYY-MM-DD'),
    }
}