import {
  Button,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../../app/contexts/user/UserContext";
import {
  useGetFinalShipment,
  useVerifyWeight,
} from "../../../api/nepal_lite/useNepalLite";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import moment from "moment";
import { toast } from "react-toastify";
import {
  useGetCustomers,
  useGetServiceProviders,
} from "../../../api/custom_shipment/useCustomShipment";
import {validateDateRange} from "../../../../../utils/validateDateRange"

const statusOptions = [
  { label: "--", value: "" },
  { label: "Delivered", value: "Delivered" },
  { label: "Exception", value: "Exception" },
  { label: "In Transit", value: "InTransit" },
  { label: "Pending", value: "Pending" },
  { label: "Return", value: "Return" },
];

const billedOptions = [
  { label: "All", value: "All" },
  { label: "Registered", value: "billed" },
  { label: "Non-registered", value: "Unbilled" },
];

const rateOptions = [
  { label: "All", value: "All" },
  { label: "Added", value: "rateAdded" },
  { label: "Not Added", value: "rateNotAdded" },
];

const shipmentTypeOptions = [
  { label: "All", value: "All" },
  {
    label: "Commercial",
    value: "Commercial",
  },
  {
    label: "Non-Commercial",
    value: "NonCommercial",
  },
];

const paymentTypeOptions = [
  { label: "All", value: "All" },
  { label: "Credit", value: "Credit" },
  { label: "Cash", value: "Cash" },
];

const weightVerifiedOptions = [
  { label: "All", value: "All" },
  { label: "Verified", value: "Verified" },
  { label: "Not Verified", value: "NotVerified" },
];

const serviceOptions = [
  { label: "All", value: "All" },
  {
    label: "Cargo",
    value: "Cargo",
  },
  {
    label: "Courier",
    value: "Courier",
  },
];

const billTypeOptions = [
  { label: "-", value: "All" },
  { label: "Pan", value: "Pan" },
  { label: "TPan", value: "TPan" },
  { label: "Vat", value: "Vat" },
];

export function useFinalShipmentTable({ billed }) {
  const { role } = useContext(UserContext);
  const oldFilter = JSON.parse(sessionStorage.getItem("c.shipment-filter"));
  const [buyingPriceOpen, setBuyingPriceOpen] = useState({
    open: false,
    id: null,
  });
  const [inputSearch, setInputSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [total, setTotal] = useState({
    totalNoOfBoxes: 0,
    totalOversize: 0,
    totalAppliedWeight: 0,
    totalActualWeight: 0,
    totalDimensionalWeight: 0,
  });
  const initialFilter = oldFilter?.filter
      ? validateDateRange(oldFilter.filter.requestDateFrom, oldFilter.filter.requestDateTo)
      : {
        requestDateFrom: moment().subtract(7, "days").format("YYYY-MM-DD"),
        requestDateTo: moment().add(7, "days").format("YYYY-MM-DD"),
      };
  const [filter, setFilter] = useState(initialFilter);
  const [uiFilter, setUiFilter] = useState(
    oldFilter?.uiFilter
      ? {
          status: oldFilter?.uiFilter?.status,
          rate: oldFilter?.uiFilter?.rate,
          service: oldFilter?.uiFilter?.service,
          shipmentType: oldFilter?.uiFilter?.shipmentType,
          paymentType: oldFilter?.uiFilter?.paymentType,
          weightVerified: oldFilter?.uiFilter?.weightVerified,
          shipper: oldFilter?.uiFilter?.shipper,
          serviceProvider: oldFilter?.uiFilter?.serviceProvider,
          billType: oldFilter?.uiFilter?.billType,
        }
      : {
          status: "",
          rate: "All",
          service: "All",
          shipmentType: "All",
          paymentType: "All",
          weightVerified: "All",
          shipper: null,
          serviceProvider: null,
          billType: "All",
        }
  );

  const handleCloseBuyingPrice = () => {
    setBuyingPriceOpen({ open: false, id: null });
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    let isCancelled = false;

    const handleChange = async () => {
      await timeout(400);

      if (!isCancelled) {
        if (inputSearch?.length > 2) {
          handleSearch(inputSearch);
        } else {
          handleSearch("");
        }
      }
    };

    handleChange();
    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch]);

  useEffect(() => {
    let isCancelled = false;

    const handleChange = async () => {
      await timeout(400);

      if (!isCancelled) {
        sessionStorage.setItem(
          "c.shipment-filter",
          JSON.stringify({ uiFilter: uiFilter, filter: filter })
        );
      }
    };

    handleChange();
    return () => {
      isCancelled = true;
    };
  }, [uiFilter, filter]);

  const { data: serviceProviderData, isLoading: serviceProviderLoading } =
    useGetServiceProviders();
  const { data: customerData, isLoading: customerLoading,refetch:refetchCustomer } = useGetCustomers();

  let serviceProviderList =
    serviceProviderData &&
    serviceProviderData?.map((serviceProvider) => {
      return { value: serviceProvider?.id, label: serviceProvider?.name };
    });

  const customerList =
    customerData &&
    customerData?.map((customer) => {
      return {
        id: customer?.id,
        email: customer?.email,
        label:
          customer?.companyName ||
          customer?.firstName + " " + customer?.lastName,
      };
    });

  const handleInputSearch = (query) => {
    setInputSearch(query);
  };

  const navigate = useNavigate();
  const [open, setOpen] = useState({
    isOpen: false,
    id: null,
  });
  const [billingForm, setBillingForm] = useState({
    open: false,
    data: {
      shipmentIds: [],
      freightTotal: 0,
      tiaTotal: 0,
      customsTotal: 0,
      docTotal: 0,
      stripTotal: 0,
      otherTotal: 0,
      vatTotal: 0,
      grandTotal: 0,
    },
  });

  const [updateWeight, setUpdateWeight] = useState({
    isOpen: false,
    data: {
      id: null,
      actualWeight: null,
      dimensionalWeight: null,
    },
  });

  function handleWeightOpen(data) {
    setUpdateWeight({
      isOpen: true,
      data: {
        id: data?.id,
        actualWeight: data?.actualWeight,
        dimensionalWeight: data?.dimensionalWeight,
      },
    });
  }

  function handleOpen(data) {
    setOpen({
      isOpen: true,
      data: data,
    });
  }

  function handleClose() {
    setOpen({
      isOpen: false,
      id: null,
    });
    setUpdateWeight({
      isOpen: false,
      data: {
        id: null,
        actualWeight: null,
        dimensionalWeight: null,
      },
    });
    setBillingForm({
      open: false,
      data: {
        shipmentIds: [],
        freightTotal: 0,
        tiaTotal: 0,
        customsTotal: 0,
        docTotal: 0,
        stripTotal: 0,
        otherTotal: 0,
        vatTotal: 0,
        grandTotal: 0,
      },
    });
  }

  const { data: shipmentData, isLoading } = useGetFinalShipment({
    filter,
    searchValue,
  });
  const [filteredData, setFilteredData] = useState();

  const handleFilteredData = useCallback(() => {
    if (!isLoading) {
      let finalFilter = shipmentData?.filter((item) => {
        if (uiFilter.status && item.status !== uiFilter?.status) {
          return false;
        } else if (
          (uiFilter.status === "" ||
            uiFilter.status === null ||
            uiFilter.status === "undefined") &&
          item.status === "Return"
        ) {
          return false;
        }
        if (billed !== "All" && item.billed !== billed) {
          return false;
        }
        if (
          uiFilter.rate !== "All" &&
          item.rateAdded !== (uiFilter.rate === "rateAdded" ? true : false)
        ) {
          return false;
        }
        if (uiFilter.service !== "All" && item.service !== uiFilter.service) {
          return false;
        }
        if (
          uiFilter.shipmentType !== "All" &&
          item.shipmentType !== uiFilter.shipmentType
        ) {
          return false;
        }
        if (uiFilter?.shipper && item.shipperId !== uiFilter?.shipper) {
          return false;
        }
        if (
          uiFilter?.serviceProvider &&
          item.serviceProviderId !== uiFilter?.serviceProvider
        ) {
          return false;
        }
        if (
          uiFilter.paymentType !== "All" &&
          item.paymentType !== uiFilter.paymentType
        ) {
          return false;
        }
        if (
          uiFilter.billType !== "All" &&
          item.billType !== uiFilter.billType
        ) {
          return false;
        }
        if (
          uiFilter.weightVerified !== "All" &&
          item.weightVerified !==
            (uiFilter.weightVerified === "Verified" ? true : false)
        ) {
          return false;
        }
        return true;
      });
      setFilteredData(finalFilter);
    }
    //eslint-disable-next-line
  }, [uiFilter, shipmentData, isLoading, billed]);

  useEffect(() => {
    let totalNoOfBoxes;
    let totalOversize;
    let totalAppliedWeight;
    let totalActualWeight;
    let totalDimensionalWeight;
    if (!isLoading && filteredData?.length > 0) {
      totalNoOfBoxes = filteredData?.reduce(
        (acc, item) => acc + item?.numberOfBoxes,
        0
      );
      totalOversize = filteredData?.reduce(
        (acc, item) => acc + item?.numberOfOversizeBoxes,
        0
      );
      totalAppliedWeight = filteredData?.reduce(
        (acc, item) => acc + item?.appliedWeight,
        0
      );
      totalActualWeight = filteredData?.reduce(
        (acc, item) => acc + item?.actualWeight,
        0
      );
      totalDimensionalWeight = filteredData?.reduce(
        (acc, item) => acc + item?.dimensionalWeight,
        0
      );
      setTotal({
        totalNoOfBoxes: totalNoOfBoxes,
        totalOversize: totalOversize,
        totalAppliedWeight: totalAppliedWeight,
        totalActualWeight: totalActualWeight,
        totalDimensionalWeight: totalDimensionalWeight,
      });
    } else {
      setTotal({
        totalNoOfBoxes: 0,
        totalOversize: 0,
        totalAppliedWeight: 0,
        totalActualWeight: 0,
        totalDimensionalWeight: 0,
      });
    }
  }, [filteredData, isLoading]);

  const handleAutoCompleteFilter = (values) => {
    values?.name === "SHIPPER"
      ? setUiFilter({ ...uiFilter, shipper: values?.value })
      : setUiFilter({ ...uiFilter, serviceProvider: values?.value });
  };

  useEffect(() => {
    handleFilteredData();
  }, [handleFilteredData]);

  const { mutate } = useVerifyWeight({});

  function handleDoubleClickRow(rowData) {
    navigate(
      `/${role === "SysAdmin" ? "super" : "admin"}/shipment-${rowData?.id}`
    );
  }

  const getItemsBetweenObjects = (data, object1, object2) => {
    const startIndex = data.findIndex((item) => item?.id === object1?.id);
    const endIndex = data.findIndex((item) => item?.id === object2?.id);

    if (startIndex === -1 || endIndex === -1) {
      return [];
    }

    if (startIndex < endIndex) {
      return data.slice(startIndex + 1, endIndex);
    } else {
      return data.slice(endIndex + 1, startIndex);
    }
  };

  function copyAwb(data) {
    if (data?.length === 2) {
      const confirmSelection = window.confirm(
        "Do you want to select multiple AWB?"
      );

      if (confirmSelection) {
        data = data.concat(
          getItemsBetweenObjects(filteredData, data[0], data[1])
        );
      }
    }
    let awbList = "";
    //     data.forEach((shipment, index) => {
    //       awbList =
    //         data?.length - 1 > index
    //           ? awbList.concat(`${shipment?.awb}
    // `)
    //           : awbList.concat(shipment?.awb);
    //     });
    data.forEach((shipment, index) => {
      awbList =
        data?.length - 1 > index
          ? awbList.concat(`${shipment?.awb}\n`)
          : awbList.concat(shipment?.awb);
    });
    navigator.clipboard.writeText(awbList);
    toast.success("AWB copied");
  }

  function groupBilling(data) {
    let shipmentIds = [];
    let freightTptal = 0;
    let tiaTotal = 0;
    let customsTotal = 0;
    let docTotal = 0;
    let stripTotal = 0;
    let otherTotal = 0;
    let vatTotal = 0;
    let grandTotal = 0;
    data.forEach((shipment, index) => {
      shipmentIds.push(shipment?.id);
      freightTptal += shipment?.freight;
      tiaTotal += shipment?.tia;
      customsTotal += shipment?.custom;
      docTotal += shipment?.doc;
      stripTotal += shipment?.strip;
      otherTotal += shipment?.others;
      vatTotal += shipment?.vat;
      grandTotal += shipment?.totalCharges;
    });
    setBillingForm({
      open: true,
      data: {
        shipmentIds: shipmentIds,
        freightTotal: freightTptal,
        tiaTotal: tiaTotal,
        customsTotal: customsTotal,
        docTotal: docTotal,
        stripTotal: stripTotal,
        otherTotal: otherTotal,
        vatTotal: vatTotal,
        grandTotal: grandTotal,
      },
    });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newFilter;

    switch (name) {
      case "requestDateFrom":
        newFilter = validateDateRange(value, filter.requestDateTo,"requestDateFrom");
        console.log(newFilter);
        break;
      case "requestDateTo":
        newFilter = validateDateRange(filter.requestDateFrom, value,"requestDateTo");
        console.log(newFilter);
        break;
      default:
        console.log(`Unhandled input name: ${name}`);
        return;
    }

    setFilter(newFilter);
  }

  const handleUiFilter = (e) => {
    switch (e.target.name) {
      case "status":
        setUiFilter({
          ...uiFilter,
          status: e.target.value,
        });
        break;
      case "billed":
        setUiFilter({
          ...uiFilter,
          billed: e.target.value,
        });
        break;
      case "rate":
        setUiFilter({
          ...uiFilter,
          rate: e.target.value,
        });
        break;
      case "service":
        setUiFilter({
          ...uiFilter,
          service: e.target.value,
        });
        break;
      case "shipmentType":
        setUiFilter({
          ...uiFilter,
          shipmentType: e.target.value,
        });
        break;
      case "shipper":
        setUiFilter({
          ...uiFilter,
          shipper: e.target.value,
        });
        break;
      case "serviceProvider":
        setUiFilter({
          ...uiFilter,
          serviceProvider: e.target.value,
        });
        break;
      case "paymentType":
        setUiFilter({
          ...uiFilter,
          paymentType: e.target.value,
        });
        break;
      case "weightVerified":
        setUiFilter({
          ...uiFilter,
          weightVerified: e.target.value,
        });
        break;
      case "billType":
        setUiFilter({
          ...uiFilter,
          billType: e.target.value,
        });
        break;
      default:
        console.log(e.target.name);
        break;
    }
  };

  const customerMemoizedOptions = useMemo(() => customerList, [customerList]);

  const customerMemoizedGetOptionLabel = useMemo(
    () => (option, i) => `${option.label} : ${option.email}`,
    []
  );

  const serviceProviderMemoizedOptions = useMemo(
    () => serviceProviderList,
    [serviceProviderList]
  );

  const serviceProviderMemoizedGetOptionLabel = useMemo(
    () => (option, i) => `${option.label}`,
    []
  );

  const sysAdminColumn = [
    {
      title: "Weight Verified",
      field: "weightVerified",
      fixed: "left",
      width: 130,
      render: (rowData) => {
        return rowData?.weightVerified ? (
          <Chip color="success" icon={<DoneIcon />} label="verified" />
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              onClick={() =>
                window.confirm("Verify package weight?")
                  ? mutate(rowData?.id)
                  : window.alert("Weight not verified")
              }
            >
              <CheckBoxOutlineBlankIcon />
            </IconButton>
          </div>
        );
      },
    },
    {
      title: "Rate Added",
      field: "rateAdded",
      fixed: "left",
      width: 140,
      render: (rowData) => {
        return rowData?.rateAdded ? (
          <Chip color="success" icon={<DoneIcon />} label="Added" />
        ) : (
          <Chip color="warning" icon={<ClearIcon />} label="Pending" />
        );
      },
    },
    {
      title: "Date",
      field: "requestDate",
      width: 150,
    },
    {
      title: "Shipper",
      field: "shipper",
      emptyValue: "-",
      width: 150,
    },
    {
      title: "Destination",
      field: "destination",
      emptyValue: "-",
      width: 150,
    },
    {
      title: "Currency",
      field: "currency",
      emptyValue: "-",
      width: 100,
    },
    {
      title: "Exchange Rate",
      field: "exchangeRate",
      emptyValue: "-",
      width: 100,
    },
    {
      title: "Courier",
      field: "service",
      emptyValue: "-",
      width: 150,
    },
    {
      title: "Shipment Type",
      field: "shipmentType",
      emptyValue: "-",
      width: 150,
    },
    {
      title: "Applied Weight",
      field: "appliedWeight",
      emptyValue: "-",
      width: 100,
    },
    {
      title: "Consignee",
      field: "consignee",
      emptyValue: "-",
      width: 150,
    },
    {
      title: "Bill Type",
      field: "billType",
      emptyValue: "-",
      width: 100,
    },
    {
      title: "Given Rate",
      field: "freightGivenRate",
      emptyValue: "-",
      width: 150,
    },
    {
      title: "Freight",
      field: "freight",
      emptyValue: "-",
      width: 150,
    },
    {
      title: "Tia",
      field: "tia",
      emptyValue: "-",
      width: 100,
    },
    {
      title: "Discount on Tia",
      field: "discountOnTia",
      emptyValue: "-",
      width: 150,
    },
    {
      title: "Strip",
      field: "strip",
      emptyValue: "-",
      width: 100,
    },
    {
      title: "Discount on Strip",
      field: "discountOnStrip",
      emptyValue: "-",
      width: 150,
    },
    {
      title: "Custom",
      field: "custom",
      emptyValue: "-",
      width: 100,
    },
    {
      title: "Discount on custom",
      field: "discountOnCustom",
      emptyValue: "-",
      width: 150,
    },
    {
      title: "Doc",
      field: "doc",
      emptyValue: "-",
      width: 100,
    },
    {
      title: "Oversize",
      field: "oversize",
      emptyValue: "-",
      width: 100,
    },
    {
      title: "Remote",
      field: "remote",
      emptyValue: "-",
      width: 100,
    },
    {
      title: "Others",
      field: "other",
      emptyValue: "-",
      width: 150,
    },
    {
      title: "Vat",
      field: "vat",
      emptyValue: "-",
      width: 150,
    },
    {
      title: "Total",
      field: "totalCharges",
      emptyValue: "-",
      width: 150,
    },
    {
      title: "Buying Price",
      field: "buyingPrice",
      width: 200,
      render: (rowData) => {
        return rowData?.buyingPrice ? (
          <Typography>{rowData?.buyingPrice}</Typography>
        ) : (
          <Button
            fullWidth
            variant="outlined"
            sx={{ textTransform: "none" }}
            onClick={() => setBuyingPriceOpen({ open: true, id: rowData?.id })}
          >
            Add price
          </Button>
        );
      },
    },
    {
      title: "Profit",
      field: "profit",
      render: (rowData) => {
        return rowData?.totalCharges && rowData?.buyingPrice ? (
          <Typography>
            {rowData?.totalCharges - rowData?.buyingPrice}
          </Typography>
        ) : (
          <Typography>0</Typography>
        );
      },
      width: 150,
    },
  ];

  const customerServiceColumn = [
    {
      title: "Weight Verified",
      field: "weightVerified",
      width: 130,
      fixed: "left",
      render: (rowData) => {
        return rowData?.weightVerified ? (
          <Chip color="success" icon={<DoneIcon />} label="verified" />
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              onClick={() =>
                window.confirm("Verify package weight?")
                  ? mutate(rowData?.id)
                  : window.alert("Weight not verified")
              }
            >
              <CheckBoxOutlineBlankIcon />
            </IconButton>
          </div>
        );
      },
    },
    {
      title: "Rate Added",
      field: "rateAdded",
      fixed: "left",
      width: 140,
      render: (rowData) => {
        return rowData?.rateAdded ? (
          <Chip color="success" icon={<DoneIcon />} label="Added" />
        ) : (
          <Chip color="warning" icon={<ClearIcon />} label="Pending" />
        );
      },
    },
    {
      title: "Date",
      field: "requestDate",
      width: 170,
    },
    {
      title: "Status",
      field: "status",
      width: 150,
      render: (rowData) => {
        return (
          <Button
            onClick={() => handleOpen(rowData)}
            variant="outlined"
            sx={{
              display: "flex",
              alignItems: "center",
              textTransform: "none",
            }}
          >
            {rowData?.status}
            <EditIcon color="primary" sx={{ ml: 1 }} />
          </Button>
        );
      },
    },
    {
      title: "Shipper",
      field: "shipper",
      emptyValue: "-",
      width: 150,
    },
    {
      title: "Boxes",
      field: "numberOfBoxes",
      emptyValue: "-",
      width: 100,
    },
    {
      title: "Oversize Boxes",
      field: "numberOfOversizeBoxes",
      emptyValue: "-",
      width: 100,
    },
    {
      title: "Applied Weight",
      field: "appliedWeight",
      width: 100,
      render: (rowData) => {
        return (
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={8}>
              <Typography>{rowData?.appliedWeight}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Tooltip title="update weight">
                <IconButton onClick={() => handleWeightOpen(rowData)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        );
      },
    },
    {
      title: "Actual Weight",
      field: "actualWeight",
      emptyValue: "-",
      width: 100,
    },
    {
      title: "Dimensional Weight",
      field: "dimensionalWeight",
      emptyValue: "-",
      width: 150,
    },
    {
      title: "Pan No.",
      field: "panNo",
      emptyValue: "-",
      width: 100,
    },
    {
      title: "Consignee",
      field: "consignee",
      emptyValue: "-",
      width: 100,
    },
    {
      title: "Destination",
      field: "destination",
      emptyValue: "-",
      width: 150,
    },
    {
      title: "Postal code",
      field: "postalCode",
      emptyValue: "-",
      width: 100,
    },
    {
      title: "Service",
      field: "service",
      emptyValue: "-",
      width: 100,
    },
    {
      title: "Service Provider",
      field: "serviceProvider",
      emptyValue: "-",
      width: 100,
    },
  ];

  const columns = [
    {
      title: "#",
      field: "index",
      width: 80,
      render: (rowData) =>
        filteredData?.findIndex((x) => x?.id === rowData?.id) + 1,
    },
    {
      title: "Awb",
      field: "awb",
      fixed: "left",
      width: 170,
    },
  ];

  return {
    statusOptions,
    billedOptions,
    rateOptions,
    data: filteredData,
    columns: columns.concat(
      role === "SysAdmin" ? sysAdminColumn : customerServiceColumn
    ),
    isLoading,
    filter,
    open,
    handleClose,
    inputSearch,
    handleChange,
    handleInputSearch,
    handleDoubleClickRow,
    copyAwb,
    groupBilling,
    updateWeight,
    billingForm,
    uiFilter,
    shipmentTypeOptions,
    paymentTypeOptions,
    serviceOptions,
    weightVerifiedOptions,
    handleUiFilter,
    serviceProviderLoading,
    serviceProviderList,
    customerLoading,
    customerMemoizedOptions,
    customerMemoizedGetOptionLabel,
    serviceProviderMemoizedOptions,
    serviceProviderMemoizedGetOptionLabel,
    handleAutoCompleteFilter,
    billTypeOptions,
    handleCloseBuyingPrice,
    buyingPriceOpen,
    total,
    refetchCustomer
  };
}
