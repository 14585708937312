import MaterialTable from "@material-table/core";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import useZoneCountryTable from "../../../../../hooks/components/table/service_provider/zone/country/useZoneCountryTable";
import AddZoneCountryForm from "../../../../form/service_provider/zone/country/AddZoneCountryForm";
import { useState } from "react";

const ZoneCountryTable = ({ id, zone }) => {
  const { data, columns, isLoading, handleDelete } = useZoneCountryTable({
    id,
    zone,
  });
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <MaterialTable
        title="Country"
        data={data}
        isLoading={isLoading}
        columns={columns}
        actions={[
          {
            icon: () => <AddIcon />,
            tooltip: "Add Country",
            isFreeAction: true,
            onClick: () => setOpen(true),
          },
          {
            icon: () => <DeleteIcon />,
            tooltip: "delete country",
            onClick: (_event, row) => handleDelete(row?.id),
          },
        ]}
        options={{
          padding: "dense",
          pageSize: 10,
          actionsColumnIndex: -1,
        }}
      />
      <AddZoneCountryForm
        id={id}
        zone={zone}
        handleClose={handleClose}
        open={open}
      />
    </div>
  );
};

export default ZoneCountryTable;
