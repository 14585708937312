import React, { createContext, useEffect, useState } from "react";
import {
  useGetMyAvatar,
  useGetUserDetails,
} from "../../hooks/api/profile/useUser";

export const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  const { data, isLoading: loading, isError: error } = useGetUserDetails();
  const [myAvatar, setMyAvatar] = useState();
  const { data: avatar } = useGetMyAvatar();

  useEffect(() => {
    setMyAvatar(avatar && URL.createObjectURL(avatar));
  }, [avatar]);

  const email = data?.email;
  const isEnabled = data?.enabled;
  const fullName = data?.fullName;
  const id = data?.id;
  const role = data?.userRole;
  const username = data?.username;
  const createdDate = data?.created;
  const modifiedDate = data?.modified;
  const mobile = data?.mobile;
  const firstName = data?.firstName;
  const lastName = data?.lastName;
  const middleName = data?.middleName;
  const lastLogin = data?.lastLogin;

  return (
    <UserContext.Provider
      value={{
        email,
        loading,
        error,
        isEnabled,
        fullName,
        id,
        role,
        username,
        createdDate,
        mobile,
        modifiedDate,
        firstName,
        lastLogin,
        lastName,
        middleName,
        myAvatar,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
