import {
  Avatar,
  Badge,
  Grid,
  IconButton,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { UserContext } from "../../../app/contexts/user/UserContext";
import { Box } from "@mui/system";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AddAvatar from "../../components/form/settings/avatar/AddAvatar";
import ChangePassword from "../../components/form/settings/profile/ChangePassword";
import RateSettings from "../../components/form/settings/rate_settings/RateSettings";
import CustomerInfoConfig from "../../../super/pages/customer-info/CustomerInfoConfig";

const SmallAvatar = styled(Avatar)(() => ({
  width: 35,
  height: 35,
  display: "flex",
  justifyContent: "center",
}));

const Settings = () => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const { email, fullName, myAvatar, role } = useContext(UserContext);

  return (
    <Grid container spacing={2} sx={{ p: { xs: "30px 0", md: "30px" } }}>
      <Grid item xs={12}>
        <Paper>
          <Grid container spacing={2} sx={{ padding: { xs: 2, md: "25px" } }}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  <SmallAvatar>
                    <IconButton onClick={() => setOpen(true)}>
                      <CameraAltIcon color="secondary" />
                    </IconButton>
                  </SmallAvatar>
                }
              >
                <Avatar
                  src={myAvatar}
                  sx={{
                    bgcolor: "primary.main",
                    width: 90,
                    height: 90,
                  }}
                />
              </Badge>
              <Box sx={{ ml: 5 }}>
                <Typography sx={{ fontWeight: 600, fontSize: "24px" }}>
                  {fullName}
                </Typography>
                <Typography variant="subtitle2">{email}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
        <AddAvatar open={open} handleClose={handleClose} />
      </Grid>
      <Grid item xs={12}>
        <ChangePassword />
      </Grid>
      {role === "SysAdmin" ? (
        <Grid item xs={12}>
          <RateSettings />
        </Grid>
      ) : (
        <></>
      )}
      {role === "SysAdmin" && (
        <Grid item xs={12}>
          <CustomerInfoConfig />
        </Grid>
      )}
    </Grid>
  );
};

export default Settings;
