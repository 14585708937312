import { axiosInstance } from "../../../app/api/axiosInterceptor";

export const getShipmentRates = async (role) => {
  if (role === "SysAdmin") {
    const { data } = await axiosInstance.get("/v/sys/shipment-rates");
    return data;
  }
};

export const updateShipmentRates = async (values) => {
  const { data } = await axiosInstance.post("/v/sys/shipment-rates", values);
  return data;
};
