import { Grid } from "@mui/material";
import React, { useState } from "react";
import GeneralDetails from "./shipment/GeneralDetails";
import ShipmentConditionDetails from "./shipment/ShipmentConditionDetails";
import DestinationDetails from "./shipment/DestinationDetails";
import PackageDetails from "./shipment/PackageDetails";
import InvoiceDetails from "./shipment/InvoiceDetails";
import BillShipmentsForm from "../../../../components/form/shipment/BillShipmentsForm";

const ShipmentDetails = ({ data }) => {
  const [billingForm, setBillingForm] = useState({
    open: false,
    data: {
      shipmentIds: [data?.id],
      freightTotal: data?.freight,
      tiaTotal: data?.tia,
      customsTotal: data?.custom,
      docTotal: data?.doc,
      stripTotal: data?.strip,
      vatTotal: data?.vat,
      grandTotal: data?.totalCharges,
    },
  });

  const handleClose = () => {
    setBillingForm({
      ...billingForm,
      open: false,
    });
  };
  const handleOpen = () => {
    setBillingForm({
      ...billingForm,
      open: true,
    });
  };
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <GeneralDetails
            billedId={data?.billedId}
            handleOpen={handleOpen}
            shipper={data?.shipper}
            consignee={data?.consignee}
            email={data?.email}
            date={data?.requestDate}
            remarks={data?.remarks}
            shipperReference={data?.shipperReference}
            status={data?.status}
            billed={data?.billed}
            paymentType={data?.paymentType}
            nepalexTrackingNumber={data?.nepalexTrackingNumber}
          />
        </Grid>
        <Grid item xs={12}>
          <ShipmentConditionDetails
            shipmentType={data?.shipmentType}
            customClearance={data?.customClearance}
            status={data?.status}
            serviceProvider={data?.serviceProvider}
            service={data?.service}
            awb={data?.awb}
            id={data?.id}
            shipper={data?.shipper}
            nepalexTrackingNumber={data?.nepalexTrackingNumber}
            importExport={data?.importExport}
          />
        </Grid>
        <Grid item xs={12}>
          <PackageDetails
            id={data?.id}
            packageType={data?.packageType}
            box={data?.numberOfBoxes}
            noOfOversizeBox={data?.numberOfOversizeBoxes}
            dimension={data?.dimension}
            actualWeight={data?.actualWeight}
            weightVerified={data?.weightVerified}
            dimensionalWeight={data?.dimensionalWeight}
            appliedWeight={data?.appliedWeight}
            noOfIronStrip={data?.noOfIronStrip}
            noOfPlasticStrip={data?.noOfPlasticStrip}
            descriptionOfGoods={data?.descriptionOfGoods}
          />
        </Grid>
        <Grid item xs={12}>
          <DestinationDetails
            destination={data?.destination}
            postal={data?.postalCode}
            remoteArea={data?.remoteArea}
          />
        </Grid>

        <Grid item xs={12}>
          <InvoiceDetails
            currency={data?.currency}
            exchangeRate={data?.exchangeRate}
            invoiceValue={data?.invoiceValue}
            invoiceValueForCustom={data?.invoiceValueForCustom}
          />
        </Grid>
      </Grid>
      <BillShipmentsForm
        open={billingForm?.open}
        handleClose={handleClose}
        data={billingForm?.data}
      />
    </>
  );
};

export default ShipmentDetails;
