import MaterialTable, {MTableToolbar} from "@material-table/core"
import React, {useState} from "react"
import {
    useCustomerPaymentHistoryTable
} from "../../../../hooks/components/table/customer/payment/useCustomerPaymentHistoryTable"
import {DeleteOutlined} from "@material-ui/icons"
import AddIcon from "@mui/icons-material/Add"
import AddCustomerPaymentForm from "../../../form/payment/AddCustomerPaymentForm"
import {Grid, TextField} from "@mui/material"
import jsPDF from "jspdf"
import 'jspdf-autotable'
import DownloadIcon from "@mui/icons-material/Download"
import {extractKeys, sortByDate} from "../../../../../app/utils/utils"
import moment from "moment"
import {useGetShipmentInvoiceByDate} from "../../../../hooks/api/payment/usePayment"
import {useParams} from "react-router-dom"
const CustomerPaymentHistoryTable = ({data}) => {
    const {
        columns,
        handleDelete,
        openAdd,
        handleOpenAdd,
        handleCloseAdd,
        billsColumns,
        handleDoubleClickRow,
    } = useCustomerPaymentHistoryTable({})
    const {id} = useParams()
    const [filter, setFilter] = useState({
        dateTo: new moment().format("YYYY-MM-DD"),
        dateFrom: "",
    })
    const {
        data:shipmentInvoiceData,
        isLoading,
    } = useGetShipmentInvoiceByDate({customerId:id,...filter})
    const exportToPDF = () => {
        const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: [350, 210]
        })
        const selectedKeyList = data?.map(obj => extractKeys(obj, ['amount', 'receiptNumber', 'date']))
        const pdfInvoiceData = sortByDate([...shipmentInvoiceData, ...selectedKeyList])
        const pdfColumns = [
            "Invoice Date",
            "Awb",
            "Shipper",
            "Consignee",
            "Destination",
            'Number Of Boxes',
            "Actual Weight",
            "Dimensional Weight",
            "Freight",
            "TIA",
            "Doc",
            "Custom",
            "Remote",
            "Others",
            "Total Charges",
            "Paid Amount",
            "Remaning Balance",
        ]

        let cumulativeRemainingBalance = 0
        const rows = []
        const totalCharges = shipmentInvoiceData.reduce((accumulator, currentObject) => {
            return accumulator + currentObject?.totalCharges
        }, 0)
        const totalPaidAmount = data.reduce((accumulator, currentObject) => {
            return accumulator + currentObject?.amount
        }, 0)
        pdfInvoiceData.forEach(invoice => {
            const rowData = [
                invoice.invoiceDate,
                invoice.awb,
                invoice.shipper,
                invoice.consignee,
                invoice.destination,
                invoice.numberOfBoxes,
                invoice.actualWeight,
                invoice.dimensionalWeight,
                invoice.freight,
                invoice.tia,
                invoice.doc,
                invoice.custom,
                invoice.remote,
                invoice.others,
                invoice.totalCharges,
            ]
            if (invoice.date) {
                rowData[0] = {content: invoice.date}
                rowData[1] = {content: invoice?.receiptNumber ?? "", colSpan: 13, styles: {halign: 'center'}}
                rowData[3] = {content: invoice.amount}
                rowData[4] = {content: (cumulativeRemainingBalance -= invoice.amount).toFixed(2)}
            } else {
                rowData[16] = {content: (cumulativeRemainingBalance += invoice.totalCharges).toFixed(2)}
            }
            rows.push(rowData)
        })
        const totalTransaction = [
            {
                content: "Total Transaction", colSpan: 14, styles: {halign: 'center'}
            },
            {
                content: !!totalCharges ? totalCharges : ""
            },
            {
                content: !!totalPaidAmount ? totalPaidAmount : " "
            }
        ]
        const pendingAmount = [
            {
                content: "Pending Amount", colSpan: 14, styles: {halign: 'center'},
            },
            {
                content: (totalCharges - totalPaidAmount > 0) ? totalCharges - totalPaidAmount : ""
            },
            {
                content: (totalCharges - totalPaidAmount < 0) ? totalPaidAmount - totalCharges : ""
            }
        ]
        rows.push(totalTransaction, pendingAmount)
        pdf.autoTable({
            head: [pdfColumns],
            body: rows,
            styles: {
                lineWidth: 0.1,
                lineColor: [0, 0, 1]
            },
        })
        pdf.save("invoice-report.pdf")
    }
    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MaterialTable
                        title="Payments"
                        data={data || []}
                        columns={columns}
                        actions={[
                            {
                                icon: () => <AddIcon/>,
                                tooltip: "Add Payment",
                                isFreeAction: true,
                                onClick: () => handleOpenAdd(),
                            },
                            (rowData) => ({
                                icon: () => <DeleteOutlined/>,
                                tooltip: "Edit",
                                onClick: () => {
                                    window.confirm("Do you want to delete this payment?")
                                        ? handleDelete(rowData)
                                        : alert("Payment deletion was cancelled.")
                                },
                            }),
                        ]}
                        options={{
                            pageSize: 5,
                            padding: "dense",
                            actionsColumnIndex: -1,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MaterialTable
                        title="Shipment Invoices"
                        isLoading={isLoading}
                        components={{
                            Toolbar: props => (
                                <Grid container spacing={2} px="1rem" alignItems="center" justifyItems="space-evenly">
                                    <Grid item xs={12}>
                                        <MTableToolbar {...props} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        Select Date Range:
                                    </Grid>
                                    <Grid item xs>
                                        <TextField
                                            size="small"
                                            margin="dense"
                                            type="date"
                                            InputLabelProps={{shrink: true}}
                                            name="dateFrom"
                                            label="From"
                                            value={filter.dateFrom}
                                            InputProps={{inputProps: {max: filter.dateTo}}}
                                            onChange={(e) => setFilter({...filter, dateFrom: e.target.value})}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <TextField
                                            size="small"
                                            margin="dense"
                                            type="date"
                                            InputLabelProps={{shrink: true}}
                                            name="dateTo"
                                            label="To"
                                            value={filter.dateTo}
                                            InputProps={{inputProps: {min: filter.dateFrom}}}
                                            onChange={(e) => setFilter({...filter, dateTo: e.target.value})}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            ),
                        }}
                        columns={billsColumns}
                        onRowDoubleClick={(_event, rowData) =>
                            handleDoubleClickRow(rowData)
                        }
                        data={shipmentInvoiceData || []}
                        options={{
                            pageSize: 5,
                            padding: "dense",
                        }}
                        actions={[
                            {
                                icon: () => <DownloadIcon/>,
                                tooltip: `Export to PDF`,
                                isFreeAction: true,
                                onClick: exportToPDF,
                            },
                        ]}
                    />
                </Grid>
            </Grid>
            <AddCustomerPaymentForm open={openAdd} handleClose={handleCloseAdd}/>
        </div>
    )
}

export default CustomerPaymentHistoryTable
