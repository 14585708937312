import { useFormik } from "formik";
import { useRestoreCustomer } from "../../../api/customer/useCustomer";

import { suspendCustomerSchema } from "./suspendCustomerValidationSchema";

export const useRestoreCustomerForm = (id) => {
  const { mutate } = useRestoreCustomer({});
  const formik = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema: suspendCustomerSchema,
    onSubmit: (values) => {
      handleRestore(values);
    },
  });

  const handleRestore = (values) => {
    values = { ...values, id: id };
    mutate(values);
  };

  return {
    formik,
  };
};
