import React from "react";
import CustomerTable from "../../components/table/customer/CustomerTable";

const Customer = () => {
  return (
    <div>
      <CustomerTable />
    </div>
  );
};

export default Customer;
