import MaterialTable from "@material-table/core";
import React from "react";
import { usePaymentTable } from "../../../hooks/components/payments/table/usePaymentTable";

const MyPaymentsTable = ({ data, isLoading }) => {
  const { columns } = usePaymentTable({});
  return (
    <MaterialTable
      title="My Payments"
      data={data || []}
      isLoading={isLoading}
      columns={columns}
    />
  );
};

export default MyPaymentsTable;
