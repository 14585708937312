import { axiosInstance } from "../axiosInterceptor";

export const getUserNotifications = async (role) => {
  if (role !== "Customer") {
    const { data } = await axiosInstance.get("/v/admin/notifications");
    return data;
  }
};

export const deleteNotifications = async (id) => {
  const { data } = await axiosInstance.delete(`/v/admin/notification?id=${id}`);
  return data;
};
