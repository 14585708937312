import { Button, CircularProgress, Grid } from "@mui/material";
import React, { useContext } from "react";

import { Link, useParams } from "react-router-dom";
import SystemDetails from "../../../../app/component/detail_view/system_details/SystemDetails";
import { UserContext } from "../../../../app/contexts/user/UserContext";
import { useGetFinalShipmentDetail } from "../../../hooks/api/nepal_lite/useNepalLite";
import BillingDetails from "./details/BillingDetails";
import ShipmentDetails from "./details/ShipmentDetails";

const ShipmentDetail = () => {
  const { id } = useParams();
  const { role } = useContext(UserContext);
  const { data, isLoading } = useGetFinalShipmentDetail(id);
  return isLoading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <Grid container spacing={1} justifyContent="flex-end">
      {!data?.rateAdded && role === "SysAdmin" && (
        <Grid item xs={12} sm={6} md={3} sx={{ p: "16px" }}>
          <Link
            to={`/super/update/shipment-billing/${data?.id}`}
            style={{ textDecoration: "none" }}
          >
            <Button
              fullWidth
              variant="contained"
              sx={{ textTransform: "none" }}
            >
              Add Shipment Rate
            </Button>
          </Link>
        </Grid>
      )}
      <Grid item xs={12}>
        <ShipmentDetails data={data} />
      </Grid>
      {role === "SysAdmin" ? (
        data?.rateAdded && (
          <Grid item xs={12}>
            <BillingDetails data={data} />
          </Grid>
        )
      ) : (
        <></>
      )}
      <Grid item xs={12}>
        <SystemDetails
          createdBy={data?.createdBy}
          editedBy={data?.editedBy}
          createdDatetime={data?.createdDatetime}
          editedDatetime={data?.editedDatetime}
        />
      </Grid>
    </Grid>
  );
};

export default ShipmentDetail;
