import React, { useState } from "react";
import CreatePickUpForm from "../../components/pickup/form/CreatePickUpForm";

import MyPickupTable from "../../components/pickup/table/MyPickupTable";

const MyPickup = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <MyPickupTable handleOpen={handleOpen} />
      <CreatePickUpForm open={open} handleClose={handleClose} />
    </div>
  );
};

export default MyPickup;
