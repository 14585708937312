import { useFormik } from "formik";
import { useAddShipmentBuyingPrice } from "../../../../api/shipment/useShipment";

export const useBuyingPriceForm = ({ handleClose, id }) => {
  const { mutate } = useAddShipmentBuyingPrice({});

  const formik = useFormik({
    initialValues: {
      buyingPrice: 0,
    },
    // validationSchema: sendBillEmailValidationSchema,
    onSubmit: (values) => {
      values = {
        buyingPrice: Number(values?.buyingPrice),
        id: id,
      };
      handleConfrim(values);
    },
  });

  const handleConfrim = (values) => {
    mutate(values, { onSuccess: () => handleClose() });
  };
  return { formik };
};
