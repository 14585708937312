import MaterialTable from "@material-table/core";
import React from "react";
import { useShipmentInvoiceTable } from "../../../hooks/components/payments/table/useShipmentInvoiceTable";

const MyShipmentInvoiceTable = ({ data, isLoading }) => {
  const { columns, handleDoubleClickRow } = useShipmentInvoiceTable({});
  return (
    <MaterialTable
      title="Shipment Invoices"
      columns={columns}
      isLoading={isLoading}
      onRowDoubleClick={(_event, rowData) => handleDoubleClickRow(rowData)}
      data={data}
    />
  );
};

export default MyShipmentInvoiceTable;
