import {Typography} from "@mui/material"
import {useSendAWBEmailMutation} from "../../../../api/shipment/useShipment"
import {useNavigate} from "react-router-dom"
import {toast} from "react-toastify"

const useMasterAWBTable = ({ids}) => {
    const navigate = useNavigate()
    const {
        mutateAsync,
        isLoading,
    } = useSendAWBEmailMutation()
    const columns = [
        {
            field: 'id',
            title: 'ID',
            type: 'numeric',
            width: 1,
        },
        {
            field: 'airlinesName',
            title: 'Airlines Name',
            render: rowData => (
                isLoading ? "Sending.." :
                    <Typography
                        onClick={(e) => e.stopPropagation()}
                        sx={{
                            textDecoration: "underline",
                            cursor: "pointer",
                        }}
                        onDoubleClick={async () => {
                            await mutateAsync(ids.length > 0 ? ids : [rowData.id])
                        }}
                    >
                        {rowData?.airlinesName}
                    </Typography>
            )
        },
        {
            field: 'mAwbNumber',
            title: 'Master AWB',
            render: rowData => (
                <Typography
                    onClick={(e) => e.stopPropagation()}
                    sx={{
                        textDecoration: "underline",
                        cursor: "pointer",
                    }}
                    onDoubleClick={async () => {
                        if (rowData?.emailSent) {
                            localStorage.setItem("addShipmentRequest-nepal", JSON.stringify(
                                {
                                    awb: rowData.mAwbNumber,
                                    shipperId: rowData.customer.id,
                                    email: rowData.customer.email,
                                    consignee:rowData?.customer.companyName,
                                    destination:rowData?.destination,
                                }
                            ))
                            navigate("/super/add-shipment-lite")
                        } else {
                            toast.warn("send email first to go to add shipment")
                        }
                    }}
                >
                    {rowData?.mAwbNumber}
                </Typography>
            )
        },
        {
            field: 'emailSent',
            title: 'Email Sent',
            type: 'boolean',
        },
        {
            field: 'customerId',
            title: 'Customer',
            render: rowData => (
                <Typography
                >
                    {rowData?.customer.companyName}
                </Typography>
            )
        },
        {
            field: 'date',
            title: 'Date',
            type: 'date',
        },
        {
            field: 'destination',
            title: 'Destination',
        },
        {
            field: 'emails',
            title: 'Emails',
            render: rowData => (
                <Typography
                >
                    {rowData?.emails?.join(', ')}
                </Typography>
            )
        },
    ]
    return {
        columns,
    }
}
export default useMasterAWBTable
