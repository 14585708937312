import { useGetShipmentRequests } from "../../../api/ship/useShip";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";

export const useMyShipmentRequestsTable = () => {
  const { data } = useGetShipmentRequests();
  const customEditDetails = {
    icon: () => <CancelScheduleSendIcon />,
    tooltip: "Cancel Shipment Request",
  };
  const column = [
    {
      title: "id",
      field: "id",
      render: (rowData) => data?.findIndex((x) => x?.id === rowData?.id) + 1,
    },
    {
      title: "Consignee",
      field: "consignee",
    },
    {
      title: "Import / Export",
      field: "importExport",
    },
    {
      title: "Number of Boxes",
      field: "numberOfBoxes",
    },
    {
      title: "Shipment Service",
      field: "shipmentService",
    },
    {
      title: "Actual Weight",
      field: "actualWeight",
    },
    {
      title: "Destination Country",
      field: "destinationCountry",
    },
    {
      title: "City",
      field: "city",
    },
    {
      title: "Street",
      field: "street",
    },
    {
      title: "House Number",
      field: "houseNumber",
    },
    {
      title: "Tracking Number",
      field: "nepalShipmentTrackingNumber",
    },
  ];

  return { data, column, customEditDetails };
};
