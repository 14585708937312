import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
    addNewShipmentRate, deleteShipmentRate,
    getShipmentRate,
    updateShipmentRate,
} from "../../../../api/service_provider/rate/serviceprovider-rate-api"
import { importShipmentRateFromFile } from "../../../../api/service_provider/serviceprovider-api";

export const useGetServiceProviderRates = ({ id, zone }) => {
  return useQuery(
    ["getServiceProviderRates", id, zone],
    () => getShipmentRate({ id, zone }),
    {
      cacheTime: 10000,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useShipmentActionMutation = ({handleClose})=>{
    const queryClient = useQueryClient()
    const deleteShipmentRateMutation = useMutation(({shipmentProviderId,zone})=>deleteShipmentRate({shipmentProviderId,zone}),{
        onSuccess:(_,variables)=>{
            const {shipmentProviderId,zone}=variables
            queryClient.invalidateQueries(["getServiceProviderRates",shipmentProviderId,zone])
            toast.success("Successfully Deleted service provider Rates")
            handleClose()
        },
        onError:()=>{
            toast.error("Unable to Delete Rates Please try again later")
        }
    })
    const handleDeleteShipmentRate = async ({shipmentProviderId,zone})=>{
        await deleteShipmentRateMutation.mutateAsync({shipmentProviderId,zone})
    }
    return{
        deleteAllRate:handleDeleteShipmentRate,
        isDeleting:deleteShipmentRateMutation.isLoading,
    }
}
export const useAddNewShipmentRate = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(
    ["addNewShipmentRate"],
    (formData) => addNewShipmentRate(formData),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["getServiceProviderRates"]);
        toast.success("Succesfully added service provider rate");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};

export const useUpdateShipmentRate = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(
    ["updateShipmentRate"],
    (formData) => updateShipmentRate(formData),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["getServiceProviderRates"]);
        toast.success("Succesfully updated service provider rate");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};

export const useImportShipmentRateFromFile = ({ onSuccess }) => {
    const queryClient = useQueryClient()
  return useMutation(
    ["importShipmentRateFromFile"],
    (formData) => importShipmentRateFromFile(formData),
    {
      onSuccess: (data, variables, context) => {
        toast.success("Succesfully uploaded");
        onSuccess && onSuccess(data, variables, context);
          queryClient.invalidateQueries('getServiceProviderRates')
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};
