import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../../../app/api/axiosInterceptor";
import { UserContext } from "../../../../../app/contexts/user/UserContext";
import { useGetBilledShipmentDetail } from "../../../api/shipment/useShipment";

export const useBilledShipmentDetail = ({ id }) => {
  const navigate = useNavigate();
  const { role } = useContext(UserContext);
  const { data, isLoading } = useGetBilledShipmentDetail(id);

  function handleDoubleClickRow(rowData) {
    navigate(
      `/${role === "SysAdmin" ? "super" : "admin"}/shipment-${rowData?.id}`
    );
  }
  const handleDownload = async () => {
    const response = await axiosInstance(
      `/v/admin/shipment-billing/download?billId=${id}`,
      {
        method: "GET",
        responseType: "blob",
      }
    );

    const file = new Blob([response]);
    const fileURL = URL.createObjectURL(file);

    const link = document.createElement("a");
    link.href = fileURL;
    link.setAttribute("download", `Bill-${id}.pdf`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };
  return { data, isLoading, handleDoubleClickRow, handleDownload };
};
