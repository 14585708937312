import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

const MessageMd = () => {
  return (
    <Box sx={{ background: "#DCDCDC" }}>
      <Container
        sx={{
          paddingTop: { xs: "50px", md: "10%" },
          paddingBottom: { xs: "50px", md: "10%" },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  src="/assests/images/landing_page/MDimg.jpg"
                  alt="office"
                  style={{
                    height: "180px",
                    width: "180px",
                    borderRadius: "50%",
                    background: "transparent",
                    objectFit: "cover",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "30px",

                    textAlign: "center",
                  }}
                >
                  Tilraj Khatiwada
                </Typography>
                <Typography sx={{ fontWeight: 600, textAlign: "center" }}>
                  Managing Director
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "26px",
                mb: "24px",
                textAlign: { xs: "center", md: "left" },
              }}
            >
              Message from our Managing Director
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>
              I am privileged to serve as the Managing Director of Nepal Express
              Parcel and Logistics, a company that is a leading provider of
              freight forwarding, customs clearance, project cargo handling,
              warehousing and distribution management, courier services, and
              logistics services in Nepal.
              <br />
              We began our journey in 2012 and have built a reputation for our
              commitment to helping our clients reduce shipping costs and gain a
              competitive edge in the Nepali market. We have partnered with
              industry leaders such as the Nepal Freight Forwarders Association,
              the Courier Service Association of Nepal, and the Confederation of
              Nepalese Industries.
              <br /> We are proud of the high quality of our services and our
              ability to meet client deadlines, as these are the foundations of
              our business and long-term client relationships. By cultivating
              close relationships and providing total solutions, we strive to be
              our clients' dependable logistics partner. <br />
              We appreciate your feedback and are constantly looking for ways to
              improve our service to you.
              <br /> Thank you for choosing us as your logistics provider.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default MessageMd;
