import MaterialTable from "@material-table/core";
import { Autocomplete, Grid, MenuItem, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { usePickupTable } from "../../../hooks/components/table/pickup/usePickupTable";
import AddIcon from "@mui/icons-material/Add";
import CreatePickupForm from "../../form/pickup/CreatePickupForm";
import CancelPickupForm from "../../form/pickup/CancelPickupForm";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../app/contexts/user/UserContext";
import moment from "moment";

const pickedOptions = [
  { value: "Pending", label: "Pending" },
  { value: "Complete", label: "Complete" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "All", label: "All" },
];

export const PickUpTable = () => {
  const { role } = useContext(UserContext);
  const [adminId, setAdminId] = useState(null);
  const [inputAdmin, setInputAdmin] = useState("");
  const [open, setOpen] = useState(false);
  const initialFilter = JSON.parse(localStorage.getItem('dateFilter')) || {
    dateFrom: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    dateTo: moment().format('YYYY-MM-DD'),
  };

  const [filter, setFilter] = useState(initialFilter);

  const handleDateChange = (key, value) => {
    const updatedFilter = { ...filter, [key]: value };
    setFilter(updatedFilter);
    localStorage.setItem('dateFilter', JSON.stringify(updatedFilter));
  };
  const {
    data,
    isLoading,
    column,
    adminList,
    openCancel,
    handleCloseCancel,
    handleSubmit,
    uiFilter,
    handleUiFilter,
  } = usePickupTable({ filter });
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item xs={6} md={3}>
          <TextField
            size="small"
            margin="dense"
            type="date"
            InputLabelProps={{ shrink: true }}
            name="dateFrom"
            label="From"
            value={filter?.dateFrom}
            InputProps={{ inputProps: { max: filter?.dateTo } }}
            onChange={(e) => handleDateChange('dateFrom', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            size="small"
            margin="dense"
            type="date"
            InputLabelProps={{ shrink: true }}
            name="dateTo"
            label="To"
            value={filter?.dateTo}
            InputProps={{ inputProps: { min: filter?.dateFrom } }}
            onChange={(e) => handleDateChange('dateTo', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            name="status"
            label="status"
            select
            fullWidth
            size="small"
            sx={{ mt: 1 }}
            value={uiFilter?.status}
            onChange={(e) =>
              handleUiFilter({ ...uiFilter, status: e.target.value })
            }
          >
            {pickedOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6} md={3}>
          <Autocomplete
            value={adminId}
            onChange={(_event, newValue) => {
              setAdminId(newValue);
              handleUiFilter({ ...uiFilter, adminId: newValue?.value });
            }}
            inputValue={inputAdmin}
            onInputChange={(_event, newInputValue) => {
              setInputAdmin(newInputValue);
            }}
            options={adminList || []}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                margin="dense"
                label="Admin"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="MTable">
            <MaterialTable
              title="Pick Up"
              data={data}
              isLoading={isLoading}
              columns={column}
              onRowDoubleClick={(_event, rowData) => {
                navigate(
                  `/${role === "SysAdmin" ? "super" : "admin"}/pickup/${
                    rowData?.id
                  }`
                );
              }}
              actions={[
                {
                  icon: () => <AddIcon />,
                  tooltip: "Create Pickup",
                  isFreeAction: true,
                  onClick: () => setOpen(true),
                },
              ]}
              options={{
                padding: "dense",
                pageSize: 20,
                rowStyle: (rowData) => {
                  return {
                    color:
                      rowData?.status === "Complete"
                        ? "green"
                        : rowData?.status === "Cancelled"
                        ? "red"
                        : "orange",
                  };
                },
              }}
            />

            <CreatePickupForm
              open={open}
              handleClose={handleClose}
              adminList={adminList}
            />
            <CancelPickupForm
              open={openCancel}
              handleClose={handleCloseCancel}
              handleSubmit={handleSubmit}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};
