import { useFormik } from "formik";
import {
  useGetCustomers,
  useGetServiceProviders,
} from "../../../api/custom_shipment/useCustomShipment";
import { useDownloadReport } from "../../../api/shipment/useShipment";

const statusOptions = [
  { label: "--", value: "" },
  { label: "Delivered", value: "Delivered" },
  { label: "Exception", value: "Exception" },
  { label: "In Transit", value: "InTransit" },
  { label: "Pending", value: "Pending" },
  { label: "Return", value: "Return" },
];

const billTypeOptions = [
  { label: "All", value: null },
  { label: "Pan", value: "Pan" },
  { label: "TPan", value: "TPan" },
  { label: "Vat", value: "Vat" },
];

const billedOptions = [
  { label: "All", value: "all" },
  { label: "Billed", value: "billed" },
  { label: "Pending", value: "pending" },
];

const weightVerifiedOptions = [
  { label: "All", value: "all" },
  { label: "Verified", value: "verified" },
  { label: "Pending", value: "pending" },
];

const packageTypeOptions = [
  { label: "-", value: null },
  { label: "Document", value: "Document" },
  { label: "Non-Document", value: "NonDocument" },
];

const useDownloadReportForm = () => {
  const { mutate } = useDownloadReport({});
  const { data: serviceProviderData, isLoading: serviceProviderLoading } =
    useGetServiceProviders();
  const { data: customerData, isLoading: customerLoading } = useGetCustomers();

  const serviceProviderList =
    serviceProviderData &&
    serviceProviderData?.map((serviceProvider) => {
      return { value: serviceProvider?.id, label: serviceProvider?.name };
    });

  const customerList =
    customerData &&
    customerData?.map((customer) => {
      return {
        id: customer?.id,
        email: customer?.email,
        label:
          customer?.companyName +
          " (" +
          customer?.firstName +
          " " +
          customer?.lastName +
          ")",
      };
    });

  const formik = useFormik({
    initialValues: {
      billType: "Pan",
      billed: "all",
      dateFrom: "",
      dateTo: "",
      serviceProviderId: null,
      shipperId: null,
      status: "Delivered",
      weightVerified: "all",
      packageType: "",
    },
    onSubmit: (values) => {
      handleCreate(values);
    },
  });

  const handleCreate = (values) => {
    let formData = {
      ...values,
      status: values?.status === "" ? null : values?.status,
      billed:
        values?.billed === "all"
          ? null
          : values?.billed === "pending"
          ? false
          : true,
      weightVerified:
        values?.weightVerified === "all"
          ? null
          : values?.weightVerified === "pending"
          ? false
          : true,
      packageType: values?.packageType === "" ? null : values?.packageType,
    };
    mutate(formData);
  };

  return {
    formik,
    statusOptions,
    billTypeOptions,
    serviceProviderList,
    customerList,
    billedOptions,
    weightVerifiedOptions,
    packageTypeOptions,
    isLoading: customerLoading || serviceProviderLoading,
  };
};

export default useDownloadReportForm;
