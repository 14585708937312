import { useState } from "react";
import { useAddAvatar } from "../../../../../../app/hooks/api/profile/useUser";

const useAddAvatarForm = ({ handleClose }) => {
  const [progress, setProgress] = useState(0);
  const { mutate } = useAddAvatar({});

  function handleSuccess() {
    handleClose();
    setProgress(0);
  }

  const handleAddAvatar = (image) => {
    mutate({ image, setProgress }, { onSuccess: () => handleSuccess() });
  };

  return { handleAddAvatar, progress, setProgress };
};

export default useAddAvatarForm;
