import MaterialTable from "@material-table/core";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import useMyPickupTable from "../../../hooks/components/pickup/table/useMyPickupTable";

const MyPickupTable = ({ handleOpen }) => {
  const { data, isLoading, columns } = useMyPickupTable({});

  return (
    <MaterialTable
      title="My Pickups"
      data={data}
      isLoading={isLoading}
      columns={columns}
      actions={[
        {
          icon: () => <AddIcon />,
          tooltip: "Create Pickup",
          isFreeAction: true,
          onClick: () => handleOpen(),
        },
      ]}
    />
  );
};

export default MyPickupTable;
