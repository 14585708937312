import React, { useState } from "react";
import { useServiceProviderRateTable } from "../../../../hooks/components/table/service_provider/rate/useServiceProviderRateTable";
import EditExportRatesForm from "../../../form/export_rates/EditExportRatesForm";
import AddServiceProviderRateForm from "../../../form/service_provider/rate/AddServiceProviderRateForm";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import MaterialTable from "@material-table/core";
import { EditOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import AddRateByFileForm from "../../../form/rate_configuration/AddRateByFileForm";
import {DeleteOutline} from "@material-ui/icons"
import DeleteAllRate from "../../../form/rate_configuration/items/DeleteAllRate"

const ServiceProviderRateTable = ({ id, zone }) => {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editData, setEditData] = useState();
  const [openFile, setOpenFile] = useState(false);
  const [openDelete, setOpenDelete] = useState(false)

  const handleEdit = (rowData) => {
    setEditData(rowData);
    setOpenEdit(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setOpenFile(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const { column, data, isLoading } = useServiceProviderRateTable({ id, zone });

  return (
    <div>
      <MaterialTable
        title="Service Providers"
        columns={column}
        data={data}
        isLoading={isLoading}
        options={{
          pageSize: 10,
          padding: "dense",
          actionsColumnIndex: -1,
        }}
        actions={[
          (rowData) => ({
            icon: () => <EditOutlined />,
            tooltip: "Edit",
            onClick: () => {
              handleEdit(rowData);
            },
          }),
          {
            icon: () => <AddIcon />,
            tooltip: `Add Customer`,
            isFreeAction: true,
            onClick: () => handleOpen(),
          },
          {
            icon: () => <UploadFileIcon />,
            tooltip: `Bulk upload`,
            isFreeAction: true,
            onClick: () => setOpenFile(true),
          },
            {
            icon: () => <DeleteOutline />,
            tooltip: `delete all`,
            isFreeAction: true,
            onClick: () => setOpenDelete(true),
          },
        ]}
      />
      <AddServiceProviderRateForm
        open={open}
        handleClose={handleClose}
        id={id}
      />
        <DeleteAllRate
            openDelete={openDelete}
            handleClose={()=>setOpenDelete(false)}
            shipmentProviderId={id}
            zone={zone}
        />
      <AddRateByFileForm open={openFile} handleClose={handleClose} />
      <EditExportRatesForm
        open={openEdit}
        handleClose={handleClose}
        data={editData}
        id={id}
      />
    </div>
  );
};

export default ServiceProviderRateTable;
