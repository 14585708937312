import { useFormik } from "formik";
import { useGroupBilledShipment } from "../../../../api/shipment/useShipment";
import { groupBillShipmentValidationSchema } from "./groupBillShipmentValidationSchema";

export const useBillingShipmentsForm = ({ data, handleClose }) => {
  const { mutate } = useGroupBilledShipment({});

  const formik = useFormik({
    initialValues: {
      billingSequence: "",
    },
    validationSchema: groupBillShipmentValidationSchema,
    onSubmit: (values) => {
      handleConfrim(values);
    },
  });

  const handleConfrim = (values) => {
    let formData = {
      ...values,
      shipmentIds: data?.shipmentIds,
    };
    mutate(formData, { onSuccess: () => handleClose() });
  };

  return { formik };
};
