import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../../app/utils/cookieHelper";
import LoginForm from "../../components/form/login/LoginForm";

const Login = () => {
  const history = useNavigate();

  const { token, tempPassword, role } = getUser();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (token) {
      if (!tempPassword) {
        switch (role) {
          case "SysAdmin":
            return history(`/super/dashboard`);
          case "Admin":
            return history(`/admin/dashboard`);
          case "Customer":
            return history("/my-shipments");
          default:
            return history("/login");
        }
      } else {
        if (tempPassword) history("/temp-password");
      }
    }

    // eslint-disable-next-line
  }, []);
  return <LoginForm />;
};

export default Login;
