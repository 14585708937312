export const usePaymentTable = () => {
  const columns = [
    {
      title: "S.No",
      field: "id",
    },
    {
      title: "amount",
      field: "amount",
    },
    {
      title: "date",
      field: "date",
    },
    {
      title: "Description",
      field: "description",
    },
    {
      title: "Received By",
      field: "receivedBy",
    },
  ];
  return { columns };
};
