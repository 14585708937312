import { Button, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const BillingDetails = ({ data }) => {
  return (
    <Paper
      elevation={4}
      sx={{
        ml: { sm: 1, md: 2 },
        mr: { sm: 1, md: 2 },
        mt: 2,
        mb: 1,
        p: "16px",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={9}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "24px",
            }}
          >
            Billing Details
          </Typography>
          <Typography variant="subtitle2">
            Bill Type : {data?.billType}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Link
            to={`/super/update/shipment-billing/${data?.id}`}
            style={{ textDecoration: "none" }}
          >
            <Button fullWidth variant="outlined" sx={{ textTransform: "none" }}>
              Edit
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography>
                <b>Freight</b>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Typography variant="subtitle2">Given Rate :</Typography>
            </Grid>
            <Grid item xs={8} sm={2}>
              <Typography>{data?.freightGivenRate || "-"}</Typography>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Typography variant="subtitle2">Freight : </Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              {data?.freight || "-"}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography>
                <b>TIA</b>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Typography variant="subtitle2">Amount :</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>{data?.tia || "-"}</Typography>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Typography variant="subtitle2">Discount :</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>{data?.discountOnTia || "-"}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography>
                <b>Strip</b>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Typography variant="subtitle2">Amount :</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>{data?.strip || "-"}</Typography>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Typography variant="subtitle2">Discount :</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>{data?.discountOnStrip || "-"}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography>
                <b>Custom</b>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Typography variant="subtitle2">Amount :</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>{data?.custom || "-"}</Typography>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Typography variant="subtitle2">Discount :</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>{data?.discountOnCustom || "-"}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={4} sm={2}>
          <Typography variant="subtitle2">Doc :</Typography>
        </Grid>
        <Grid item xs={8} sm={4}>
          <Typography>{data?.doc || "-"}</Typography>
        </Grid>
        <Grid item xs={4} sm={2}>
          <Typography variant="subtitle2">Oversize :</Typography>
        </Grid>
        <Grid item xs={8} sm={4}>
          <Typography>{data?.oversize || "-"}</Typography>
        </Grid>
        <Grid item xs={4} sm={2}>
          <Typography variant="subtitle2">Remote :</Typography>
        </Grid>
        <Grid item xs={8} sm={4}>
          <Typography>{data?.remote || "-"}</Typography>
        </Grid>
        <Grid item xs={4} sm={2}>
          <Typography>Others :</Typography>
        </Grid>
        <Grid item xs={8} sm={4}>
          <Typography>{data?.others || "-"}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>Other Details : </Typography>
          <Typography>{data?.otherDetails || "-"}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="subtitle1">Vat :</Typography>
          <Typography sx={{ ml: 2 }}>{data?.vat || "-"}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <b>Total Charges (Rs.)</b>: {data?.totalCharges || "-"}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BillingDetails;
