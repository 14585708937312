import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { superRegister } from "../../../api/superregister/superregister-api";

export const useSuperRegister = ({ onSuccess }) => {
  const navigate = useNavigate();
  return useMutation(["register"], (formData) => superRegister(formData), {
    onSuccess: (data, variables, context) => {
      toast.success("Successfully Registered");
      navigate("/login");
      onSuccess && onSuccess(data, variables, context);
    },
    onError: (err, _variables, _context) => {
      toast.error(err.message);
    },
  });
};
