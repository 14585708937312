import { Container, Grid, Typography } from "@mui/material";
import React from "react";

const Overview = () => {
  return (
    <Container
      sx={{
        paddingTop: { xs: "50px", md: "70px" },
        minHeight: "90vh",
        color: "text.primary",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div style={{ display: "flex" }}>
            <Typography sx={{ fontWeight: 800, fontSize: "30px", mr: "8px" }}>
              Our
            </Typography>
            <Typography
              sx={{
                fontWeight: 800,
                fontSize: "30px",
                color: "primary.main",
                textDecoration: "underline",
              }}
            >
              Story
            </Typography>
          </div>
          <Typography sx={{ fontSize: "14px" }}>
            We established Nepal Express Parcel and Logistics in 2012 with the
            goal of providing our clients with unrivaled logistics and cargo
            services. <br />
            We brought a unique blend of expertise, skill, integrity, and
            dependability to the table as a family-owned and operated Nepali
            company. We have earned a distinguished reputation for bringing the
            world of courier, cargo, and freight business closer to our valued
            customers through our commitment to excellence.
            <br /> With each passing year, we have expanded our capabilities,
            broadened our reach, and strengthened our commitment to providing
            exceptional service.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src="/assests/images/landing_page/office.jpg"
            alt="office"
            style={{
              height: "250px",
              width: "250px",
              objectFit: "cover",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "center", mt: "15%" }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "26px",
              color: "primary.main",
              mr: "8px",
            }}
          >
            Our
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "26px",
              textDecoration: "underline",
            }}
          >
            mission
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "center", mb: "15%" }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: "center",
              width: { xs: "100%", md: "600px" },
            }}
          >
            Provide exceptional logistics and fulfillment through passion,
            respect, openness, and integrity while meeting the changing needs of
            our customers.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", justifyContent: "center", mb: "10%" }}
        >
          <img
            src="/assests/images/landing_page/office.jpg"
            alt="office"
            style={{
              height: "250px",
              width: "250px",
              objectFit: "cover",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ mb: "10%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginBottom: "16px",
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "26px",
              }}
            >
              Our
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "26px",
                color: "primary.main",
                m: "0px 8px",
                textDecoration: "underline",
              }}
            >
              Desire
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "26px",
              }}
            >
              to contribute
            </Typography>
          </div>
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: "left",
              width: { xs: "100%", md: "600px" },
            }}
          >
            Through our efficient network, Nepal Express Parcel and Logistics
            provides consistent and high-quality domestic and international
            delivery services. <br />
            We offer overnight and same-day shipping to any location on the
            planet. Through staff training and effective communication with our
            strategic suppliers and customers from Nepal and around the world,
            we strive to understand our clients' needs and provide customized
            services.
            <br /> Our comprehensive full-service portfolio and commitment to
            the e-commerce industry distinguishes us as a leader in Nepal's
            logistics and freight service industry, serving both start-ups and
            established businesses globally.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Overview;
