import { axiosInstance } from "../../../app/api/axiosInterceptor";

export const createCustomerInfoConfig = async (values) => {
  const { data } = await axiosInstance.post(
    "/v/sys/customer-info-config",
    values
  );
  return data;
};

export const updateCustomerInfoConfig = async (values) => {
  const { data } = await axiosInstance.put(
    "/v/sys/customer-info-config",
    values
  );
  return data;
};
