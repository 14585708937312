import { useFormik } from "formik";
import { useUpdateServiceProvider } from "../../../api/service_provider/useServiceProvider";
import { serviceProviderValidationSchema } from "./serviceProviderValidationSchema";

export const useEditServiceProviderForm = ({ data, handleClose }) => {
  const { mutate: mutateUpdate } = useUpdateServiceProvider({});
  const formik = useFormik({
    initialValues: {
      apiKey: data?.apiKey,
      apiSecret: data?.apiSecret,
      apiService: data?.apiService,
      description: data?.description,
      enabled: data?.enabled,
      name: data?.name,
    },
    validationSchema: serviceProviderValidationSchema,
    enableReinitialize: "true",
    onSubmit: (values) => {
      handleUpdate(values);
      formik.handleReset();
      handleClose();
    },
  });

  const handleUpdate = (values) => {
    values = {
      ...values,
      id: data?.id,
    };
    mutateUpdate(values);
  };

  return { formik };
};
