import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import React from "react";

const SendCustomerNoticeFormItem = ({ formik }) => {
  return (
    <Box component="form" form={formik} noValidate sx={{ mt: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            id="subject"
            name="subject"
            label="Subject"
            value={formik.values.subject}
            onChange={formik.handleChange}
            error={formik.touched.subject && Boolean(formik.errors.subject)}
            helperText={formik.touched.subject && formik.errors.subject}
            fullWidth
            required
            autoFocus
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="message"
            name="message"
            label="Message"
            required
            fullWidth
            multiline
            value={formik.values.message}
            onChange={formik.handleChange}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            id="important"
            name="important"
            value={formik.values.important}
            onChange={formik.handleChange}
            control={
              <Checkbox
                checked={formik.values.important}
                onChange={formik.handleChange}
              />
            }
            label="Important"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SendCustomerNoticeFormItem;
