import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../../app/contexts/user/UserContext";
import { useGetBilledShipments } from "../../../api/shipment/useShipment";
import moment from "moment";

const useBilledShipmentsTable = () => {
  const { role } = useContext(UserContext);
  const [filter, setFilter] = useState({
    dateFrom: moment().subtract(30, "days").format("yyyy-MM-DD"),
    dateTo: moment().format("yyyy-MM-DD"),
  });
  const navigate = useNavigate();
  const { data, isLoading } = useGetBilledShipments({ filter });

  const handleChange = (e) => {
    switch (e.target.name) {
      case "dateFrom":
        setFilter({
          ...filter,
          dateFrom: e.target.value,
        });
        break;
      case "dateTo":
        setFilter({
          ...filter,
          dateTo: e.target.value,
        });
        break;
      default:
        console.log(e.target.name);
        break;
    }
  };

  function handleDoubleClickRow(rowData) {
    navigate(`/${role === "SysAdmin" ? "super" : "admin"}/bill/${rowData?.id}`);
  }
  const columns = [
    {
      title: "Reference",
      field: "billingSequence",
      emptyValue: "-",
    },
    {
      title: "Shipper",
      field: "shipper",
      emptyValue: "-",
    },
    {
      title: "Freight",
      field: "freightTotal",
    },
    {
      title: "Strip",
      field: "stripTotal",
    },
    {
      title: "Tia",
      field: "tiaTotal",
    },
    {
      title: "Custom",
      field: "customsTotal",
    },
    {
      title: "Doc",
      field: "docTotal",
    },
    {
      title: "Other",
      field: "otherTotal",
    },
    {
      title: "Vat",
      field: "vatTotal",
    },
    {
      title: "Total",
      field: "grandTotal",
    },
  ];
  return {
    data,
    isLoading,
    columns,
    handleDoubleClickRow,
    handleChange,
    filter,
  };
};

export default useBilledShipmentsTable;
