import React from "react";
import CustomShippingRequestForm from "../../components/form/custom_shipment/CustomShippingRequestForm";

export const Customshipment = () => {
  return (
    <div>
      <CustomShippingRequestForm />
    </div>
  );
};
