import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useSendBillEmail } from "../../../hooks/components/form/shipments/bill/useSendBillEmail";

const SendBillEmail = ({ open, handleClose, id }) => {
  const { formik } = useSendBillEmail({ handleClose, id });
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>
        <Typography>Send Email</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box form={formik} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                size="small"
                margin="dense"
                fullWidth
                name="emailTo"
                label="Email To"
                value={formik.values.emailTo}
                onChange={formik.handleChange}
                error={formik.touched.emailTo && Boolean(formik.errors.emailTo)}
                helperText={formik.touched.emailTo && formik.errors.emailTo}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => handleClose()} sx={{ textTransform: "none" }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={() => formik.handleSubmit()}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendBillEmail;
