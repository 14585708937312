import moment from "moment"

export function hasChildren(item) {
    const {items: children} = item

    if (children === undefined) {
        return false
    }

    if (children.constructor !== Array) {
        return false
    }

    if (children.length === 0) {
        return false
    }

    return true
}

export function extractKeys(obj, keys) {
    return Object.fromEntries(keys.map(key => [key, obj[key]]))
}

export function sortByDate(jsonData) {
    const parseAndValidateDate = date => moment(date, "YYYY-MM-DD", true)
    return jsonData.sort((a, b) => {
        const dateA = a.date ? parseAndValidateDate(a.date) : parseAndValidateDate(a.invoiceDate);
        const dateB = b.date ? parseAndValidateDate(b.date) : parseAndValidateDate(b.invoiceDate);

        // If the dates are the same, sort by key presence: "invoiceDate" vs "date"
        if (dateA.isSame(dateB)) {
            if (a.invoiceDate && !b.invoiceDate) {
                return -1; // Object 'a' has invoiceDate, 'b' doesn't, so 'a' comes first
            } else if (!a.invoiceDate && b.invoiceDate) {
                return 1; // Object 'b' has invoiceDate, 'a' doesn't, so 'b' comes first
            }
            // If both have invoiceDate or both have date, maintain the original order
            return 0;
        }

        // Compare by date
        return dateA.isBefore(dateB) ? -1 : 1;
    })
}
