import { Box, Container, Grid, Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PlaceIcon from "@mui/icons-material/Place";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";

import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const scrollToTop = () => {
    if (pathname) {
      window.scrollTo(0, 0);
    }
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}>
        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: "auto",
            backgroundColor: "#000",
          }}>
          <Container maxWidth="lg">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Grid item xs={12} sx={{ mb: 5 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Link to="/" style={{ textDecoration: "none" }}>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontWeight: "800",
                            fontSize: "24px",
                            paddingTop: "5px",
                          }}>
                          NepalEx
                        </Typography>
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="p" color="#A7A7A7">
                        We innovate to keep long-term customer relationships by
                        understanding their needs and strategies, creating value
                        to meet their dynamic service requirements.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" color="primary.main">
                    Follow Us on
                  </Typography>
                  <Grid container spacing={0} sx={{ mt: 2 }}>
                    <Grid item xs={3} md={2}>
                      <a
                        href="https://www.facebook.com/Nepal-Express-Parcel-and-Logistics-PLtd-180511518745653/"
                        target="_blank"
                        rel="noreferrer">
                        <FacebookIcon sx={{ color: "#A7A7A7" }} />
                      </a>
                    </Grid>
                    <Grid item xs={3} md={2}>
                      <LinkedInIcon sx={{ color: "#A7A7A7" }} />
                    </Grid>
                    <Grid item xs={3} md={2}>
                      <TwitterIcon sx={{ color: "#A7A7A7" }} />
                    </Grid>
                    <Grid item xs={3} md={2}>
                      <a
                        href="https://www.instagram.com/nepalexpress9/"
                        target="_blank"
                        rel="noreferrer">
                        <InstagramIcon sx={{ color: "#A7A7A7" }} />
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="primary.main">
                      Useful links
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={12}>
                    <Link
                      to="/"
                      style={{ textDecoration: "none", color: "#A7A7A7" }}
                      onClick={scrollToTop}>
                      Home
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={12}>
                    <Link
                      to="/services"
                      style={{ textDecoration: "none", color: "#A7A7A7" }}
                      onClick={scrollToTop}>
                      Services
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={12}>
                    <Link
                      to=""
                      style={{ textDecoration: "none", color: "#A7A7A7" }}
                      onClick={scrollToTop}>
                      Tracking
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={12}>
                    <Link
                      to="/contactus"
                      style={{ textDecoration: "none", color: "#A7A7A7" }}
                      onClick={scrollToTop}>
                      Contact Us
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <Typography variant="h6" color="primary.main">
                      Contact Info
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", alignItems: "center" }}>
                    <PlaceIcon sx={{ color: "#A7A7A7" }} />
                    <Typography color="#A7A7A7" sx={{ ml: 1 }}>
                      Airport Road, Sinamangal, Kathmandu, Nepal
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", alignItems: "center" }}>
                    <CallIcon sx={{ color: "#A7A7A7" }} />
                    <Typography color="#A7A7A7" sx={{ ml: 1 }}>
                      +977-1-4117957, +977-1-4117958
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", alignItems: "center" }}>
                    <WhatsAppIcon sx={{ color: "#A7A7A7" }} />
                    <Typography color="#A7A7A7" sx={{ ml: 1 }}>
                      +977-9851148010
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <a
                      href="mailto:expressmela@gmail.com"
                      style={{
                        textDecoration: "none",
                        display: "flex",
                        color: "#000",
                      }}>
                      <EmailIcon sx={{ color: "#A7A7A7" }} />
                      <Typography color="#A7A7A7" sx={{ ml: 1 }}>
                        cs@nepalex.com
                      </Typography>
                    </a>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", alignItems: "center" }}>
                    <HourglassTopIcon sx={{ color: "#A7A7A7" }} />
                    <Typography color="#A7A7A7" sx={{ ml: 1 }}>
                      Sunday - Friday <br /> 10:00 AM TO 7:00 PM
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "primary.main",
          paddingTop: "3px",
          paddingBottom: "3px",
        }}>
        <Container>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}>
              <Typography color="#fff" sx={{ fontSize: "12px" }}>
                &#169; NepalExpress
              </Typography>
              <Typography color="#fff" sx={{ fontSize: "12px" }}>
                Privacy Policy . Terms of use
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ textAlign: { xs: "left", md: "center" } }}>
              <Typography color="#fff" sx={{ fontSize: "12px" }}>
                Powered By Neesum Technology
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
