import { useFormik } from "formik";
import { useState } from "react";
import {
  useCalculateShipmentRate,
  useGetPublicServiceProviders,
  useGetShipmentProviderCountries,
} from "../../../api/usePublicServiceProvider";

const useShippingRatesForm = () => {
  const { data: serviceProviderData, isLoading: serviceProviderLoading } =
    useGetPublicServiceProviders();
  const [rateValues, setRateValues] = useState();
  const [serviceProvider, setServiceProvider] = useState(null);
  const [inputServiceProvider, setInputServiceProvider] = useState("");
  const { mutate } = useCalculateShipmentRate({});
  const [openModal, setOpenModal] = useState({
    open: false,
    data: null,
  });
  const { data: destinationList, isLoading: destinationLoading } =
    useGetShipmentProviderCountries(serviceProvider?.value);

  const packageTypeOptions = [
    { label: "Document", value: "Document" },
    { label: "Non-Document", value: "NonDocument" },
  ];

  const handleServiceProvider = ({ type, value }) => {
    type === "input"
      ? setInputServiceProvider(value)
      : setServiceProvider(value);
  };

  const serviceOptions = [
    {
      label: "Cargo",
      value: "Cargo",
    },
    {
      label: "Courier",
      value: "Courier",
    },
  ];

  const handleCloseModal = () => {
    setOpenModal({
      open: false,
      data: null,
    });
  };

  const formik = useFormik({
    initialValues: {
      country: "",
      packageType: "",
      shipmentProviderId: null,
      shipmentServiceType: "",
      weight: null,
    },
    onSubmit: (values) => {
      values = {
        ...values,
        shipmentProviderId: serviceProvider?.value,
      };
      mutate(values, {
        onSuccess: (data) => {
          setRateValues(data?.rate);
          setOpenModal({
            open: true,
            data: {
              country: values?.country,
              packageType: values?.packageType,
              shipmentProvider: serviceProvider,
              shipmentServiceType: values?.shipmentServiceType,
              weight: values?.weight,
              rate: data?.rate,
            },
          });
        },
      });
    },
  });

  const serviceProviderList =
    serviceProviderData &&
    serviceProviderData?.map((serviceProvider) => {
      return { value: serviceProvider?.id, label: serviceProvider?.name };
    });

  return {
    serviceProviderList,
    serviceProviderLoading,
    rateValues,
    formik,
    packageTypeOptions,
    serviceOptions,
    serviceProvider,
    inputServiceProvider,
    destinationLoading,
    destinationList,
    openModal,
    handleCloseModal,
    handleServiceProvider,
  };
};

export default useShippingRatesForm;
