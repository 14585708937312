import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setUser } from "../../../../app/utils/cookieHelper";
import { login } from "../../../api/login/login-api";

export const useLogin = ({ onSuccess }) => {
  const history = useNavigate();

  return useMutation(
    ["login"],
    ({ username, password }) => login(username, password),
    {
      onSuccess: (data, variables, context) => {
        toast.success("Login Successful");
        setUser({
          token: data?.jwt,
          role: data?.userRole,
          userId: data?.userId,
          tempPassword: data?.tempPassword,
        });
        if (data?.tempPassword) {
          history(`/temp-password`);
        } else {
          switch (data?.userRole) {
            case "SysAdmin":
              return history(`/super/dashboard`);
            case "Admin":
              return history(`/admin/dashboard`);
            case "Customer":
              return history("/my-shipments");
            default:
              return history("/login");
          }
        }

        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(err.message);
      },
    }
  );
};
