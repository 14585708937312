import * as Yup from "yup";

const serviceProviderValidationSchema = Yup.object().shape({
  apiKey: Yup.string(),
  apiSecret: Yup.string(),
  apiService: Yup.boolean(),
  description: Yup.string(),
  enabled: Yup.boolean(),
  name: Yup.string().required("Required"),
});

export { serviceProviderValidationSchema };
