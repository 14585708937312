import { axiosInstance } from "../../../app/api/axiosInterceptor";

export const createShipmentRequest = async (formData) => {
  const { data } = await axiosInstance.post("/v/shipment-request", formData);
  return data;
};

export const getShipmentRequests = async () => {
  const { data } = await axiosInstance.get("/v/shipment-requests");
  return data;
};

export const getShipments = async () => {
  const { data } = await axiosInstance.get("/v/shipments");
  return data;
};

export const getMyShipmentDetail = async (id) => {
  const { data } = await axiosInstance.get(`/v/shipments?id=${id}`);
  return data;
};

export const getServiceProviders = async () => {
  const { data } = await axiosInstance.get("/v/service-providers");
  return data;
};

export const cancelShipment = async (id) => {
  const { data } = await axiosInstance.put(`/v/cancel-shipment?id=${id}`);
  return data;
};

export const trackShipment = async (nstn) => {
  if (nstn) {
    const { data } = await axiosInstance.get(`/v/track?nstn=${nstn}`);
    return data;
  }
};

export const getMyBills = async (id) => {
  if (id) {
    const { data } = await axiosInstance.get(`/v/bill?id=${id}`);
    return data;
  }
};
