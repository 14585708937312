import { axiosInstance } from "../../../app/api/axiosInterceptor";

export const getUsers = async () => {
  const { data } = await axiosInstance.get("/v/admin/admins");
  return data;
};

export const addAdmin = async (formData) => {
  const { data } = await axiosInstance.post("/v/sys/admin", formData);
  return data;
};

export const disableAdmin = async (id) => {
  const { data } = await axiosInstance.post(`/v/sys/disable-admin?id=${id}`);
  return data;
};

export const enableAdmin = async (id) => {
  const { data } = await axiosInstance.post(`/v/sys/enable-admin?id=${id}`);
  return data;
};

export const updateAdmin = async (values) => {
  const { data } = await axiosInstance.put("/v/sys/admin", values);
  return data;
};
