import { useMutation, useQuery, useQueryClient } from "react-query";
import {
    addAdditionalReceivable,
    addCustomerPayment,
    deletePayment,
    getCustomerPayments,
    getShipmentInvoiceByDate,
    updateCustomerPayment,
} from "../../../api/payment/payment-api"
import { toast } from "react-toastify";

export const useGetCustomerPayments = (filters) => {
  return useQuery(
    ["getCustomerPayments", filters],
    () => getCustomerPayments(filters),
    {
      cacheTime: 12000,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );
};
export const useGetShipmentInvoiceByDate = ({customerId,dateFrom,dateTo})=>{
    return useQuery(["getShipmentInvoiceByDate",{customerId,dateFrom,dateTo}],()=>getShipmentInvoiceByDate({customerId,dateFrom,dateTo}))
}

export const useAddCustomerPayment = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(
    ["addCustomerPayment"],
    (formData) => addCustomerPayment(formData),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["getCustomerPayments"]);
        toast.success("Succesfully added payment");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};

export const useAddAdditionalReceivable = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(
    ["addAdditionalReceivable"],
    (formData) => addAdditionalReceivable(formData),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["getCustomerPayments"]);
        toast.success("Succesfully added payment");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};

export const useUpdateCustomerPayment = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(
    ["updateCustomerPayment"],
    (formData) => updateCustomerPayment(formData),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["getCustomerPayments"]);
        toast.success("Succesfully updated payment");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};

export const useDeleteCustomerPayment = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(["deleteCustomerPayment"], (id) => deletePayment(id), {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["getCustomerPayments"]);
      toast.success("Succesfully deleted payment");
      onSuccess && onSuccess(data, variables, context);
    },
    onError: (err, _variables, _context) => {
      toast.error(`error: ${err.message}`);
    },
  });
};
