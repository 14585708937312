import { useFormik } from "formik";
import { useRegister } from "../../api/register/useRegister";
import {
  registerSchema,
  businessRegisterSchema,
} from "./registerValidationSchema";

export const useRegisterForm = ({ isBusiness }) => {
  const { mutate } = useRegister({});
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      mobile: "",
      companyName: "",
      email: "",
      panNo: "",
    },
    validationSchema: isBusiness ? businessRegisterSchema : registerSchema,
    onSubmit: (values) => {
      handleRegister(values);
    },
  });

  const handleRegister = (values) => {
    values = {
      ...values,
      companyName: isBusiness ? values.companyName : null,
      customerType: isBusiness ? "Business" : "Personal",
    };
    mutate(values, { onSuccess: () => formik.handleReset() });
  };

  return {
    handleRegister,
    formik,
  };
};
