import MaterialTable from "@material-table/core";
import { DeleteOutlined, EditOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Switch } from "@mui/material";
import React from "react";

const CustomTable = ({
  title,
  data,
  columns,
  deleteAction,
  editAction,
  customEdit,
  addAction,
  action,
  customEditDetails,
  handleEdit,
  activeAction,
  handleChange,
  handleOpen,
  handleDelete,
  options,
  isLoading,
  tableActions,
}) => {
  return (
    <div className="MTable">
      <MaterialTable
        title={title}
        columns={columns}
        data={data ? data : []}
        isLoading={isLoading}
        actions={
          action
            ? [
                (rowData) =>
                  editAction &&
                  (customEdit
                    ? {
                        icon: customEditDetails?.icon,
                        tooltip: `${customEditDetails?.tooltip}`,
                        onClick: () => {
                          handleEdit(rowData);
                        },
                      }
                    : {
                        icon: () => <EditOutlined />,
                        tooltip: `Edit ${title}`,
                        onClick: () => {
                          handleEdit(rowData);
                        },
                      }),
                (rowData) =>
                  deleteAction && {
                    icon: () => <DeleteOutlined />,
                    tooltip: `Delete ${title}`,
                    onClick: () => handleDelete(rowData?.id),
                  },
                addAction && {
                  icon: () => <AddIcon />,
                  tooltip: `Add ${title}`,
                  isFreeAction: true,
                  onClick: () => handleOpen(),
                },
                (rowData) =>
                  activeAction && {
                    icon: () => <Switch checked={rowData?.enabled} />,
                    tooltip: `Enable/ Disable ${title}`,
                    onClick: (event) =>
                      handleChange(rowData?.id, event.target.checked),
                  },
              ]
            : [
                addAction && {
                  icon: () => <AddIcon />,
                  tooltip: `Add ${title}`,
                  isFreeAction: true,
                  onClick: () => handleOpen(),
                },
              ]
        }
        options={{
          ...options,
          actionsColumnIndex: -1,
          pageSize: 10,
          padding: "dense",
        }}
      />
    </div>
  );
};

export default CustomTable;
