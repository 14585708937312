import { useFormik } from "formik";
import moment from "moment";
import { useState } from "react";
import { useUpdatePickup } from "../../../api/pickup/usePickup";
import { pickupValidationSchema } from "./pickupValidationSchema";

export const useUpdatePickupForm = ({ handleClose, data, adminList }) => {
  const initialAdmin = adminList?.filter(
    (admin) => admin?.value === data?.adminId
  )[0];
  const [adminId, setAdminId] = useState(initialAdmin);
  const [inputAdmin, setInputAdmin] = useState(initialAdmin?.label);
  const { mutate } = useUpdatePickup({});
  const formik = useFormik({
    initialValues: {
      adminId: data?.adminId,
      cnNumber: data?.cnNumber,
      dateTime: data?.dateTime,
      partyName: data?.partyName,
      phoneNumber: data?.phoneNumber,
      status: data?.status,
      pickupAddress: data?.pickupAddress,
      remarks: data?.remarks,
      vehicle: data?.vehicle,
    },
    validationSchema: pickupValidationSchema,
    onSubmit: (values) => {
      values = {
        ...values,
        id: data?.id,
        dateTime: moment(values?.dateTime).format("YYYY-MM-DD HH:mm"),
      };
      handleCreate(values);
    },
  });

  const handleCreate = (values) => {
    mutate(values, {
      onSuccess: () => {
        handleClose();
      },
    });
  };
  return { formik, adminId, setAdminId, inputAdmin, setInputAdmin };
};
