import React from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetServiceProviders } from "../../../hooks/api/service_provider/useServiceProvider";
// import ServiceProviderRateTable from "../../../components/table/service_provider/rate/ServiceProviderRateTable";
// import { TabContext, TabList, TabPanel } from "@mui/lab";
import { ZoneTable } from "../../../components/table/service_provider/zone/ZoneTable";

export const ServiceProviderDetailPage = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetServiceProviders(id);
  // const [value, setValue] = React.useState("1");

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  return isLoading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <div>
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6} md={4} sx={{ display: "flex" }}>
          <Typography variant="h5" color="text.primary">
            Service Provider:
          </Typography>
          <Typography variant="h5" color={"primary"} sx={{ ml: 2 }}>
            {data?.name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ZoneTable id={id} />
        </Grid>
      </Grid>
      {/* <Box sx={{ width: "100%" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Rate" value="1" />
              <Tab label="Zone" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <ServiceProviderRateTable id={id} />
          </TabPanel>
          <TabPanel value="2"></TabPanel>
        </TabContext>
      </Box> */}
    </div>
  );
};
