import * as Yup from "yup";

const personalSchema = Yup.object().shape({
  companyName: Yup.string(),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  mobile: Yup.string().nullable(),
  panNo: Yup.string().required("Required"),
  email: Yup.string()
    .email("Not a valid email")
    .required("Required")
    .notOneOf(
      [Yup.ref("emailOne"), Yup.ref("emailTwo")],
      "Email is similar to optional email"
    ),
  emailOne: Yup.string()
    .email("Not a valid email")
    .notOneOf([Yup.ref("email")], "Email is similar to default email")
    .test("isSameToTwo", "Similar to email Two", (value, context) => {
      return (
        value === undefined ||
        value === null ||
        value === "" ||
        value !== context.parent.emailTwo
      );
    })
    .nullable(),
  emailTwo: Yup.string()
    .email("Not a valid email")
    .notOneOf([Yup.ref("email")], "Email is similar to default email")
    .test(
      "isSameToOne",
      "Similar to email One",
      (value, context) =>
        value === undefined ||
        value === null ||
        value === "" ||
        value !== context.parent.emailOne
    )
    .nullable(),
});

const businessSchema = Yup.object().shape({
  companyName: Yup.string().required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  mobile: Yup.string().nullable(),
  panNo: Yup.string().required("Required"),
  email: Yup.string()
    .email("Not a valid email")
    .required("Required")
    .notOneOf(
      [Yup.ref("emailOne"), Yup.ref("emailTwo")],
      "Email is similar to optional email"
    ),
  emailOne: Yup.string()
    .email("Not a valid email")
    .notOneOf([Yup.ref("email")], "Email is similar to default email")
    .test("isSameToTwo", "Similar to email Two", (value, context) => {
      return (
        value === undefined ||
        value === null ||
        value === "" ||
        value !== context.parent.emailTwo
      );
    })
    .nullable(),
  emailTwo: Yup.string()
    .email("Not a valid email")
    .notOneOf([Yup.ref("email")], "Email is similar to default email")
    .test(
      "isSameToOne",
      "Similar to email One",
      (value, context) =>
        value === undefined ||
        value === null ||
        value === "" ||
        value !== context.parent.emailOne
    )
    .nullable(),
});
export { personalSchema, businessSchema };
