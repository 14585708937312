import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const Priority = () => {
  return (
    <Grid container spacing={0} direction={{ xs: "column-reverse", md: "row" }}>
      <Grid item xs={12} md={6}>
        <img
          src="/assests/images/landing_page/priority.jpg"
          alt="aircargo"
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: { xs: "300px", md: "600px" },
          padding: "16px",
        }}
      >
        <Box sx={{ maxWidth: "600px" }}>
          <Typography sx={{ fontWeight: 800, fontSize: "30px" }}>
            Priority
          </Typography>
          <Typography sx={{ fontSize: "14px" }}>
            We provide fastest mode courier service to our client with picking
            up to delivery your courier. Our courier service facilitates you
            almost all over the world. Using our courier service, you can send
            your courier to any locations from any location in the world.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Priority;
