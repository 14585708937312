import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import AppRoute from "./routes/AppRoute";

const queryClient = new QueryClient({
    defaultOptions: {
        queries:{
            refetchOnWindowFocus:false,
        }
    }
})

function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        theme="colored"
        draggable
        pauseOnHover
      />
              <QueryClientProvider client={queryClient}>
        <AppRoute />
      </QueryClientProvider>
    </div>
  );
}

export default App;
