export function info() {
  const countries = [
    "Andorra",
    "United Arab Emirates",
    "Afghanistan",
    "Antigua & Barbuda",
    "Anguilla",
    "Albania",
    "Armenia",
    "Angola",
    "Antarctica",
    "Argentina",
    "American Samoa",
    "Austria",
    "Australia",
    "Aruba",
    "Åland Islands",
    "Azerbaijan",
    "Bosnia & Herzegovina",
    "Barbados",
    "Bangladesh",
    "Belgium",
    "Burkina Faso",
    "Bulgaria",
    "Bahrain",
    "Burundi",
    "Benin",
    "St Barthélemy",
    "Bermuda",
    "Brunei",
    "Bolivia",
    "Caribbean Netherlands",
    "Brazil",
    "Bahamas",
    "Bhutan",
    "Bouvet Island",
    "Botswana",
    "Belarus",
    "Belize",
    "Canada",
    "Cocos (Keeling) Islands",
    "Congo - Kinshasa",
    "Central African Republic",
    "Congo - Brazzaville",
    "Switzerland",
    "Côte d’Ivoire",
    "Cook Islands",
    "Chile",
    "Cameroon",
    "China",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Cape Verde",
    "Curaçao",
    "Christmas Island",
    "Cyprus",
    "Czechia",
    "Germany",
    "Djibouti",
    "Denmark",
    "Dominica",
    "Dominican Republic",
    "Algeria",
    "Ecuador",
    "Estonia",
    "Egypt",
    "Western Sahara",
    "Eritrea",
    "Spain",
    "Ethiopia",
    "Finland",
    "Fiji",
    "Falkland Islands",
    "Micronesia",
    "Faroe Islands",
    "France",
    "Gabon",
    "United Kingdom",
    "Grenada",
    "Georgia",
    "French Guiana",
    "Guernsey",
    "Ghana",
    "Gibraltar",
    "Greenland",
    "Gambia",
    "Guinea",
    "Guadeloupe",
    "Equatorial Guinea",
    "Greece",
    "South Georgia & South Sandwich Islands",
    "Guatemala",
    "Guam",
    "Guinea-Bissau",
    "Guyana",
    "Hong Kong SAR China",
    "Heard & McDonald Islands",
    "Honduras",
    "Croatia",
    "Haiti",
    "Hungary",
    "Indonesia",
    "Ireland",
    "Israel",
    "Isle of Man",
    "India",
    "British Indian Ocean Territory",
    "Iraq",
    "Iran",
    "Iceland",
    "Italy",
    "Jersey",
    "Jamaica",
    "Jordan",
    "Japan",
    "Kenya",
    "Kyrgyzstan",
    "Cambodia",
    "Kiribati",
    "Comoros",
    "St Kitts & Nevis",
    "North Korea",
    "South Korea",
    "Kuwait",
    "Cayman Islands",
    "Kazakhstan",
    "Laos",
    "Lebanon",
    "St Lucia",
    "Liechtenstein",
    "Sri Lanka",
    "Liberia",
    "Lesotho",
    "Lithuania",
    "Luxembourg",
    "Latvia",
    "Libya",
    "Morocco",
    "Monaco",
    "Moldova",
    "Montenegro",
    "St Martin",
    "Madagascar",
    "Marshall Islands",
    "Macedonia",
    "Mali",
    "Myanmar (Burma)",
    "Mongolia",
    "Macau SAR China",
    "Northern Mariana Islands",
    "Martinique",
    "Mauritania",
    "Montserrat",
    "Malta",
    "Mauritius",
    "Maldives",
    "Malawi",
    "Mexico",
    "Malaysia",
    "Mozambique",
    "Namibia",
    "New Caledonia",
    "Niger",
    "Norfolk Island",
    "Nigeria",
    "Nicaragua",
    "Netherlands",
    "Norway",
    "Nepal",
    "Nauru",
    "Niue",
    "New Zealand",
    "Oman",
    "Panama",
    "Peru",
    "French Polynesia",
    "Papua New Guinea",
    "Philippines",
    "Pakistan",
    "Poland",
    "St Pierre & Miquelon",
    "Pitcairn Islands",
    "Puerto Rico",
    "Palestinian Territories",
    "Portugal",
    "Palau",
    "Paraguay",
    "Qatar",
    "Réunion",
    "Romania",
    "Serbia",
    "Russia",
    "Rwanda",
    "Saudi Arabia",
    "Solomon Islands",
    "Seychelles",
    "Sudan",
    "Sweden",
    "Singapore",
    "St Helena",
    "Slovenia",
    "Svalbard & Jan Mayen",
    "Slovakia",
    "Sierra Leone",
    "San Marino",
    "Senegal",
    "Somalia",
    "Suriname",
    "South Sudan",
    "São Tomé & Príncipe",
    "El Salvador",
    "Sint Maarten",
    "Syria",
    "Swaziland",
    "Turks & Caicos Islands",
    "Chad",
    "French Southern Territories",
    "Togo",
    "Thailand",
    "Tajikistan",
    "Tokelau",
    "Timor-Leste",
    "Turkmenistan",
    "Tunisia",
    "Tonga",
    "Turkey",
    "Trinidad & Tobago",
    "Tuvalu",
    "Taiwan",
    "Tanzania",
    "Ukraine",
    "Uganda",
    "US Outlying Islands",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vatican City",
    "St Vincent & Grenadines",
    "Venezuela",
    "British Virgin Islands",
    "US Virgin Islands",
    "Vietnam",
    "Vanuatu",
    "Wallis & Futuna",
    "Samoa",
    "Yemen",
    "Mayotte",
    "South Africa",
    "Zambia",
    "Zimbabwe",
  ];
  const currencies = [
    "GIP",
    "TOP",
    "BEF",
    "CVE",
    "LTL",
    "FRF",
    "ILS",
    "DOP",
    "XPF",
    "AFA",
    "CUP",
    "SOS",
    "ZWN",
    "CUC",
    "ARS",
    "STD",
    "KMF",
    "SDG",
    "MRU",
    "SDD",
    "ZMK",
    "NZD",
    "MUR",
    "VUV",
    "SEK",
    "TZS",
    "MYR",
    "SVC",
    "XAU",
    "GYD",
    "JMD",
    "WST",
    "XSU",
    "PLN",
    "USD",
    "LRD",
    "MMK",
    "LSL",
    "CLF",
    "BYN",
    "IQD",
    "RON",
    "KPW",
    "BYR",
    "GRD",
    "HUF",
    "ZMW",
    "CZK",
    "KHR",
    "PKR",
    "YUM",
    "MGA",
    "NOK",
    "FJD",
    "BZD",
    "STN",
    "CHE",
    "CLP",
    "COP",
    "GHS",
    "PYG",
    "MGF",
    "EGP",
    "BGL",
    "CDF",
    "ZWR",
    "BSD",
    "MXN",
    "MTL",
    "TRY",
    "SZL",
    "HTG",
    "LBP",
    "XBB",
    "COU",
    "KGS",
    "FKP",
    "JPY",
    "CHF",
    "VES",
    "XAG",
    "RUB",
    "ZAR",
    "TWD",
    "FIM",
    "XPD",
    "UYU",
    "UYI",
    "SCR",
    "SLL",
    "KRW",
    "CYP",
    "SSP",
    "IDR",
    "ATS",
    "QAR",
    "VND",
    "DZD",
    "ZWD",
    "ESP",
    "ADP",
    "AMD",
    "RWF",
    "SBD",
    "CHW",
    "XBA",
    "CAD",
    "GMD",
    "XFO",
    "XOF",
    "ISK",
    "ZWL",
    "IEP",
    "GEL",
    "IRR",
    "SHP",
    "AYM",
    "CRC",
    "PEN",
    "XBD",
    "BHD",
    "PGK",
    "ETB",
    "BIF",
    "DJF",
    "MAD",
    "BGN",
    "TMM",
    "ALL",
    "PAB",
    "GBP",
    "BWP",
    "YER",
    "BMD",
    "NPR",
    "NIO",
    "SAR",
    "SRG",
    "BND",
    "SGD",
    "BOV",
    "TMT",
    "TRL",
    "AED",
    "BDT",
    "KES",
    "GHC",
    "GWP",
    "AZM",
    "UGX",
    "USS",
    "DKK",
    "LAK",
    "EUR",
    "CSD",
    "KYD",
    "ITL",
    "BOB",
    "BRL",
    "NGN",
    "ERN",
    "LKR",
    "THB",
    "PTE",
    "HNL",
    "MVR",
    "CNY",
    "RUR",
    "MZN",
    "ANG",
    "LUF",
    "TND",
    "LYD",
    "TTD",
    "XBC",
    "VEB",
    "SYP",
    "KWD",
    "SIT",
    "XDR",
    "TPE",
    "MNT",
    "AUD",
    "ROL",
    "MWK",
    "UZS",
    "JOD",
    "MDL",
    "LVL",
    "MOP",
    "DEM",
    "SRD",
    "BTN",
    "AOA",
    "NLG",
    "INR",
    "NAD",
    "BAM",
    "HKD",
    "OMR",
    "BYB",
    "AZN",
    "GTQ",
    "AWG",
    "PHP",
    "BBD",
    "VEF",
    "KZT",
    "MXV",
    "XFU",
    "EEK",
    "XCD",
    "HRK",
    "MKD",
    "UAH",
    "XUA",
    "XPT",
    "RSD",
    "MZM",
    "SKK",
    "AFN",
    "XAF",
    "GNF",
    "TJS",
    "MRO",
    "USN",
  ];

  const toObject = (array) => {
    let objectList = [];
    array.forEach((element) => {
      objectList.push({ label: element, value: element });
    });
    return objectList;
  };
  return { countries: toObject(countries), currencies: toObject(currencies) };
}
