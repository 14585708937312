import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const Extra = () => {
  return (
    <Grid container spacing={0}>
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: "primary.main",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "300px",
          padding: "16px",
        }}
      >
        <Box sx={{ maxWidth: "1024px" }}>
          <Typography
            sx={{ fontWeight: 800, fontSize: "24px", textAlign: "center" }}
          >
            In addition to above services, we provide the custom clearance for
            our customer.
          </Typography>
          <Typography
            sx={{ fontSize: "14px", textAlign: "center", color: "#fff" }}
          >
            Our licensed professional customs clearing team provides more
            desirable and effective service through the complex import and
            export customs clearing process.Our Licensed Customs Brokers will
            work with you to expedite the customs clearance of your shipment,
            while at the same time ensuring adherence to all customs compliance
            and government regulations. Apex GlobalLogistics is your experienced
            partner that understands the confusing documents and requirements
            needed for export and import. We guide your shipments through the
            process, shorten the supply chain, cut costs and keep your business
            running smoothly.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Extra;
