import { useContext } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { RateState } from "../../../../app/contexts/rates/RateContext";
import { UserContext } from "../../../../app/contexts/user/UserContext";
import {
  getShipmentRates,
  updateShipmentRates,
} from "../../../api/settings/settings-api";

export const useGetShipmentRates = () => {
  const { role } = useContext(UserContext);
  return useQuery(["getShipmentRates", role], () => getShipmentRates(role), {
    cacheTime: 60000,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};

export const useUpdateShipmentRates = ({ onSuccess }) => {
  const { dispatch } = RateState();
  return useMutation(
    ["updateShipmentRates"],
    (values) => updateShipmentRates(values),
    {
      onSuccess: (data, variables, context) => {
        dispatch({
          type: "SET_RATE",
          payload: data,
        });
        toast.success("Succesfully added service provider zone");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};
