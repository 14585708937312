import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import React, { useState } from "react";
import { useEditCustomerForm } from "../../../hooks/components/form/customer/useEditCustomerForm";
import EditCustomerFormItem from "./items/EditCustomerFormItem";

const EditCustomerForm = ({ open, handleClose, data }) => {
  const [isBusiness, setIsBusiness] = useState(
    data?.customerType?.toUpperCase() === "PERSONAL" ? false : true
  );
  const { formik } = useEditCustomerForm({ data, isBusiness, handleClose });
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>Edit Customer</DialogTitle>
      <Divider />
      <DialogContent>
        <EditCustomerFormItem
          formik={formik}
          isBusiness={isBusiness}
          setIsBusiness={setIsBusiness}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
            formik.handleReset();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCustomerForm;
