import { Grid } from "@mui/material";
import React from "react";
import AirCargo from "./parts/AirCargo";
import EcommerceLogistics from "./parts/EcommerceLogistics";
import Extra from "./parts/Extra";
import OurServices from "./parts/OurServices";
import Parcels from "./parts/Parcels";
import Priority from "./parts/Priority";
import SeaCargo from "./parts/SeaCargo";
import WarehouseAndDistribution from "./parts/WarehouseAndDistribution";

function Services() {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <OurServices />
      </Grid>
      <Grid item xs={12}>
        <Parcels />
      </Grid>
      <Grid item xs={12}>
        <AirCargo />
      </Grid>
      <Grid item xs={12}>
        <SeaCargo />
      </Grid>
      <Grid item xs={12}>
        <WarehouseAndDistribution />
      </Grid>
      <Grid item xs={12}>
        <EcommerceLogistics />
      </Grid>
      <Grid item xs={12}>
        <Priority />
      </Grid>
      <Grid item xs={12}>
        <Extra />
      </Grid>
    </Grid>
  );
}

export default Services;
