import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { createPayment, getMyPayments } from "../../../api/payment/payment-api";

export const useCreatePayment = ({ onSuccess }) => {
  return useMutation(["createPayment"], (values) => createPayment(values), {
    onSuccess: (data, variables, context) => {
      onSuccess && onSuccess(data, variables, context);
    },
    onError: (err, _variables, _context) => {
      toast.error(`error: ${err.message}`);
    },
  });
};

export const useGetMyPayments = () => {
  return useQuery(["getMyPayments"], getMyPayments, {
    cacheTime: 60000,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};
