import React from "react";
import { useAddAdditionalReceivableForm } from "../../../hooks/components/form/payments/useAddAdditionalReceivableForm";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import AddAdditionalReceivableFormItem from "./items/AddAdditionalReceivableFormItem";

const AddAdditionalReceivableForm = ({ open, handleClose }) => {
  const {
    formik,
    customer,
    handleCustomer,
    customerOptionList,
    customerLabelOptions,
  } = useAddAdditionalReceivableForm({
    handleClose,
  });
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>Add Additional Payment</DialogTitle>
      <Divider />
      <DialogContent>
        <AddAdditionalReceivableFormItem
          formik={formik}
          customerList={customerOptionList}
          customerOptionLabel={customerLabelOptions}
          customer={customer}
          handleCustomer={handleCustomer}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAdditionalReceivableForm;
