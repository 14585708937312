import { useQuery } from "react-query";
import { getExchangeRate } from "../../../api/exchange_rate/exchangerate_api";

export const useGetExchangeRate = ({ date }) => {
  return useQuery(["userDetail", date], () => getExchangeRate(date), {
    cacheTime: 12000,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};
