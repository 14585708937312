import {
  Button,
  Chip,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import { useVerifyWeight } from "../../../../../hooks/api/nepal_lite/useNepalLite";

const PackageDetails = ({
  id,
  box,
  noOfOversizeBox,
  dimension,
  actualWeight,
  packageType,
  weightVerified,
  dimensionalWeight,
  appliedWeight,
  noOfIronStrip,
  noOfPlasticStrip,
  descriptionOfGoods,
}) => {
  const { mutate } = useVerifyWeight({});
  return (
    <Paper
      elevation={4}
      sx={{
        ml: { sm: 1, md: 2 },
        mr: { sm: 1, md: 2 },
        mt: 1,
        mb: 1,
        p: "16px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={9}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "24px",
            }}
          >
            Package Details
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {weightVerified ? (
            <Chip color="success" icon={<DoneIcon />} label="verified" />
          ) : (
            <Button
              fullWidth
              variant="contained"
              onClick={() =>
                window.confirm("Verify package weight")
                  ? mutate(id)
                  : window.alert("weight not verified")
              }
              sx={{ textTransform: "none" }}
            >
              Verify Weight
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Type :</Typography>
          <Typography>{packageType || "-"}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Description of Good :</Typography>
          <Typography>{descriptionOfGoods}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "18px",
                }}
              >
                Strips
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">Iron Strips:</Typography>
              <Typography>{noOfIronStrip}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">Plasic Strips:</Typography>
              <Typography>{noOfPlasticStrip}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "18px",
                }}
              >
                Box :
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: "#000",
                        width: "50%",
                        color: "#fff",
                      }}
                    >
                      Number
                    </TableCell>
                    <TableCell>{box}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: "#000",
                        width: "50%",
                        color: "#fff",
                      }}
                    >
                      Oversize Box
                    </TableCell>
                    <TableCell>{noOfOversizeBox}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "18px",
                }}
              >
                Weight :
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: "#000",
                        width: "50%",
                        color: "#fff",
                      }}
                    >
                      Actual
                    </TableCell>
                    <TableCell>{actualWeight}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: "#000",
                        width: "50%",
                        color: "#fff",
                      }}
                    >
                      Dimensional
                    </TableCell>
                    <TableCell>{dimensionalWeight}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: "#000",
                        width: "50%",
                        color: "#fff",
                      }}
                    >
                      Applied
                    </TableCell>
                    <TableCell>{appliedWeight || "-"}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Dimensions:</Typography>
          <Typography>{dimension}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PackageDetails;
