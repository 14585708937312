import React from "react";
import ServiceProviderTable from "../../components/table/service_provider/ServiceProviderTable";

function ServiceProvider() {
  return (
    <>
      <ServiceProviderTable />
    </>
  );
}

export default ServiceProvider;
