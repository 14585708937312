import React from "react";
import { useParams } from "react-router-dom";
import UpdateShipmentForm from "../../components/form/nepal_lite/update_shipment/UpdateShipmentForm";
import { useGetShipmentRequestLiteDetail } from "../../hooks/api/nepal_lite/useNepalLite";

const EditShipmentLite = () => {
  const { id } = useParams({});
  const { data, isLoading } = useGetShipmentRequestLiteDetail(id);

  return isLoading ? (
    <div style={{ display: "flex", justifyContent: "center" }}>
      Is Loading ...
    </div>
  ) : (
    <div>
      <UpdateShipmentForm data={data} />
    </div>
  );
};

export default EditShipmentLite;
