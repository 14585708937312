import React from "react";
import ShipmentRequestsTable from "../../components/ship/table/shipment_requests/ShipmentRequestsTable";

const MyShipmentRequests = () => {
  return (
    <div>
      <ShipmentRequestsTable />
    </div>
  );
};

export default MyShipmentRequests;
