import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Pagination,
  TextField,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const DimensionDialog = ({
  open,
  handleClose,
  formik,
  handleDimensionalWeight,
}) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [open]);

  const boxesPerPage = 10;
  const numPages = Math.ceil(formik.values.numberOfBoxes / boxesPerPage);

  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const renderBoxes = () => {
    const startIndex = (page - 1) * boxesPerPage;

    return Array.from({ length: boxesPerPage }, (_, index) => {
      const boxIndex = startIndex + index;

      if (boxIndex >= formik.values.numberOfBoxes) {
        return null;
      }

      return (
        <Grid container spacing={1} key={boxIndex} sx={{ width: "100%" }}>
          <Grid item xs={12} sm={3}>
            <TextField
              size="small"
              margin="dense"
              fullWidth
              name={`length[${boxIndex}]`}
              label={`Length ${boxIndex + 1}`}
              type="number"
              value={formik.values.length[boxIndex]}
              onChange={formik.handleChange}
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              error={formik.touched.length && Boolean(formik.errors.length)}
              helperText={formik.touched.length && formik.errors.length}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              size="small"
              margin="dense"
              fullWidth
              name={`bredth[${boxIndex}]`}
              label={`Bredth ${boxIndex + 1}`}
              type="number"
              value={formik.values.bredth[boxIndex]}
              onChange={formik.handleChange}
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              error={formik.touched.bredth && Boolean(formik.errors.bredth)}
              helperText={formik.touched.bredth && formik.errors.bredth}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              size="small"
              margin="dense"
              fullWidth
              name={`height[${boxIndex}]`}
              label={`Height ${boxIndex + 1}`}
              type="number"
              value={formik.values.height[boxIndex]}
              onChange={formik.handleChange}
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              error={formik.touched.height && Boolean(formik.errors.height)}
              helperText={formik.touched.height && formik.errors.height}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              size="small"
              margin="dense"
              fullWidth
              name={`noOfBoxesToCalculate[${boxIndex}]`}
              label={`No. of Boxes: ${boxIndex + 1}`}
              type="number"
              value={formik.values.noOfBoxesToCalculate[boxIndex]}
              onChange={formik.handleChange}
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              error={formik.touched.height && Boolean(formik.errors.height)}
              helperText={formik.touched.height && formik.errors.height}
            />
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>Calculate Dimensional weight</DialogTitle>
      <Divider />
      <DialogContent>{renderBoxes()}</DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          sx={{ textTransform: "none" }}
          onClick={() => handleClose()}
        >
          Close
        </Button>
        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={() => {
            handleDimensionalWeight();
            handleClose();
          }}
        >
          Calculate
        </Button>
        <Pagination count={numPages} page={page} onChange={handleChangePage} />
      </DialogActions>
    </Dialog>
  );
};

export default DimensionDialog;
