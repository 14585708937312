import { useFormik } from "formik";
import { useSendCustomerNotice } from "../../../api/office_notices/useOfficeNotices";
import { sendOfficeNoticeValidationSchema } from "../office_notices/sendOfficeNoticeValidationSchema";

export const useSendCustomerNoticeForm = () => {
  const { mutate } = useSendCustomerNotice({});
  const formik = useFormik({
    initialValues: {
      important: false,
      message: "",
      subject: "",
    },
    validationSchema: sendOfficeNoticeValidationSchema,
    onSubmit: (values) => {
      handleUpdate(values);
    },
  });

  const handleUpdate = (values) => {
    mutate(values);
  };
  return { formik };
};
