import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { RateState } from "../../../../../../app/contexts/rates/RateContext";
import { useAddShipmentBilling } from "../../../../api/nepal_lite/useNepalLite";
import { shipmentBillingSchema } from "./shipmentBillingSchema";
import {useRetrieveFreightQuery} from "../../../../api/shipment/useShipmentFreightRate"

export const useAddShipmentBillingForm = ({ id, data }) => {
  const { state } = RateState();
  const { mutate } = useAddShipmentBilling({});
  const [tia, setTia] = useState(0);
  const [strip, setStrip] = useState(0);
  const [customs, setCustoms] = useState(0);
  const {
    data:freightData,
  }= useRetrieveFreightQuery({
      freight:data?.doc,
      shipmentData:{
          country:data?.destination,
          customerCategory :data?.customer?.customerCategory ,
          packageType :data?.packageType ,
          shipmentProviderId :data?.serviceProviderId ,
          weight:data?.appliedWeight,
    }})

  //calculate initial tia
  const calculateTia = useCallback(() => {
    if (data?.customClearance === "Self") {
      return;
    }

    let calculatedTia;

    if (data?.service === "Courier") {
      calculatedTia = calculateCourierTia();
    } else {
      calculatedTia = calculateCargoTia();
    }

    setTia(calculatedTia);
    //eslint-disable-next-line
  }, [data, state]);

  function calculateCargoTia() {
    return Math.ceil(data?.appliedWeight) * state?.tiaCargo >
      state.minTiaForCargo
      ? Math.ceil(data?.appliedWeight) * state?.tiaCargo
      : state.minTiaForCargo;
  }

  function calculateCourierTia() {
    if (data?.shipmentType === "NonCommercial") {
      return data?.appliedWeight * state?.tiaCourier;
    } else {
      return state?.minTiaForCourierCommercial >
        Math.ceil(data?.appliedWeight) * state?.tiaCourier
        ? state?.minTiaForCourierCommercial
        : Math.ceil(data?.appliedWeight) * state?.tiaCourier;
    }
  }

  //calculate initial strip
  const getStripValue = (data, state) => {
    if (data?.customClearance === "NepalEx") {
      return (
        data?.noOfIronStrip * state?.ironStrip +
        data?.noOfPlasticStrip * state?.plasticStrip
      );
    }
    return 0;
  };

  const calculateStrip = useCallback(() => {
    setStrip(getStripValue(data, state));
  }, [data, state]);

  //calculate custom
  const calculateCustom = useCallback(() => {
    let customValue;
    if (data?.customClearance === "Self") {
      customValue = 0;
    } else {
      if (data?.shipmentType === "Commercial") {
        customValue =
          state.minCustomCharge > data?.numberOfBoxes * state.customClearance
            ? state.minCustomCharge
            : data?.numberOfBoxes * state.customClearance;
      } else if (
        data?.shipmentType === "NonCommercial" &&
        data?.appliedWeight > 10
      ) {
        customValue = data?.numberOfBoxes * state.customClearance;
      } else {
        customValue = 0;
      }
    }

    setCustoms(customValue);
  }, [data, state]);

  const formik = useFormik({
    initialValues: {
      billType: data?.billType || "",
      custom: data?.custom || customs,
      discountOfCustom: data?.discountOfCustom || 0,
      discountOfStrip: data?.discountOfStrip || 0,
      discountOnTia: data?.discountOnTia || 0,
      doc: data?.doc || 0,
      freight: (data?.freight || 0).toFixed(2),
      freightGivenRate: (freightData?.rate || data?.freightGivenRate || 0).toFixed(2),
      otherDetails: data?.otherDetails || "",
      others: data?.others || 0,
      oversize: data?.oversize || 0,
      remote: data?.remote || 0,
      shipmentId: data?.shipmentId || id,
      strip: data?.strip || strip - data?.discountOfStrip,
      tia: data?.tia || tia - data?.discountOnTia,
      totalCharges: data?.totalCharges || 0,
      vat: data?.vat || 0,
    },
    enableReinitialize: true,
    validationSchema: shipmentBillingSchema,
    onSubmit: (values) => {
      handleConfrim(values);
    },
  });

  useEffect(() => {
    calculateTia();
  }, [calculateTia]);

  useEffect(() => {
    calculateStrip();
  }, [calculateStrip]);

  useEffect(() => {
    calculateCustom();
  }, [calculateCustom]);

  const handleTotalCharges = useCallback(() => {
    let totalCharges =
      formik.values.freight +
      formik.values.tia +
      formik.values.custom +
      formik.values.doc +
      formik.values.strip +
      formik.values.oversize +
      formik.values.remote +
      formik.values.others +
      formik.values.vat;
    formik.setFieldValue("totalCharges", parseFloat(totalCharges.toFixed(2)));

    // eslint-disable-next-line
  }, [
    formik.values.freight,
    formik.values.tia,
    formik.values.custom,
    formik.values.doc,
    formik.values.strip,
    formik.values.oversize,
    formik.values.remote,
    formik.values.others,
    formik.values.vat,
    formik.values.discountOfCustom,
    formik.values.discountOfStrip,
    formik.values.discountOnTia,
    formik.values.billType,
    state,
  ]);

  const handleDiscountOnTia = useCallback(() => {
    if (tia) {
      formik.setFieldValue("tia", tia - formik.values.discountOnTia);
    }

    //eslint-disable-next-line
  }, [formik.values.discountOnTia]);

  const useDebouncedEffect = (effect, delay, deps) => {
    useEffect(() => {
      let isCancelled = false;

      const handleChange = async () => {
        await timeout(delay);
        if (!isCancelled) {
          effect();
        }
      };

      handleChange();

      return () => {
        isCancelled = true;
      };
      //eslint-disable-next-line
    }, deps);
  };

  const handleDiscountOnStrip = useCallback(() => {
    if (strip) {
      formik.setFieldValue("strip", strip - formik.values.discountOfStrip);
    }
    //eslint-disable-next-line
  }, [formik.values.discountOfStrip]);

  const handleDiscountOnCustoms = useCallback(() => {
    if (customs) {
      formik.setFieldValue("custom", customs - formik.values.discountOfCustom);
    }
    //eslint-disable-next-line
  }, [formik.values.discountOfCustom]);

  const handleVat = useCallback(() => {
    let vat = 0;
    if (formik.values.billType === "Vat") {
      vat =
        (formik.values.freight +
          formik.values.tia +
          formik.values.doc +
          formik.values.custom +
          formik.values.strip +
          formik.values.remote +
          formik.values.others) *
        0.13;
      formik.setFieldValue("vat", parseFloat(vat.toFixed(2)));
    } else if (formik.values.billType === "Pan") {
      vat =
        (formik.values.tia +
          formik.values.doc +
          formik.values.custom +
          formik.values.strip +
          formik.values.remote +
          formik.values.others) *
        0.13;
      formik.setFieldValue("vat", parseFloat(vat.toFixed(2)));
    } else {
      formik.setFieldValue("vat", vat);
    }
    // eslint-disable-next-line
  }, [
    formik.values.billType,
    formik.values.tia,
    formik.values.doc,
    formik.values.custom,
    formik.values.strip,
    formik.values.oversize,
    formik.values.remote,
    formik.values.others,
    formik.values.freight,
    formik.values.discountOfCustom,
    formik.values.discountOfStrip,
    formik.values.discountOnTia,
    state,
  ]);

  const handleOversize = useCallback(() => {
    formik.setFieldValue(
      "oversize",
      data?.numberOfOversizeBoxes * state.oversize
    );
    //eslint-disable-next-line
  }, [formik.values.billType, state]);

  const handleRemote = useCallback(() => {
    if (data?.remoteArea) {
      if (formik.values.billType === "Vat") {
        formik.setFieldValue(
          "remote",
          parseInt((state.remote / 1.13).toFixed(2))
        );
      } else {
        formik.setFieldValue("remote", state.remote);
      }
    }
    //eslint-disable-next-line
  }, [formik.values.billType, state]);

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handelFreight = useCallback(() => {
    if (formik.values.billType === "Vat") {
      formik.setFieldValue(
        "freight",
        formik.values.freightGivenRate
          ? parseFloat((formik.values.freightGivenRate / 1.13).toFixed(2))
          : 0
      );
    } else {
      formik.setFieldValue("freight", formik.values.freightGivenRate);
    }
    // eslint-disable-next-line
  }, [formik.values.freightGivenRate, formik.values.billType, state]);

  useDebouncedEffect(handleDiscountOnTia, 500, [handleDiscountOnTia]);
  useDebouncedEffect(handleDiscountOnStrip, 500, [handleDiscountOnStrip]);
  useDebouncedEffect(handleDiscountOnCustoms, 500, [handleDiscountOnCustoms]);
  useDebouncedEffect(handleTotalCharges, 500, [handleTotalCharges]);
  useDebouncedEffect(handleVat, 500, [handleVat]);
  useDebouncedEffect(handleOversize, 500, [handleOversize]);
  useDebouncedEffect(handleRemote, 500, [handleRemote]);
  useDebouncedEffect(handelFreight, 500, [handelFreight]);

  const handleConfrim = (values) => {
    values = {
      ...values,
    };
    mutate(values);
  };

  return {
    formik,
    tia,
  };
};
