import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import React, { useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { useImportShipmentRateFromFile } from "../../../hooks/api/service_provider/rates/useServiceProviderRate";
import { useParams } from "react-router-dom";

const AddRateByFileForm = ({ open, handleClose }) => {
  const [progress, setProgress] = useState(false);
  const { id, zone } = useParams();

  const [file, setFile] = useState(null);

  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };

  const { mutate } = useImportShipmentRateFromFile({});

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>Upload File</DialogTitle>
      {progress ? (
        <></>
      ) : (
        <DialogContent>
          <input
            type="file"
            name="file"
            className="custom-file-input"
            id="inputGroupFile"
            required
            onChange={handleFile}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </DialogContent>
      )}
      <Divider />
      {progress ? (
        <DialogActions>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress color="primary" />
          </Box>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button
            variant="outlined"
            href={"/template/rateTemplate.xlsx"}
            download="Rate_Template.xlsx"
            sx={{ textTransform: "none", mr: 3 }}
          >
            * Download template
            <DownloadIcon />
          </Button>
          <Button
            sx={{ textTransform: "none" }}
            variant="outlined"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ textTransform: "none" }}
            onClick={() => {
              setProgress(true);
              mutate(
                { file, id, zone },
                {
                  onSettled: () => {
                    setProgress(false);
                    handleClose();
                  },
                }
              );
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default AddRateByFileForm;
