import {
  useDeleteZoneCountry,
  useGetZoneCountries,
} from "../../../../../api/service_provider/countries/useZoneCountries";

const useZoneCountryTable = ({ id, zone }) => {
  const { data, isLoading } = useGetZoneCountries({ id, zone });

  const { mutate } = useDeleteZoneCountry({});

  const handleDelete = (id) => {
    mutate(id);
  };

  const columns = [
    {
      title: "id",
      field: "id",
      render: (rowData) => data?.findIndex((x) => x?.id === rowData?.id) + 1,
    },
    {
      title: "Country",
      field: "country",
    },
  ];
  return { data, isLoading, columns, handleDelete };
};

export default useZoneCountryTable;
