import { Grid, TextField } from "@mui/material";
import React from "react";

const DeliveredStatusFormItem = ({ formik }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          size="small"
          margin="dense"
          fullWidth
          name="receiverName"
          label="Receiver"
          value={formik.values.receiverName}
          onChange={formik.handleChange}
          error={
            formik.touched.receiverName && Boolean(formik.errors.receiverName)
          }
          helperText={formik.touched.receiverName && formik.errors.receiverName}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          size="small"
          margin="dense"
          fullWidth
          required
          name="receivedDate"
          label="Received Date"
          value={formik.values.receivedDate}
          onChange={formik.handleChange}
          error={
            formik.touched.receivedDate && Boolean(formik.errors.receivedDate)
          }
          helperText={formik.touched.receivedDate && formik.errors.receivedDate}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      {/* <Grid item xs={12}>
        <TextField
          size="small"
          margin="dense"
          fullWidth
          required
          name="receivedTime"
          label="Time"
          type="time"
          value={formik.values.receivedTime}
          onChange={formik.handleChange}
          error={
            formik.touched.receivedTime && Boolean(formik.errors.receivedTime)
          }
          helperText={formik.touched.receivedTime && formik.errors.receivedTime}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid> */}
      <Grid item xs={12}>
        <TextField
          size="small"
          margin="dense"
          fullWidth
          required
          name="remarks"
          label="Remarks"
          multiline
          value={formik.values.remarks}
          onChange={formik.handleChange}
          error={formik.touched.remarks && Boolean(formik.errors.remarks)}
          helperText={formik.touched.remarks && formik.errors.remarks}
        />
      </Grid>
    </Grid>
  );
};

export default DeliveredStatusFormItem;
