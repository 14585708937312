import { Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";

const OurServices = () => {
  return (
    <Box sx={{ background: "#000" }}>
      <Container
        sx={{
          paddingTop: { xs: "100px", md: "10%" },
          paddingBottom: "10%",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              className="tracking-in-expand-fwd-bottom"
              sx={{
                pr: 1,
                color: "primary.main",
                fontWeight: 800,
                fontSize: "30px",
                lineHeight: "26px",
              }}
            >
              Our Services
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{ fontSize: "14px", maxWidth: "600px", color: "#fff" }}
            >
              Customized Courier Delivery Services Globally working more than 9
              Years providing International Cargo and Courier Services, we have
              always focused on improvising our services. As a comprehensive
              logistics company, we offer variety of delivery service range
              according to your requirements.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default OurServices;
