import { useFormik } from "formik";
import { useUpdateShipmentStatus } from "../../../../api/nepal_lite/useNepalLite";
import { updateShipmentStatusValidationSchema } from "./updateShipmentStatusValidationSchema";

const useUpdateShipmentStatusForm = ({ handleClose, id }) => {
  const { mutate } = useUpdateShipmentStatus({});
  const formik = useFormik({
    initialValues: {
      receivedDate: "",
      receivedTime: "",
      receiverName: "",
      remarks: "",
      status: "Delivered",
    },
    validationSchema: updateShipmentStatusValidationSchema,
    onSubmit: (values) => {
      handleConfrim(values);
      handleClose();
    },
  });

  const handleConfrim = (values) => {
    values = {
      ...values,
      shipmentId: id,
    };
    mutate(values, {
      onSuccess: () => {
        handleClose();
        formik.handleReset();
      },
    });
  };

  return { formik };
};

export default useUpdateShipmentStatusForm;
