import * as Yup from "yup";

const shipmentLiteValidationSchema = Yup.object().shape({
  serviceProviderId: Yup.number().required("Required"),
  awb: Yup.string().required("Required"),
  shipperId: Yup.number().required("Required"),
  invoiceValue: Yup.number().required("Required"),
  currency: Yup.string().required("Required"),
  packageType: Yup.string().required("Required"),
  consignee: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  destination: Yup.string().required("Required"),
  dimensionalWeight: Yup.number()
    .min(0, "Should be a positive number")
    .required("Required"),
  actualWeight: Yup.number()
    .min(0.1, "Should be greater than 0")
    .required("Required"),
  appliedWeight: Yup.number()
    .min(0.1, "Should be greater than 0")
    .required("Required"),
  descriptionOfGoods: Yup.string().required("Required"),
  exchangeRate: Yup.string().required("Required"),
  invoiceValueForCustom: Yup.number()
    .min(0, "Should be greater than 0")
    .required("Required"),
  numberOfBoxes: Yup.number()
    .min(1, "Should be greater than 0")
    .required("Required"),
  numberOfOversizeBoxes: Yup.number().required("Required"),
  postalCode: Yup.string().required("Required"),
  requestDate: Yup.string().required("Required"),
  importExport: Yup.string().required("Required"),
  shipperReference: Yup.string(),
});

export { shipmentLiteValidationSchema };
