import React, { useState } from "react";
import CustomTable from "../../../../../app/component/table/CustomTable";
import { useMyShipmentRequestsTable } from "../../../../hooks/components/ship/table/useMyShipmentRequestsTable";
import CancelShippmentDialog from "../../form/CancelShippmentDialog";

const ShipmentRequestsTable = () => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  const { data, column, customEditDetails } = useMyShipmentRequestsTable({});
  const handleEdit = (values) => {
    setOpen(true);
    setId(values?.id);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <CustomTable
        title="My Shipping Requests"
        data={data}
        columns={column}
        customEdit={true}
        handleEdit={handleEdit}
        editAction={true}
        customEditDetails={customEditDetails}
        action={true}
      />
      <CancelShippmentDialog id={id} open={open} handleClose={handleClose} />
    </div>
  );
};

export default ShipmentRequestsTable;
