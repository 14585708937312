import {
  useDisableAdmin,
  useEnableAdmin,
  useGetUsers,
} from "../../../api/admin/useAdmin";

export const useAdminTable = () => {
  const { data } = useGetUsers();

  const { mutate: mutateEnable } = useEnableAdmin({});

  const { mutate: mutateDisable } = useDisableAdmin({});

  const handleChange = (id, active) => {
    active ? mutateEnable(id) : mutateDisable(id);
  };

  const columns = [
    {
      title: "id",
      field: "id",
      render: (rowData) => data?.findIndex((x) => x?.id === rowData?.id) + 1,
    },
    {
      title: "Name",
      field: "fullName",
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Role",
      field: "userRole",
    },
  ];

  return { columns, data, handleChange };
};
