import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useChangePasswordForm } from "../../../../hooks/components/form/settings/profile/useChangePasswordForm";

const ChangePassword = () => {
  const {
    formik,
    showValues,
    handleClickShowPassword,
    handleMouseDownPassword,
    handleClickShowConfirmPassword,
    handleMouseDownConfirmPassword,
    handleClickShowNewPassword,
    handleMouseDownNewPassword,
  } = useChangePasswordForm({});
  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "70%" }}>Change Password</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Old Password"
                  fullWidth
                  required
                  autoComplete="old-password"
                  value={formik.values.oldPassword}
                  onChange={formik.handleChange}
                  name="oldPassword"
                  error={
                    formik.touched.oldPassword &&
                    Boolean(formik.errors.oldPassword)
                  }
                  helperText={
                    formik.touched.oldPassword && formik.errors.oldPassword
                  }
                  id="oldPassword"
                  type={showValues.showPassword ? "text" : "password"}
                  sx={{ minWidth: "10vw", mt: 1 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword(true)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showValues.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="New Password"
                  fullWidth
                  autoComplete="new-password"
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  required
                  name="newPassword"
                  error={
                    formik.touched.newPassword &&
                    Boolean(formik.errors.newPassword)
                  }
                  helperText={
                    formik.touched.newPassword && formik.errors.newPassword
                  }
                  id="newPassword"
                  type={showValues.showNewPassword ? "text" : "password"}
                  sx={{ minWidth: "10vw", mt: 1 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowNewPassword(true)}
                          onMouseDown={handleMouseDownNewPassword}
                          edge="end"
                        >
                          {showValues.showNewPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Repeat Password"
                  fullWidth
                  required
                  autoComplete="new-password"
                  name="repeatNewPassword"
                  id="repeatNewPassword"
                  type={showValues.showConfirmPassword ? "text" : "password"}
                  value={formik.values.repeatNewPassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.repeatNewPassword &&
                    Boolean(formik.errors.repeatNewPassword)
                  }
                  helperText={
                    formik.touched.repeatNewPassword &&
                    formik.errors.repeatNewPassword
                  }
                  sx={{ minWidth: "10vw", mt: 1 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                          edge="end"
                        >
                          {showValues.showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  onClick={() => formik.handleSubmit()}
                >
                  Change Password
                </Button>
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ChangePassword;
