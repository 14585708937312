import React from "react";
import { useGetCustomerPayments } from "../../../../hooks/api/payment/usePayment";
import { CircularProgress, Divider, Grid, Typography } from "@mui/material";
import CustomerPaymentHistoryTable from "../../../../components/table/customer/payment/CustomerPaymentHistoryTable";

const CustomerPaymentDetailPage = ({ id }) => {
  const { data, isLoading } = useGetCustomerPayments({ id: id });

  return isLoading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "10vh",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={4}>
            <Typography variant="subtitle2">Total</Typography>
            <Typography>{data?.totalAmount}</Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography variant="subtitle2">Amount Paid</Typography>
            <Typography>{data?.amountPaid}</Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography variant="subtitle2">Credit Remaining</Typography>
            <Typography>{data?.creditRemaining}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <CustomerPaymentHistoryTable
          data={data?.customerPayments}
        />
      </Grid>
    </Grid>
  );
};

export default CustomerPaymentDetailPage;
