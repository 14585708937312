import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const AirCargo = () => {
  return (
    <Grid container spacing={0} direction={{ xs: "column-reverse", md: "row" }}>
      <Grid item xs={12} md={6}>
        <img
          src="/assests/images/landing_page/aircargo.jpg"
          alt="aircargo"
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: { xs: "300px", md: "600px" },
          padding: "16px",
        }}
      >
        <Box sx={{ maxWidth: "600px" }}>
          <Typography sx={{ fontWeight: 800, fontSize: "30px" }}>
            Air Cargo
          </Typography>
          <Typography sx={{ fontSize: "14px" }}>
            Our network covers almost all locations in the world to give you
            best air cargo service.When cargos are small and time crucial, air
            cargo could be the better option.We serve you with our airport- to -
            airport, door - to - door or any combination. You don’t need to
            worry if your cargo size is large and heavy weight or any type, we
            have services that meet your needs. We also offer export and import
            custom clearing service and help you to documentation. Our fruitful
            relationship with global airline partnerships aim to offer lowest -
            price, transit time, fixed allocations and lift capacity of carriers
            in each major trade lane and their ability to add efficiency to our
            clients' supply chain management.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AirCargo;
