import React from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Badge,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  useDeleteNotification,
  useGetUserNotifications,
} from "../../hooks/api/notifications/useNotifications";
import { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const Notification = () => {
  const { data } = useGetUserNotifications();
  const { mutate } = useDeleteNotification({});

  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpen = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElUser(null);
  };

  return (
    <div style={{ marginRight: "16px" }}>
      <IconButton size="large" color="inherit" onClick={handleOpen}>
        <Badge badgeContent={data ? data?.length : 0} color="error">
          <NotificationsIcon color="primary" />
        </Badge>
      </IconButton>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar-notification"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleClose}
      >
        {data?.length > 0 ? (
          data?.map((item) => {
            return (
              <MenuItem key={item?.id}>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={9}>
                    <Link
                      to={`/super/pickup/${item?.pickupId}`}
                      style={{
                        textDecoration: "none",
                        color: "#000",
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            color="text.primary"
                            align="left"
                            sx={{
                              overflowWrap: "anywhere",
                            }}
                          >
                            {item?.text}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography color="text.primary" variant="subtitle2">
                            Created: {moment(item?.created).format("lll")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider sx={{ color: "text.primary" }} />
                        </Grid>
                      </Grid>
                    </Link>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <IconButton onClick={() => mutate(item?.id)}>
                      <Tooltip title="delete">
                        <DeleteIcon color="error" />
                      </Tooltip>
                    </IconButton>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </MenuItem>
            );
          })
        ) : (
          <MenuItem>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>No new notifications</Typography>
              </Grid>
            </Grid>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default Notification;
