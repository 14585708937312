import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import RateModal from "../../../components/modal/rate_modal/RateModal";
import useShippingRatesForm from "../../../hooks/components/form/shipping-rates/useShippingRatesForm";

const HeroContent = () => {
  const {
    formik,
    packageTypeOptions,
    serviceOptions,
    serviceProvider,
    inputServiceProvider,
    handleServiceProvider,
    serviceProviderList,
    serviceProviderLoading,
    destinationList,
    destinationLoading,
    openModal,
    handleCloseModal,
  } = useShippingRatesForm();

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background:
          "linear-gradient(0deg, rgba(0, 0, 0, 0.97), rgba(0, 0, 0, 0.27)), url(/assests/images/landing_page/1.jpg)",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container
        sx={{
          paddingTop: { xs: "100px", md: 0 },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "88vh",
        }}
      >
        <Grid container spacing={10}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              borderRight: { md: "1px solid #fff" },
              display: "flex",
              alignItems: "center",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      pr: 1,
                      color: "#fff",
                      fontWeight: 800,
                      fontSize: "30px",
                      lineHeight: "38.73px",
                    }}
                  >
                    You
                  </Typography>
                  <Typography
                    sx={{
                      pr: 1,
                      color: "primary.main",
                      fontWeight: 800,
                      fontSize: "32px",
                      lineHeight: "38.73px",
                    }}
                  >
                    STAY
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: 800,
                      fontSize: "30px",
                      lineHeight: "38.73px",
                    }}
                  >
                    at Home,
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      pr: 1,
                      color: "#fff",
                      fontWeight: 800,
                      fontSize: "30px",
                      lineHeight: "38.73px",
                    }}
                  >
                    We
                  </Typography>
                  <Typography
                    sx={{
                      pr: 1,
                      color: "primary.main",
                      fontWeight: 800,
                      fontSize: "32px",
                      lineHeight: "38.73px",
                    }}
                  >
                    DELIVER
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: 800,
                      fontSize: "30px",
                      lineHeight: "38.73px",
                    }}
                  >
                    to You
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: 800,
                    fontSize: "30px",
                    lineHeight: "38.73px",
                    width: { xs: "100%", sm: "500px" },
                    textAlign: { xs: "center", md: "left" },
                  }}
                >
                  Check Our rates
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  value={serviceProvider}
                  onChange={(_event, newValue) => {
                    handleServiceProvider({ type: "data", value: newValue });
                  }}
                  inputValue={inputServiceProvider}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onInputChange={(_event, newInputValue) => {
                    handleServiceProvider({
                      type: "input",
                      value: newInputValue,
                    });
                  }}
                  InputProps={{
                    style: { color: "#fff" },
                  }}
                  InputLabelProps={{
                    style: { color: "#fff" },
                  }}
                  loading={serviceProviderLoading}
                  options={serviceProviderList || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      margin="dense"
                      error={
                        formik.touched.serviceProviderId &&
                        Boolean(formik.errors.serviceProviderId)
                      }
                      helperText={
                        formik.touched.serviceProviderId &&
                        formik.errors.serviceProviderId
                      }
                      label="Service Provider"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  margin="dense"
                  select
                  name="packageType"
                  label="Package Type"
                  InputProps={{
                    style: { color: "#fff" },
                  }}
                  InputLabelProps={{
                    style: { color: "#fff" },
                  }}
                  value={formik.values.packageType || ""}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.packageType &&
                    Boolean(formik.errors.packageType)
                  }
                  helperText={
                    formik.touched.packageType && formik.errors.packageType
                  }
                  fullWidth
                >
                  {packageTypeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  options={destinationList || []}
                  disabled={destinationList ? false : true}
                  getOptionLabel={(option) => option}
                  value={formik.values.country}
                  onChange={(_event, newValue) => {
                    formik.setFieldValue("country", newValue);
                  }}
                  loading={destinationLoading}
                  InputProps={{
                    style: { color: "#fff" },
                  }}
                  InputLabelProps={{
                    style: { color: "#fff" },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Destination"
                      size="small"
                      margin="dense"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  size="small"
                  margin="dense"
                  select
                  name="shipmentServiceType"
                  label="Type"
                  InputProps={{
                    style: { color: "#fff" },
                  }}
                  InputLabelProps={{
                    style: { color: "#fff" },
                  }}
                  value={formik.values.shipmentServiceType}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.shipmentServiceType &&
                    Boolean(formik.errors.shipmentServiceType)
                  }
                  helperText={
                    formik.touched.shipmentServiceType &&
                    formik.errors.shipmentServiceType
                  }
                  fullWidth
                >
                  {serviceOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="weight"
                  label="Estimated Weight (in Kg)"
                  size="small"
                  margin="dense"
                  type="number"
                  fullWidth
                  InputProps={{
                    style: { color: "#fff" },
                    inputProps: {
                      min: 0,
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#fff" },
                  }}
                  value={formik.values.weight}
                  onChange={formik.handleChange}
                  error={formik.touched.weight && Boolean(formik.errors.weight)}
                  helperText={formik.touched.weight && formik.errors.weight}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                  onClick={formik.handleSubmit}
                >
                  Check Rate
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <RateModal
        open={openModal?.open}
        data={openModal?.data}
        handleClose={handleCloseModal}
      />
    </Box>
  );
};

export default HeroContent;
