import {axiosInstance} from "../../../app/api/axiosInterceptor"

export const getShipments = async (dateRange, date) => {
    let link = "/v/admin/shipments"
    if (
        dateRange !== undefined &&
        dateRange?.dateFrom !== "" &&
        dateRange?.dateTo !== ""
    )
        link = link.concat(
            `?dateFrom=${dateRange?.dateFrom}&dateTo=${dateRange?.dateTo}`
        )
    if (date && date !== "") link = link.concat(`?date=${date}`)
    const {data} = await axiosInstance.get(link)
    return data
}

export const getShipmentDetail = async (id) => {
    const {data} = await axiosInstance.get(`/v/admin/shipments?id=${id}`)
    return data
}

export const updateShipment = async (values) => {
    const {data} = await axiosInstance.put("/v/admin", values)
    return data
}

export const confirmShipmentRequest = async (values) => {
    const {data} = await axiosInstance.post(
        "/v/admin/confirm-shipment-request",
        values
    )
    return data
}

export const groupBillShipment = async (values) => {
    const {data} = await axiosInstance.post(
        "/v/admin/shipment-billings",
        values
    )
    return data
}

export const updateBillShipment = async (values) => {
    const {data} = await axiosInstance.put(
        "/v/admin/shipment-billings",
        values
    )
    return data
}

export const addShipmentBuyingPrice = async (values) => {
    if (values?.id) {
        const {data} = await axiosInstance.put(
            `/v/admin/shipment-price?id=${values?.id}&buyingPrice=${values?.buyingPrice}`
        )
        return data
    }
}

export const getBillShipment = async ({id, filter}) => {
    if (id) {
        const {data} = await axiosInstance.get(
            `/v/admin/shipment-billings?id=${id}`
        )
        return data
    }
    const {data} = await axiosInstance.get("/v/admin/shipment-billings", {
        params: filter,
    })
    return data
}

export const sendBillShipment = async (values) => {
    let link = `/v/admin/shipment-billing/send-email?billId=${values?.billId}`
    if (values?.emailTo) {
        link = link.concat(`&emailTo=${values?.emailTo}`)
    }
    const {data} = await axiosInstance.post(link)
    return data
}

export const downloadReport = async (values) => {
    const response = await axiosInstance.post(
        "/v/admin/download-report",
        values,
        {
            responseType: "blob",
        }
    )
    const file = new Blob([response])
    const fileURL = URL.createObjectURL(file)
    const link = document.createElement("a")
    link.href = fileURL

    link.setAttribute("download", `report.pdf`)

    // Append to html link element page
    document.body.appendChild(link)

    // Start download
    link.click()

    // Clean up and remove the link
    link.parentNode.removeChild(link)

    return null
}

export const getMasterAWB = async () => {
    const {data} = await axiosInstance.get('/v/admin/master-awbs')
    return data
}
export const addMasterAWB = async (awb) => {
    const config = {
        ...awb,
    }
    const {data} = await axiosInstance.post('/v/admin/master-awb', config)
    return data
}
export const addBulkMasterAWB = async (bulkAWB) => {
    const config = {
        ...bulkAWB,
    }
    const {data} = await axiosInstance.post('/v/admin/bulk-master-awb', config)
    return data
}
export const updateMasterAWB = async (awbData) => {
    const config = {
        ...awbData
    }
    const {data} = await axiosInstance.put('/v/admin/master-awb', config)
    return data
}
export const deleteMasterAWB = async (id) => {
    const config = {
        params: {
            id
        }
    }
    const {data} = await axiosInstance.delete('/v/admin/master-awb', config)
    return data
}
export const sendAwbEmail = async (ids) => {
    const config = {
        params: {
            ids: ids.join(',')
        }
    }
    const {data} = await axiosInstance.put("/v/admin/master-awb/email", null, config)
    return data
}