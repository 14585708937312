import { useQuery } from "react-query";
import { getAdminDashboard } from "../../../api/dashboard/dashboard-api";

export const useGetAdminDashboard = () => {
  return useQuery(["getAdminDashboard"], () => getAdminDashboard(), {
    cacheTime: 10000,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};
