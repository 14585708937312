import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import React, { useState } from "react";
import { useAddCustomerForm } from "../../../hooks/components/form/customer/useAddCustomerForm";
import AddCustomerFormItem from "./items/AddCustomerFormItem";

const AddCustomerForm = ({ open, handleClose }) => {
  const [isBusiness, setIsBusiness] = useState(false);
  const { formik } = useAddCustomerForm({ isBusiness, handleClose });
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>Add Customer</DialogTitle>
      <Divider />
      <DialogContent>
        <AddCustomerFormItem
          formik={formik}
          isBusiness={isBusiness}
          setIsBusiness={setIsBusiness}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCustomerForm;
