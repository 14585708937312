import { Grid } from "@mui/material";
import React from "react";
import AboutHero from "./parts/AboutHero";
import MessageMd from "./parts/MessageMd";
import Overview from "./parts/Overview";

const About = () => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <AboutHero />
      </Grid>
      <Grid item xs={12}>
        <Overview />
      </Grid>
      <Grid item xs={12}>
        <MessageMd />
      </Grid>
    </Grid>
  );
};

export default About;
