import { Autocomplete, Grid, MenuItem, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const pickedOptions = [
  { value: "Pending", label: "Pending" },
  { value: "Complete", label: "Complete" },
  { value: "Cancelled", label: "Cancelled" },
];

const CreatePickupFormItem = ({
  formik,
  adminList,
  adminId,
  setAdminId,
  inputAdmin,
  setInputAdmin,
}) => {
  const handlePickAdmin = (value) => {
    formik.setFieldValue("adminId", value);
  };
  return (
    <Box component="form" form={formik} noValidate sx={{ mt: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Autocomplete
            value={adminId}
            onChange={(_event, newValue) => {
              handlePickAdmin(newValue?.value);
              setAdminId(newValue);
            }}
            inputValue={inputAdmin}
            onInputChange={(_event, newInputValue) => {
              setInputAdmin(newInputValue);
            }}
            options={adminList}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                margin="dense"
                label="Admin"
                error={formik.touched.adminId && Boolean(formik.errors.adminId)}
                helperText={formik.touched.adminId && formik.errors.adminId}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="cnNumber"
            label="CN Number"
            required
            fullWidth
            value={formik.values.cnNumber}
            onChange={formik.handleChange}
            error={formik.touched.cnNumber && Boolean(formik.errors.cnNumber)}
            helperText={formik.touched.cnNumber && formik.errors.cnNumber}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="dateTime"
            label="Date Time"
            type="datetime-local"
            required
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={formik.values.dateTime}
            onChange={formik.handleChange}
            error={formik.touched.dateTime && Boolean(formik.errors.dateTime)}
            helperText={formik.touched.dateTime && formik.errors.dateTime}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="partyName"
            label="Party Name"
            required
            fullWidth
            value={formik.values.partyName}
            onChange={formik.handleChange}
            error={formik.touched.partyName && Boolean(formik.errors.partyName)}
            helperText={formik.touched.partyName && formik.errors.partyName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="phoneNumber"
            label="Phone Number"
            required
            fullWidth
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
            }
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="pickupAddress"
            label="Pick up Address"
            required
            fullWidth
            value={formik.values.pickupAddress}
            onChange={formik.handleChange}
            error={
              formik.touched.pickupAddress &&
              Boolean(formik.errors.pickupAddress)
            }
            helperText={
              formik.touched.pickupAddress && formik.errors.pickupAddress
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="vehicle"
            label="vehicle"
            required
            fullWidth
            value={formik.values.vehicle}
            onChange={formik.handleChange}
            error={formik.touched.vehicle && Boolean(formik.errors.vehicle)}
            helperText={formik.touched.vehicle && formik.errors.vehicle}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="remarks"
            label="Remarks"
            required
            multiline
            fullWidth
            value={formik.values.remarks}
            onChange={formik.handleChange}
            error={formik.touched.remarks && Boolean(formik.errors.remarks)}
            helperText={formik.touched.remarks && formik.errors.remarks}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="status"
            label="status"
            required
            select
            fullWidth
            value={formik.values.status}
            onChange={formik.handleChange}
            error={formik.touched.status && Boolean(formik.errors.status)}
            helperText={formik.touched.status && formik.errors.status}
          >
            {pickedOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </Box>
  );
};

export default CreatePickupFormItem;
