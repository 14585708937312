import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { forgetPassword } from "../../../api/forgetpassword/forgetpassword-api";

export const useForgetPassword = ({ onSuccess }) => {
  return useMutation(
    ["forget-password"],
    (username) => forgetPassword(username),
    {
      onSuccess: (data, variables, context) => {
        toast.success("Successfully Request for One Time Password");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(err.message);
      },
    }
  );
};
