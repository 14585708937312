import React from "react";
import CommuteIcon from "@mui/icons-material/Commute";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import { Avatar, Card, CardContent, Grid, Typography } from "@mui/material";
import { useGetAdminDashboard } from "../../../hooks/api/dashboard/useDashboard";

const AdminDashboardChips = () => {
  const { data } = useGetAdminDashboard();
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Card elevation={4} sx={{ minHeight: "15vh" }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                  <Avatar
                    sx={{
                      width: 76,
                      height: 76,
                      bgcolor: "primary.main",
                      ml: { xs: 0, sm: "2em" },
                      mt: 1,
                    }}
                  >
                    <CommuteIcon fontSize="large" />
                  </Avatar>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  container
                  sx={{ textAlign: { xs: "center", sm: "left" } }}
                  justifyContent={{ xs: "center", sm: "flex-start" }}
                >
                  <Grid item xs container direction="row" spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        gutterBottom
                        sx={{
                          fontSize: "18px",
                          fontWeight: 600,
                          color: "primary.main",
                        }}
                      >
                        In Transit
                      </Typography>
                      <Typography
                        sx={{ ml: "1vw", fontSize: "36px", fontWeight: 500 }}
                      >
                        {data?.inTransit}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card elevation={4} sx={{ minHeight: "15vh" }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                  <Avatar
                    sx={{
                      width: 76,
                      height: 76,
                      bgcolor: "primary.main",
                      ml: { xs: 0, sm: "2em" },
                      mt: 1,
                    }}
                  >
                    <HourglassEmptyIcon fontSize="large" />
                  </Avatar>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  container
                  sx={{ textAlign: { xs: "center", sm: "left" } }}
                  justifyContent={{ xs: "center", sm: "flex-start" }}
                >
                  <Grid item xs container direction="row" spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        gutterBottom
                        sx={{
                          fontSize: "18px",
                          fontWeight: 600,
                          color: "primary.main",
                        }}
                      >
                        Pending
                      </Typography>
                      <Typography
                        sx={{ ml: "1vw", fontSize: "36px", fontWeight: 500 }}
                      >
                        {data?.pending}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card elevation={4} sx={{ minHeight: "15vh" }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                  <Avatar
                    sx={{
                      width: 76,
                      height: 76,
                      bgcolor: "primary.main",
                      ml: { xs: 0, sm: "2em" },
                      mt: 1,
                    }}
                  >
                    <CheckCircleOutlineIcon fontSize="large" />
                  </Avatar>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  container
                  sx={{ textAlign: { xs: "center", sm: "left" } }}
                  justifyContent={{ xs: "center", sm: "flex-start" }}
                >
                  <Grid item xs container direction="row" spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        gutterBottom
                        sx={{
                          fontSize: "18px",
                          fontWeight: 600,
                          color: "primary.main",
                        }}
                      >
                        Delivered
                      </Typography>
                      <Typography
                        sx={{ ml: "1vw", fontSize: "36px", fontWeight: 500 }}
                      >
                        {data?.delivered}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card elevation={4} sx={{ minHeight: "15vh" }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                  <Avatar
                    sx={{
                      width: 76,
                      height: 76,
                      bgcolor: "primary.main",
                      ml: { xs: 0, sm: "2em" },
                      mt: 1,
                    }}
                  >
                    <ErrorOutlineIcon fontSize="large" />
                  </Avatar>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  container
                  sx={{ textAlign: { xs: "center", sm: "left" } }}
                  justifyContent={{ xs: "center", sm: "flex-start" }}
                >
                  <Grid item xs container direction="row" spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        gutterBottom
                        sx={{
                          fontSize: "18px",
                          fontWeight: 600,
                          color: "primary.main",
                        }}
                      >
                        Exception
                      </Typography>
                      <Typography
                        sx={{ ml: "1vw", fontSize: "36px", fontWeight: 500 }}
                      >
                        {data?.exception}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card elevation={4} sx={{ minHeight: "15vh" }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                  <Avatar
                    sx={{
                      width: 76,
                      height: 76,
                      bgcolor: "primary.main",
                      ml: { xs: 0, sm: "2em" },
                      mt: 1,
                    }}
                  >
                    <ArrowBackIcon fontSize="large" />
                  </Avatar>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  container
                  sx={{ textAlign: { xs: "center", sm: "left" } }}
                  justifyContent={{ xs: "center", sm: "flex-start" }}
                >
                  <Grid item xs container direction="row" spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        gutterBottom
                        sx={{
                          fontSize: "18px",
                          fontWeight: 600,
                          color: "primary.main",
                        }}
                      >
                        Return
                      </Typography>
                      <Typography
                        sx={{ ml: "1vw", fontSize: "36px", fontWeight: 500 }}
                      >
                        {data?.returns}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card elevation={4} sx={{ minHeight: "15vh" }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} container justifyContent="center">
                  <Avatar
                    sx={{
                      width: 76,
                      height: 76,
                      bgcolor: "primary.main",
                      ml: { xs: 0, sm: "2em" },
                      mt: 1,
                    }}
                  >
                    <AllInclusiveIcon fontSize="large" />
                  </Avatar>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  container
                  sx={{ textAlign: { xs: "center", sm: "left" } }}
                  justifyContent={{ xs: "center", sm: "flex-start" }}
                >
                  <Grid item xs container direction="row" spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        gutterBottom
                        sx={{
                          fontSize: "18px",
                          fontWeight: 600,
                          color: "primary.main",
                        }}
                      >
                        Total
                      </Typography>
                      <Typography
                        sx={{ ml: "1vw", fontSize: "36px", fontWeight: 500 }}
                      >
                        {data?.total}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default AdminDashboardChips;
