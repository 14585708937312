import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";

const CancelPickupForm = ({ handleClose, open, handleSubmit }) => {
  const [reason, setReason] = useState("");
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>Cancel Pickup</DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          multiline
          required
          minRows={3}
          value={reason}
          label="Reason"
          onChange={(e) => setReason(e.target.value)}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button
          onClick={() =>
            reason !== ""
              ? handleSubmit({ reason, setReason })
              : toast.warn(
                  "Please fill up the reason why you are cancelling this pickup?"
                )
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelPickupForm;
