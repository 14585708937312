import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { signUp } from "../../../api/register/register-api";

export const useRegister = ({ onSuccess }) => {
  const navigate = useNavigate();
  return useMutation(["register"], (formData) => signUp(formData), {
    onSuccess: (data, variables, context) => {
      toast.success(
        "Successfully Registered, temporary password has been sent to your registered email"
      );
      navigate("/login");
      onSuccess && onSuccess(data, variables, context);
    },
    onError: (err, _variables, _context) => {
      toast.error(err.message);
    },
  });
};
