import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../component/footer/Footer";
import Navbar from "../component/navbar/Navbar";

const AppLayout = () => {
  return (
    <>
      <Box sx={{ minHeight: "100vh", backgroundColor: "background.default" }}>
        <Navbar />

        <Outlet />

        <Footer />
      </Box>
    </>
  );
};

export default AppLayout;
