import {Fab, Grid, MenuItem, TextField} from "@mui/material"
import React, { useState } from "react";
import { useCustomerTable } from "../../../hooks/components/table/customer/useCustomerTable";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddCustomerBulkForm from "../../form/customer/AddCustomerBulkForm";
import AddCustomerForm from "../../form/customer/AddCustomerForm";
import AddIcon from "@mui/icons-material/Add";
import MaterialTable from "@material-table/core";
import MailLockIcon from "@mui/icons-material/MailLock";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const customerCategoryOptions = [
  { label: "--", value: "" },
  { label: "C1", value: "C1" },
  { label: "C2", value: "C2" },
  { label: "M1", value: "M1" },
  { label: "Counter", value: "COUNTER" },
];

const customerTypeOptions = [
  { label: "--", value: "" },
  { label: "Business", value: "Business" },
  { label: "Personal", value: "Personal" },
];
const CustomerTable = () => {
  const [open, setOpen] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setOpenFile(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const {
    data,
    isLoading,
    column,
    categoryFilter,
    typeFilter,
    handleCategoryFilter,
    handleTypeFilter,
    copyEmail,
    issuePassword,
    refetch,
  } = useCustomerTable({});

  const handleRefetchCustomers = () => {
    sessionStorage.removeItem("customerData");
    refetch()
  };
  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        spacing={2}
        sx={{ mb: 2 }}
      >
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <TextField
            label="Category"
            select
            variant="standard"
            value={categoryFilter}
            onChange={handleCategoryFilter}
            fullWidth
          >
            {customerCategoryOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <TextField
            label="Type"
            select
            variant="standard"
            value={typeFilter}
            onChange={handleTypeFilter}
            fullWidth
          >
            {customerTypeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <MaterialTable
        columns={column}
        data={data}
        title="Customer"
        isLoading={isLoading}
        options={{
          pageSize: 10,
          selection: true,
          padding: "dense",
        }}
        actions={[
          {
            icon: () => <AddIcon />,
            tooltip: `Add Customer`,
            isFreeAction: true,
            onClick: () => handleOpen(),
          },
          {
            icon: () => <UploadFileIcon />,
            tooltip: `Bulk upload`,
            isFreeAction: true,
            onClick: () => setOpenFile(true),
          },
          {
            tooltip: "Copy Email",
            icon: () => <ContentCopyIcon />,
            onClick: (_evt, data) => {
              copyEmail(data);
            },
          },
          {
            tooltip: "Issue Password",
            icon: () => <MailLockIcon />,
            onClick: (_evt, data) => {
              issuePassword(data);
            },
          },
        ]}
      />
      <AddCustomerForm open={open} handleClose={handleClose} />
      <AddCustomerBulkForm open={openFile} handleClose={handleClose} />
      <Fab
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
            fontSize:"0.5rem",
          }}
          color="primary"
          aria-label="refetch-customers"
          onClick={handleRefetchCustomers}
      >
        Refetch Customers
      </Fab>
    </div>
  );
};

export default CustomerTable;
