import { Grid } from "@mui/material";
import React from "react";
// import { useCreatePayment } from "../../hooks/api/payment/usePayment";

const Dashboard = () => {
  // const { mutate } = useCreatePayment({});
  // const handlePayment = () => {
  //   mutate({
  //     amount: 1000,
  //     description: "test",
  //     email: "test@gmail.com",
  //   });
  // };
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          {/* <Button
            variant="outlined"
            fullWidth
            sx={{ textTransform: "none" }}
            onClick={() => handlePayment()}
          >
            Online Pay
          </Button> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
