import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AddShipmentFormLite from "../../components/form/nepal_lite/add_shipment/AddShipmentFormLite";
import { useGetFinalShipmentDetail } from "../../hooks/api/nepal_lite/useNepalLite";
import { useUpdateConfirmShipmentLiteForm } from "../../hooks/components/form/nepal_lite/update_confirm_shipping/useUpdateConfirmShippingLiteForm";

const UpdateConfirmShipmentLite = () => {
  const { id } = useParams({});
  const navigate = useNavigate();
  const { data, isLoading: dataLoading } = useGetFinalShipmentDetail(id);
  const { formik, isLoading, serviceProviderList, customerList } =
    useUpdateConfirmShipmentLiteForm({ data, isLoading: dataLoading });
  return isLoading ? (
    <div style={{ display: "flex", justifyContent: "center" }}>
      Is Loading ...
    </div>
  ) : (
    <div>
      <Grid container spacing={3} sx={{ mt: 1, mb: 4, pl: 4 }}>
        <Grid item xs={12}>
          <Typography>Update Shipment Detail</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box component="form" form={formik} noValidate>
            <AddShipmentFormLite
              formik={formik}
              serviceProviderList={serviceProviderList}
              customerList={customerList}
            />
            <Grid container spacing={3} justifyContent="flex-end">
              <Grid item xs={3}>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  onClick={() => {
                    if (formik.values.shipperId || formik.values.shipper) {
                      formik.handleSubmit();
                    } else {
                      toast.warn("Shipper detail is empty");
                    }
                  }}
                >
                  Save
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  onClick={() => {
                    if (formik.values.shipperId || formik.values.shipper) {
                      formik.setFieldValue("sendEmail", true);
                      formik.handleSubmit();
                    } else {
                      toast.warn("Shipper detail is empty");
                    }
                  }}
                >
                  Save & send
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default UpdateConfirmShipmentLite;
