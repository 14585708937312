import { useGetServiceProviderRates } from "../../../../api/service_provider/rates/useServiceProviderRate";

export const useServiceProviderRateTable = ({ id, zone }) => {
  const { data, isLoading } = useGetServiceProviderRates({ id, zone });

  const column = [
    {
      title: "id",
      field: "id",
      render: (rowData) => data?.findIndex((x) => x?.id === rowData?.id) + 1,
    },
    {
      title: "Country",
      field: "country",
    },
    {
      title: "Currency",
      field: "currency",
    },
    {
      title: "Package Type",
      field: "packageType",
    },
    {
      title: "Customer Category",
      field: "customerCategory",
    },
    {
      title: "Weight",
      field: "weight",
    },
    {
      title: "Rate",
      field: "rate",
    },
    {
      title: "Emergency Surcharge",
      field: "emergencySurcharge",
    },
    {
      title: "Final Rate",
      field: "finalRate",
    },
  ];

  return { column, data, isLoading };
};
