import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useGetShipmentRequestLiteDetail } from "../../../../hooks/api/nepal_lite/useNepalLite";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link } from "react-router-dom";
import ConfirmShipmentRequestLiteForm from "../../../../components/form/nepal_lite/ConfirmShipment/ConfirmShipmentRequestLiteForm";
import { UserContext } from "../../../../../app/contexts/user/UserContext";
import SystemDetails from "../../../../../app/component/detail_view/system_details/SystemDetails";

function ShipmentDetail({ id }) {
  const { role } = useContext(UserContext);
  const { data, isLoading } = useGetShipmentRequestLiteDetail(id);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return isLoading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <Paper sx={{ padding: "16px", mb: "20px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: "26px", fontWeight: 500 }}>
                Shipment Detail # {data?.id}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                variant="contained"
                sx={{ textTransform: "none" }}
                onClick={() => setOpen(true)}
              >
                Confirm
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Link
                to={`/${
                  role === "SysAdmin" ? "super" : "admin"
                }/edit-shipment-lite/${data?.id}`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                >
                  Edit
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <Typography>
                <b>Date</b> : {data?.requestDate}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography>
                <b>Exchange Rate</b> : {data?.exchangeRate}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography>
                <b>Payment Type</b> : {data?.paymentType}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <Typography>
                <b>AWB</b> : {data?.awb}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography>
                <b>Shipment Type</b> : {data?.shipmentType}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography>
                <b>Package Type</b> : {data?.packageType}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <Typography>
                <b>Shipper</b> : {data?.shipper}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography>
                <b>Consignee</b> : {data?.consignee}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography>
                <b>Import/Export</b> : {data?.importExport}
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Typography>
                <b>Shipper Reference</b> : {data?.shipperReference}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography>
                <b>Email</b> : {data?.email}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <Typography>
                <b>Destination</b>: {data?.destination}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography>
                <b>Postal</b>: {data?.postalCode}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography>
                <b>Remote Area</b>:{" "}
                {data?.remoteArea ? (
                  <CheckBoxIcon color="success" />
                ) : (
                  <CancelIcon color="error" />
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <Typography>
                <b>Service</b>: {data?.service}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography>
                <b>Service Provider</b>: {data?.serviceProvider}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <Typography>
                <b>Box</b>: {data?.numberOfBoxes}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography>
                <b>No of Oversize Box</b>: {data?.numberOfOversizeBoxes}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography>
                <b>Dimension</b>: {data?.dimension}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <Typography>
                <b>Actual Weight</b>: {data?.actualWeight}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography>
                <b>Dimensional Weight</b>: {data?.dimensionalWeight}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography>
                <b>Applied Weight</b>: {data?.appliedWeight}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Typography>
                <b>No.of Iron Strip</b>: {data?.noOfIronStrip}
              </Typography>
            </Grid>
            <Grid item sm={6}>
              <Typography>
                <b>No. of Plastic Strip</b>: {data?.noOfPlasticStrip}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <b>Description of Goods</b>: {data?.descriptionOfGoods}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <Typography>
                <b>Invoice Value</b>: {data?.invoiceValue}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography>
                <b>Currency</b>: {data?.currency}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography>
                <b>Invoice Value for custom</b>: {data?.invoiceValueForCustom}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <b>Custom Clearance</b>: {data?.customClearance}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <b>Status</b>: {data?.status}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <b>Remarks</b>: {data?.remarks}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SystemDetails
            createdBy={data?.createdBy}
            editedBy={data?.editedBy}
            createdDatetime={data?.createdDatetime}
            editedDatetime={data?.editedDatetime}
          />
        </Grid>
      </Grid>
      <ConfirmShipmentRequestLiteForm
        open={open}
        handleClose={handleClose}
        id={id}
      />
    </Paper>
  );
}

export default ShipmentDetail;
