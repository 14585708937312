import { Button, Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../../../app/contexts/user/UserContext";

const GeneralDetails = ({
  handleOpen,
  shipper,
  consignee,
  email,
  date,
  remarks,
  billed,
  billedId,
  paymentType,
  shipperReference,
  nepalexTrackingNumber,
}) => {
  const navigate = useNavigate();
  const { role } = useContext(UserContext);
  return (
    <Paper
      elevation={4}
      sx={{
        ml: { sm: 1, md: 2 },
        mr: { sm: 1, md: 2 },
        mt: 2,
        mb: 1,
        p: "16px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={9}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "24px",
            }}
          >
            Shipper Details
          </Typography>
          <Typography>Request Date: {date}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {billed ? (
            <Button
              variant="outlined"
              sx={{ textTransform: "none" }}
              onClick={() =>
                navigate(
                  `/${role === "SysAdmin" ? "super" : "admin"}/bill/${billedId}`
                )
              }
            >
              Billing Details
            </Button>
          ) : role === "SysAdmin" ? (
            <Button
              fullWidth
              variant="contained"
              sx={{ textTransform: "none" }}
              onClick={() => handleOpen()}
            >
              Bill Shipment
            </Button>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">Shipper :</Typography>
          <Typography>{shipper}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">Consignee :</Typography>
          <Typography>{consignee}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">Email :</Typography>
          <Typography>{email}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">Payment Type :</Typography>
          <Typography>{paymentType || "-"}</Typography>
        </Grid>
        {remarks ? (
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">Remarks</Typography>
            <Typography>{remarks}</Typography>
          </Grid>
        ) : (
          <></>
        )}
        {shipperReference ? (
          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle2">Shipper Reference</Typography>
            <Typography>{shipperReference}</Typography>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Paper>
  );
};

export default GeneralDetails;
