import { useFormik } from "formik";
import { useUpdateCustomerInfoConfig } from "../../../api/customer-info/useCustomerInfo";
import { customerInfoConfigValidationSchema } from "./customerInfoConfigValidationSchema";

export const useEditCustomerInfoConfigForm = ({ handleClose, data }) => {
  const { mutate } = useUpdateCustomerInfoConfig({});
  const formik = useFormik({
    initialValues: {
      infoKey: data?.infoKey,
      value: data?.value,
    },
    validationSchema: customerInfoConfigValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleUpdate(values);
    },
  });

  const handleUpdate = (values) => {
    mutate(values, {
      onSuccess: () => {
        handleClose();
        formik.handleReset();
      },
    });
  };

  return { formik };
};
