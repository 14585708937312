import { Container } from "@mui/material";
import React, { useEffect } from "react";
import RegisterForm from "../../components/form/register/RegisterForm";
import SuperRegisterForm from "../../components/form/register/SuperRegisterForm";

const Register = ({ superSignUp }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return superSignUp ? (
    <div>
      <Container component="main" maxWidth="xs">
        <SuperRegisterForm />
      </Container>
    </div>
  ) : (
    <div>
      <Container component="main" maxWidth="xs">
        <RegisterForm />
      </Container>
    </div>
  );
};

export default Register;
