import {Grid} from "@mui/material"
import MaterialTable from "@material-table/core"
import React, {useState} from "react"
import {useGetMasterAWBQuery, useMasterAWBActionMutation} from "../../../../hooks/api/shipment/useShipment"
import useMasterAWBTable from "../../../../hooks/components/table/nepal_lite/shipment/useMasterAWBTable"
import AddIcon from "@mui/icons-material/Add"
import AddAWBForm from "../../../form/shipment/AddAWBForm"
import {CircleOutlined, DeleteOutlined, EditOutlined} from "@mui/icons-material"

const MasterAWBTable = () => {
    const [open, setOpen] = useState(false)
    const [initialDataToEdit, setInitialDataToEdit] = useState(null)
    const [ids, setIds] = useState([])
    const {
        data,
        isLoading,
    } = useGetMasterAWBQuery()
    const {
        columns
    } = useMasterAWBTable({ids})
    const{
        deleteMasterAWB,
        isDeletingMasterAWB,
        ConfirmationDialog
    }=useMasterAWBActionMutation()
    const handleClose = () => {
        setOpen(false)
        setInitialDataToEdit(null)
    }
    const handleOpen = () => {
        setOpen(true)
    }
    return (
        <Grid container spacing={2} sx={{mb: 2}} justifyContent="flex-end">
            <Grid item xs={12}>
                <MaterialTable
                    columns={columns}
                    data={data}
                    title="Master AWB"
                    isLoading={isLoading}
                    onSelectionChange={(e) => setIds(e.map(id => id.id))}
                    options={{
                        pageSize: 10,
                        padding: "dense",
                        selection: true,
                        actionsColumnIndex: -1,
                    }}
                    actions={[
                        {
                            icon: () => <EditOutlined/>,
                            tooltip: "Edit",
                            position: 'row',
                            onClick: (_,row) => {
                                const modifiedRow = {
                                    ...row,
                                    customerId:row.customer.id
                                }
                                delete modifiedRow.customer
                                setInitialDataToEdit(modifiedRow)
                                setOpen(true)
                            },
                        },
                        {
                            icon: () => isDeletingMasterAWB?<CircleOutlined/>:<DeleteOutlined/>,
                            tooltip: "Delete",
                            position: 'row',
                            onClick: async (_,row) => {
                                await deleteMasterAWB(row.id)
                            },
                        },
                        {
                            icon: () => <AddIcon/>,
                            tooltip: `Add AWB`,
                            isFreeAction: true,
                            onClick: handleOpen,
                        },
                    ]}

                />
                <AddAWBForm open={open} initialData={initialDataToEdit} handleClose={handleClose}/>
                {ConfirmationDialog}
            </Grid>
        </Grid>
    )
}
export default MasterAWBTable
