import {useFormik} from "formik"
import {useCallback, useEffect, useState} from "react"
import {
    useGetCustomers,
    useGetServiceProviders,
} from "../../../../api/custom_shipment/useCustomShipment"
import {useCreateShipmentRequestLite} from "../../../../api/nepal_lite/useNepalLite"
import {shipmentLiteValidationSchema} from "./shipmentLiteValidationSchema"
import moment from "moment"

function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export const useShipmentLiteForm = () => {
    const [details, setDetails] = useState(
        JSON.parse(localStorage.getItem("shippingDetail"))
    )
    const [loading, setLoading] = useState(false)

    let isOldValues = JSON.parse(
        localStorage.getItem("addShipmentRequest-nepal")
    )

    const handleOldData = useCallback(() => {
        if (isOldValues) {
            formik.setValues({
                actualWeight: isOldValues?.actualWeight || 0,
                appliedWeight: isOldValues?.appliedWeight || 0,
                awb: isOldValues?.awb || "",
                consignee: isOldValues?.consignee || "",
                currency: isOldValues?.currency || "USD",
                customClearance: isOldValues?.customClearance || "NepalEx",
                descriptionOfGoods: isOldValues?.descriptionOfGoods || "",
                destination: isOldValues?.destination || "",
                length: isOldValues?.length || [],
                bredth: isOldValues?.bredth || [],
                height: isOldValues?.height || [],
                noOfBoxesToCalculate: isOldValues?.noOfBoxesToCalculate || [],
                dimensionalWeight: isOldValues?.dimensionalWeight || 0,
                email: isOldValues?.email || "",
                exchangeRate: isOldValues?.exchangeRate || details?.exchangeRate || "",
                invoiceValue: isOldValues?.invoiceValueForCustom || 0,
                invoiceValueForCustom: isOldValues?.invoiceValueForCustom || 0,
                noOfIronStrip: isOldValues?.noOfIronStrip || 0,
                noOfPlasticStrip: isOldValues?.noOfPlasticStrip || 0,
                numberOfBoxes: isOldValues?.numberOfBoxes || 0,
                numberOfOversizeBoxes: isOldValues?.numberOfOversizeBoxes || 0,
                postalCode: isOldValues?.postalCode || "",
                remarks: isOldValues?.remarks || "",
                remoteArea: isOldValues?.remoteArea || false,
                requestDate:
                    isOldValues?.requestDate || moment().format("yyyy-MM-DD") || "",
                packageType: isOldValues?.packageType || "",
                paymentType: isOldValues?.paymentType || "Credit",
                service: isOldValues?.service || null,
                serviceProviderId: isOldValues?.serviceProviderId || null,
                shipmentType: isOldValues?.shipmentType || "NonCommercial",
                status: isOldValues?.status || "InTransit",
                shipperId: isOldValues?.shipperId || null,
                shipper: isOldValues?.shipper || "",
                importExport: isOldValues?.importExport || "Export",
                shipperReference: isOldValues?.shipperReference || "",
            })
        }
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        let isCancelled = false

        const handleChange = async () => {
            await timeout(200)
            if (!isCancelled) {
                handleOldData()
            }
        }
        handleChange()
        return () => {
            isCancelled = true
        }
    }, [handleOldData])

    const {data: serviceProviderData, isLoading: serviceProviderLoading} =
        useGetServiceProviders()
    const {data: customerData, isLoading: customerLoading} = useGetCustomers()

    let serviceProviderList =
        serviceProviderData &&
        serviceProviderData?.map((serviceProvider) => {
            return {value: serviceProvider?.id, label: serviceProvider?.name}
        })

    const customerList =
        customerData &&
        customerData?.map((customer) => {
            return {
                id: customer?.id,
                email: customer?.email,
                label:
                    customer?.companyName ||
                    customer?.firstName + " " + customer?.lastName,
            }
        })

    const {mutate} = useCreateShipmentRequestLite({})

    const formik = useFormik({
        initialValues: {
            actualWeight: 0,
            appliedWeight: 0,
            awb: "",
            consignee: "",
            currency: "USD",
            customClearance: "NepalEx",
            descriptionOfGoods: "",
            destination: "",
            length: [],
            breadth: [],
            height: [],
            noOfBoxesToCalculate: [],
            dimensionalWeight: 0,
            email: "",
            exchangeRate: details?.exchangeRate || "",
            invoiceValue: 0,
            invoiceValueForCustom: 0,
            noOfIronStrip: 0,
            noOfPlasticStrip: 0,
            numberOfBoxes: 0,
            numberOfOversizeBoxes: 0,
            postalCode: "",
            remarks: "",
            remoteArea: false,
            requestDate: moment().format("yyyy-MM-DD"),
            packageType: "",
            paymentType: "Credit",
            service: null,
            serviceProviderId: null,
            shipmentType: "NonCommercial",
            status: "InTransit",
            shipperId: null,
            shipper: "",
            importExport: "",
            shipperReference: "",
        },
        validationSchema: shipmentLiteValidationSchema,
        onSubmit: (values) => {
            setLoading(true)
            handleConfrim(values)
        },
    })

    const handleConfrim = (values) => {
        let dimension = ""
        for (let i = 0; i < values?.length?.length; i++) {
            dimension += `${values?.length[i] || 0}*${values?.bredth[i] || 0}*${
                values?.height[i] || 0
            }*${values?.noOfBoxesToCalculate[i] || 1}_`
        }
        dimension = dimension.slice(0, -1)

        values = {
            actualWeight: values?.actualWeight,
            appliedWeight: values?.appliedWeight,
            awb: values?.awb,
            consignee: values?.consignee,
            currency: values?.currency,
            customClearance: values?.customClearance,
            descriptionOfGoods: values?.descriptionOfGoods,
            destination: values?.destination,
            dimension: dimension,
            dimensionalWeight: values?.dimensionalWeight,
            email: values?.email,
            exchangeRate: values?.exchangeRate,
            invoiceValue: values?.invoiceValue,
            invoiceValueForCustom: values?.invoiceValueForCustom,
            noOfIronStrip: values?.noOfIronStrip,
            noOfPlasticStrip: values?.noOfPlasticStrip,
            numberOfBoxes: values?.numberOfBoxes,
            numberOfOversizeBoxes: values?.numberOfOversizeBoxes,
            postalCode: values?.postalCode,
            remarks: values?.remarks,
            remoteArea: values?.remoteArea,
            requestDate: values?.requestDate,
            packageType: values?.packageType,
            paymentType: values?.paymentType,
            service: values?.service,
            serviceProviderId: values?.serviceProviderId,
            shipmentType: values?.shipmentType,
            status: values?.status,
            shipperId: values?.shipperId,
            shipper: values?.shipper,
            importExport: values?.importExport,
            shipperReference: values?.shipperReference,
        }
        let shippingDetail = {
            requestDate: values?.requestDate,
            exchangeRate: values?.exchangeRate,
        }
        localStorage.setItem("shippingDetail", JSON.stringify(shippingDetail))
        setDetails(shippingDetail)
        mutate(values, {
            onSuccess: (data) => {
                formik.resetForm()
                formik.setValues({
                    actualWeight: 0,
                    appliedWeight: 0,
                    awb: "",
                    consignee: "",
                    currency: "USD",
                    customClearance: "NepalEx",
                    descriptionOfGoods: "",
                    destination: "",
                    length: [],
                    bredth: [],
                    height: [],
                    noOfBoxesToCalculate: [],
                    dimensionalWeight: 0,
                    email: "",
                    exchangeRate: data?.exchangeRate || "",
                    invoiceValue: 0,
                    invoiceValueForCustom: 0,
                    noOfIronStrip: 0,
                    noOfPlasticStrip: 0,
                    numberOfBoxes: 0,
                    numberOfOversizeBoxes: 0,
                    postalCode: "",
                    remarks: "",
                    remoteArea: false,
                    requestDate: data?.requestDate || "",
                    packageType: "",
                    paymentType: "Credit",
                    service: null,
                    serviceProviderId: null,
                    shipmentType: "NonCommercial",
                    status: "InTransit",
                    shipperId: null,
                    shipper: "",
                    importExport: "",
                    shipperReference: "",
                })
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                })
            },
            onSettled: () => setLoading(false),
        })
    }

    return {
        formik,
        serviceProviderList,
        customerList,
        isLoading: serviceProviderLoading || customerLoading,
        loading,
    }
}
