import { Grid, MenuItem, TextField } from "@mui/material";
import React from "react";

const options = [
  { label: "Bank Transfer", value: "BankTransfer" },
  { label: "Cash", value: "Cash" },
  { label: "Cheque", value: "Cheque" },
  { label: "ESewa", value: "ESewa" },
  { label: "Other", value: "Other" },
];
const PaymentDetailsForm = ({ formik }) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="parcelValue"
            name="parcelValue"
            label="Parcel Value"
            fullWidth
            value={formik.values.parcelValue}
            onChange={formik.handleChange}
            error={
              formik.touched.parcelValue && Boolean(formik.errors.parcelValue)
            }
            helperText={formik.touched.parcelValue && formik.errors.parcelValue}
            autoComplete="parcel-value"
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            select
            id="paymentMethod"
            name="paymentMethod"
            label="Payment Method"
            value={formik.values.paymentMethod}
            onChange={formik.handleChange}
            error={
              formik.touched.paymentMethod &&
              Boolean(formik.errors.paymentMethod)
            }
            helperText={
              formik.touched.paymentMethod && formik.errors.paymentMethod
            }
            fullWidth
            autoComplete="Payment-method"
            variant="standard">
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="currency"
            name="currency"
            label="Currency"
            fullWidth
            value={formik.values.currency}
            onChange={formik.handleChange}
            error={formik.touched.currency && Boolean(formik.errors.currency)}
            helperText={formik.touched.currency && formik.errors.currency}
            autoComplete="currency"
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="exchangeRate"
            name="exchangeRate"
            label="Exchange Rate"
            fullWidth
            value={formik.values.exchangeRate}
            onChange={formik.handleChange}
            error={
              formik.touched.exchangeRate && Boolean(formik.errors.exchangeRate)
            }
            helperText={
              formik.touched.exchangeRate && formik.errors.exchangeRate
            }
            autoComplete="Exchange-Rate"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="otherNotes"
            name="otherNotes"
            label="Other Notes"
            multiline
            fullWidth
            value={formik.values.otherNotes}
            onChange={formik.handleChange}
            error={
              formik.touched.otherNotes && Boolean(formik.errors.otherNotes)
            }
            helperText={formik.touched.otherNotes && formik.errors.otherNotes}
            autoComplete="other-notes"
            variant="standard"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentDetailsForm;
