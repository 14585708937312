import { useFormik } from "formik";
import moment from "moment";
import { useMemo, useState } from "react";
import { useAddCustomerPayment } from "../../../api/payment/usePayment";
import { paymentValidationSchema } from "./paymentValidationSchema";
import { useGetCustomers } from "../../../api/custom_shipment/useCustomShipment";

export const useAddPaymentForm = ({ handleClose }) => {
  const [customer, setCustomer] = useState({ id: null, input: "" });
  const { mutate } = useAddCustomerPayment({});
  const { data: customerData, isLoading: customerLoading } = useGetCustomers();
  const customerList =
    customerData &&
    customerData?.map((customer) => {
      return {
        id: customer?.id,
        email: customer?.email,
        label:
          customer?.companyName ||
          customer?.firstName + " " + customer?.lastName,
      };
    });

  const customerOptionList = useMemo(() => customerList, [customerList]);

  const customerLabelOptions = useMemo(
    () => (option, i) => `${option.label} : ${option.email}`,
    []
  );
  const formik = useFormik({
    initialValues: {
      customerId: 0,
      amount: null,
      date: moment().format("YYYY-MM-DD"),
      receiptNumber: "",
      description: "",
    },
    validationSchema: paymentValidationSchema,
    onSubmit: (values) => {
      values = {
        ...values,
        date: moment(values?.dateTime).format("YYYY-MM-DD"),
      };
      handleCreate(values);
    },
  });

  const handleCustomer = (values) => {
    setCustomer(values);
  };

  const handleCreate = (values) => {
    values = {
      ...values,
      customerId: values?.customerId === 0 ? null : values?.customerId,
    };
    mutate(values, {
      onSuccess: () => {
        handleClose();
        formik.handleReset();
        setCustomer({ id: null, input: "" });
      },
    });
  };
  return {
    formik,
    customer,
    handleCustomer,
    customerOptionList,
    customerLabelOptions,
    customerLoading,
  };
};
