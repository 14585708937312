import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  LinearProgress,
  Tooltip,
  Box,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import React, { useState } from "react";
import { toast } from "react-toastify";
import { fileResize } from "../../../../../app/utils/image";

import useAddAvatarForm from "../../../../hooks/components/form/settings/avatar/useAddAvatarForm";

const AddAvatar = ({ open, handleClose }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImage = async (e) => {
    const fileSize = e.target.files[0].size / 1024 / 1024;
    setSelectedImage(
      fileSize <= 1 ? e.target.files[0] : await fileResize(e.target.files[0])
    );
  };

  const { handleAddAvatar, progress } = useAddAvatarForm({
    handleClose,
  });

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>Profile Image</DialogTitle>
      <Divider />
      <DialogContent>
        <Box component="form" noValidate>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Tooltip title="Add Profile Picture" placement="right">
                <Button
                  aria-label="upload picture"
                  variant="outlined"
                  component="label"
                  sx={{
                    width: "200px",
                    height: "200px",
                    textTransform: "none",
                    display: selectedImage ? "none" : "flex",
                  }}
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    name="image"
                    onChange={handleImage}
                  />
                  <Typography> + Upload Photo</Typography>
                </Button>
              </Tooltip>
            </Grid>

            {selectedImage && (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <img
                      alt="not found"
                      width={"100%"}
                      src={URL.createObjectURL(selectedImage)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      fullWidth
                      sx={{ textTransform: "none" }}
                      onClick={() => setSelectedImage(null)}
                    >
                      Remove
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      </DialogContent>
      <Divider />

      {progress ? (
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          {progress < 100 ? (
            <Box sx={{ width: "100%" }}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
          ) : (
            <CheckCircleIcon color="success" />
          )}
        </DialogActions>
      ) : (
        <DialogActions>
          <Button sx={{ textTransform: "none" }} onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ textTransform: "none" }}
            onClick={() => {
              selectedImage
                ? handleAddAvatar(selectedImage)
                : toast.warn("Image is not selected");
            }}
          >
            Save
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default AddAvatar;
