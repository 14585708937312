import {
  createContext,
  useEffect,
  useContext,
  useReducer,
  useMemo,
} from "react";
import { useGetShipmentRates } from "../../../admin/hooks/api/settings/useSettings";
import { rateReducer } from "./RateReducer";

const RateContext = createContext();

const RateContextProvider = ({ children }) => {
  const { data } = useGetShipmentRates();

  const initialState = useMemo(
    () => ({
      plasticStrip: data?.plasticStrip || 0,
      ironStrip: data?.ironStrip || 0,
      remote: data?.remote || 0,
      tiaCourier: data?.tiaCourier || 0,
      tiaCargo: data?.tiaCargo || 0,
      customClearance: data?.customClearance || 0,
      minCustomCharge: data?.minCustomCharge || 0,
      minTiaForCourierCommercial: data?.minTiaForCourierCommercial || 0,
      minTiaForCargo: data?.minTiaForCargo || 0,
      oversize: data?.oversize || 0,
    }),
    [data]
  );

  const [state, dispatch] = useReducer(rateReducer, initialState);

  useEffect(() => {
    if (data) {
      dispatch({
        type: "SET_RATE",
        payload: data,
      });
    }
  }, [data]);

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return <RateContext.Provider value={value}>{children}</RateContext.Provider>;
};

export default RateContextProvider;

export const RateState = () => {
  return useContext(RateContext);
};
