import { useFormik } from "formik";
import { useUpdateCustomer } from "../../../api/customer/useCustomer";
import { businessSchema, personalSchema } from "./addCustomerValidationSchema";

export const useEditCustomerForm = ({ data, isBusiness, handleClose }) => {
  const { mutate: mutateUpdate } = useUpdateCustomer({});
  const formik = useFormik({
    initialValues: {
      id: data?.id,
      firstName: data?.firstName,
      lastName: data?.lastName,
      mobile: data?.mobile,
      companyName: data?.companyName || "",
      email: data?.email,
      panNo: data?.panNo,
      customerCategory: data?.customerCategory,
      customerType: data?.customerType,
      emailOne: data?.emailOne,
      emailTwo: data?.emailTwo,
    },
    enableReinitialize: "true",
    validationSchema: isBusiness ? businessSchema : personalSchema,
    onSubmit: (values) => {
      handleUpdate(values);
    },
  });

  const handleUpdate = (values) => {
    values = {
      ...values,
      companyName: isBusiness ? values.companyName : null,
      customerType: isBusiness ? "Business" : "Personal",
    };
    mutateUpdate(values, { onSuccess: () => handleClose() });
  };

  return { formik };
};
