import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetCustomerDetail } from "../../../hooks/api/customer/useCustomer";
import SuspendCustomerForm from "../../../components/form/customer/SuspendCustomerForm";
import EditCustomerForm from "../../../components/form/customer/EditCustomerForm";
import RestoreCustomerForm from "../../../components/form/customer/RestoreCustomerForm";
import CustomerPaymentDetailPage from "./payment/CustomerPaymentDetailPage";

const CustomerDetailPage = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetCustomerDetail(id);
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const [suspendOpen, setSuspendOpen] = useState(false);
  const [restoreOpen, setRestoreOpen] = useState(false);

  const handleSuspend = () => {
    setSuspendOpen(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleClose = () => {
    setSuspendOpen(false);
    setRestoreOpen(false);
  };

  return isLoading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <>
      <Paper elevation={4} sx={{ m: { sm: 1, md: 2 }, p: "16px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={9}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "24px",
              }}
            >{`${data?.firstName} ${data?.lastName}`}</Typography>
            <Typography variant="subtitle2">
              Company : {data?.companyName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {data?.suspended ? (
              <Button
                variant="contained"
                fullWidth
                sx={{ textTransform: "none" }}
                onClick={() => setRestoreOpen(true)}
              >
                Restore Customer
              </Button>
            ) : (
              <Button
                variant="outlined"
                fullWidth
                sx={{ textTransform: "none" }}
                onClick={handleSuspend}
              >
                Suspend Customer
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2">Customer Type</Typography>
            <Typography>{data?.customerType}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2">Category</Typography>
            <Typography>{data?.customerCategory}</Typography>
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={4} sx={{ m: { sm: 1, md: 2 }, p: "16px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={9}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "20px",
              }}
            >
              Details
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {data?.suspended ? (
              <></>
            ) : (
              <Button
                variant="contained"
                fullWidth
                sx={{ textTransform: "none" }}
                onClick={handleOpenEdit}
              >
                Edit
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Typography>Id</Typography>
              </Grid>
              <Grid item xs={1}>
                :
              </Grid>
              <Grid item xs={6}>
                <Typography>{data?.customerId || "-"}</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography>Contact Info</Typography>
              </Grid>
              <Grid item xs={1}>
                :
              </Grid>
              <Grid item xs={6}>
                <Typography>{data?.mobile || "-"}</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography>Pan No.</Typography>
              </Grid>
              <Grid item xs={1}>
                :
              </Grid>
              <Grid item xs={6}>
                <Typography>{data?.panNo || "-"}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Typography>Default Email</Typography>
              </Grid>
              <Grid item xs={1}>
                :
              </Grid>
              <Grid item xs={6}>
                <Typography>{data?.email || "-"}</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography>Optional 1</Typography>
              </Grid>
              <Grid item xs={1}>
                :
              </Grid>
              <Grid item xs={6}>
                <Typography>{data?.emailOne || "-"}</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography>Optional 2</Typography>
              </Grid>
              <Grid item xs={1}>
                :
              </Grid>
              <Grid item xs={6}>
                <Typography>{data?.emailTwo || "-"}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "24px",
              color: "text.primary",
              paddingLeft: 2,
            }}
          >
            Payment History
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={4} sx={{ m: { sm: 1, md: 2 }, p: "16px" }}>
            <CustomerPaymentDetailPage id={id} />
          </Paper>
        </Grid>
      </Grid>

      <SuspendCustomerForm
        open={suspendOpen}
        handleClose={handleClose}
        id={data?.id}
      />
      <RestoreCustomerForm
        open={restoreOpen}
        handleClose={handleClose}
        id={data?.id}
      />
      <EditCustomerForm
        open={openEdit}
        handleClose={handleEditClose}
        data={data}
      />
    </>
  );
};

export default CustomerDetailPage;
