import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { useRateSettingForm } from "../../../../hooks/components/form/settings/rate/useRateSettingForm";

const RateSettings = () => {
  const { formik } = useRateSettingForm({});
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header">
        <Typography sx={{ width: "70%" }}>Update Rates</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                required
                name="plasticStrip"
                id="plasticStrip"
                label="Plastic Strip"
                type="number"
                autoComplete="plasticStrip"
                value={formik.values.plasticStrip}
                onChange={formik.handleChange}
                error={
                  formik.touched.plasticStrip &&
                  Boolean(formik.errors.plasticStrip)
                }
                helperText={
                  formik.touched.plasticStrip && formik.errors.plasticStrip
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="ironStrip"
                id="ironStrip"
                label="Iron Strip"
                type="number"
                autoComplete="ironStrip"
                value={formik.values.ironStrip}
                onChange={formik.handleChange}
                error={
                  formik.touched.ironStrip && Boolean(formik.errors.ironStrip)
                }
                helperText={formik.touched.ironStrip && formik.errors.ironStrip}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="remote"
                id="remote"
                label="Remote"
                type="number"
                autoComplete="remote"
                value={formik.values.remote}
                onChange={formik.handleChange}
                error={formik.touched.remote && Boolean(formik.errors.remote)}
                helperText={formik.touched.remote && formik.errors.remote}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="tiaCourier"
                id="tiaCourier"
                label="Courier"
                type="number"
                autoComplete="tiaCourier"
                value={formik.values.tiaCourier}
                onChange={formik.handleChange}
                error={
                  formik.touched.tiaCourier && Boolean(formik.errors.tiaCourier)
                }
                helperText={
                  formik.touched.tiaCourier && formik.errors.tiaCourier
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="tiaCargo"
                id="tiaCargo"
                label="Cargo"
                type="number"
                autoComplete="tiaCargo"
                value={formik.values.tiaCargo}
                onChange={formik.handleChange}
                error={
                  formik.touched.tiaCargo && Boolean(formik.errors.tiaCargo)
                }
                helperText={formik.touched.tiaCargo && formik.errors.tiaCargo}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="customClearance"
                id="customClearance"
                label="Custom Clearance"
                type="number"
                autoComplete="customClearance"
                value={formik.values.customClearance}
                onChange={formik.handleChange}
                error={
                  formik.touched.customClearance &&
                  Boolean(formik.errors.customClearance)
                }
                helperText={
                  formik.touched.customClearance &&
                  formik.errors.customClearance
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="minCustomCharge"
                id="minCustomCharge"
                label="Min Custom Charge"
                type="number"
                autoComplete="minCustomCharge"
                value={formik.values.minCustomCharge}
                onChange={formik.handleChange}
                error={formik.touched.minCustomCharge && Boolean(formik.errors.minCustomCharge)}
                helperText={formik.touched.minCustomCharge && formik.errors.minCustomCharge}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="minTiaForCourierCommercial"
                id="minTiaForCourierCommercial"
                label="Min Tia For Courier Commercial"
                type="number"
                autoComplete="minTiaForCourierCommercial"
                value={formik.values.minTiaForCourierCommercial}
                onChange={formik.handleChange}
                error={
                  formik.touched.minTiaForCourierCommercial &&
                  Boolean(formik.errors.minTiaForCourierCommercial)
                }
                helperText={
                  formik.touched.minTiaForCourierCommercial &&
                  formik.errors.minTiaForCourierCommercial
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="minTiaForCargo"
                id="minTiaForCargo"
                label="Min Tia For Cargo"
                type="number"
                autoComplete="minTiaForCargo"
                value={formik.values.minTiaForCargo}
                onChange={formik.handleChange}
                error={
                  formik.touched.minTiaForCargo &&
                  Boolean(formik.errors.minTiaForCargo)
                }
                helperText={
                  formik.touched.minTiaForCargo &&
                  formik.errors.minTiaForCargo
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="oversize"
                id="oversize"
                label="Oversize"
                type="number"
                autoComplete="oversize"
                value={formik.values.oversize}
                onChange={formik.handleChange}
                error={
                  formik.touched.oversize &&
                  Boolean(formik.errors.oversize)
                }
                helperText={
                  formik.touched.oversize &&
                  formik.errors.oversize
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{ textTransform: "none" }}
                onClick={() => formik.handleSubmit()}>
                Update Rates
              </Button>
            </Grid>
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default RateSettings;
