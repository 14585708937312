import { useCancelShipment } from "../../../../api/ship/useShip";

export const useShipmentCancelForm = () => {
  const { mutate } = useCancelShipment({});

  const handleCancel = (id) => {
    mutate(id);
  };
  return { handleCancel };
};
