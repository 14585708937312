import { Grid, TextField } from "@mui/material";
import React from "react";

const PackageDetailsForm = ({ formik }) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="numberOfBoxes"
            name="numberOfBoxes"
            label="Number of Boxes"
            type="number"
            inputProps={{ min: 0 }}
            fullWidth
            required
            value={formik.values.numberOfBoxes}
            onChange={formik.handleChange}
            error={
              formik.touched.numberOfBoxes &&
              Boolean(formik.errors.numberOfBoxes)
            }
            helperText={
              formik.touched.numberOfBoxes && formik.errors.numberOfBoxes
            }
            autoComplete="number"
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="numberOfOversizeBoxes"
            name="numberOfOversizeBoxes"
            label="Number of Oversize Boxes"
            type="number"
            inputProps={{ min: 0 }}
            fullWidth
            value={formik.values.numberOfOversizeBoxes}
            onChange={formik.handleChange}
            error={
              formik.touched.numberOfOversizeBoxes &&
              Boolean(formik.errors.numberOfOversizeBoxes)
            }
            helperText={
              formik.touched.numberOfOversizeBoxes &&
              formik.errors.numberOfOversizeBoxes
            }
            autoComplete="number"
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            required
            id="actualWeight"
            name="actualWeight"
            label="Weight"
            type="number"
            inputProps={{ min: 0 }}
            fullWidth
            value={formik.values.actualWeight}
            onChange={formik.handleChange}
            error={
              formik.touched.actualWeight && Boolean(formik.errors.actualWeight)
            }
            helperText={
              formik.touched.actualWeight && formik.errors.actualWeight
            }
            autoComplete="weight"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="dimensionalWeight"
            name="dimensionalWeight"
            label="Dimension"
            type="number"
            inputProps={{ min: 0 }}
            fullWidth
            value={formik.values.dimensionalWeight}
            onChange={formik.handleChange}
            error={
              formik.touched.dimensionalWeight &&
              Boolean(formik.errors.dimensionalWeight)
            }
            helperText={
              formik.touched.dimensionalWeight &&
              formik.errors.dimensionalWeight
            }
            autoComplete="weight"
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            id="totalDimension"
            name="totalDimension"
            label="Total Dimension"
            type="number"
            inputProps={{ min: 0 }}
            fullWidth
            value={formik.values.dimensionalWeight}
            onChange={formik.handleChange}
            error={
              formik.touched.totalDimension &&
              Boolean(formik.errors.totalDimension)
            }
            helperText={
              formik.touched.totalDimension && formik.errors.totalDimension
            }
            autoComplete="total-dimension"
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="ironStrips"
            name="ironStrips"
            label="Iron Strips"
            type="number"
            inputProps={{ min: 0 }}
            fullWidth
            value={formik.values.ironStrips}
            onChange={formik.handleChange}
            error={
              formik.touched.ironStrips && Boolean(formik.errors.ironStrips)
            }
            helperText={formik.touched.ironStrips && formik.errors.ironStrips}
            autoComplete="number"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="plasticStrips"
            name="plasticStrips"
            label="Plastic Strips"
            type="number"
            inputProps={{ min: 0 }}
            fullWidth
            value={formik.values.plasticStrips}
            onChange={formik.handleChange}
            error={
              formik.touched.plasticStrips &&
              Boolean(formik.errors.plasticStrips)
            }
            helperText={
              formik.touched.plasticStrips && formik.errors.plasticStrips
            }
            autoComplete="number"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="contentType"
            name="contentType"
            label="Content Type"
            inputProps={{ min: 0 }}
            fullWidth
            value={formik.values.contentType}
            onChange={formik.handleChange}
            error={
              formik.touched.contentType && Boolean(formik.errors.contentType)
            }
            helperText={formik.touched.contentType && formik.errors.contentType}
            autoComplete="type"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="packageType"
            name="packageType"
            label="Package Type"
            inputProps={{ min: 0 }}
            fullWidth
            value={formik.values.packageType}
            onChange={formik.handleChange}
            error={
              formik.touched.packageType && Boolean(formik.errors.packageType)
            }
            helperText={formik.touched.packageType && formik.errors.packageType}
            autoComplete="type"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="description"
            name="description"
            label="Parcel Description"
            fullWidth
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            autoComplete="description"
            variant="standard"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PackageDetailsForm;
