import MaterialTable from "@material-table/core";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import useOfficeNoticesTable from "../../../hooks/components/table/office_notices/useOfficeNoticesTable";
import SendOfficeNoticeForm from "../../form/office_notices/SendOfficeNoticeForm";

const OfficeNoticesTable = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const { officeNoticeData, column } = useOfficeNoticesTable({});
  return (
    <div className="MTable">
      <MaterialTable
        title="Office Notices"
        data={officeNoticeData}
        columns={column}
        actions={[
          {
            icon: () => <AddIcon />,
            tooltip: "Create Office Notices",
            isFreeAction: true,
            onClick: () => setOpen(true),
          },
        ]}
      />
      <SendOfficeNoticeForm open={open} handleClose={handleClose} />
    </div>
  );
};

export default OfficeNoticesTable;
