import { axiosInstance } from "../../../app/api/axiosInterceptor";

export const getCustomers = async (categoryFilter, typeFilter) => {
  let link = "/v/admin/customers";
  if (categoryFilter && categoryFilter !== "") {
    link = link.concat(`?customerCategory=${categoryFilter}`);
    if (typeFilter && typeFilter !== "")
      link = link.concat(`&customerType=${typeFilter}`);
  } else {
    if (typeFilter && typeFilter !== "")
      link = link.concat(`?customerType=${typeFilter}`);
  }

  const { data } = await axiosInstance.get(link);
  return data;
};

export const getCustomerDetail = async (id) => {
  const { data } = await axiosInstance.get(`/v/admin/customers?id=${id}`);
  return data;
};

export const addCustomer = async (values) => {
  const { data } = await axiosInstance.post("/v/admin/customer", values);
  return data;
};

export const suspendCustomer = async (values) => {
  const { data } = await axiosInstance.put("/v/admin/suspend-customer", values);
  return data;
};

export const restoreCustomer = async (values) => {
  const { data } = await axiosInstance.put("/v/admin/restore-customer", values);
  return data;
};

export const updateCustomer = async (values) => {
  const { data } = await axiosInstance.put("/v/admin/customer", values);
  return data;
};

export const getCustomerShipments = async (id) => {
  const { data } = await axiosInstance.get(
    `/v/admin/shipments?customerId=${id}`
  );
  return data;
};

export const importCustomerFromFile = async ({ file }) => {
  const fileData = new FormData();
  fileData.append("file", file);
  const { data } = await axiosInstance.post(
    `/v/admin/customers/import-xls`,
    fileData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return data;
};

export const issueTempPassword = async (ids) => {
  const { data } = await axiosInstance.put(
    `/v/admin/issue-temp-password?ids=${ids}`
  );
  return data;
};
