import * as Yup from "yup"

export const AWBSchema = Yup.object().shape({
    airlinesName: Yup.string().required('Required'),
    customerId: Yup.string().required('Required'),
    date: Yup.string().required('Required'),
    destination: Yup.string().required('Required'),
    emails: Yup.array()
        .of(Yup.string().email('Invalid email format')),
    mAwbNumber: Yup.string().required('Required'),
})
export const bulkAWBSchema = Yup.object().shape({
    airlinesName: Yup.string().required('Required'),
    masterAwbs: Yup.array()
        .of(Yup.string())
        .test('at-least-one-required', 'At least one master AWB is required', function (value) {
            return value && value.length > 0
        }),
})