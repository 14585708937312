import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  addAdmin,
  disableAdmin,
  enableAdmin,
  getUsers,
  updateAdmin,
} from "../../../api/admin/admin-api";

export const useGetUsers = () => {
  return useQuery(["getAdmin"], getUsers, {
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};

export const useAddAdmin = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(["addAdmin"], (formData) => addAdmin(formData), {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["getAdmin"]);
      toast.success("Succesfully added admin");
      onSuccess && onSuccess(data, variables, context);
    },
    onError: (err, _variables, _context) => {
      toast.error(`error: ${err.message}`);
    },
  });
};

export const useDisableAdmin = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(["DisableAdmin"], (id) => disableAdmin(id), {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["getAdmin"]);
      toast.success("Succesfully disable admin");
      onSuccess && onSuccess(data, variables, context);
    },
    onError: (err, _variables, _context) => {
      toast.error(`error: ${err.message}`);
    },
  });
};

export const useUpdateAdmin = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(["updateAdmin"], (id) => updateAdmin(id), {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["getAdmin"]);
      toast.success("Succesfully enabled admin");
      onSuccess && onSuccess(data, variables, context);
    },
    onError: (err, _variables, _context) => {
      toast.error(`error: ${err.message}`);
    },
  });
};

export const useEnableAdmin = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(["EnableAdmin"], (id) => enableAdmin(id), {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["getAdmin"]);
      toast.success("Succesfully updated admin");
      onSuccess && onSuccess(data, variables, context);
    },
    onError: (err, _variables, _context) => {
      toast.error(`error: ${err.message}`);
    },
  });
};
