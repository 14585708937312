import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  createCustomShipmentRequest,
  getCustomers,
  getServiceProviders,
} from "../../../api/custom_shipment/useCustomShipment";

export const useCreateCustomShipmentRequest = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(
    ["createShipmentRequest"],
    (formData) => createCustomShipmentRequest(formData),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["getMyShipments"]);
        toast.success("Succesfully requested for shipping");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};

export const useGetServiceProviders = (id) => {
  return useQuery(["getServiceProviders", id], () => getServiceProviders(id), {
    cacheTime: 10000,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetCustomers = (search) => {
  return useQuery(["getCustomers"], () => {
    const customerData = sessionStorage.getItem("customerData");

    if (!customerData || search) {
      return getCustomers().then((data) => {
        sessionStorage.setItem("customerData", JSON.stringify(data));
        return data;
      });
    } else {
      return JSON.parse(customerData);
    }
  }, {
    cacheTime: 10000,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};
