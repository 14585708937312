import {
  Button,
  FormControlLabel,
  Grid,
  Link,
  Switch,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useRegisterForm } from "../../../hooks/components/register/useRegisterForm";

const RegisterForm = () => {
  const [isBusiness, setIsBusiness] = useState(false);
  const { formik } = useRegisterForm({ isBusiness });

  return (
    <>
      <Box
        sx={{
          paddingTop: 15,
          mb: "20%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src="/assests/nepal-express-parcel.svg"
          alt="NepalEx"
          height="100px"
          style={{
            marginBottom: "20px",
          }}
        />
        <Box component="form" noValidate sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <FormControlLabel
                label={isBusiness ? "Business Account" : "Personal Account"}
                labelPlacement="bottom"
                control={
                  <Switch
                    checked={isBusiness}
                    onChange={(event) => setIsBusiness(event.target.checked)}
                  />
                }
              />
            </Grid>
            {isBusiness && (
              <Grid item xs={12}>
                <TextField
                  autoComplete="Business Name"
                  name="companyName"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.companyName &&
                    Boolean(formik.errors.companyName)
                  }
                  helperText={
                    formik.touched.companyName && formik.errors.companyName
                  }
                  required
                  fullWidth
                  label="Business Name"
                  autoFocus
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                value={formik.values.panNo}
                onChange={formik.handleChange}
                error={formik.touched.panNo && Boolean(formik.errors.panNo)}
                helperText={formik.touched.panNo && formik.errors.panNo}
                id="panNo"
                label="Pan No."
                name="panNo"
                autoComplete="Pan No."
              />
            </Grid>
            {/* <Grid item xs={6}>
              <TextField
                required
                fullWidth
                name="country"
                label="Country"
                type="text"
                id="country"
                autoComplete="Country"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                name="postalCode"
                label="Postal Code"
                type="text"
                id="postalCode"
                autoComplete="Postal Code"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="city"
                label="City"
                type="text"
                id="city"
                autoComplete="City"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="state"
                label="State"
                type="text"
                id="state"
                autoComplete="State"
              />
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                value={formik.values.mobile}
                onChange={formik.handleChange}
                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                helperText={formik.touched.mobile && formik.errors.mobile}
                name="mobile"
                label="Contact Number"
                type="text"
                id="phoneNumber"
                autoComplete="Contact Number"
              />
            </Grid>
          </Grid>
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => formik.submitForm()}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default RegisterForm;
