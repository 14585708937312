import {Box, Grid, TextField} from "@mui/material"
import React from "react"

const AddAWBFormItem = ({formik}) => {
    return (
        <Box component="form" form={formik} noValidate sx={{mt: 1}}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        fullWidth
                        value={formik.values.airlinesName}
                        onChange={formik.handleChange}
                        error={formik.touched.airlinesName && Boolean(formik.errors.airlinesName)}
                        helperText={formik.touched.airlinesName && formik.errors.airlinesName}
                        id="airlinesName"
                        label="Airlines Name"
                        name="airlinesName"
                        autoComplete="airlinesName"
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        required
                        fullWidth
                        value={formik.values.masterAwbs}
                        onChange={(e) => {
                            const enteredValues = e.target.value
                            const valuesArray = enteredValues.split(',').map((value) => value.trim())
                            formik.handleChange({
                                target: {name: 'masterAwbs', value: valuesArray},
                            })
                        }}
                        error={formik.touched.masterAwbs && Boolean(formik.errors.masterAwbs)}
                        helperText={formik.touched.masterAwbs && formik.errors.masterAwbs}
                        id="masterAwbs"
                        label="Master AWB Number's"
                        name="mAwbNumber"
                        autoComplete="mAwbNumber"
                    />
                </Grid>
            </Grid>
        </Box>
    )
}
export default AddAWBFormItem
