import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import useUpdateShipmentStatusForm from "../../../hooks/components/form/shipments/status/useUpdateShipmentStatusForm";
import DeliveredStatusFormItem from "./items/status/DeliveredStatusFormItem";

const statusOptions = [
  { label: "Delivered", value: "Delivered" },
  { label: "Exception", value: "Exception" },
  { label: "Pending", value: "Pending" },
  { label: "InTransit", value: "InTransit" },
  { label: "Return", value: "Return" },
];

const UpdateShipmentStatus = ({ open, handleClose, data }) => {
  const { formik } = useUpdateShipmentStatusForm({ handleClose, id: data?.id });
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Update Status</Typography>
          </Grid>
          <Grid item sm={12} md={6}>
            <Typography sx={{ fontSize: "12px" }}>AWB: {data?.awb}</Typography>
          </Grid>
          <Grid item sm={12} md={6}>
            <Typography sx={{ fontSize: "12px" }}>
              Shipper: {data?.shipper}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box form={formik} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                size="small"
                margin="dense"
                required
                select
                name="status"
                label="status"
                value={formik.values.status}
                onChange={formik.handleChange}
                error={formik.touched.status && Boolean(formik.errors.status)}
                helperText={formik.touched.status && formik.errors.status}
                fullWidth
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              {formik.values.status === "Delivered" ? (
                <DeliveredStatusFormItem formik={formik} />
              ) : formik.values.status === "Pending" ||
                formik.values.status === "InTransit" ? (
                <></>
              ) : (
                <TextField
                  size="small"
                  margin="dense"
                  fullWidth
                  required
                  name="remarks"
                  label="Remarks"
                  multiline
                  value={formik.values.remarks}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.remarks && Boolean(formik.errors.remarks)
                  }
                  helperText={formik.touched.remarks && formik.errors.remarks}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => handleClose()} sx={{ textTransform: "none" }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={() => formik.handleSubmit()}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateShipmentStatus;
