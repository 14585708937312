import { Box, Grid, TextField } from "@mui/material";
import React from "react";

const ServiceProviderFormItem = ({ formik }) => {
  return (
    <Box component="form" form={formik} noValidate sx={{ mt: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            name="name"
            id="name"
            label="Name"
            autoComplete="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            fullWidth
          />
        </Grid>
        {/* <Grid item xs={12} sm={4}>
          <FormControlLabel
            id="apiService"
            name="apiService"
            control={
              <Checkbox
                checked={formik.values.apiService}
                onChange={formik.handleChange}
              />
            }
            label="Api Service"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            id="enabled"
            name="enabled"
            control={
              <Checkbox
                checked={formik.values.enabled}
                onChange={formik.handleChange}
              />
            }
            label="Enabled"
          />
        </Grid> */}

        {formik.values.apiService && (
          <Grid item xs={12} sm={6}>
            <TextField
              name="apiKey"
              id="apiKey"
              label="Api Key"
              autoComplete="apiKey"
              value={formik.values.apiKey}
              onChange={formik.handleChange}
              error={formik.touched.apiKey && Boolean(formik.errors.apiKey)}
              helperText={formik.touched.apiKey && formik.errors.apiKey}
              fullWidth
            />
          </Grid>
        )}
        {formik.values.apiService && (
          <Grid item xs={12} sm={6}>
            <TextField
              name="apiSecret"
              id="apiSecret"
              label="Api Secret"
              autoComplete="apiSecret"
              value={formik.values.apiSecret}
              onChange={formik.handleChange}
              error={
                formik.touched.apiSecret && Boolean(formik.errors.apiSecret)
              }
              helperText={formik.touched.apiSecret && formik.errors.apiSecret}
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            multiline
            minRows={2}
            name="description"
            id="description"
            label="Description"
            autoComplete="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServiceProviderFormItem;
