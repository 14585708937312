import React from "react";
import AdminTable from "../../components/admin/table/AdminTable";

const Admin = () => {
  return (
    <>
      <AdminTable />
    </>
  );
};

export default Admin;
