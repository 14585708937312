import { useGetShipments } from "../../../api/ship/useShip";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const useMyShipmentTable = () => {
  const { data, isLoading } = useGetShipments();
  const navigate = useNavigate();

  const customEditDetails = {
    icon: () => <CancelScheduleSendIcon />,
    tooltip: "Cancel Shipment Request",
  };
  const column = [
    {
      title: "id",
      field: "id",
      render: (rowData) => data?.findIndex((x) => x?.id === rowData?.id) + 1,
    },
    {
      title: "AWB",
      field: "awb",
      render: (rowData) => (
        <Button onClick={() => navigate(`/my-shipments/${rowData?.id}`)}>
          {rowData?.awb}
        </Button>
      ),
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Import / Export",
      field: "importExport",
    },
    {
      title: "Number of Boxes",
      field: "numberOfBoxes",
    },
    {
      title: "Service",
      field: "serviceProvider",
    },
    {
      title: "Destination Country",
      field: "destination",
    },
    {
      title: "Postal Code",
      field: "postalCode",
    },
  ];
  return { data, column, customEditDetails, isLoading };
};

export default useMyShipmentTable;
