import { axiosInstance } from "../../../app/api/axiosInterceptor";

export const createCustomShipmentRequest = async (formData) => {
  const { data } = await axiosInstance.post("/v/admin/shipment", formData);
  return data;
};

export const getServiceProviders = async (id) => {
  if (id) {
    const { data } = await axiosInstance.get(
      `/v/admin/service-providers?id=${id}`
    );
    return data;
  }
  const { data } = await axiosInstance.get("/v/admin/service-providers");
  return data;
};
export const getCustomers = async () => {
  const { data } = await axiosInstance.get("/v/admin/customers");
  return data;
};
