import { useFormik } from "formik";
import { useAddNewShipmentRate } from "../../../api/service_provider/rates/useServiceProviderRate";
import { shipmentProviderCountryValidationSchema } from "./shipmentProviderCountryValidationSchema";

export const useExportRatesForm = ({ id, handleClose }) => {
  const { mutate: mutateAdd } = useAddNewShipmentRate({});
  const formik = useFormik({
    initialValues: {
      country: "",
      currency: "Dollar",
      packageType: "Document",
      customerCategory: "C1",
      rate: 0,
      weight: 0,
    },
    validationSchema: shipmentProviderCountryValidationSchema,
    onSubmit: (values) => {
      handleCreate(values);
    },
  });

  const handleCreate = (values) => {
    values = { ...values, shipmentProviderId: Number(id) };
    mutateAdd(values, {
      onSuccess: () => {
        handleClose();
        formik.handleReset();
      },
    });
  };

  return { formik };
};
