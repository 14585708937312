import { Grid, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import React from "react";

const DetailTypography = ({ title, name, icon, bool }) => {
  return (
    <Grid container>
      <Grid item xs={3} sx={{ paddingTop: 1 }}>
        {icon}
      </Grid>
      <Grid item xs={12}>
        <Grid container spcaing={2}>
          <Grid item xs={12}>
            <Typography
              component="div"
              sx={{ color: "text.secondary" }}
              variant="h7"
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {bool ? (
              name ? (
                <CheckCircleOutlineIcon color="success" />
              ) : (
                <CancelIcon color="error" />
              )
            ) : (
              <Typography component="div" variant="h7" color="text.primary">
                {name ? name : "-"}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailTypography;
