import {
  Box,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useAddShipmentBillingForm } from "../../../../hooks/components/form/nepal_lite/shipment_bill/useAddShipmentBill";

const billTypeOptions = [
  { label: "-", value: "" },
  { label: "Pan", value: "Pan" },
  { label: "TPan", value: "TPan" },
  { label: "Vat", value: "Vat" },
];

const AddShipmentBillingForm = ({ id, data }) => {
  const { formik } = useAddShipmentBillingForm({ id, data });

  return (
    <Box component="form" form={formik} noValidate>
      <Grid container spacing={0.5}>
        <Grid item xs={12}>
          <Typography>
            Billing for Shipment # {formik.values.shipmentId}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            margin="dense"
            select
            name="billType"
            label="billType"
            value={formik.values.billType}
            onChange={formik.handleChange}
            error={formik.touched.billType && Boolean(formik.errors.billType)}
            helperText={formik.touched.billType && formik.errors.billType}
            fullWidth
          >
            {billTypeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            margin="dense"
            fullWidth
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Rs.</InputAdornment>
              ),
            }}
            name="freightGivenRate"
            label="Freight Given Rate"
            value={formik.values.freightGivenRate}
            onChange={formik.handleChange}
            error={
              formik.touched.freightGivenRate &&
              Boolean(formik.errors.freightGivenRate)
            }
            helperText={
              formik.touched.freightGivenRate && formik.errors.freightGivenRate
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            margin="dense"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Rs.</InputAdornment>
              ),
            }}
            type="number"
            name="freight"
            label="Freight"
            value={formik.values.freight}
            onChange={formik.handleChange}
            error={formik.touched.freight && Boolean(formik.errors.freight)}
            helperText={formik.touched.freight && formik.errors.freight}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            margin="dense"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Rs.</InputAdornment>
              ),
            }}
            type="number"
            name="tia"
            label="Tia"
            value={formik.values.tia}
            onChange={formik.handleChange}
            error={formik.touched.tia && Boolean(formik.errors.tia)}
            helperText={formik.touched.tia && formik.errors.tia}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            margin="dense"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Rs.</InputAdornment>
              ),
            }}
            type="number"
            name="discountOnTia"
            label="Discount On Tia"
            value={formik.values.discountOnTia}
            onChange={formik.handleChange}
            error={
              formik.touched.discountOnTia &&
              Boolean(formik.errors.discountOnTia)
            }
            helperText={
              formik.touched.discountOnTia && formik.errors.discountOnTia
            }
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            size="small"
            margin="dense"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Rs.</InputAdornment>
              ),
            }}
            type="number"
            name="strip"
            label="Strip"
            value={formik.values.strip}
            onChange={formik.handleChange}
            error={formik.touched.strip && Boolean(formik.errors.strip)}
            helperText={formik.touched.strip && formik.errors.strip}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            margin="dense"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Rs.</InputAdornment>
              ),
            }}
            type="number"
            name="discountOfStrip"
            label="Discount Of Strip"
            value={formik.values.discountOfStrip}
            onChange={formik.handleChange}
            error={
              formik.touched.discountOfStrip &&
              Boolean(formik.errors.discountOfStrip)
            }
            helperText={
              formik.touched.discountOfStrip && formik.errors.discountOfStrip
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            margin="dense"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Rs.</InputAdornment>
              ),
            }}
            type="number"
            name="custom"
            label="Custom"
            value={formik.values.custom}
            onChange={formik.handleChange}
            error={formik.touched.custom && Boolean(formik.errors.custom)}
            helperText={formik.touched.custom && formik.errors.custom}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            margin="dense"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Rs.</InputAdornment>
              ),
            }}
            type="number"
            name="discountOfCustom"
            label="Discount Of Custom"
            value={formik.values.discountOfCustom}
            onChange={formik.handleChange}
            error={
              formik.touched.discountOfCustom &&
              Boolean(formik.errors.discountOfCustom)
            }
            helperText={
              formik.touched.discountOfCustom && formik.errors.discountOfCustom
            }
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            size="small"
            margin="dense"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Rs.</InputAdornment>
              ),
            }}
            type="number"
            name="doc"
            label="Doc"
            value={formik.values.doc}
            onChange={formik.handleChange}
            error={formik.touched.doc && Boolean(formik.errors.doc)}
            helperText={formik.touched.doc && formik.errors.doc}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            margin="dense"
            fullWidth
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Rs.</InputAdornment>
              ),
            }}
            name="oversize"
            label="Oversize"
            value={formik.values.oversize}
            onChange={formik.handleChange}
            error={formik.touched.oversize && Boolean(formik.errors.oversize)}
            helperText={formik.touched.oversize && formik.errors.oversize}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            margin="dense"
            fullWidth
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Rs.</InputAdornment>
              ),
            }}
            name="remote"
            label="Remote"
            value={formik.values.remote}
            onChange={formik.handleChange}
            error={formik.touched.remote && Boolean(formik.errors.remote)}
            helperText={formik.touched.remote && formik.errors.remote}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            margin="dense"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Rs.</InputAdornment>
              ),
            }}
            type="number"
            name="others"
            label="Others"
            value={formik.values.others}
            onChange={formik.handleChange}
            error={formik.touched.others && Boolean(formik.errors.others)}
            helperText={formik.touched.others && formik.errors.others}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            margin="dense"
            fullWidth
            name="otherDetails"
            label="Other Details"
            value={formik.values.otherDetails}
            onChange={formik.handleChange}
            error={
              formik.touched.otherDetails && Boolean(formik.errors.otherDetails)
            }
            helperText={
              formik.touched.otherDetails && formik.errors.otherDetails
            }
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            size="small"
            margin="dense"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Rs.</InputAdornment>
              ),
            }}
            type="number"
            name="vat"
            label="Vat"
            value={formik.values.vat}
            onChange={formik.handleChange}
            error={formik.touched.vat && Boolean(formik.errors.vat)}
            helperText={formik.touched.vat && formik.errors.vat}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            margin="dense"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Rs.</InputAdornment>
              ),
            }}
            type="number"
            name="totalCharges"
            label="Total Charges"
            value={formik.values.totalCharges}
            onChange={formik.handleChange}
            error={
              formik.touched.totalCharges && Boolean(formik.errors.totalCharges)
            }
            helperText={
              formik.touched.totalCharges && formik.errors.totalCharges
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3} justifyContent="flex-end">
            <Grid item xs={3}>
              <Button
                fullWidth
                variant="outlined"
                sx={{ textTransform: "none" }}
                onClick={() => formik.handleReset()}
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                variant="contained"
                sx={{ textTransform: "none" }}
                onClick={() => formik.handleSubmit()}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddShipmentBillingForm;
