import { useGetOfficeNotices } from "../../../api/office_notices/useOfficeNotices";
import { Link } from "react-router-dom";

const useOfficeNoticesTable = () => {
  const { data } = useGetOfficeNotices();
  let officeNoticeData = [];
  let customerNoticeData = [];

  data &&
    data !== undefined &&
    Object.values(data).forEach((key) => {
      key.noticeType === "OfficeStaffs"
        ? officeNoticeData.push(key)
        : customerNoticeData.push(key);
    });

  const column = [
    {
      title: "id",
      field: "id",
      render: (rowData) =>
        rowData?.noticeType === "Customers"
          ? customerNoticeData?.findIndex((x) => x?.id === rowData?.id) + 1
          : officeNoticeData?.findIndex((x) => x?.id === rowData?.id) + 1,
    },
    {
      title: "Subject",
      field: "subject",
      render: (rowData) => (
        <Link to={`${rowData?.id}`}>{rowData?.subject}</Link>
      ),
    },
  ];
  return { column, officeNoticeData, customerNoticeData };
};

export default useOfficeNoticesTable;
