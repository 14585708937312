import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import React from "react";

const AddressDetailsForm = ({ formik }) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="consignee"
            name="consignee"
            label="Consignee"
            fullWidth
            value={formik.values.consignee}
            onChange={formik.handleChange}
            error={formik.touched.consignee && Boolean(formik.errors.consignee)}
            helperText={formik.touched.consignee && formik.errors.consignee}
            autoComplete="consignee"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="destinationCountry"
            name="destinationCountry"
            label="Destination Country"
            fullWidth
            value={formik.values.destinationCountry}
            onChange={formik.handleChange}
            error={
              formik.touched.destinationCountry &&
              Boolean(formik.errors.destinationCountry)
            }
            helperText={
              formik.touched.destinationCountry &&
              formik.errors.destinationCountry
            }
            autoComplete="destination-country"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="houseNumber"
            name="houseNumber"
            label="House Number"
            fullWidth
            value={formik.values.houseNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.houseNumber && Boolean(formik.errors.houseNumber)
            }
            helperText={formik.touched.houseNumber && formik.errors.houseNumber}
            autoComplete="house-number"
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="postalCode"
            name="postalCode"
            label="Postal Code"
            fullWidth
            value={formik.values.postalCode}
            onChange={formik.handleChange}
            error={
              formik.touched.postalCode && Boolean(formik.errors.postalCode)
            }
            helperText={formik.touched.postalCode && formik.errors.postalCode}
            autoComplete="consignee"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="street"
            name="street"
            label="Street Name"
            fullWidth
            value={formik.values.street}
            onChange={formik.handleChange}
            error={formik.touched.street && Boolean(formik.errors.street)}
            helperText={formik.touched.street && formik.errors.street}
            autoComplete="street"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label="City"
            fullWidth
            value={formik.values.city}
            onChange={formik.handleChange}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
            autoComplete="city"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="fullPickupAddress"
            name="fullPickupAddress"
            label="Full Pick-up Address"
            fullWidth
            value={formik.values.fullPickupAddress}
            onChange={formik.handleChange}
            error={
              formik.touched.fullPickupAddress &&
              Boolean(formik.errors.fullPickupAddress)
            }
            helperText={
              formik.touched.fullPickupAddress &&
              formik.errors.fullPickupAddress
            }
            autoComplete="fullPickupAddress"
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            id="remoteArea"
            name="remoteArea"
            value={formik.values.remoteArea}
            onChange={formik.handleChange}
            control={
              <Checkbox
                checked={formik.values.remoteArea}
                onChange={formik.handleChange}
              />
            }
            label="Remote Area"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AddressDetailsForm;
