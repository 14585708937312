import { Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import DetailTypography from "../../../../app/component/detail_view/DetailTypography";

const MyPayment = ({ data }) => {
  return (
    <Paper sx={{ pb: 4, mt: 3, mb: 2 }} elevation={4}>
      <Grid container spacing={3} sx={{ pl: 2, pr: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h5">Shipping Payment</Typography>
          <Divider sx={{ mt: 2 }} />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <DetailTypography title="Method" name={data?.paymentMethod} />
        </Grid>
        <Grid item xs={6} md={4}>
          <DetailTypography
            title="Vat"
            name={`Rs. ${data?.vat?.toLocaleString("en-US")}`}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <DetailTypography
            title="Total"
            name={`Rs. ${data?.totalCharges?.toLocaleString("en-US")}`}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DetailTypography title="billed" name={data?.billed} bool={true} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MyPayment;
