import {axiosInstance} from "../../../app/api/axiosInterceptor"

export const calculateFreightRate = async ({shipmentData})=>{
    const endpoint = '/v/admin/calculate-rate'
    const config = {
        params:{
            ...shipmentData,
        }
    }
    const {data} =await axiosInstance.post(endpoint,null,config)
    return data
}