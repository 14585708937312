import {Grid} from "@mui/material"
import MasterAWBTable from "../../../../components/table/nepal_lite/shipment/MasterAWBTable"

const MasterAWB = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <MasterAWBTable />
            </Grid>
        </Grid>
    )
}
export default MasterAWB
