import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  createServiceProvider,
  getServiceProviders,
  updateServiceProvider,
} from "../../../api/service_provider/serviceprovider-api";

export const useGetServiceProviders = (id) => {
  return useQuery(["getServiceProviders", id], () => getServiceProviders(id), {
    cacheTime: 10000,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};

export const useCreateServiceProvider = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(
    ["createServiceProvider"],
    (formData) => createServiceProvider(formData),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["getServiceProviders"]);
        toast.success("Succesfully added service provider");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};

export const useUpdateServiceProvider = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(
    ["updateServiceProvider"],
    (formData) => updateServiceProvider(formData),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["getServiceProviders"]);
        toast.success("Succesfully updated service provider");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};
