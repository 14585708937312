import { useFormik } from "formik";
import { useCreateServiceProvider } from "../../../api/service_provider/useServiceProvider";
import { serviceProviderValidationSchema } from "./serviceProviderValidationSchema";

export const useServiceProviderForm = () => {
  const { mutate: mutateAdd } = useCreateServiceProvider({});
  const formik = useFormik({
    initialValues: {
      apiKey: "",
      apiSecret: "",
      apiService: false,
      description: "",
      enabled: false,
      name: "",
    },
    validationSchema: serviceProviderValidationSchema,
    onSubmit: (values) => {
      handleCreate(values);
    },
  });

  const handleCreate = (values) => {
    mutateAdd(values, { onSuccess: () => formik.handleReset() });
  };

  return { formik };
};
