import { Box } from "@mui/system";
import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import { MobileSideDrawer } from "../component/navbar/MobileSideDrawer";
import { SideDrawer } from "../component/navbar/SideDrawer";
import ThemeModeContext from "../contexts/theme/ThemeModeContext";

const SuperLayout = () => {
  const { isMobile } = useContext(ThemeModeContext);
  return isMobile ? (
    <MobileSideDrawer>
      <Box sx={{ minHeight: "100vh" }}>
        <Outlet />
      </Box>
    </MobileSideDrawer>
  ) : (
    <SideDrawer>
      <Box sx={{ minHeight: "100vh" }}>
        <Outlet />
      </Box>
    </SideDrawer>
  );
};

export default SuperLayout;
