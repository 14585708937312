import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const drawerWidth = 240;

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const pathname = window.location.pathname;
  const [color, setColor] = useState(
    pathname === "/" ? "transparent" : "inherit"
  );

  useEffect(() => {
    if (pathname !== "/" && pathname !== "/about") {
      setColor("inherit");
      setBoxShadow("2");
    } else {
      setColor("transparent");
      setBoxShadow("none");
    }
  }, [pathname]);

  const [boxShadow, setBoxShadow] = useState("none");

  const changeColor = () => {
    if (pathname === "/" || pathname === "/about") {
      if (window.scrollY >= 400) {
        setColor("inherit");
        setBoxShadow("2");
      } else {
        setColor("transparent");
        setBoxShadow("none");
      }
    } else {
      setColor("inherit");
      setBoxShadow("2");
    }
  };

  window.addEventListener("scroll", changeColor);

  const drawer = (
    <Box
      onClose={handleDrawerToggle}
      sx={{ textAlign: "center", backgroundColor: "#000", minHeight: "100vh" }}
    >
      <Box component="div" sx={{ m: 3 }}>
        <Button onClick={() => navigate("/")} sx={{ color: "#000" }}>
          <img
            src="/assests/nepal-express-parcel.svg"
            alt="logo"
            height={"36px"}
          />
        </Button>
      </Box>
      <Divider sx={{ color: "#fff" }} />
      <List>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center", color: "#fff" }}
            onClick={() => navigate("/")}
          >
            <ListItemText>Home</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center", color: "#fff" }}
            onClick={() => navigate("about")}
          >
            <ListItemText>About</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center", color: "#fff" }}
            onClick={() => navigate("services")}
          >
            <ListItemText>Services</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center", color: "#fff" }}
            onClick={() => navigate("contact")}
          >
            <ListItemText>Contact Us</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center", color: "#fff" }}
            onClick={() => navigate("login")}
          >
            <ListItemText>Login</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        color={color}
        component="nav"
        sx={{
          backdropFilter: "opacity(10%)",
          boxShadow: { boxShadow },
          height: "60px",
          backgroundColor: color === "transparent" ? "transparent" : "#000",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: "16px", display: { md: "none" }, pt: 2 }}
          >
            <MenuIcon sx={{ color: "#fff" }} />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100vw",
            }}
          >
            <Box sx={{ margin: { xs: "5px", sm: "12px 80px " } }}>
              <Link to="/" style={{ textDecoration: "none" }}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: "800",
                    fontSize: "24px",
                    paddingTop: "5px",
                  }}
                >
                  NepalEx
                </Typography>
              </Link>
            </Box>
            <Box sx={{ display: "flex", gap: "32px", alignItems: "center" }}>
              <Box
                sx={{
                  display: { xs: "none", sm: "none", md: "flex" },
                  gap: "2rem",
                }}
              >
                <Link to="/" style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: "700",
                      fontSize: "18px",
                      paddingTop: "5px",
                    }}
                  >
                    Home
                  </Typography>
                </Link>
                <Link to="/about" style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: "700",
                      fontSize: "18px",
                      paddingTop: "5px",
                    }}
                  >
                    About
                  </Typography>
                </Link>
                <Link to="/services" style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: "700",
                      fontSize: "18px",
                      paddingTop: "5px",
                    }}
                  >
                    Services
                  </Typography>
                </Link>
                <Link to="/contact" style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: "700",
                      fontSize: "18px",
                      paddingTop: "5px",
                    }}
                  >
                    Contact Us
                  </Typography>
                </Link>
                <Link
                  to="/my-shipments"
                  style={{
                    textDecoration: "none",
                    backgroundColor: "#F18605",
                    borderRadius: "16px",
                    marginRight: "20px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: "700",
                      fontSize: "20px",
                      padding: { xs: "2px 12px", md: "2px 16px" },
                    }}
                  >
                    Login
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default Navbar;
