import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import React from "react";
import { useShipmentCancelForm } from "../../../hooks/components/ship/form/shipment_request/useShipmentCancelForm";

const CancelShippmentDialog = ({ id, open, handleClose }) => {
  const { handleCancel } = useShipmentCancelForm({});
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>Cancel Shipment Requests</DialogTitle>
      <Divider />
      <DialogContent>
        Are you sure you want to cancel this shipment request?
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => handleClose()}>Close</Button>
        <Button
          onClick={() => {
            handleCancel(id);
            handleClose();
          }}
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelShippmentDialog;
