import { useState } from "react";
import { useGetCustomerInfoConfigs } from "../../../../../app/hooks/api/customer-info/useCustomerInfo";

export const useCustomerInfoConfigTable = () => {
  const { data, isLoading } = useGetCustomerInfoConfigs({});
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState({ open: false, data: {} });
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenEdit = (values) => {
    setOpenEdit({
      open: true,
      data: values,
    });
  };

  const handleCloseEdit = () => {
    setOpenEdit({ open: false, data: {} });
  };

  const columns = [
    { title: "Info Key", field: "infoKey" },
    { title: "Value", field: "value" },
  ];
  return {
    data,
    isLoading,
    columns,
    open,
    handleClose,
    handleOpen,
    openEdit,
    handleCloseEdit,
    handleOpenEdit,
  };
};
