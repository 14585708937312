import React from "react";
import { usePaymentTables } from "../../../hooks/components/table/payments/usePaymentTables";
import MaterialTable from "@material-table/core";
import { Box, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddPaymentForm from "../../form/payment/AddPaymentForm";
import AddAdditionalReceivableForm from "../../form/payment/AddAdditionalReceivableForm";
import AddCardIcon from "@mui/icons-material/AddCard";

const PaymentTable = () => {
  const {
    data,
    isLoading,
    columns,
    handleOpen,
    handleClose,
    open,
    handleRowDoubleClick,
    handleOpenAdditional,
    handleCloseAdditional,
    openAdditional,
  } = usePaymentTables();
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaterialTable
            columns={columns}
            data={data}
            title="Payments"
            onRowDoubleClick={(_event, rowData) =>
              handleRowDoubleClick(rowData)
            }
            isLoading={isLoading}
            actions={[
              {
                icon: () => <AddIcon />,
                tooltip: "Add Payment",
                isFreeAction: true,
                onClick: () => handleOpen(),
              },
              {
                icon: () => <AddCardIcon />,
                tooltip: "Add Additional Payment",
                isFreeAction: true,
                onClick: () => handleOpenAdditional(),
              },
            ]}
            options={{
              pageSize: 10,
              padding: "dense",
            }}
          />
        </Grid>
      </Grid>
      <AddPaymentForm open={open} handleClose={handleClose} />
      <AddAdditionalReceivableForm
        open={openAdditional}
        handleClose={handleCloseAdditional}
      />
    </Box>
  );
};

export default PaymentTable;
