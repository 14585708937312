import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

const AboutUs = () => {
  return (
    <Box sx={{ background: "whitesmoke" }}>
      <Container sx={{ paddingTop: "5%", paddingBottom: "5%" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: 800,
                    fontSize: "24px",
                    textDecoration: "underline",
                    color: "primary.main",
                  }}
                >
                  About Us
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "14px" }}>
                  Nepal Express Parcel and Logistics a international courier and
                  cargo service started on 2012, earning a dignified reputation
                  in bringing the world of Courier, Cargo and Freight business
                  closer and cheaper to you. <br />
                  Delivers packages to every business address in World Wide at
                  very affordable rates. Through an efficient, multi-service
                  domestic and international delivery Network we have been very
                  consistent in our quality services.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <img
              alt="person-box"
              src="/assests/images/landing_page/about1.png"
              height="400px"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AboutUs;
