import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
} from "@mui/material";
import React from "react";
import { useConfirmShipmentRequestLite } from "../../../../hooks/api/nepal_lite/useNepalLite";

const ConfirmShipmentRequestLiteForm = ({ open, handleClose, id }) => {
  const { mutate } = useConfirmShipmentRequestLite({});
  const handleConfirm = () => {
    mutate([id]);
  };
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogContent>Confirm this shipment request?</DialogContent>
      <Divider />
      <DialogActions>
        <Button
          sx={{ textTransform: "none" }}
          variant="outlined"
          onClick={() => handleClose()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={() => {
            handleConfirm();
            handleClose();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmShipmentRequestLiteForm;
