import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setUser } from "../../../../app/utils/cookieHelper";
import { tempPassword } from "../../../api/temppassword/temppassword-api";

export const useTempPassword = ({ onSuccess }) => {
  const navigate = useNavigate();
  return useMutation(["temppassword"], (formData) => tempPassword(formData), {
    onSuccess: (data, variables, context) => {
      toast.success(`Successfully changed password`);
      navigate("/");
      setUser({
        token: data?.jwt,
        role: data?.role,
        userId: data?.userId,
        tempPassword: data?.tempPassword,
      });
      onSuccess && onSuccess(data, variables, context);
    },
    onError: (err, _variables, _context) => {
      toast.error(err.message || `Could not change password`);
    },
  });
};
