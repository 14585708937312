import { useFormik } from "formik";
import { useUpdateWeight } from "../../../../api/nepal_lite/useNepalLite";
import { updateWeightValidationSchema } from "./updateWeightValidationSchema";

export const useUpdateWeightForm = ({ handleClose, data }) => {
  const { mutate } = useUpdateWeight({});
  const formik = useFormik({
    initialValues: {
      actualWeight: data?.actualWeight,
      dimensionalWeight: data?.dimensionalWeight,
    },
    validationSchema: updateWeightValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleConfrim(values);
    },
  });
  const handleConfrim = (values) => {
    values = {
      ...values,
      shipmentId: data?.id,
      appliedWeight:
        values?.actualWeight > values?.dimensionalWeight
          ? values?.actualWeight
          : values?.dimensionalWeight,
    };
    mutate(values, {
      onSuccess: () => {
        handleClose();
      },
    });
  };
  return { formik };
};
