import { axiosInstance } from "../../../app/api/axiosInterceptor";

export const getFindPickups = async ({ adminId, status, dateTo, dateFrom }) => {
  let link = "/v/admin/pickups";
  /*if (dateFrom && dateTo) {
    link = link.concat(`?dateFrom=${dateFrom}&dateTo=${dateTo}`);
  }*/
  const config = {
    params:{
      status,
      dateFrom,
      dateTo,
      adminId,
    }
  }
  const { data } = await axiosInstance.get(link,config);
  return data;
};

export const getPickupDetail = async (id) => {
  if (id) {
    const { data } = await axiosInstance.get(`/v/admin/pickups?id=${id}`);
    return data;
  }
};

export const createPickup = async (values) => {
  const { data } = await axiosInstance.post("/v/admin/pickup", values);
  return data;
};

export const updatePickup = async (values) => {
  const { data } = await axiosInstance.put("/v/admin/pickup", values);
  return data;
};

export const markPicked = async (id) => {
  const { data } = await axiosInstance.put(`/v/admin/pickup/picked?id=${id}`);
  return data;
};
