import { axiosInstance } from "../../../app/api/axiosInterceptor";

export const getCustomerPayments = async ({ id }) => {
  const { data } = await axiosInstance.get("/v/admin/payments", {
    params: {
      customerId: id,
      page: 0,
    },
  });
  return data;
};
export const getShipmentInvoiceByDate = async (date)=>{
  const endpoint = "/v/admin/payments/shipment-invoices"
  const config = {
    params:{
      ...date
    }
  }
  const {data} = await axiosInstance.get(endpoint,config)
  return data
}
export const addCustomerPayment = async (values) => {
  const { data } = await axiosInstance.post("/v/admin/payment", values);
  return data;
};

export const addAdditionalReceivable = async (values) => {
  const { data } = await axiosInstance.post("/v/admin/", values);
  return data;
};

export const updateCustomerPayment = async (values) => {
  const { data } = await axiosInstance.put("/v/admin/payment", values);
  return data;
};

export const deletePayment = async (id) => {
  console.log(id);
  if (id) {
    const { data } = await axiosInstance.delete(`/v/admin/payment?id=${id}`);
    return data;
  }
};
