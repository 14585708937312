import moment from "moment";
import { useAddCustomerPayment } from "../../../api/payment/usePayment";
import { useFormik } from "formik";
import { paymentValidationSchema } from "./paymentValidationSchema";
import { useParams } from "react-router-dom";

export const useAddCustomerPaymentForm = ({ handleClose }) => {
  const { mutate } = useAddCustomerPayment({});
  const { id } = useParams();

  const formik = useFormik({
    initialValues: {
      customerId: id,
      amount: null,
      date: moment().format("YYYY-MM-DD"),
      receiptNumber: "",
      description: "",
    },
    validationSchema: paymentValidationSchema,
    onSubmit: (values) => {
      values = {
        ...values,
        date: moment(values?.dateTime).format("YYYY-MM-DD"),
      };
      handleCreate(values);
    },
  });

  const handleCreate = (values) => {
    values = {
      ...values,
    };
    mutate(values, {
      onSuccess: () => {
        handleClose();
        formik.handleReset();
      },
    });
  };
  return {
    formik,
  };
};
