import {
  Box,
  Button,
  Container,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import useCustomShipmentRequestForm from "../../../hooks/components/form/custom_shipment/useCustomShipmentForm";
import AddressDetailsForm from "./steps/address_details/AddressDetailsForm";
import PackageDetailsForm from "./steps/package_details/PackageDetailsForm";
import PaymentDetailsForm from "./steps/payment_details/PaymentDetailsForm";
import ShipmentDetailsForm from "./steps/shipment_details/ShipmentDetailsForm";

const steps = [
  "Shipment Details",
  "Package details",
  "Address Details",
  "Payment Details",
];

const CustomShippingRequestForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { formik, serviceProviderList, customerList } =
    useCustomShipmentRequestForm();

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        formik.setFieldTouched("serviceProviderId");
        break;
      case 1:
        formik.setFieldTouched("numberOfBoxes");
        formik.setFieldTouched("actualWeight");
        break;
      case 2:
        formik.setFieldTouched("consignee");
        formik.setFieldTouched("destinationCountry");
        formik.setFieldTouched("city");
        formik.setFieldTouched("street");
        formik.setFieldTouched("houseNumber");
        break;
      case 3:
        break;
      default:
        break;
    }
    setActiveStep(activeStep + 1);
  };

  const isStepFailed = (step) => {
    switch (step) {
      case 0:
        return (
          formik.errors.serviceProviderId && formik.touched.serviceProviderId
        );
      case 1:
        return (
          (formik.touched.numberOfBoxes && formik.errors.numberOfBoxes) ||
          (formik.touched.actualWeight && formik.errors.actualWeight)
        );
      case 2:
        return (
          (formik.errors.consignee && formik.touched.consignee) ||
          (formik.errors.destinationCountry &&
            formik.touched.destinationCountry) ||
          (formik.errors.city && formik.touched.city) ||
          (formik.errors.street && formik.touched.street) ||
          (formik.errors.houseNumber && formik.touched.houseNumber)
        );
      case 3:
        break;
      default:
        break;
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <ShipmentDetailsForm
            formik={formik}
            serviceProviderList={serviceProviderList}
            customerList={customerList}
          />
        );
      case 1:
        return <PackageDetailsForm formik={formik} />;
      case 2:
        return <AddressDetailsForm formik={formik} />;
      case 3:
        return <PaymentDetailsForm formik={formik} />;
      default:
        throw new Error("Unknown Step");
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleReturn = () => {
    setActiveStep(0);
  };
  return (
    <Container sx={{ mt: 5, pb: 10 }}>
      <Paper
        variant="plain"
        sx={{
          backgroundColor: "transparent",
        }}
      >
        <Typography component="h1" varient="h4" align="center">
          Shipping Request
        </Typography>
        <Box sx={{ mt: 5 }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const labelProps = {};
              if (isStepFailed(index)) {
                labelProps.optional = (
                  <Typography variant="caption" color="error">
                    Missing Fields
                  </Typography>
                );

                labelProps.error = true;
              }

              return (
                <Step key={label} sx={{ display: { xs: "none", md: "block" } }}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>

        <React.Fragment>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                Thank you for your shipping request.
              </Typography>
              <Typography variant="subtitle1">
                Your shipping request has been placed. We have emailed your
                shipment confirmation, and will send you an update when your
                order has shipped.
              </Typography>
              <Button onClick={handleReturn} sx={{ mt: 3, ml: 1 }}>
                Return
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Box>{getStepContent(activeStep)}</Box>

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                )}
                {activeStep === steps.length - 1 ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      formik.handleSubmit();
                      formik.isValid
                        ? handleNext()
                        : toast.error(
                            "Please make sure you have filled the form correctly"
                          );
                    }}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    Place Request
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    Next
                  </Button>
                )}
              </Box>
            </React.Fragment>
          )}
        </React.Fragment>
      </Paper>
    </Container>
  );
};

export default CustomShippingRequestForm;
