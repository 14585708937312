import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";
import React from "react";
import { useSuspendCustomerForm } from "../../../hooks/components/form/customer/useSuspendCustomerForm";

const SuspendCustomerForm = ({ open, handleClose, id }) => {
  const { formik } = useSuspendCustomerForm(id);
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      onClose={() => handleClose()}
    >
      <DialogTitle>Suspend Customer</DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          id="reason"
          name="reason"
          label="Reason"
          fullWidth
          multiline
          value={formik.values.reason}
          onChange={formik.handleChange}
          error={formik.touched.reason && Boolean(formik.errors.reason)}
          helperText={formik.touched.reason && formik.errors.reason}
          autoFocus
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button
          onClick={() => {
            formik.handleSubmit();
            handleClose();
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuspendCustomerForm;
