import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import React from "react";
import useCreatePickupForm from "../../../hooks/components/pickup/form/useCreatePickupForm";
import CreatePickupFormItem from "./item/CreatePickupFormItem";

const CreatePickUpForm = ({ open, handleClose }) => {
  const { formik } = useCreatePickupForm({ handleClose });
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>Create Pick up</DialogTitle>
      <Divider />
      <DialogContent>
        <CreatePickupFormItem formik={formik} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatePickUpForm;
