import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import React from "react";
import { useSendCustomerNoticeForm } from "../../../hooks/components/form/customer_notices/useSendCustomerNoticeForm";
import SendCustomerNoticeFormItem from "./items/SendCustomerNoticeFormItem";

const SendCustomerNoticeForm = ({ open, handleClose }) => {
  const { formik } = useSendCustomerNoticeForm({});
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>Send Customer Notice</DialogTitle>
      <Divider />
      <DialogContent>
        <SendCustomerNoticeFormItem formik={formik} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button
          onClick={() => {
            formik.handleSubmit();
            handleClose();
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendCustomerNoticeForm;
