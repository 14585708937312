import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import React from "react";
import { useExportRatesForm } from "../../../../hooks/components/form/export_rates/useExportRatesForm";
import ServiceProviderRateFormItem from "./items/ServiceProviderRateFormItem";

const AddServiceProviderRateForm = ({ open, handleClose, id }) => {
  const { formik } = useExportRatesForm({ id, handleClose });

  return (
    <Dialog open={open} onClose={() => handleClose()} fullWidth>
      <DialogTitle>Add Rate</DialogTitle>
      <Divider />
      <DialogContent>
        <ServiceProviderRateFormItem formik={formik} id={id} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddServiceProviderRateForm;
