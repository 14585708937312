import {Autocomplete, Box, Button, Grid, MenuItem, TextField, Typography} from "@mui/material"
import React from "react"
import {info} from "../../../../../app/utils/info"

const AddAWBFormItem = ({formik, customerList}) => {
    const handleCustomerChange = (value) => {
        formik.setFieldValue("customerId", value.target.value)
        formik.setFieldValue("emails[0]", customerList.find(obj => obj.value === value.target.value).email)
    }
    const {countries} = info()
    return (
        <Box component="form" form={formik} noValidate sx={{mt: 1}}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <TextField
                        required
                        fullWidth
                        value={formik.values.mAwbNumber}
                        onChange={formik.handleChange}
                        error={formik.touched.mAwbNumber && Boolean(formik.errors.mAwbNumber)}
                        helperText={formik.touched.mAwbNumber && formik.errors.mAwbNumber}
                        id="mAwbNumber"
                        label="Master AWB Number"
                        name="mAwbNumber"
                        autoComplete="mAwbNumber"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        fullWidth
                        value={formik.values.airlinesName}
                        onChange={formik.handleChange}
                        error={formik.touched.airlinesName && Boolean(formik.errors.airlinesName)}
                        helperText={formik.touched.airlinesName && formik.errors.airlinesName}
                        id="airlinesName"
                        label="Airlines Name"
                        name="airlinesName"
                        autoComplete="airlinesName"
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        select
                        name="customerId"
                        label="Customer"
                        value={formik.values.customerId}
                        onChange={handleCustomerChange}
                        SelectProps={{
                            style: {
                                color: "text.primary",
                            },
                        }}
                        error={
                            formik.touched.customerId && Boolean(formik.errors.customerId)
                        }
                        helperText={formik.touched.customerId && formik.errors.customerId}
                        fullWidth
                    >
                        {customerList.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                {/*{console.log(formik.values)}*/}
                <Grid item xs={12}>
                    <TextField
                        size="small"
                        margin="dense"
                        type="date"
                        value={formik.values.date}
                        onChange={formik.handleChange}
                        error={formik.touched.date && Boolean(formik.errors.date)}
                        helperText={formik.touched.date && formik.errors.date}
                        InputLabelProps={{shrink: true}}
                        name="date"
                        label="Date"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        value={formik.values.destination}
                        onChange={(event, newValue) => {
                            formik.setFieldValue('destination', newValue?.value)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                required
                                fullWidth
                                error={formik.touched.destination && Boolean(formik.errors.destination)}
                                helperText={formik.touched.destination && formik.errors.destination}
                                id="destination"
                                label="Destination"
                                name="destination"
                                autoComplete="destination"
                            />
                        )}
                        options={countries}
                    />
                    {/*<TextField
                        required
                        fullWidth
                        value={formik.values.destination}
                        onChange={formik.handleChange}
                        error={formik.touched.destination && Boolean(formik.errors.destination)}
                        helperText={formik.touched.destination && formik.errors.destination}
                        id="destination"
                        label="Destination"
                        name="destination"
                        autoComplete="destination"
                    />*/}
                    {/*<TextField
                        required
                        select
                        name="destination"
                        label="Destination"
                        value={formik.values.destination}
                        onChange={formik.handleChange}
                        SelectProps={{
                            style: {
                                color: "text.primary",
                            },
                        }}
                        error={
                            formik.touched.destination && Boolean(formik.errors.destination)
                        }
                        helperText={formik.touched.destination && formik.errors.destination}
                        fullWidth
                    >
                        {countries.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.value}
                            </MenuItem>
                        ))}
                    </TextField>*/}
                </Grid>
                <br/>
                <Grid item xs={12}>
                    <Typography>Add Emails here:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <>
                        {formik.values.emails.map((email, index) => (
                            <div key={index} style={{
                                display: "flex",
                                marginBottom: "1rem",
                            }}>
                                <TextField
                                    style={{flex: 4}}
                                    name={`emails[${index}]`}
                                    type="text"
                                    value={email}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    id={`emails[${index}]`}
                                    label="Email"
                                    error={
                                        formik.touched.emails &&
                                        formik.touched.emails[index] &&
                                        Boolean(formik.errors.emails?.[index])
                                    }
                                    helperText={
                                        formik.touched.emails &&
                                        formik.touched.emails[index] &&
                                        formik.errors.emails?.[index]
                                    }
                                />
                                {index > 0 && (
                                    <Button
                                        variant="contained"
                                        type="button"
                                        onClick={() => {
                                            const updatedEmails = [...formik.values.emails]
                                            updatedEmails.splice(index, 1)
                                            formik.setFieldValue("emails", updatedEmails)
                                        }}
                                    >
                                        Remove
                                    </Button>
                                )}
                            </div>
                        ))}
                        {formik.values.emails.length < 5 && formik.values.emails.every(opt => opt !== "") ? (
                                <Button
                                    variant="contained"
                                    type="button"
                                    onClick={() => {
                                        const updatedEmails = [...formik.values.emails, ""]
                                        formik.setFieldValue("emails", updatedEmails)
                                    }}
                                >
                                    Add Email +
                                </Button>
                            ) :
                            (
                                <Button
                                    variant="contained"
                                    type="button"
                                    disabled
                                >
                                    Add Email +
                                </Button>
                            )
                        }
                    </>
                </Grid>
            </Grid>
        </Box>
    )
}
export default AddAWBFormItem
