import { useFormik } from "formik";
import { useSendBilledShipment } from "../../../../api/shipment/useShipment";
import { sendBillEmailValidationSchema } from "./sendBillEmailValidationSchema";

export const useSendBillEmail = ({ id, handleClose }) => {
  const { mutate } = useSendBilledShipment({});

  const formik = useFormik({
    initialValues: {
      emailTo: "",
      billId: id,
    },
    validationSchema: sendBillEmailValidationSchema,
    onSubmit: (values) => {
      handleConfrim(values);
    },
  });

  const handleConfrim = (values) => {
    mutate(values, { onSuccess: () => handleClose() });
  };
  return { formik };
};
