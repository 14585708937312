import { Autocomplete, Box, Grid, MenuItem, TextField } from "@mui/material";
import { useState } from "react";
import { info } from "../../../../../../app/utils/info";

const packageTypeOptions = [
  { label: "Document", value: "Document" },
  { label: "Non-Document", value: "NonDocument" },
];

const currencyOptions = [
  { label: "Dollar", value: "Dollar" },
  { label: "Nrs", value: "Nrs" },
];

const customerCategoryOptions = [
  { label: "C1", value: "C1" },
  { label: "C2", value: "C2" },
  { label: "M1", value: "M1" },
  { label: "Counter", value: "COUNTER" },
];

const ServiceProviderRateFormItem = ({ formik }) => {
  const { countries } = info();

  const [country, setcountry] = useState(
    countries?.filter((country) => {
      return (
        country?.value?.toUpperCase() === formik.values.country?.toUpperCase()
      );
    })[0] || null
  );

  const [inputcountry, setInputcountry] = useState(
    countries?.filter((country) => {
      return (
        country?.value?.toUpperCase() === formik.values.country?.toUpperCase()
      );
    })[0]?.label || ""
  );

  return (
    <Box component="form" form={formik} noValidate sx={{ mt: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Autocomplete
            value={country}
            onChange={(_event, newValue) => {
              setcountry(newValue);
              formik.setFieldValue("country", newValue?.value);
            }}
            inputValue={inputcountry}
            isOptionEqualToValue={(option, value) => {
              return option.value === value.value;
            }}
            onInputChange={(_event, newInputValue) => {
              setInputcountry(newInputValue);
            }}
            options={countries}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                size="small"
                margin="dense"
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
                label="Destination"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            margin="dense"
            required
            select
            name="currency"
            label="Currency"
            value={formik.values.currency}
            onChange={formik.handleChange}
            error={formik.touched.currency && Boolean(formik.errors.currency)}
            helperText={formik.touched.currency && formik.errors.currency}
            fullWidth
          >
            {currencyOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            margin="dense"
            required
            select
            name="packageType"
            label="Package Type"
            value={formik.values.packageType}
            onChange={formik.handleChange}
            error={
              formik.touched.packageType && Boolean(formik.errors.packageType)
            }
            helperText={formik.touched.packageType && formik.errors.packageType}
            fullWidth
          >
            {packageTypeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            select
            value={formik.values.customerCategory}
            onChange={formik.handleChange}
            error={
              formik.touched.customerCategory &&
              Boolean(formik.errors.customerCategory)
            }
            helperText={
              formik.touched.customerCategory && formik.errors.customerCategory
            }
            id="customerCategory"
            label="Customer Category"
            name="customerCategory"
          >
            {customerCategoryOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            margin="dense"
            required
            name="weight"
            label="Weight"
            type="number"
            value={formik.values.weight}
            onChange={formik.handleChange}
            error={formik.touched.weight && Boolean(formik.errors.weight)}
            helperText={formik.touched.weight && formik.errors.weight}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            margin="dense"
            required
            name="rate"
            label="Rate"
            type="number"
            value={formik.values.rate}
            onChange={formik.handleChange}
            error={formik.touched.rate && Boolean(formik.errors.rate)}
            helperText={formik.touched.rate && formik.errors.rate}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
              size="small"
              margin="dense"
              name="emergencySurcharge"
              label="Emergency SurCharge"
              type="number"
              value={formik.values.emergencySurcharge}
              onChange={formik.handleChange}
              error={formik.touched.emergencySurcharge && Boolean(formik.errors.emergencySurcharge)}
              helperText={formik.touched.emergencySurcharge && formik.errors.emergencySurcharge}
              fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServiceProviderRateFormItem;
