import * as Yup from "yup";

export const paymentValidationSchema = Yup.object().shape({
  customerId: Yup.number().required("Required"),
  amount: Yup.number()
    .min(0, "Minimum amount shouldnot exceed 0")
    .required("Required"),
  date: Yup.date().required("Required"),
  receiptNumber: Yup.string(),
  description: Yup.string(),
});
