import MaterialTable from "@material-table/core";
import React from "react";
import { useCustomerInfoConfigTable } from "../../../hooks/components/customer-info/table/useCustomerInfoConfigTable";
import AddCustomerInfoConfigForm from "../form/AddCustomerInfoConfigForm";
import AddIcon from "@mui/icons-material/Add";
import UpdateCustomerInfoConfigForm from "../form/UpdateCustomerInfoConfigForm";
import { EditOutlined } from "@mui/icons-material";

const CustomerInfoConfigTable = () => {
  const {
    data,
    isLoading,
    columns,
    open,
    handleClose,
    handleOpen,
    openEdit,
    handleCloseEdit,
    handleOpenEdit,
  } = useCustomerInfoConfigTable({});
  return (
    <>
      <MaterialTable
        data={data}
        isLoading={isLoading}
        columns={columns}
        title="List"
        options={{
          padding: "dense",
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: () => <AddIcon />,
            tooltip: `Add Customer info config`,
            isFreeAction: true,
            onClick: () => handleOpen(),
          },
          (rowData) => ({
            icon: () => <EditOutlined />,
            tooltip: "Edit",
            onClick: () => {
              handleOpenEdit(rowData);
            },
          }),
        ]}
      />
      <AddCustomerInfoConfigForm open={open} handleClose={handleClose} />
      <UpdateCustomerInfoConfigForm
        open={openEdit?.open}
        handleClose={handleCloseEdit}
        data={openEdit?.data}
      />
    </>
  );
};

export default CustomerInfoConfigTable;
