import { axiosInstance } from "../../../app/api/axiosInterceptor";

export const getPublicServiceProviders = async () => {
  const { data } = await axiosInstance.get("/shipment-providers");
  return data;
};

export const calculateShipmentRate = async (values) => {
  if (values) {
    const { data } = await axiosInstance.post("/calculate-rate", values);
    return data;
  }
};

export const getServiceProviderRates = async () => {
  const { data } = await axiosInstance.get("/shipment-providers");
  return data;
};

export const getShipmentProviderCountries = async (id) => {
  if (id) {
    const { data } = await axiosInstance.get(
      `/shipment-provider/countries?shipmentProviderId=${id}`
    );
    return data;
  }
};
