import { useFormik } from "formik";

import { zoneCountryValidationSchema } from "./zoneCountryValidationSchema";
import { useAddZone } from "../../../../api/service_provider/zones/useZones";

const useAddZoneForm = ({ id, handleClose, selectedOptions }) => {
  const { mutate } = useAddZone({});
  const handleCreate = (values) => {
    let countries = selectedOptions?.map((item) => item.value);
    values = {
      ...values,
      countries: countries,
      shipmentProviderId: id,
    };
    mutate(values, {
      onSuccess: () => {
        handleClose();
      },
    });
  };
  const formik = useFormik({
    initialValues: {
      zone: "",
    },
    validationSchema: zoneCountryValidationSchema,
    onSubmit: (values) => {
      handleCreate(values);
    },
  });
  return { formik };
};

export default useAddZoneForm;
