import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import SuperLayout from "../app/layout/SuperLayout";
import AppLayout from "../app/layout/AppLayout";
import Login from "../auth/pages/login/Login";
import Register from "../auth/pages/register/Register";
import TempPassword from "../auth/pages/tempPassword/TempPassword";
import Home from "../landing_page/pages/home/Index";
import Ship from "../customer/pages/ship/Ship";
import Track from "../customer/pages/track/Track";
import ProtectedRoute from "./ProtectedRoute";
import Admin from "../super/pages/admin/Admin";
import ServiceProvider from "../admin/pages/service_provider/ServiceProvider";
import NotFound from "../app/pages/NotFound";
import Customer from "../admin/pages/customer/Customer";
import OfficeNotices from "../admin/pages/office_notice/OfficeNotices";
import LoggedInRoutes from "./LoggedInRoutes";
import MyShipmentRequests from "../customer/pages/ship/MyShipmentRequests";
import MyShipments from "../customer/pages/ship/MyShipments";
import OfficeNoticesDetailPage from "../admin/pages/office_notice/detail/OfficeNoticesDetailPage";
import Settings from "../admin/pages/settings/Settings";
import CustomerNotice from "../admin/pages/customer_notice/CustomerNotice";
import CustomerNoticesDetailPage from "../admin/pages/customer_notice/detail/CustomerNoticesDetailPage";
import CustomerDetailPage from "../admin/pages/customer/detail/CustomerDetailPage";
import MyShipmentDetailPage from "../customer/pages/ship/detail/MyShipmentDetailPage";
import AdminDashboard from "../admin/pages/dashboard/AdminDashboard";
import Dashboard from "../customer/pages/dashboard/Dashboard";
import { ServiceProviderDetailPage } from "../admin/pages/service_provider/detail/ServiceProviderDetailPage";
import { Customshipment } from "../admin/pages/custom_shipment/Customshipment";
import AddShipmentLite from "../admin/pages/nepal_lite/AddShipmentLite";
import ShipmentTable from "../admin/pages/nepal_lite/Shipment/ShipmentTable";
import EditShipmentLite from "../admin/pages/nepal_lite/EditShipmentLite";
import UpdateConfirmShipmentLite from "../admin/pages/nepal_lite/UpdateConfirmShipmentLite";
import UpdateShipmentBillingForm from "../admin/components/form/nepal_lite/update_shipment/UpdateShipmentBillingForm";
import PickUp from "../admin/pages/pickup/PickUp";
import { PickupDetail } from "../admin/pages/pickup/detail/PickupDetail";
import Shipments from "../admin/pages/shipment-final/Shipments";
import ShipmentDetail from "../admin/pages/shipment-final/detail/ShipmentDetail";
import Reports from "../admin/pages/reports/Reports";
import About from "../landing_page/pages/about/About";
import Services from "../landing_page/pages/services/Services";
import Contact from "../landing_page/pages/contact/Contact";
import BilledShipments from "../admin/pages/billed_shipments/BilledShipments";
import BilledShipmentDetail from "../admin/pages/billed_shipments/detail/BilledShipmentDetail";
import MyPickup from "../customer/pages/pickup/MyPickup";
import ZoneDetail from "../admin/pages/service_provider/detail/zone/ZoneDetail";
import Payment from "../admin/pages/payments/Payment";
import Payments from "../customer/pages/payments/Payments";
import NepalExInfo from "../customer/pages/nepalex-info/NepalExInfo";
import MyBillDetails from "../customer/pages/payments/bill-details/MyBillDetails";
import MasterAWB from "../admin/pages/nepal_lite/Shipment/MasterAWB/MasterAWB"
// import MyBillDetails from "../customer/pages/payments/bill-details/MyBillDetails";
const AppRoute = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<AppLayout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="services" element={<Services />} />
          <Route path="contact" element={<Contact />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="*" element={<NotFound />} />
        </Route>

        <Route path="/signup/">
          <Route path="super" element={<Register superSignUp={true} />} />
        </Route>

        <Route path="/" element={<ProtectedRoute redirectTo="/login" />}>
          <Route element={<SuperLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="track" element={<Track />} />
            <Route path="shipping-request" element={<Ship />} />
            <Route
              path="my-shipment-requests"
              element={<MyShipmentRequests />}
            />
            <Route path="my-shipments" element={<MyShipments />} />
            <Route path="my-pickups" element={<MyPickup />} />
            <Route path="payments" element={<Payments />} />
            <Route path="nepalex-info" element={<NepalExInfo />} />
            <Route path="settings" element={<Settings />} />

            <Route path="my-shipments/:id" element={<MyShipmentDetailPage />} />
            <Route path="bill/:id" element={<MyBillDetails />} />
            {/* <Route
              path="/confirmed-shipment-lite"
              element={<ConfirmedShipments />}
            />
            <Route path="/accounts" element={<AccountsShipments />} />
            <Route path="/all-shipments" element={<AllShipments />} /> */}

            {/* <Route path="/all-shipments/:id" element={<AllShipmentDetails />} /> */}
          </Route>
        </Route>

        <Route element={<ProtectedRoute redirectTo="/login" />}>
          <Route path="temp-password" element={<TempPassword />} />
          <Route path="/super" element={<SuperLayout />}>
            <Route
              element={<LoggedInRoutes redirectTo="/404" isRole="SysAdmin" />}
            >
              <Route path="dashboard" element={<AdminDashboard />} />
              <Route path="settings" element={<Settings />} />
              <Route path="admin" element={<Admin />} />
              <Route path="pickup" element={<PickUp />} />
              <Route path="service-provider" element={<ServiceProvider />} />
              <Route path="customer" element={<Customer />} />
              <Route path="office-notices" element={<OfficeNotices />} />
              <Route path="customer-notices" element={<CustomerNotice />} />
              <Route path="custom-shipment" element={<Customshipment />} />
              <Route path="add-shipment-lite" element={<AddShipmentLite />} />
              <Route path="master-awb" element={<MasterAWB />} />
              <Route path="shipment-lite" element={<ShipmentTable />} />
              <Route path="payments" element={<Payment />} />
              <Route
                path="final-shipments"
                element={<Shipments billed={false} />}
              />
              <Route
                path="billed-shipments"
                element={<Shipments billed={true} />}
              />
              <Route path="bill" element={<BilledShipments />} />
              <Route path="reports" element={<Reports />} />
              <Route
                path="office-notices/:id"
                element={<OfficeNoticesDetailPage />}
              />
              <Route
                path="service-provider/:id"
                element={<ServiceProviderDetailPage />}
              />
              <Route
                path="service-provider/:id/:zone"
                element={<ZoneDetail />}
              />
              <Route
                path="customer-notices/:id"
                element={<CustomerNoticesDetailPage />}
              />
              <Route path="customer/:id" element={<CustomerDetailPage />} />
              <Route
                path="update/shipment-billing/:id"
                element={<UpdateShipmentBillingForm />}
              />
              <Route path="pickup/:id" element={<PickupDetail />} />
              <Route path="shipment-:id" element={<ShipmentDetail />} />

              <Route
                path="edit-shipment-lite/:id"
                element={<EditShipmentLite />}
              />
              <Route
                path="edit-confirmshipment-lite/:id"
                element={<UpdateConfirmShipmentLite />}
              />
              <Route path="bill/:id" element={<BilledShipmentDetail />} />
            </Route>
          </Route>
          <Route path="/admin" element={<SuperLayout />}>
            <Route
              element={<LoggedInRoutes redirectTo="/404" isRole="Admin" />}
            >
              <Route path="dashboard" element={<AdminDashboard />} />
              <Route path="admin-list" element={<Admin />} />
              <Route path="service-provider" element={<ServiceProvider />} />
              <Route path="customer" element={<Customer />} />
              <Route path="office-notices" element={<OfficeNotices />} />
              <Route path="settings" element={<Settings />} />
              <Route path="customer-notices" element={<CustomerNotice />} />
              <Route path="custom-shipment" element={<Customshipment />} />
              <Route path="pickup" element={<PickUp />} />
              <Route path="add-shipment-lite" element={<AddShipmentLite />} />
              <Route path="shipment-lite" element={<ShipmentTable />} />
              <Route path="payments" element={<Payment />} />
              <Route
                path="final-shipments"
                element={<Shipments billed="All" />}
              />
              <Route path="bill" element={<BilledShipments />} />
              <Route path="reports" element={<Reports />} />

              <Route
                path="office-notices/:id"
                element={<OfficeNoticesDetailPage />}
              />
              <Route
                path="customer-notices/:id"
                element={<CustomerNoticesDetailPage />}
              />
              <Route path="customer/:id" element={<CustomerDetailPage />} />
              <Route
                path="service-provider/:id"
                element={<ServiceProviderDetailPage />}
              />
              <Route
                path="service-provider/:id/:zone"
                element={<ZoneDetail />}
              />
              <Route path="pickup/:id" element={<PickupDetail />} />
              <Route path="shipment-:id" element={<ShipmentDetail />} />

              <Route
                path="edit-shipment-lite/:id"
                element={<EditShipmentLite />}
              />
              <Route
                path="edit-confirmshipment-lite/:id"
                element={<UpdateConfirmShipmentLite />}
              />
              <Route path="bill/:id" element={<BilledShipmentDetail />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoute;
