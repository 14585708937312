import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import Link from "@mui/material/Link";
import { useLoginForm } from "../../../hooks/components/login/useLoginForm";
import ForgetPasswordForm from "../forgetpassword/ForgetPasswordForm";
import { LoadingButton } from "@mui/lab";

const LoginForm = () => {
  const {
    formik,
    showValues,
    loading,
    handleClickShowPassword,
    handleMouseDownPassword,
  } = useLoginForm();

  const [open, setOpen] = React.useState(false);

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: 20,
        paddingBottom: 40,
      }}
    >
      <img
        src="/assests/nepal-express-parcel.svg"
        alt="NepalEx"
        height="100px"
        style={{
          marginBottom: "20px",
        }}
      />
      <Box component="form" noValidate sx={{ mt: 1 }}>
        <TextField
          required
          value={formik.values.username}
          onChange={formik.handleChange}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
          name="username"
          label="Username"
          fullWidth
          variant="outlined"
          type="text"
        />

        <TextField
          variant="outlined"
          label="Password"
          name="password"
          autoComplete="current-password"
          fullWidth
          value={formik.values.password}
          onChange={formik.handleChange}
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              formik.handleSubmit();
              ev.preventDefault();
            }
          }}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          type={showValues.showPassword ? "text" : "password"}
          sx={{ minWidth: "10vw", mt: 1 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showValues.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <LoadingButton
          fullWidth
          onClick={() => formik.submitForm()}
          variant="contained"
          loading={loading}
          sx={{ mt: 2, mb: 2, textTransform: "none", fontWeight: 600 }}
        >
          Login
        </LoadingButton>
        <Grid container>
          <Grid item xs>
            <Link
              href="#"
              variant="body2"
              aria-haspopup="true"
              aria-controls="ringtone-menu"
              aria-label="phone ringtone"
              onClick={handleClickListItem}
            >
              Forgot password?
            </Link>
            <ForgetPasswordForm
              id="ringtone-menu"
              keepMounted
              open={open}
              onClose={handleClose}
            />
          </Grid>
          <Grid item>
            <Link href="/register" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LoginForm;
