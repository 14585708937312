import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import {
  faCircleDollarToSlot,
  faCoins,
  faIndianRupee,
  faNoteSticky,
} from "@fortawesome/free-solid-svg-icons";
import DetailTypography from "../../../../app/component/detail_view/DetailTypography";
import styled from "@emotion/styled";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f7f7f7;
  }

  &:last-of-type {
    td {
      border-bottom: none;
    }
  }
`;

const MyPaymentDetail = ({ data }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <FontAwesomeIcon size="lg" icon={faCircleDollarToSlot} />
        <Typography sx={{ width: "50%", flexShrink: 0, ml: 3 }}>
          Payment Details
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={4}>
            <DetailTypography
              icon={<FontAwesomeIcon size="lg" icon={faCoins} />}
              title="Currency"
              name={data?.currency}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <DetailTypography
              icon={<FontAwesomeIcon size="lg" icon={faIndianRupee} />}
              title="Exchange Rate"
              name={data?.exchangeRate}
            />
          </Grid>
          <Grid item xs={12}>
            <DetailTypography
              icon={<FontAwesomeIcon size="lg" icon={faNoteSticky} />}
              title="Other Notes"
              name={data?.otherDetails}
            />
          </Grid>
          {data?.rateAdded ? (
            <Grid item xs={12}>
              <Table sx={{ border: "1px solid", marginBottom: "16px" }}>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Details</StyledTableCell>
                    <StyledTableCell>Price (Rs.)</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  <StyledTableCell>Freight : </StyledTableCell>
                  <TableCell>
                    {data?.freight?.toLocaleString("en-US")}
                  </TableCell>
                </TableBody>
                <TableBody>
                  <StyledTableCell>TIA : </StyledTableCell>
                  <TableCell>{data?.tia?.toLocaleString("en-US")}</TableCell>
                </TableBody>
                <TableBody>
                  <StyledTableCell>Strip : </StyledTableCell>
                  <TableCell>{data?.strip?.toLocaleString("en-US")}</TableCell>
                </TableBody>
                <TableBody>
                  <StyledTableCell>remote : </StyledTableCell>
                  <TableCell>{data?.remote?.toLocaleString("en-US")}</TableCell>
                </TableBody>
                <TableBody>
                  <StyledTableCell>Oversize : </StyledTableCell>
                  <TableCell>
                    {data?.oversize?.toLocaleString("en-US")}
                  </TableCell>
                </TableBody>
                <TableBody>
                  <StyledTableCell>Others : </StyledTableCell>
                  <TableCell>{data?.others?.toLocaleString("en-US")}</TableCell>
                </TableBody>
              </Table>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default MyPaymentDetail;
