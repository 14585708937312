import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";
import React from "react";
import { useRestoreCustomerForm } from "../../../hooks/components/form/customer/useResotreCustomerForm";

const RestoreCustomerForm = ({ open, handleClose, id }) => {
  const { formik } = useRestoreCustomerForm(id);
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      onClose={() => handleClose()}>
      <DialogTitle>Restore Customer</DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          id="reason"
          name="reason"
          label="Reason"
          fullWidth
          multiline
          value={formik.values.reason}
          onChange={formik.handleChange}
          error={formik.touched.reason && Boolean(formik.errors.reason)}
          helperText={formik.touched.reason && formik.errors.reason}
          autoFocus
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button
          onClick={() => {
            formik.handleSubmit();
            handleClose();
          }}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RestoreCustomerForm;
