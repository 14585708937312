import { axiosInstance } from "../../../app/api/axiosInterceptor";

export const getMyPickUps = async () => {
  const { data } = await axiosInstance.get("/v/pickups");
  return data;
};

export const createPickup = async (values) => {
  const { data } = await axiosInstance.post("/v/pickup", values);
  return data;
};
