import React, { useContext, useState } from "react";
import CustomTable from "../../../../app/component/table/CustomTable";
import { UserContext } from "../../../../app/contexts/user/UserContext";
import { useAdminTable } from "../../../hooks/components/admin/table/useAdminTable";
import AddAdminForm from "../form/AddAdminForm";
import EditAdminForm from "../form/EditAdminForm";

const AdminTable = () => {
  const { role } = useContext(UserContext);
  const [open, setOpen] = useState({ add: false, edit: false });
  const [editData, setEditData] = useState();

  const handleClose = () => {
    setOpen({ add: false, edit: false });
  };

  const handleOpen = () => {
    setOpen({ edit: false, add: true });
  };

  const handleEdit = (rowData) => {
    setEditData(rowData);
    setOpen({ edit: true, add: false });
  };
  const isSysAdmin = role === "SysAdmin" ? true : false;
  const { columns, data, handleChange } = useAdminTable();
  return (
    <>
      <CustomTable
        title="Admin"
        columns={columns}
        data={data}
        action={isSysAdmin ? true : false}
        activeAction={isSysAdmin ? true : false}
        isCustom={isSysAdmin ? true : false}
        handleOpen={handleOpen}
        handleEdit={handleEdit}
        editAction={isSysAdmin ? true : false}
        handleChange={isSysAdmin && handleChange}
        addAction={isSysAdmin ? true : false}
      />
      <EditAdminForm
        open={open?.edit}
        handleClose={handleClose}
        data={editData}
      />
      <AddAdminForm open={open?.add} handleClose={handleClose} />
    </>
  );
};

export default AdminTable;
