import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  getOfficeNoticeDetail,
  getOfficeNotices,
  sendCustomerNotice,
  sendOfficeNotice,
} from "../../../api/office_notices/officenotices-api";

export const useGetOfficeNotices = () => {
  return useQuery(["getOfficeNotices"], getOfficeNotices, {
    cacheTime: 10000,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetOfficeNoticeDetail = (id) => {
  return useQuery(
    ["getOfficeNoticeDetail", id],
    () => getOfficeNoticeDetail(id),
    {
      cacheTime: 10000,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSendOfficeNotice = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(
    ["sendOfficeNotice"],
    (formData) => sendOfficeNotice(formData),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["getOfficeNotices"]);
        toast.success("Succesfully sent office notice");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};

export const useSendCustomerNotice = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(
    ["sendOfficeNotice"],
    (formData) => sendCustomerNotice(formData),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["getOfficeNotices"]);
        toast.success("Succesfully sent customer notice");
        onSuccess && onSuccess(data, variables, context);
      },
      onError: (err, _variables, _context) => {
        toast.error(`error: ${err.message}`);
      },
    }
  );
};
