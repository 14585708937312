import { useFormik } from "formik";
import { useSendOfficeNotice } from "../../../api/office_notices/useOfficeNotices";
import { sendOfficeNoticeValidationSchema } from "./sendOfficeNoticeValidationSchema";

export const useSendOfficeNoticeForm = () => {
  const { mutate } = useSendOfficeNotice({});
  const formik = useFormik({
    initialValues: {
      important: false,
      message: "",
      subject: "",
    },
    validationSchema: sendOfficeNoticeValidationSchema,
    onSubmit: (values) => {
      handleUpdate(values);
    },
  });

  const handleUpdate = (values) => {
    mutate(values);
  };
  return { formik };
};
