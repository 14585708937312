export const rateReducer = (state, action) => {
  switch (action.type) {
    case "SET_RATE":
      let data = action.payload;
      return {
        plasticStrip: data?.plasticStrip || 0,
        ironStrip: data?.ironStrip || 0,
        remote: data?.remote || 0,
        tiaCourier: data?.tiaCourier || 0,
        tiaCargo: data?.tiaCargo || 0,
        customClearance: data?.customClearance || 0,
        minCustomCharge: data?.minCustomCharge || 0,
        minTiaForCourierCommercial: data?.minTiaForCourierCommercial || 0,
        minTiaForCargo: data?.minTiaForCargo || 0,
        oversize: data?.oversize || 0,
      };
    default:
      return state;
  }
};
