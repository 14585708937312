import React from "react";
import ShippingRequestForm from "../../components/ship/form/ShippingRequestForm";

const Ship = () => {
  return (
    <div>
      <ShippingRequestForm />
    </div>
  );
};

export default Ship;
