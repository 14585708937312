import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  deleteNotifications,
  getUserNotifications,
} from "../../../api/notification/notification-api";
import { getUser } from "../../../utils/cookieHelper";

export const useGetUserNotifications = () => {
  const { role } = getUser();
  return useQuery(["getUserNotifications"], () => getUserNotifications(role), {
    refetchInterval: 300000,
    cacheTime: 300000,
    retry: 1,
    refetchOnWindowFocus: false,
  });
};

export const useDeleteNotification = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  return useMutation(["deleteNotification"], (id) => deleteNotifications(id), {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("getUserNotifications");
      onSuccess && onSuccess(data, variables, context);
    },
    onError: (err, _variables, _context) => {
      toast.error(`error: ${err.message}`);
    },
  });
};
