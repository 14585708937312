import { useState } from "react";
import { useAddAdditionalReceivable } from "../../../api/payment/usePayment";
import { useGetCustomers } from "../../../api/custom_shipment/useCustomShipment";
import { useMemo } from "react";
import { useFormik } from "formik";
import { addAdditionalReceivableValidationSchema } from "./addAdditionalReceivableValidationScheme";

export const useAddAdditionalReceivableForm = ({ handleClose }) => {
  const [customer, setCustomer] = useState({ id: null, input: "" });
  const { mutate } = useAddAdditionalReceivable({});
  const { data: customerData, isLoading: customerLoading } = useGetCustomers();
  const customerList =
    customerData &&
    customerData?.map((customer) => {
      return {
        id: customer?.id,
        email: customer?.email,
        label:
          customer?.companyName ||
          customer?.firstName + " " + customer?.lastName,
      };
    });

  const customerOptionList = useMemo(() => customerList, [customerList]);

  const customerLabelOptions = useMemo(
    () => (option, i) => `${option.label} : ${option.email}`,
    []
  );
  const formik = useFormik({
    initialValues: {
      customerId: 0,
      amount: null,
      reason: "",
    },
    validationSchema: addAdditionalReceivableValidationSchema,
    onSubmit: (values) => {
      handleCreate(values);
    },
  });

  const handleCustomer = (values) => {
    setCustomer(values);
  };

  const handleCreate = (values) => {
    values = {
      ...values,
      customerId: values?.customerId === 0 ? null : values?.customerId,
    };
    mutate(values, {
      onSuccess: () => {
        handleClose();
        formik.handleReset();
        setCustomer({ id: null, input: "" });
      },
    });
  };
  return {
    formik,
    customer,
    handleCustomer,
    customerOptionList,
    customerLabelOptions,
    customerLoading,
  };
};
