import { Box, Grid, TextField } from "@mui/material";
import React from "react";

const CustomerPaymentFormItem = ({ formik }) => {
  return (
    <Box component="form" form={formik} noValidate sx={{ mt: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            name="amount"
            label="Amount"
            required
            type="number"
            fullWidth
            value={formik.values.amount}
            onChange={formik.handleChange}
            error={formik.touched.amount && Boolean(formik.errors.amount)}
            helperText={formik.touched.amount && formik.errors.amount}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="date"
            label="Date"
            type="date"
            required
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={formik.values.date}
            onChange={formik.handleChange}
            error={formik.touched.date && Boolean(formik.errors.date)}
            helperText={formik.touched.date && formik.errors.date}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="receiptNumber"
            label="Receipt Number"
            fullWidth
            value={formik.values.receiptNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.receiptNumber &&
              Boolean(formik.errors.receiptNumber)
            }
            helperText={
              formik.touched.receiptNumber && formik.errors.receiptNumber
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="description"
            label="Description"
            multiline
            fullWidth
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerPaymentFormItem;
