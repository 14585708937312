import { useFormik } from "formik";
import moment from "moment";
import { useState } from "react";
import { useCreatePickup } from "../../../api/pickup/usePickup";
import { pickupValidationSchema } from "./pickupValidationSchema";

export const useCreatePickupForm = ({ handleClose }) => {
  const [adminId, setAdminId] = useState(null);
  const [inputAdmin, setInputAdmin] = useState("");
  const { mutate } = useCreatePickup({});
  const formik = useFormik({
    initialValues: {
      adminId: 0,
      cnNumber: "",
      dateTime: "",
      partyName: "",
      phoneNumber: "",
      status: "Pending",
      pickupAddress: "",
      remarks: "",
      vehicle: "",
    },
    validationSchema: pickupValidationSchema,
    onSubmit: (values) => {
      values = {
        ...values,
        dateTime: moment(values?.dateTime).format("YYYY-MM-DD HH:mm"),
      };
      handleCreate(values);
    },
  });

  const handleCreate = (values) => {
    values = {
      ...values,
      adminId: values?.adminId === 0 ? null : values?.adminId,
    };
    mutate(values, {
      onSuccess: () => {
        handleClose();
        formik.handleReset();
        setAdminId(null);
        setInputAdmin("");
      },
    });
  };
  return { formik, adminId, setAdminId, inputAdmin, setInputAdmin };
};
