import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useBillingShipmentsForm } from "../../../hooks/components/form/shipments/bill/useBillingShipmentsForm";

function BillShipmentsForm({ open, handleClose, data }) {
  const { formik } = useBillingShipmentsForm({ data, handleClose });
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>Bill Shipment Form</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Reference"
              name="billingSequence"
              fullWidth
              required
              value={formik.values.billingSequence}
              onChange={formik.handleChange}
              error={
                formik.touched.billingSequence &&
                Boolean(formik.errors.billingSequence)
              }
              helperText={
                formik.touched.billingSequence && formik.errors.billingSequence
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4} sm={3}>
                <Typography variant="subtitle2">Total Freight :</Typography>
              </Grid>
              <Grid item xs={8} sm={3}>
                <Typography>{data?.freightTotal || "-"}</Typography>
              </Grid>
              <Grid item xs={4} sm={3}>
                <Typography variant="subtitle2">Total tia : </Typography>
              </Grid>
              <Grid item xs={8} sm={3}>
                {data?.tiaTotal || "-"}
              </Grid>
              <Grid item xs={4} sm={3}>
                <Typography variant="subtitle2">Total Customs :</Typography>
              </Grid>
              <Grid item xs={8} sm={3}>
                <Typography>{data?.customsTotal || "-"}</Typography>
              </Grid>
              <Grid item xs={4} sm={3}>
                <Typography variant="subtitle2">Total Doc : </Typography>
              </Grid>
              <Grid item xs={8} sm={3}>
                {data?.docTotal || "-"}
              </Grid>
              <Grid item xs={4} sm={3}>
                <Typography variant="subtitle2">Total Strip :</Typography>
              </Grid>
              <Grid item xs={8} sm={3}>
                <Typography>{data?.stripTotal || "-"}</Typography>
              </Grid>
              <Grid item xs={4} sm={3}>
                <Typography variant="subtitle2">Total Vat : </Typography>
              </Grid>
              <Grid item xs={8} sm={3}>
                {data?.vatTotal || "-"}
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">Grand Total : </Typography>
              </Grid>
              <Grid item xs={8}>
                {data?.grandTotal || "-"}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={() => handleClose()}
          sx={{ textTransform: "none" }}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          sx={{ textTransform: "none" }}
          variant="contained"
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BillShipmentsForm;
