import { useFormik } from "formik";

import {
  useCreateCustomShipmentRequest,
  useGetCustomers,
  useGetServiceProviders,
} from "../../../api/custom_shipment/useCustomShipment";
import { customShipmentRequestSchema } from "./customShipmentFormValidationSchema";

const useCustomShipmentRequestForm = () => {
  const { data: serviceProviderData } = useGetServiceProviders();
  const { data: customerData } = useGetCustomers();

  const serviceProviderList =
    serviceProviderData &&
    serviceProviderData?.map((serviceProvider) => {
      return { value: serviceProvider?.id, label: serviceProvider?.name };
    });

  const customerList =
    customerData &&
    customerData?.map((customer) => {
      return {
        value: customer?.id,
        label: customer?.firstName + " " + customer?.lastName,
      };
    });
  const { mutate } = useCreateCustomShipmentRequest({});

  const formik = useFormik({
    initialValues: {
      importExport: "Export",
      shipmentService: "Courier",
      serviceProviderId: 0,
      customerId: 0,
      customClearanceProvider: "NepalX",
      doorAirport: "AirportToAirport",
      pickupDateTime: "",

      consignee: "",
      destinationCountry: "",
      houseNumber: "",
      street: "",
      city: "",
      fullPickupAddress: "",
      postalCode: "",
      remoteArea: false,

      numberOfBoxes: null,
      numberOfOversizeBoxes: 0,
      ironStrips: 0,
      plasticStrips: 0,
      actualWeight: null,
      dimensionalWeight: 0,
      description: "",
      contentType: "",
      packageType: "",

      parcelValue: 0,
      currency: "",
      exchangeRate: "",
      paymentMethod: "BankTransfer",
      otherNotes: "",
    },
    validationSchema: customShipmentRequestSchema,
    onSubmit: (values) => {
      handleRequest(values);
      formik.handleReset();
    },
  });

  const handleRequest = (values) => {
    values = {
      ...values,
    };
    mutate(values, formik);
  };

  return {
    formik,
    serviceProviderList,
    customerList,
  };
};

export default useCustomShipmentRequestForm;
