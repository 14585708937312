import { Button, Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../../../../../app/contexts/user/UserContext";
import EditIcon from "@mui/icons-material/Edit";
import UpdateShipmentStatus from "../../../../../components/form/shipment/UpdateShipmentStatus";

const ShipmentConditionDetails = ({
  shipmentType,
  customClearance,
  status,
  serviceProvider,
  service,
  shipper,
  awb,
  nepalexTrackingNumber,
  importExport,
  id,
}) => {
  const [open, setOpen] = useState(false);
  function handleClose() {
    setOpen(false);
  }

  const { role } = useContext(UserContext);
  return (
    <Paper
      elevation={4}
      sx={{
        ml: { sm: 1, md: 2 },
        mr: { sm: 1, md: 2 },
        mt: 1,
        mb: 1,
        p: "16px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "24px",
            }}
          >
            Shipment Details
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={5}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "24px",
            }}
          >
            Awb :
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "24px",
            }}
          >
            {serviceProvider?.toUpperCase()?.includes("DHL") ? (
              <a
                href={`https://www.dhl.com/np-en/home/tracking/tracking-express.html?submit=1&tracking-id=${awb}`}
                target="_blank"
                rel="noreferrer"
              >
                {nepalexTrackingNumber}
              </a>
            ) : serviceProvider?.toUpperCase().includes("FEDEX") ? (
              <a
                href={`https://www.fedex.com/fedextrack/?trknbr=${awb}`}
                target="_blank"
                rel="noreferrer"
              >
                {nepalexTrackingNumber}
              </a>
            ) : (
              nepalexTrackingNumber
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Link
            to={`/${
              role === "SysAdmin" ? "super" : "admin"
            }/edit-confirmshipment-lite/${id}`}
            style={{ textDecoration: "none" }}
          >
            <Button fullWidth variant="outlined" sx={{ textTransform: "none" }}>
              Edit
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="subtitle2">Type :</Typography>
          <Typography>{shipmentType}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="subtitle2">Custom Clearance :</Typography>
          <Typography>{customClearance}</Typography>
        </Grid>
        <Grid item sm={12} md={4}>
          <Typography variant="subtitle2">Status :</Typography>
          <Button
            onClick={() => setOpen(true)}
            variant="outlined"
            sx={{
              display: "flex",
              alignItems: "center",
              textTransform: "none",
            }}
          >
            {status}
            <EditIcon color="primary" sx={{ ml: 1 }} />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mb: 1 }} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "18px",
            }}
          >
            Service Details
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="subtitle2">Service Provider :</Typography>
          <Typography>{serviceProvider}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="subtitle2">Service :</Typography>
          <Typography>{service}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="subtitle2">Reference :</Typography>
          <Typography>{awb}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="subtitle2">Import/Export :</Typography>
          <Typography>{importExport}</Typography>
        </Grid>
      </Grid>
      <UpdateShipmentStatus
        open={open}
        handleClose={handleClose}
        data={{ id: id, shipper: shipper, awb: awb }}
      />
    </Paper>
  );
};

export default ShipmentConditionDetails;
