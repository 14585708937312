import React, { useContext, useState } from "react";
import { UserContext } from "../../contexts/user/UserContext";
import { Link, useLocation } from "react-router-dom";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem as Menu,
  Tooltip,
} from "@mui/material";
import { supermenu } from "./super/supermenu";
import { hasChildren } from "../../utils/utils";
import { adminmenu } from "./admin/adminmenu";
import { customermenu } from "./customer/customermenu";

const MenuItem = ({ item }) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} />;
};

const SingleLevel = ({ item }) => {
  let location = useLocation();
  return (
    <Menu
      type="button"
      component={Link}
      id={item?.to === location.pathname ? "active" : "false"}
      to={item.to || "/404"}
      sx={{ paddingBottom: "1vh", minHeight: "30px" }}
    >
      <Tooltip title={item.title} placement="right-start">
        <ListItemIcon
          id={item?.to === location.pathname ? "active" : "false"}
          sx={{
            color: "text.white",
            minWidth: 0,
            mr: 3,
            justifyContent: "center",
          }}
        >
          {item.icon}
        </ListItemIcon>
      </Tooltip>

      <ListItemText
        id={item?.to === location.pathname ? "active" : "false"}
        primary={item.title}
        sx={{
          color: "text.white",
          fontWeight: "600",
          fontSize: "20px",
          fontFamily: "Inter",
        }}
      />
    </Menu>
  );
};

const MultiLevel = ({ item }) => {
  const { items: children } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <React.Fragment>
      <ListItem
        button
        onClick={handleClick}
        sx={{ ml: 0.5, minHeight: "30px" }}
      >
        <Tooltip title={item.title} placement="right-start">
          <ListItemIcon
            sx={{
              color: "text.white",
              minWidth: 0,
              mr: 4,
              justifyContent: "center",
            }}
          >
            {item.icon}
          </ListItemIcon>
        </Tooltip>

        <ListItemText
          primary={item.title}
          sx={{
            color: "text.white",
            fontWeight: "600",
            fontSize: "20px",
            fontFamily: "Inter",
          }}
        />
        {open ? (
          <ExpandLessIcon sx={{ color: "text.white" }} />
        ) : (
          <ExpandMoreIcon sx={{ color: "text.white" }} />
        )}
      </ListItem>
      {open && <Divider sx={{ background: "#fff" }} />}
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        style={{ marginLeft: 5 }}
      >
        <List component="div" disablePadding>
          {children.map((child, key) => (
            <MenuItem key={key} item={child} />
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

const RoleMenuItem = () => {
  const { role } = useContext(UserContext);

  const superMenuItems = supermenu.map((item, key) => (
    <MenuItem key={key} item={item} />
  ));

  const adminMenuItems = adminmenu.map((item, key) => (
    <MenuItem key={key} item={item} />
  ));

  const customerMenuItems = customermenu.map((item, key) => (
    <MenuItem key={key} item={item} sx={{ mt: 1, mb: 1 }} />
  ));

  switch (role) {
    case "SysAdmin":
      return superMenuItems;
    case "Admin":
      return adminMenuItems;
    case "Customer":
      return customerMenuItems;
    default:
      return [];
  }
};

export default RoleMenuItem;
