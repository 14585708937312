import { useContext, useState } from "react";
import { useGetCustomerPayments } from "../../../api/payment/usePayment";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../../app/contexts/user/UserContext";

export const usePaymentTables = () => {
  const navigate = useNavigate();

  const { role } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [openAdditional, setOpenAdditional] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenAdditional = () => {
    setOpenAdditional(true);
  };

  const handleCloseAdditional = () => {
    setOpenAdditional(false);
  };
  const handleRowDoubleClick = (values) => {
    navigate(
      `/${role === "SysAdmin" ? "super" : "admin"}/customer/${
        values?.customer?.id
      }`
    );
  };

  const { data, isLoading } = useGetCustomerPayments({});

  const columns = [
    {
      title: "Customer",
      field: "customerName",
    },
    {
      title: "Amount Paid",
      field: "amountPaid",
    },
    {
      title: "Total Amount",
      field: "totalAmount",
    },
    {
      title: "Remaining",
      field: "creditRemaining",
    },
  ];
  return {
    data,
    isLoading,
    columns,
    handleRowDoubleClick,
    handleOpen,
    handleClose,
    handleOpenAdditional,
    handleCloseAdditional,
    openAdditional,
    open,
  };
};
