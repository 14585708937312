import { Box, Container, Grid, Paper, Typography } from "@mui/material";

import React from "react";

const Services = () => {
  return (
    <Box
      sx={{
        background:
          "linear-gradient(0deg, rgba(0, 0, 0, 0.97), rgba(0, 0, 0, 0.27)), url(/assests/images/landing_page/2.jpg)",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container
        sx={{
          paddingTop: "7%",
          paddingBottom: "8%",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ textAlign: "center", mb: 8 }}>
            <Typography
              sx={{
                fontWeight: 800,
                fontSize: "24px",
                color: "#fff",
              }}
            >
              Our Services
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  className="service-card"
                  sx={{
                    background:
                      "linear-gradient(0deg, rgba(0, 0, 0, 0.77), rgba(0, 0, 0, 0.27)), url(/assests/images/landing_page/parcels.jpg)",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sx={{ position: "absolute", bottom: 10 }}
                    >
                      <Typography
                        className="service-title"
                        color="text.primary"
                        sx={{ fontWeight: 600 }}
                      >
                        Parcels
                      </Typography>
                      <Typography
                        className="service-content"
                        sx={{
                          pr: "8px",
                          fontSize: "14px",
                        }}
                      >
                        Transportation by postal systems, express mail
                        companies, private couriers and LTL carriers.
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  className="service-card"
                  sx={{
                    background:
                      "linear-gradient(0deg, rgba(0, 0, 0, 0.77), rgba(0, 0, 0, 0.27)), url(/assests/images/landing_page/aircargo.jpg)",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sx={{ position: "absolute", bottom: 10 }}
                    >
                      <Typography
                        className="service-title"
                        sx={{ fontWeight: 600, color: "primary.main" }}
                      >
                        Air Cargo
                      </Typography>
                      <Typography
                        className="service-content"
                        sx={{
                          color: "text.white",
                          pr: "8px",
                          fontSize: "14px",
                        }}
                      >
                        Shipment of goods via air transportation, often on
                        dedicated cargo aircraft
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  className="service-card"
                  sx={{
                    p: "25px",
                    height: "160px",
                    background:
                      "linear-gradient(0deg, rgba(0, 0, 0, 0.77), rgba(0, 0, 0, 0.27)), url(/assests/images/landing_page/seacargo.jpg)",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}></Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ position: "absolute", bottom: 10 }}
                    >
                      <Typography
                        className="service-title"
                        sx={{ fontWeight: 600, color: "primary.main" }}
                      >
                        Sea Cargo
                      </Typography>
                      <Typography
                        className="service-content"
                        sx={{
                          color: "text.white",
                          pr: "8px",
                          fontSize: "14px",
                        }}
                      >
                        Shipment of goods via sea transportation, often on cargo
                        ships or freight ships.
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  className="service-card"
                  sx={{
                    p: "25px",
                    height: "160px",
                    background:
                      "linear-gradient(0deg, rgba(0, 0, 0, 0.77), rgba(0, 0, 0, 0.27)), url(/assests/images/landing_page/priority.jpg)",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sx={{ position: "absolute", bottom: 10 }}
                    >
                      <Typography
                        className="service-title"
                        sx={{ fontWeight: 600, color: "primary.main" }}
                      >
                        Priority
                      </Typography>
                      <Typography
                        className="service-content"
                        sx={{
                          color: "text.white",
                          pr: "8px",
                          fontSize: "14px",
                        }}
                      >
                        Fast, worldwide courier service from pickup to delivery
                        for easy global shipping.
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  className="service-card"
                  sx={{
                    p: "25px",
                    height: "160px",
                    background:
                      "linear-gradient(0deg, rgba(0, 0, 0, 0.77), rgba(0, 0, 0, 0.27)), url(/assests/images/landing_page/ecommerce.jpg)",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sx={{ position: "absolute", bottom: 10 }}
                    >
                      <Typography
                        className="service-title"
                        sx={{ fontWeight: 600, color: "primary.main" }}
                      >
                        Ecommerce Logistics
                      </Typography>
                      <Typography
                        className="service-content"
                        sx={{
                          color: "text.white",
                          pr: "8px",
                          fontSize: "14px",
                        }}
                      >
                        Transportation, storage, and distribution of goods for
                        online businesses.
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  className="service-card"
                  sx={{
                    p: "25px",
                    height: "160px",
                    background:
                      "linear-gradient(0deg, rgba(0, 0, 0, 0.77), rgba(0, 0, 0, 0.27)), url(/assests/images/landing_page/warehousing.jpg)",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sx={{ position: "absolute", bottom: 10 }}
                    >
                      <Typography
                        className="service-title"
                        sx={{ fontWeight: 600, color: "primary.main" }}
                      >
                        Warehousing and Distribution
                      </Typography>
                      <Typography
                        className="service-content"
                        sx={{
                          color: "text.white",
                          pr: "8px",
                          fontSize: "14px",
                        }}
                      >
                        Storage and movement of goods for the purpose of supply
                        chain management.
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Services;
