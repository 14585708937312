import React, { useEffect } from "react";
import { useNavigate, Navigate, Outlet } from "react-router-dom";
import RateContextProvider from "../app/contexts/rates/RateContext";
import { UserProvider } from "../app/contexts/user/UserContext";
import { getUser, removeUser } from "../app/utils/cookieHelper";

const ProtectedRoute = ({ redirectTo }) => {
  const history = useNavigate();

  const { token, tempPassword } = getUser();

  useEffect(() => {
    if (!token) {
      removeUser();
      history("/login");
    } else {
      if (tempPassword) history("/temp-password");
    }
    // eslint-disable-next-line
  }, []);

  if (!token) return <Navigate exact to={redirectTo} />;

  return (
    <UserProvider>
      <RateContextProvider>
        <Outlet />
      </RateContextProvider>
    </UserProvider>
  );
};

export default ProtectedRoute;
