import { axiosInstance } from "../../../app/api/axiosInterceptor";

export const createShipmentRequestLite = async (formData) => {
  const { data } = await axiosInstance.post(
    "/v/admin/lite-shipment-request",
    formData
  );
  return data;
};

export const checkDuplicateAwb = async (awb) => {
  const { data } = await axiosInstance.post(`/v/admin/check-awb?awb=${awb}`);
  return data;
};

export const getShipmentRequestLite = async ({ requestDate }) => {
  const { data } = await axiosInstance.get(
    `/v/admin/lite-shipment-requests?requestDate=${requestDate}`
  );
  return data;
};

export const getShipmentRequestLiteDetail = async (id) => {
  if (id) {
    const { data } = await axiosInstance.get(
      `/v/admin/lite-shipment-requests?id=${id}`
    );
    return data;
  }
};

export const updateShipmentRequestLite = async (formData) => {
  const { data } = await axiosInstance.put(
    "/v/admin/lite-shipment-request",
    formData
  );
  return data;
};

export const getConfirmedShipmentRequestLite = async ({
  status,
  requestDateFrom,
  requestDateTo,
}) => {
  const { data } = await axiosInstance.get(
    `/v/admin/confirmed-shipments?requestDateFrom=${requestDateFrom}&requestDateTo=${requestDateTo}&status=${status}`
  );
  return data;
};

export const getConfirmedShipmentRequestLiteDetail = async (id) => {
  if (id) {
    const { data } = await axiosInstance.get(
      `/v/admin/confirmed-shipments?id=${id}`
    );
    return data;
  }
};

export const updateConfirmedShipmentRequestLite = async (formData) => {
  const { data } = await axiosInstance.put(
    `/v/admin/lite-confirmed-shipment?sendEmail=${formData?.sendEmail}`,
    formData
  );
  return data;
};

export const confirmShipmentRequestLite = async (id) => {
  if (id) {
    const { data } = await axiosInstance.put(
      `/v/admin/lite-shipment-request/confirm?shipmentRequestIds=${id}`
    );
    return data;
  }
};

export const addShipmentBilling = async (formData) => {
  const { data } = await axiosInstance.post(
    "/v/admin/shipment/billing",
    formData
  );
  return data;
};

export const importProductsFromFile = async ({ file }) => {
  const fileData = new FormData();
  fileData.append("file", file);
  const { data } = await axiosInstance.post(
    `/v/admin/products/import-xls`,
    fileData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return data;
};

export const getBilledShipmentRequestLite = async ({
  status,
  requestDateFrom,
  requestDateTo,
}) => {
  const { data } = await axiosInstance.get(
    `/v/admin/billed-shipments?requestDateFrom=${requestDateFrom}&requestDateTo=${requestDateTo}&status=${status}`
  );
  return data;
};

export const getBilledShipmentRequestLiteDetail = async (id) => {
  if (id) {
    const { data } = await axiosInstance.get(
      `/v/admin/billed-shipments?id=${id}`
    );
    return data;
  }
};

export const getFinalShipments = async ({ filter, searchValue }) => {
  if (searchValue?.length > 2) {
    const { data } = await axiosInstance(
      `/v/admin/shipments/search?str=${searchValue}`
    );
    return data;
  } else {
    let link = `/v/admin/final-shipments?requestDateFrom=${filter?.requestDateFrom}&requestDateTo=${filter?.requestDateTo}`;
    const { data } = await axiosInstance.get(link);
    return data;
  }
};

export const getFinalShipmentDetail = async (id) => {
  if (id) {
    const { data } = await axiosInstance.get(
      `/v/admin/final-shipments?id=${id}`
    );
    return data;
  }
};

export const billShipment = async (id) => {
  if (id) {
    const { data } = await axiosInstance.put(
      `/v/admin/shipment/bill?shipmentIds=${id}`
    );
    return data;
  }
};

export const verifyWeight = async (id) => {
  if (id) {
    const { data } = await axiosInstance.put(
      `/v/admin/shipments/verify-weight?shipmentId=${id}`
    );
    return data;
  }
};

export const updateShipmentStatus = async (values) => {
  const { data } = await axiosInstance.put("/v/admin/shipments/status", values);
  return data;
};

export const updateWeight = async (values) => {
  const { data } = await axiosInstance.put("/v/admin/shipments/weight", values);
  return data;
};
