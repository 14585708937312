import React from "react";

import CustomTable from "../../../../../app/component/table/CustomTable";
import useMyShipmentTable from "../../../../hooks/components/ship/table/useMyShipmentTable";

const MyShipmentTable = () => {
  const { data, column, isLoading } = useMyShipmentTable({});

  return (
    <div>
      <CustomTable
        title="My Shipments"
        data={data}
        columns={column}
        isLoading={isLoading}
      />
    </div>
  );
};

export default MyShipmentTable;
