import { Autocomplete, Grid, MenuItem, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

const ShipmentTableFilters = ({
  filter,
  handleChange,
  uiFilter,
  handleUiFilter,
  statusOptions,
  billedOptions,
  rateOptions,
  weightVerifiedOptions,
  paymentTypeOptions,
  serviceOptions,
  shipmentTypeOptions,
  handleAutoCompleteFilter,
  customerMemoizedGetOptionLabel,
  customerMemoizedOptions,
  serviceProviderMemoizedGetOptionLabel,
  serviceProviderMemoizedOptions,
  billTypeOptions,
}) => {
  const initialShipper = customerMemoizedOptions?.filter((customer) => {
    return customer?.id === uiFilter?.shipper;
  })[0];

  const initialServiceProvider =
    serviceProviderMemoizedOptions?.filter((serviceProvider) => {
      return serviceProvider?.value === uiFilter?.serviceProvider;
    })[0] || null;

  const [shipper, setShipper] = useState({
    shipperId: null,
    shipperInput: "",
  });

  const [serviceProvider, setServiceProvider] = useState({
    serviceProviderId: null,
    serviceProviderInput: "",
  });

  const handleSetInitialShipper = useCallback(() => {
    if (initialShipper) {
      setShipper({
        shipperId: initialShipper,
        shipperInput: initialShipper?.label,
      });
    }
  }, [initialShipper]);

  const handleSetInitialServiceProvider = useCallback(() => {
    if (initialServiceProvider) {
      setServiceProvider({
        serviceProviderId: initialServiceProvider,
        serviceProviderInput: initialServiceProvider?.label,
      });
    }
  }, [initialServiceProvider]);

  useEffect(() => {
    handleSetInitialShipper();
  }, [handleSetInitialShipper]);

  useEffect(() => {
    handleSetInitialServiceProvider();
  }, [handleSetInitialServiceProvider]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={3}>
        <TextField
          size="small"
          margin="dense"
          type="date"
          InputLabelProps={{ shrink: true }}
          name="requestDateFrom"
          label="From"
          value={filter?.requestDateFrom}
          InputProps={{ inputProps: { max: filter?.requestDateTo } }}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField
          size="small"
          margin="dense"
          type="date"
          InputLabelProps={{ shrink: true }}
          name="requestDateTo"
          label="To"
          value={filter?.requestDateTo}
          InputProps={{ inputProps: { min: filter?.requestDateFrom } }}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField
          size="small"
          margin="dense"
          select
          name="status"
          label="Status"
          value={uiFilter.status}
          onChange={handleUiFilter}
          fullWidth
        >
          {statusOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4} md={3}>
            <TextField
              size="small"
              margin="dense"
              select
              name="rate"
              label="Rate"
              value={uiFilter.rate}
              onChange={handleUiFilter}
              fullWidth
            >
              {rateOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4} md={3}>
            <TextField
              size="small"
              margin="dense"
              select
              name="billType"
              label="Bill Type"
              value={uiFilter.billType}
              onChange={handleUiFilter}
              fullWidth
            >
              {billTypeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4} md={3}>
            <TextField
              size="small"
              margin="dense"
              select
              name="weightVerified"
              label="Weight Verified"
              value={uiFilter.weightVerified}
              onChange={handleUiFilter}
              fullWidth
            >
              {weightVerifiedOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4} md={3}>
            <TextField
              size="small"
              margin="dense"
              select
              name="paymentType"
              label="Payment Type"
              value={uiFilter.paymentType}
              onChange={handleUiFilter}
              fullWidth
            >
              {paymentTypeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4} md={3}>
            <TextField
              size="small"
              margin="dense"
              select
              name="service"
              label="Service"
              value={uiFilter.service}
              onChange={handleUiFilter}
              fullWidth
            >
              {serviceOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4} md={3}>
            <TextField
              size="small"
              margin="dense"
              select
              name="shipmentType"
              label="Shipment Type"
              value={uiFilter.shipmentType}
              onChange={handleUiFilter}
              fullWidth
            >
              {shipmentTypeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4} md={3}>
            <Autocomplete
              name="shipper"
              value={shipper?.shipperId}
              onChange={(_event, newValue) => {
                setShipper({ ...shipper, shipperId: newValue });
                handleAutoCompleteFilter({
                  name: "SHIPPER",
                  value: newValue?.id || null,
                });
              }}
              options={customerMemoizedOptions || []}
              getOptionLabel={customerMemoizedGetOptionLabel}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              inputValue={shipper?.shipperInput}
              onInputChange={(_event, newInputValue) => {
                setShipper({ ...shipper, shipperInput: newInputValue });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  margin="dense"
                  label="Shipper"
                />
              )}
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <Autocomplete
              value={serviceProvider?.serviceProviderId}
              name="serviceProvider"
              onChange={(_event, newValue) => {
                setServiceProvider({
                  ...serviceProvider,
                  serviceProviderId: newValue,
                });
                handleAutoCompleteFilter({
                  name: "SERVICE_PROVIDER",
                  value: newValue?.value || null,
                });
              }}
              options={serviceProviderMemoizedOptions || []}
              getOptionLabel={serviceProviderMemoizedGetOptionLabel}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              inputValue={serviceProvider?.serviceProviderInput}
              onInputChange={(_event, newInputValue) => {
                setServiceProvider({
                  ...serviceProvider,
                  serviceProviderInput: newInputValue,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  margin="dense"
                  label="Service Provider"
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ShipmentTableFilters;
