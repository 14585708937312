import { Divider, Grid, Paper, Typography } from "@mui/material";
import moment from "moment";
import React from "react";

const SystemDetails = ({
  createdBy,
  editedBy,
  createdDatetime,
  editedDatetime,
}) => {
  return (
    <Paper
      elevation={4}
      sx={{
        ml: { sm: 1, md: 2 },
        mr: { sm: 1, md: 2 },
        mt: 2,
        mb: 1,
        p: "16px",
      }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={9}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "24px",
            }}>
            System Details
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">Created By :</Typography>
          <Typography>{createdBy || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">Created date/time :</Typography>
          <Typography>
            {createdDatetime ? moment(createdDatetime).format("lll") : "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">Edited By :</Typography>
          <Typography>{editedBy || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">Edited date/time :</Typography>
          <Typography>
            {editedDatetime ? moment(editedDatetime).format("lll") : "-"}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SystemDetails;
