import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import React from "react";
import { useBuyingPriceForm } from "../../../hooks/components/form/shipments/buying_price/useBuyingPriceForm";
import { Box } from "@material-ui/core";

const BuyingPriceForm = ({ id, open, handleClose }) => {
  const { formik } = useBuyingPriceForm({ id, handleClose });
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>Add Buying Price</DialogTitle>
      <Divider />
      <DialogContent>
        <Box component="form" form={formik} noValidate sx={{ mt: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="buyingPrice"
                id="buyingPrice"
                label="Buying Price"
                type="number"
                value={formik.values.buyingPrice}
                onChange={formik.handleChange}
                error={
                  formik.touched.buyingPrice &&
                  Boolean(formik.errors.buyingPrice)
                }
                helperText={
                  formik.touched.buyingPrice && formik.errors.buyingPrice
                }
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button sx={{ textTransform: "none" }} onClick={() => handleClose()}>
          Cancel
        </Button>
        <Button
          sx={{ textTransform: "none" }}
          variant="contained"
          onClick={() => formik.handleSubmit()}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BuyingPriceForm;
