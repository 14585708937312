import React from "react";
import OfficeNoticesTable from "../../components/table/office_notices/OfficeNoticesTable";

const OfficeNotices = () => {
  return (
    <div>
      <OfficeNoticesTable />
    </div>
  );
};

export default OfficeNotices;
