import * as Yup from "yup";

export const customerPickupValidationSchema = Yup.object().shape({
  cnNumber: Yup.string().required("Required"),
  dateTime: Yup.string().required("Required"),
  phoneNumber: Yup.string().required("Required"),
  pickupAddress: Yup.string().required("Required"),
  remarks: Yup.string().required("Required"),
  vehicle: Yup.string().required("Required"),
});
