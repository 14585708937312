import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const SeaCargo = () => {
  return (
    <Grid container spacing={0}>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: { xs: "300px", md: "600px" },
          padding: "16px",
        }}
      >
        <Box sx={{ maxWidth: "600px" }}>
          <Typography sx={{ fontWeight: 800, fontSize: "30px" }}>
            Sea Cargo
          </Typography>
          <Typography xsx={{ fontSize: "14px" }}>
            If time is not the major concern and cargo is large and heavy, our
            ocean freight service definitely befits you.Our network covers all
            the major ports in the world. We also serve you with mix of road,
            air and sea transportation services. Delivery and pick up facilities
            also available.Our experienced professionals will guide your goods
            through the complexities of global shipping. We can offer a
            single-source solution to and from any point worldwide and keep you
            informed of your shipment status at every step. We offer a full
            array of ocean related services with documentation; pick up, custom
            clearing, delivery. We handle less-than-container load,
            full-container load, break bulks and all the options. We serve you
            with our door-to-door, port-to-port or any combination.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <img
          src="/assests/images/landing_page/seacargo.jpg"
          alt="aircargo"
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default SeaCargo;
