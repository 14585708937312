import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useUpdateWeightForm } from "../../../hooks/components/form/shipments/weight/useUpdateWeightForm";

const UpdateWeightForm = ({ open, handleClose, data }) => {
  const { formik } = useUpdateWeightForm({ handleClose, data });
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Update Weight</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box form={formik} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                margin="dense"
                required
                name="actualWeight"
                label="Actual Weight"
                value={formik.values.actualWeight}
                onChange={formik.handleChange}
                error={
                  formik.touched.actualWeight &&
                  Boolean(formik.errors.actualWeight)
                }
                helperText={
                  formik.touched.actualWeight && formik.errors.actualWeight
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                margin="dense"
                required
                name="dimensionalWeight"
                label="Dimensional Weight"
                value={formik.values.dimensionalWeight}
                onChange={formik.handleChange}
                error={
                  formik.touched.dimensionalWeight &&
                  Boolean(formik.errors.dimensionalWeight)
                }
                helperText={
                  formik.touched.dimensionalWeight &&
                  formik.errors.dimensionalWeight
                }
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => handleClose()} sx={{ textTransform: "none" }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={() => formik.handleSubmit()}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateWeightForm;
