import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import React from "react";
import { useSendOfficeNoticeForm } from "../../../hooks/components/form/office_notices/useSendOfficeNoticeForm";
import SendOfficeNoticeFormItem from "./items/SendOfficeNoticeFormItem";

const SendOfficeNoticeForm = ({ open, handleClose }) => {
  const { formik } = useSendOfficeNoticeForm({});
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>Send Office Notice</DialogTitle>
      <Divider />
      <DialogContent>
        <SendOfficeNoticeFormItem formik={formik} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button
          onClick={() => {
            formik.handleSubmit();
            handleClose();
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendOfficeNoticeForm;
