import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomerInfoConfigTable from "../../components/customer-config/table/CustomerInfoConfigTable";

const CustomerInfoConfig = () => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography sx={{ width: "70%" }}>Customer Info Config</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <CustomerInfoConfigTable />
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomerInfoConfig;
