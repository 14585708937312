import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

const AboutHero = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        background:
          "linear-gradient(0deg, rgba(0, 0, 0, 0.97), rgba(0, 0, 0, 0.27)), url(/assests/images/landing_page/about1.jpg)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container
        sx={{
          paddingTop: { xs: "100px", md: 0 },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "90vh",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              className="tracking-in-expand-fwd-bottom"
              sx={{
                pr: 1,
                color: "primary.main",
                textAlign: "left",
                fontWeight: 800,
                fontSize: "30px",
                lineHeight: "26px",
              }}
            >
              Get To Know Us
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                pr: 1,
                color: "#fff",
                textAlign: "left",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20px",
              }}
            >
              Years of Expertise in Serving the Dynamic
            </Typography>
            <Typography
              sx={{
                pr: 1,
                color: "#fff",
                textAlign: "left",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20px",
              }}
            >
              need of On Demand Cargo and Courier Service.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AboutHero;
