import {
  useGetCustomers,
  useIssueTempPassword,
} from "../../../api/customer/useCustomer";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Chip } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { toast } from "react-toastify";

export const useCustomerTable = () => {
  const [categoryFilter, setCategoryFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const { data, isLoading,refetch } = useGetCustomers(categoryFilter, typeFilter);
  const { mutate } = useIssueTempPassword({});

  const handleCategoryFilter = (event) => {
    setCategoryFilter(event.target.value);
  };

  const handleTypeFilter = (event) => {
    setTypeFilter(event.target.value);
  };

  function copyEmail(data) {
    let emailList = "";
    data.forEach((customer, index) => {
      emailList =
        data?.length - 1 > index
          ? emailList.concat(`${customer?.email}, `)
          : emailList.concat(customer?.email);
    });
    navigator.clipboard.writeText(emailList);
    toast.success("Copied Email");
  }

  function issuePassword(data) {
    let ids = data?.map((item) => item?.id);
    window.confirm("issue password")
      ? mutate(ids)
      : toast.warn("password wasnt sent");
  }

  const customEditDetails = {
    icon: () => <PersonRemoveIcon />,
    tooltip: "Suspend customer",
  };
  const column = [
    {
      title: "S.No",
      field: "id",
      render: (rowData) => data?.findIndex((x) => x?.id === rowData?.id) + 1,
    },
    {
      title: "Customer Id",
      field: "customerId",
    },
    {
      title: "Company Name",
      field: "companyName",
      render: (rowData) => (
        <Link to={`${rowData?.id}`}>{rowData?.companyName || "-"}</Link>
      ),
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Contact No.",
      field: "mobile",
    },
    {
      title: "Type",
      field: "customerType",
    },
    {
      title: "Category",
      field: "customerCategory",
    },
    {
      title: "Suspended",
      field: "suspended",
      render: (rowData) => {
        return rowData?.suspended ? (
          <Chip color="error" icon={<ClearIcon />} label="Suspended" />
        ) : (
          <Chip color="info" icon={<DoneIcon />} label="user" />
        );
      },
    },
  ];
  return {
    data,
    isLoading,
    column,
    customEditDetails,
    categoryFilter,
    typeFilter,
    handleCategoryFilter,
    handleTypeFilter,
    copyEmail,
    issuePassword,
    refetch
  };
};
